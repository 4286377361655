import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { ShipmentEventsService } from "../../../services/shipment/shipmentEvents.ts";
import { StockReleasesService } from "../../../services/shipment/stockReleases.ts";
import { ShipmentsService } from "../../../services/shipment/shipments.ts";
import { callErrorToast } from "../../../utilities";
import { ShipmentEvent } from "../../../Models/ShipmentEvent";
import { defaultFetchParam } from "../../../Models/FetchParam.ts";
import {
  converStockReleaseShipmentInShipmentEvent,
  StockReleaseShipment,
} from "../../../Models/StockReleaseShipment.ts";
import { Shipment } from "../../../Models/Shipment.ts";
import { toast } from "react-toastify";
import { useAppContext } from "../../../AppProvider.jsx";

const shipmentEventsService = new ShipmentEventsService();
const stockReleasesService = new StockReleasesService();
const shipmentsService = new ShipmentsService();

interface TrackingProviderProps {
  children: ReactNode;
  callback?: () => void;
  shipment: Shipment;
}

interface TrackingContextValue {
  isOpen: boolean;
  data: ShipmentEvent[];
  loader: boolean;
  getData: (isOpen: boolean) => void;
  retracking: () => void;
  shipment: Shipment;
}

const TrackingContext = createContext<TrackingContextValue | undefined>(
  undefined
);

const TrackingProvider: React.FC<TrackingProviderProps> = ({
  children,
  shipment,
}) => {
  const { dictionary } = useAppContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<ShipmentEvent[]>([]);
  const [error, setError] = useState<unknown>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const getStockReleases = async (): Promise<any> => {
    const response = await stockReleasesService.all(
      {
        ...defaultFetchParam,
        pagination: { size: 50, page: 0 },
      },
      shipment.internalReference
    );
    return response.data;
  };

  const getShipmentEvents = async (): Promise<any> => {
    const response = await shipmentEventsService.all({
      ...defaultFetchParam,
      pagination: { size: 50, page: 0 },
      search: [{ selector: "shipmentId", value: shipment.id }],
    });

    return response.data;
  };

  const getEvents = (isOpen: boolean): void => {
    setIsOpen(isOpen);
    setLoader(true);
    if (isOpen) {
      Promise.all([getShipmentEvents(), getStockReleases()])
        .then(([shipmentEvents, stockReleases]) => {
          const stockReleaseEvents = (stockReleases.content || []).map(
            (x: StockReleaseShipment) =>
              converStockReleaseShipmentInShipmentEvent(x, shipment.id)
          );

          const events: ShipmentEvent[] = [
            ...(shipmentEvents.content || []),
            ...stockReleaseEvents,
          ].sort(
            (a, b) =>
              new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime()
          );
          setData(events);
          setLoader(false);
        })
        .catch((error) => {
          setError(error);
          setLoader(false);
        });
      return;
    }
    setData([]);
  };

  const retracking = (): void => {
    setLoader(true);
    shipmentsService
      .forceTracking(shipment.id)
      .then(() => {
        toast.success(dictionary.messages.shipment_tracked_successfully);
        getEvents(true);
      })
      .catch((error) => {
        setError(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (error) {
      callErrorToast(error);
    }
  }, [error]);

  return (
    <TrackingContext.Provider
      value={{
        isOpen,
        data,
        loader,
        getData: getEvents,
        retracking,
        shipment,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

const useTrackingContext = (): TrackingContextValue => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error(
      "useTrackingContext must be used within an TrackingProvider"
    );
  }
  return context;
};

export { TrackingProvider, useTrackingContext };
