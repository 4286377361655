import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLoader,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";

export const ButtonDelete = ({
  children,
  className = "",
  onClick,
  disabled = false,
  loading = false,
}) => {
  const { dictionary } = useAppContext();
  return (
    <button
      className={`button-1 button-1-danger ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled || loading}
      title={dictionary.actions.delete}
    >
      {loading ? (
        <FontAwesomeIcon icon={faLoader} spin />
      ) : (
        <>
          <FontAwesomeIcon icon={faTrash} />
          {children || dictionary.actions.delete}
        </>
      )}
    </button>
  );
};
