import React, { useState } from "react";
import { Button } from "reactstrap";
import { UsersService } from "../../services/user/users";
import { toast } from "react-toastify";
import InputBox from "../../Components/common/InputBox";
import { callErrorToast, checkErrors, valueIsEmpty } from "../../utilities";
import { dictionary } from "../../utilities/dictionary";
import { Loader } from "../../Components/common/Loader";
import { useAppContext } from "../../AppProvider";

const usersService = new UsersService();

const ResetPassword = ({ userId, modalToogle, refreshTable }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const { dictionary } = useAppContext();

  const resetPassword = () => {
    usersService
      .resetPassword(userId, { password })
      .then((response) => {
        refreshTable();
        modalToogle();

        toast.success(dictionary.users.password_reset_successfully, {
          position: toast.POSITION.TOP_CENTER,
        });

        setLoading(false);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const passwordValidation = () => {
    let errors = {};

    if (valueIsEmpty(password))
      errors["password"] = dictionary.messages.mandatory_field;
    if (valueIsEmpty(confirmPassword))
      errors["confirmPassword"] = dictionary.messages.mandatory_field;
    if (
      !valueIsEmpty(password) &&
      !valueIsEmpty(confirmPassword) &&
      password !== confirmPassword
    )
      errors["confirmPassword"] = dictionary.messages.the_values_do_not_match;

    return errors;
  };

  const savePassword = () => {
    const errors = passwordValidation();
    if (valueIsEmpty(errors)) {
      setLoading(true);
      resetPassword();
    }

    setErrors(errors);
  };

  return (
    <React.Fragment>
      {loading ? <Loader show={loading} /> : ""}

      <div className="row">
        <div className="col">
          <label className="form-label">{dictionary.users.password}:</label>
          <InputBox
            name="password"
            value={password}
            type="password"
            onChange={(event) => {
              setPassword(event.target.value);
              checkErrors(event.target.name, errors, setErrors);
            }}
            error={errors && errors["password"]}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <label className="form-label">
            {dictionary.users.confirm_password}:
          </label>
          <InputBox
            name="confirmPassword"
            value={confirmPassword}
            type="password"
            onChange={(event) => {
              setConfirmPassword(event.target.value);
              checkErrors(event.target.name, errors, setErrors);
            }}
            error={errors && errors["confirmPassword"]}
          />
        </div>
      </div>
      <div className="row mt-3 align-items-center">
        <div className="col">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                modalToogle();
                refreshTable();
              }}
            >
              {dictionary.actions.cancel}
            </button>
            <Button type="button" className="btn ms-3" onClick={savePassword}>
              {dictionary.actions.save}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ResetPassword;
