import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, forwardRef } from "react";
import { Input, FormFeedback, InputProps } from "reactstrap";

/**
 * List of valid input types supported by the component.
 */
const inputTypes = [
  "text",
  "textarea",
  "password",
  "email",
  "number",
  "radio",
  "date",
  "url",
  "file",
  "color",
] as const;

/**
 * Props interface for the InputBox component.
 */
export interface InputBoxProps {
  value?: string | number;
  name: string;
  type?:
    | (typeof inputTypes)[number]
    | "positive-number"
    | "positive-integer-number";
  placeholder?: string;
  className?: string;
  position?: string;
  onChange?: (event: {
    target: { name: string; value: string | number; dataId?: string };
  }) => void;
  readOnly?: boolean;
  disabled?: boolean;
  error?: string;
  isValid?: string | null | undefined;
  min?: number;
  max?: number;
  precision?: number;
  dataId?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  width?: string;
  required?: boolean;
}

const InputBox = forwardRef<HTMLInputElement, InputBoxProps>(
  (
    {
      value,
      name,
      type = "text",
      placeholder,
      className = "",
      onChange,
      readOnly = false,
      disabled = false,
      error = "",
      isValid,
      min,
      max,
      precision = 2,
      dataId,
      onKeyDown,
      width = "100%",
      required = false,
    },
    ref
  ) => {
    /** State to manage the displayed input value */
    const [inputValue, setInputValue] = useState<string>("");

    /** Ensures the number is within the min/max range */
    const checkNumber = (val: string): string => {
      const numValue = parseFloat(val);
      if (isNaN(numValue)) return "";
      if (min !== undefined && numValue < min)
        return required ? min.toString() : "";
      if (max !== undefined && numValue > max) return max.toString();
      return val;
    };

    /** Ensures the string is within the character limits */
    const checkString = (val: string): string => {
      if (min !== undefined && max !== undefined)
        return val.substring(min, max);
      if (min !== undefined) return val.substring(min);
      if (max !== undefined) return val.substring(0, max);
      return val;
    };

    /** Handles input changes and validates values */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        const { value } = event.target;
        let regex: RegExp | undefined;

        switch (type) {
          case "positive-number":
            regex = /^(?:\d+(?:[.,]\d*)?|)$/;
            break;
          case "positive-integer-number":
            regex = /^(?:[0-9]\d*|)$/;
            break;
        }

        if (regex && !regex.test(value)) return;
        setInputValue(value);
      }
    };

    /** Handles input blur and ensures correct formatting */
    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (!disabled) {
        let { value } = event.target;

        switch (type) {
          case "number":
          case "positive-number":
            value = value.replaceAll(",", ".").replace(/[.,]$/, "");
            value = checkNumber(value);
            if (value) value = parseFloat(value).toFixed(precision);
            break;
          case "positive-integer-number":
            value = checkNumber(value);
            break;
          case "text":
            value = checkString(value);
            break;
        }

        setInputValue(value);
        if (onChange) {
          onChange({ target: { name, value, dataId } });
        }
      }
    };

    /** Updates the input state when value prop changes */
    useEffect(() => {
      if (
        (type === "positive-number" || type === "number") &&
        value !== undefined
      ) {
        setInputValue(parseFloat(String(value)).toFixed(precision));
      } else {
        setInputValue(String(value ?? ""));
      }
    }, [value, precision, type]);

    return (
      <>
        <Input
          type={
            ["positive-number", "positive-integer-number"].includes(type)
              ? "text"
              : (type as InputProps["type"])
          }
          name={name}
          className={`form-control ${className}`}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          readOnly={readOnly}
          disabled={disabled}
          invalid={!!error}
          valid={isValid === "true" || undefined}
          innerRef={ref}
          onKeyDown={onKeyDown}
          style={{ width }}
        />
        {error && <FormFeedback type="invalid">{error}</FormFeedback>}
      </>
    );
  }
);

export default InputBox;
