import { LogisticIntegrationsService } from "../../../services/logistic/logisticIntegrations.ts"; // Service for logistic integrations
import { ArticlesService } from "../../../services/logistic/articles.ts"; // Service for articles
import { CarriersService } from "../../../services/registry/carriers.ts";
import { FetchParam } from "../../../Models/FetchParam"; // Defines fetch parameters structure
import { prepareFetchParam } from "../../../utilities/api"; // Utility function to prepare fetch parameters
import { callErrorToast } from "../../../utilities"; // Utility to show error toasts

const logisticIntegrationsService = new LogisticIntegrationsService(); // Instantiate the logistic integration service
const articlesService = new ArticlesService(); // Instantiate the articles service
const carriersService = new CarriersService(); // Instantiate the carriers service

/**
 * Fetches all logistic integrations based on the provided search parameters.
 * It prepares the necessary filters before making the API request.
 *
 * @param {FetchParam} searchParams - The search parameters including input value, pagination, sorting, and optional search filters.
 * @returns {Promise<any>} - A promise resolving with the response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getAllLogisticIntegrations = async (searchParams: FetchParam) => {
  try {
    // Prepare the search parameters by processing the input values, filters, and pagination
    const filters = prepareFetchParam(searchParams);

    // Make the API call to get all logistic integrations with the prepared filters
    const response = await logisticIntegrationsService.all(filters);

    // Return the response data
    return response;
  } catch (err) {
    // If there's an error, display an error toast and rethrow the error
    callErrorToast(err);
    throw err;
  }
};

/**
 * Fetches all articles based on the provided search parameters.
 * It prepares the necessary filters before making the API request.
 *
 * @param {FetchParam} searchParams - The search parameters including input value, pagination, sorting, and optional search filters.
 * @returns {Promise<any>} - A promise resolving with the response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getAllArticles = async (searchParams: FetchParam) => {
  try {
    // Prepare the search parameters by processing the input values, filters, and pagination
    const filters = prepareFetchParam(searchParams);

    // Make the API call to get all articles with the prepared filters
    const response = await articlesService.all(filters);

    // Return the response data
    return response;
  } catch (err) {
    // If there's an error, display an error toast and rethrow the error
    callErrorToast(err);
    throw err;
  }
};

/**
 * Fetches all articles based on the provided search parameters.
 * It prepares the necessary filters before making the API request.
 *
 * @param {string} carrierCode -
 * @returns {Promise<any>} - A promise resolving with the response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const getStockReleasesByCarrier = async (carrierCode: string) => {
  try {
    // Make the API call to get all articles with the prepared filters
    const response = await carriersService.getStockReleases(carrierCode);

    // Return the response data
    return response;
  } catch (err) {
    // If there's an error, display an error toast and rethrow the error
    callErrorToast(err);
    throw err;
  }
};
