import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { createModalConfig, ModalConfig } from "../../../Models/ModalConfig";
import { callErrorToast } from "../../../utilities";

interface IndexArticlesProviderProps {
  children: ReactNode;
  callback?: () => void;
}

interface IndexArticlesContextValue {
  modalConfig: ModalConfig;
  setModalConfig: Dispatch<SetStateAction<ModalConfig>>;
  callback?: () => void;
}

const IndexArticlesContext = createContext<
  IndexArticlesContextValue | undefined
>(undefined);

const IndexArticlesProvider: React.FC<IndexArticlesProviderProps> = ({
  children,
  callback,
}) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>(
    createModalConfig()
  );

  useEffect(() => {
    console.log("dentro");
  }, []);

  return (
    <IndexArticlesContext.Provider
      value={{ modalConfig, setModalConfig, callback }}
    >
      {children}
    </IndexArticlesContext.Provider>
  );
};

const useIndexArticlesContext = (): IndexArticlesContextValue => {
  const context = useContext(IndexArticlesContext);
  if (!context) {
    throw new Error(
      "useIndexArticlesContext must be used within an IndexArticlesProvider"
    );
  }
  return context;
};

export { IndexArticlesProvider, useIndexArticlesContext };
