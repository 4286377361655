import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../../../AppProvider";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";
import "flatpickr/dist/l10n/fr";

export const DatePickerFilter = ({
  filter,
  value,
  onChange,
  className = "",
  isDisabled = false,
}) => {
  const { dictionary, language } = useAppContext();
  const ref = useRef(null);

  const datePeriods = {
    today: [moment().startOf("day").toDate(), moment().endOf("day").toDate()],
    yesterday: [
      moment().subtract(1, "days").startOf("day").toDate(),
      moment().subtract(1, "days").endOf("day").toDate(),
    ],
    "current-month": [
      moment().startOf("month").toDate(),
      moment().endOf("day").toDate(),
    ],
    "previous-month": [
      moment().subtract(1, "months").startOf("month").toDate(),
      moment().subtract(1, "months").endOf("month").toDate(),
    ],
    "current-quarter": [
      moment().startOf("quarter").toDate(),
      moment().endOf("day").toDate(),
    ],
    "last-30-days": [
      moment().subtract(1, "months").startOf("day").toDate(),
      moment().endOf("day").toDate(),
    ],
  };

  // Funzione per creare i pulsanti di selezione rapida
  const createButton = (text, periodKey) => {
    const button = document.createElement("button");
    button.className = "button-label-1 text-white m-1 custom-button";
    button.textContent = text;
    button.addEventListener("click", () => onChangeDefaultDate(periodKey));
    return button;
  };

  // Funzione chiamata quando il calendario è pronto
  const onReady = (selectedDates, dateStr, instance) => {
    const calendarInstance =
      instance.calendarContainer.children[1].querySelector(
        ".flatpickr-rContainer"
      );
    appendButtons(calendarInstance);
  };

  const appendButtons = (calendarInstance) => {
    const customButtons = calendarInstance.querySelectorAll(".custom-button");
    customButtons.forEach((el) => el.parentNode.removeChild(el));

    const buttons = [
      { text: dictionary.times.today, period: "today" },
      { text: dictionary.times.yesterday, period: "yesterday" },
      { text: dictionary.times.current_month, period: "current-month" },
      {
        text: dictionary.times.previous_month,
        period: "previous-month",
      },
      {
        text: dictionary.times.current_quarter,
        period: "current-quarter",
      },
      { text: dictionary.times.last_30_days, period: "last-30-days" },
    ];

    buttons.forEach(({ text, period }) =>
      calendarInstance.appendChild(createButton(text, period))
    );
  };

  // Funzione per cambiare la data di default
  const onChangeDefaultDate = (period) => {
    const periodArray = datePeriods[period];
    if (ref.current && ref.current.flatpickr) {
      const instance = ref.current.flatpickr;
      onChange(ref.current.props.name, periodArray);
      instance.setDate(periodArray);
      instance.close();
    }
  };

  // Imposta il range degli ultimi 30 giorni se non è impostato alcun valore
  useEffect(() => {
    if (ref.current && ref.current.flatpickr) {
      const instance = ref.current.flatpickr;

      // Se non è presente un valore o il valore è vuoto, setta gli ultimi 30 giorni
      if (!value || value.length === 0) {
        instance.setDate(filter.value || null);
      } else {
        instance.setDate(value); // Imposta le date se il valore è già presente
      }
    }
  }, [value]);

  useEffect(() => {
    if (ref.current.flatpickr) {
      appendButtons(ref.current.flatpickr.calendarContainer);
    }
  }, [dictionary]);

  return (
    <Flatpickr
      disabled={isDisabled}
      placeholder={dictionary.actions.select}
      value={value}
      ref={ref}
      name={filter?.fieldName}
      className={className}
      options={{
        mode: "range",
        dateFormat: "d-m-Y",
        locale: language,
        onClose: (selectedDates) => {
          if (selectedDates.length < 2) {
            onChange(ref.current.props.name, filter?.value || null);
            ref.current.flatpickr.setDate(filter?.value || null);
          }
        },
        static: true,
      }}
      onChange={(selectedDates) => {
        if (selectedDates.length === 2) {
          onChange(filter?.fieldName, selectedDates, true);
        }
      }}
    />
  );
};
