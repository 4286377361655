import React, { createContext, useContext, useEffect, useState } from "react";

import { OrderDetailsService } from "../../../services/logistic/orderDetails";
import { callErrorToast } from "../../../utilities";
import { dictionary } from "../../../utilities/dictionary";

const DeleteOrderDetailContext = createContext();

const orderDetailsService = new OrderDetailsService();

const DeleteOrderDetailProvider = ({ children, data, callback }) => {
  const [orderDetails, setOrderDetails] = useState(data);
  const [orderDetailsError, setOrderDetailsError] = useState(null);
  const [orderDetailsLoader, setOrderDetailsLoader] = useState(false);

  const deleteOrderDetails = () => {
    setOrderDetailsLoader(true);
    const promises = orderDetails.map((elem) => {
      return orderDetailsService.delete(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setOrderDetailsLoader(false);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setOrderDetailsError(err);
      });
  };

  useEffect(() => {
    if (orderDetailsError) {
      callErrorToast(orderDetailsError, dictionary);
    }
  }, [orderDetailsError]);

  return (
    <DeleteOrderDetailContext.Provider
      value={{
        orderDetails,
        orderDetailsError,
        orderDetailsLoader,
        deleteOrderDetails,

        callback,
      }}
    >
      {children}
    </DeleteOrderDetailContext.Provider>
  );
};

const useDeleteOrderDetailContext = () => {
  return useContext(DeleteOrderDetailContext);
};

export { DeleteOrderDetailProvider, useDeleteOrderDetailContext };
