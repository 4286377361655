import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useAppContext } from "../../AppProvider";
import { useTypes } from "../../utilities/types";

const LanguageDropdown = ({ classNameTextColor = "fw-light" }) => {
  const { onChangeLanguage, language } = useAppContext();
  const { languages } = useTypes();
  const [isDropdown, setIsDropdown] = useState(false);

  const toggleDropdown = () => {
    setIsDropdown(!isDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isDropdown}
        toggle={toggleDropdown}
        className="nav-item nav-item-dropdown-lg dropdown cursor-pointer"
      >
        <DropdownToggle
          tag="button"
          type="button"
          role="button"
          className="btn border-0 p-1 me-2"
          data-bs-toggle="dropdown"
        >
          <img
            className="me-2"
            style={{ width: "25px" }}
            src={languages.find((l) => l.value === language).logoUrl}
          ></img>{" "}
          <label className={`${classNameTextColor} d-block cursor-pointer`}>
            {languages.find((l) => l.value === language).label}
          </label>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            onClick={() => onChangeLanguage("it")}
            className="dropdown-item"
          >
            <img
              className="me-2"
              style={{ width: "25px" }}
              src={languages.find((l) => l.value === "it").logoUrl}
            ></img>{" "}
            <label className="fw-light d-block cursor-pointer">
              {languages.find((l) => l.value === "it").label}
            </label>
          </DropdownItem>
          <DropdownItem
            onClick={() => onChangeLanguage("en")}
            className="dropdown-item"
          >
            <img
              className="me-2"
              style={{ width: "25px" }}
              src={languages.find((l) => l.value === "en").logoUrl}
            ></img>{" "}
            <label className="fw-light d-block cursor-pointer">
              {languages.find((l) => l.value === "en").label}
            </label>
          </DropdownItem>
          <DropdownItem
            onClick={() => onChangeLanguage("fr")}
            className="dropdown-item"
          >
            <img
              className="me-2"
              style={{ width: "25px" }}
              src={languages.find((l) => l.value === "fr").logoUrl}
            ></img>{" "}
            <label className="fw-light d-block cursor-pointer">
              {languages.find((l) => l.value === "fr").label}
            </label>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
