import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasRole } from "../../utilities";
import {
  faHouse,
  faMapLocationDot,
  faLock,
  faTruckFast,
  faFileContract,
  faPuzzle,
  faAddressCard,
  faProjectDiagram,
  faFileExport,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../AppProvider";

const Navdata = () => {
  const { dictionary, accessTokenPayload } = useAppContext();
  const navigate = useNavigate();

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isDashboard, setIsDashboard] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [isRegistry, setIsRegistry] = useState(false);
  const [isLogistic, setIsLogistic] = useState(false);
  const [isContracts, setIsContracts] = useState(false);
  const [isLocations, setIsLocations] = useState(false);
  const [isIntegrations, setIsIntegrations] = useState(false);
  const [isExports, setIsExports] = useState(false);

  useEffect(() => {
    if (iscurrentState === "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Authentication") {
      setIsAuthentication(false);
    }
    if (iscurrentState !== "Shipping") {
      setIsShipping(false);
    }
    if (iscurrentState !== "Registry") {
      setIsRegistry(false);
    }
    if (iscurrentState !== "Logistic") {
      setIsLogistic(false);
    }
    if (iscurrentState !== "Locations") {
      setIsLocations(false);
    }
    if (iscurrentState !== "Contracts") {
      setIsContracts(false);
    }
    if (iscurrentState !== "Integrations") {
      setIsIntegrations(false);
    }
    if (iscurrentState !== "Exports") {
      setIsExports(false);
    }
  }, [
    navigate,
    iscurrentState,
    isAuthentication,
    isDashboard,
    isShipping,
    isRegistry,
    isLogistic,
    isContracts,
    isIntegrations,
    isLocations,
    isExports,
  ]);

  const userRoles = accessTokenPayload?.roles || [];

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: faHouse,
      link: "/dashboard",
      stateVariables: isDashboard,
      visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
    },
    {
      id: "locations",
      label: dictionary.locations.locations,
      icon: faMapLocationDot,
      stateVariables: isLocations,
      click: function (e) {
        e.preventDefault();
        setIsLocations(!isLocations);
        setIscurrentState("Locations");
      },
      visibility: hasRole(userRoles, "ROLE_AFFILIATE_ADMINISTRATOR"),
      subItems: [
        {
          id: "zones",
          label: dictionary.locations.zones,
          link: "/locations/zones",
          parentId: "locations",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "authentication",
      label: dictionary.words.authentications,
      icon: faLock,
      stateVariables: isAuthentication,
      click: function (e) {
        e.preventDefault();
        setIsAuthentication(!isAuthentication);
        setIscurrentState("Authentication");
      },
      visibility: hasRole(userRoles, "ROLE_AFFILIATE_ADMINISTRATOR"),
      subItems: [
        {
          id: "users",
          label: dictionary.users.users,
          link: "/users",
          parentId: "authentication",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "shipping",
      label: dictionary.shipments.shipments,
      icon: faTruckFast,
      stateVariables: isShipping,
      click: function (e) {
        e.preventDefault();
        setIsShipping(!isShipping);
        setIscurrentState("Shipping");
      },
      visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
      subItems: [
        {
          id: "shipments",
          label: dictionary.shipments.shipments,
          link: "/shipments",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
        {
          id: "collections",
          label: dictionary.collections.collections,
          link: "/collections",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
        {
          id: "parcelTemplates",
          label: dictionary.parcels.packages_2_templates,
          link: "/parcel-templates",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
        {
          id: "codCollections",
          label: dictionary.cod_collections.cod_collections,
          link: "/cod-collections",
          parentId: "shipping",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "contracts",
      label: dictionary.contracts.contracts,
      icon: faFileContract,
      stateVariables: isContracts,
      click: function (e) {
        e.preventDefault();
        setIsContracts(!isContracts);
        setIscurrentState("Contracts");
      },
      visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
      subItems: [
        {
          id: "shipments",
          label: dictionary.contracts.contracts,
          link: "/contracts",
          parentId: "contracts",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "integrations",
      label: dictionary.integrations.integrations,
      icon: faPuzzle,
      stateVariables: isIntegrations,
      click: function (e) {
        e.preventDefault();
        setIsIntegrations(!isIntegrations);
        setIscurrentState("Integrations");
      },
      visibility: hasRole(userRoles),
      subItems: [
        {
          id: "carriers",
          label: dictionary.registries.carriers,
          link: "/carriers-integrations",
          parentId: "integrations",
          visibility: hasRole(userRoles),
        },
        {
          id: "stores",
          label: dictionary.stores.stores,
          link: "/stores-integrations",
          parentId: "integrations",
          visibility: hasRole(userRoles),
          isNew: true,
        },
        {
          id: "logistic",
          label: dictionary.words.logistic,
          link: "/logistic-integrations",
          parentId: "integrations",
          visibility: hasRole(userRoles),
          isNew: true,
        },
      ],
    },
    {
      id: "registry",
      label: dictionary.registries.registries,
      icon: faAddressCard,
      stateVariables: isRegistry,
      click: function (e) {
        e.preventDefault();
        setIsRegistry(!isRegistry);
        setIscurrentState("Registry");
      },
      visibility: hasRole(userRoles),
      subItems: [
        {
          id: "customers",
          label: dictionary.registries.customers,
          link: "/customers",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_AFFILIATE_ADMINISTRATOR"),
        },
        {
          id: "affiliates",
          label: dictionary.registries.affiliates,
          link: "/affiliates",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_ORGANIZATION_ADMINISTRATOR"),
        },
        {
          id: "organizations",
          label: dictionary.registries.organizations,
          link: "/organizations",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_SPECIAL_ADMINISTRATOR"),
        },
        {
          id: "addressBook",
          label: dictionary.contacts.destinations_address_book,
          link: "/address-book",
          parentId: "registry",
          visibility: hasRole(userRoles, "ROLE_CLIENT_ADMINISTRATOR"),
        },
      ],
    },
    {
      id: "logistic",
      label: dictionary.words.logistic,
      icon: faProjectDiagram,
      link: "/#",
      stateVariables: isLogistic,
      click: function (e) {
        e.preventDefault();
        setIsLogistic(!isLogistic);
        setIscurrentState("Logistic");
      },
      visibility: hasRole(userRoles),
      subItems: [
        {
          id: "articles",
          label: dictionary.products.products,
          link: "/articles",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
        {
          id: "orders",
          label: dictionary.orders.orders,
          link: "/orders",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
        {
          id: "warehouses",
          label: dictionary.registries.warehouses,
          link: "/warehouses",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
        {
          id: "incomingGoods",
          label: dictionary.incoming_goods.incoming_goods,
          link: "/incoming-goods",
          parentId: "logistic",
          visibility: hasRole(userRoles),
        },
      ],
    },
    {
      id: "export",
      label: dictionary.words.exports,
      icon: faFileExport,
      link: "/exports",
      stateVariables: isExports,
      click: function (e) {
        e.preventDefault();
        setIsExports(!isExports);
        setIscurrentState("Exports");
      },
      visibility: hasRole(userRoles),
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
