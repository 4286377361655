import React from "react";
import { Button } from "reactstrap";
import { UsersService } from "../../services/user/users";
import { toast } from "react-toastify";
import { useAppContext } from "../../AppProvider";
import { callErrorToast } from "../../utilities";

const usersService = new UsersService();

const Delete = ({ userId, modalToogle, refreshTable }) => {
  const { dictionary } = useAppContext();

  const deleteUser = () => {
    usersService
      .delete(userId)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success(dictionary.messages.save_successful, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };
  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>{dictionary.messages.ask_to_delete}</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToogle();
            refreshTable();
          }}
        >
          {dictionary.actions.cancel}
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteUser}>
          {dictionary.actions.delete}
        </Button>
      </div>
    </React.Fragment>
  );
};
export default Delete;
