import { defaultPagination, Pagination } from "./Pagination";
import { SearchFilter } from "./SearchFilter";
import { defaultSorting, Sorting } from "./Sorting";

export interface FetchParam {
  inputValue: string;
  pagination: Pagination;
  sorting: Sorting;
  search?: SearchFilter[];
  freeSearch?: boolean;
}

export const defaultFetchParam: FetchParam = {
  inputValue: "",
  pagination: defaultPagination,
  sorting: defaultSorting,
  freeSearch: false,
};
