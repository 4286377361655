import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "../../utilities/fontawesomeIcons";
import { useAppContext } from "../../AppProvider";
import { Button } from "../../Models/Button";

const ButtonConfirm: React.FC<Button> = ({
  id,
  children,
  className = "",
  onClick,
  disabled = false,
  loading = false,
  icon = true,
}) => {
  const { dictionary } = useAppContext();

  return (
    <button
      id={id}
      className={`button-1 button-1-dark ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={faCheck} />}
          {children || dictionary.actions.confirm}
        </>
      )}
    </button>
  );
};

export default ButtonConfirm;
