import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  carrierLogos,
  getParcelTypeBadge,
  getShipmentTypeBadge,
  orderBykey,
} from "../../utilities";
import { parcelTypesList } from "../../constants";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { CarriersService } from "../../services/registry-service/carriers";
import { AddressBookService } from "../../services/shipment-service/addressbook";
import { AddressTranscodesService } from "../../services/registry-service/addressTranscode";
import { OrdersService } from "../../services/logistic/orders";

const shipmentService = new ShipmentsService();
const carriersService = new CarriersService();
const addressBookService = new AddressBookService();
const addressTranscodesService = new AddressTranscodesService();
const ordersService = new OrdersService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  action: "",
};

const RateItem = ({
  data,
  shipmentId,
  shipmentData,
  loadingAll,
  setLoadingAll,
  loadingItem,
  setLoadingItem,
  getRate,
  updateRate,
  setUpdateRate,
  pickup,
  delivery,
  shipmentSetting,
  shipmentSettingDetail,
  callback,
}) => {
  const { id } = useParams();

  const [rateItem, setRateItem] = useState(data);
  const [chosenOptions, setChosenOptions] = useState(
    shipmentData.options || []
  );
  const [shipment, setShipment] = useState(shipmentData);
  const [cashOnDeliveryCollectionMethods, setCashOnDeliveryCollectionMethods] =
    useState([]);
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [labelTypeCode, setLabelTypeCode] = useState(
    shipmentSettingDetail?.defaultLabelType || ""
  );
  const [cashOnDeliveryCollectionMethod, setCashOnDeliveryCollectionMethod] =
    useState(
      shipmentSettingDetail?.defaultCashOnDeliveryCollectionMethod || {
        code: "CASH",
        name: "Contante",
      }
    );
  const [pickupNormalized, setPickupNormalized] = useState(null);
  const [deliveryNormalized, setDeliveryNormalized] = useState(null);
  const [normalizedPickupAddresses, setNormalizedPickupAddresses] =
    useState(null);
  const [normalizedDeliveryAddresses, setNormalizedDeliveryAddresses] =
    useState(null);
  const [checkAddressEnabled, setCheckAddressEnabled] = useState(false);
  const [saveTranscodingEnabled, setSaveTranscodingEnabled] = useState(false);
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const navigate = useNavigate();

  const getCashOnDeliveryCollectionMethods = (carrierCode) => {
    carriersService
      .getCashOnDeliveryCollectionMethod(carrierCode)
      .then((response) => {
        setCashOnDeliveryCollectionMethods(response.sort());
      })
      .catch((err) => {
        toast.error(`Non ho recuperato i metodi incasso contrassegni`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getAttachmentTypes = (carrierCode) => {
    carriersService
      .getAttachmentTypes(carrierCode)
      .then((response) => {
        setAttachmentTypes(response.sort());
      })
      .catch((err) => {
        toast.error(`Non ho recuperato le tipologie etichette`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const chooseRateItem = () => {
    setLoadingItem(true);
    if (checkAddressEnabled) {
      checkAddress();
      return false;
    }
    rateConfirm();
  };

  const checkAddress = () => {
    let addressToNormalize = false;
    if (pickup && delivery) {
      addressBookService
        .checkAddress(pickup.id, rateItem.contract.serviceId)
        .then((response) => {
          //Se presente response l'indirizzo è da normalizzare
          if (response) {
            let addresses = response.addresses.map((elem) => {
              return { ...elem, uuid: uuidv4() };
            });
            setNormalizedPickupAddresses(addresses);
            addressToNormalize = true;
          }
          addressBookService
            .checkAddress(delivery.id, rateItem.contract.serviceId)
            .then((response) => {
              //Se presente response l'indirizzo è da normalizzare
              if (response) {
                let addresses = response.addresses.map((elem) => {
                  return { ...elem, uuid: uuidv4() };
                });
                setNormalizedDeliveryAddresses(addresses);
                addressToNormalize = true;
              }

              //Se almeno un indirizzo tra pickup e delivery dev'essere normalizzato apri il modal
              if (addressToNormalize) {
                setLoadingItem(false);
                setModalSettings({
                  ...modalSettings,
                  title: "Valida indirizzo",
                  icon: "ph-check",
                  open: !modalSettings.open,
                  action: "checkAddress",
                });
                return false;
              }

              rateConfirm();
            })
            .catch((err) => {
              toast.error(
                "Si sono verificati dei problemi nella check address dell'indirizzo del destinatario",
                {
                  position: toast.POSITION.TOP_CENTER,
                }
              );
            });
        })
        .catch((err) => {
          toast.error(
            "Si sono verificati dei problemi nella check address dell'indirizzo del mittente",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });

      return false;
    }
    toast.error(
      `Impossibile effettuare la validazione degli indirizzi. ${
        !pickup && !delivery
          ? "Indirizzo mittente e destinatario non caricati correttamente"
          : !pickup
          ? "Indirizzo mittente non caricato correttamente"
          : !delivery
          ? "Indirizzo destinatario non caricato correttamente"
          : ""
      }`,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    setLoadingItem(false);
  };

  const createPromiseAddressTranscode = (addressTranscode) => {
    return new Promise((resolve, reject) => {
      addressTranscodesService
        .create(addressTranscode)
        .then((response) => {
          if (response) {
            resolve();
            return false;
          }
          reject();
        })
        .catch((err) => {
          reject();
        });
    });
  };

  const addressTranscoding = () => {
    setLoadingItem(true);

    const promises = [];
    if (pickupNormalized) {
      const transcodes = prepareAddressTranscodes(
        pickup.location,
        pickupNormalized
      );
      transcodes.forEach((addressTranscode) => {
        promises.push(createPromiseAddressTranscode(addressTranscode));
      });
    }
    if (deliveryNormalized) {
      const transcodes = prepareAddressTranscodes(
        delivery.location,
        deliveryNormalized
      );
      transcodes.forEach((addressTranscode) => {
        promises.push(createPromiseAddressTranscode(addressTranscode));
      });
    }

    Promise.all(promises)
      .then(() => {
        rateConfirm();
      })
      .catch(() => {
        setLoadingItem(false);
        toast.error(
          "Si sono verificati dei problemi nel salvataggio della transcodifica indirizzo",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const rateConfirm = () => {
    ordersService
      .confirmRating(prepareChoiceRateObj())
      .then((response) => {
        bookShipment(response.data);
      })
      .catch((err) => {
        setLoadingItem(false);
        toast.error(`Non ho salvato il servizio scelto`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const bookShipment = (shipmentId) => {
    shipmentService
      .book(shipmentId)
      .then((response) => {
        if (shipmentSetting.automaticConfirmEnabled) {
          confirmShipment(shipmentId);
          return false;
        }
        changeOrderStatusInShipped();
      })
      .catch((err) => {
        setLoadingItem(false);
        toast.error(`Non ho prenotato la spedizione - ${JSON.stringify(err)}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const confirmShipment = (shipmentId) => {
    shipmentService
      .confirm(shipmentId)
      .then((response) => {
        changeOrderStatusInShipped();
      })
      .catch(() => {
        toast.error("Non ho confermato la spedizione", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const changeOrderStatusInShipped = () => {
    ordersService
      .ship(id)
      .then((response) => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        toast.error("Non ho cambiato lo stato dell'ordine", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const deleteOptions = () => {
    shipmentService
      .deleteOptions(shipmentId)
      .then((response) => {
        if (chosenOptions.length > 0) {
          createOptions();
          return false;
        }
        getRate();
      })
      .catch((err) => {
        setLoadingItem(false);
        toast.error(`Non ho cancellato le opzioni`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const createOptions = () => {
    const newOptions = chosenOptions.map((option) => {
      return { surchargeCode: option.code };
    });
    shipmentService
      .createOptions(newOptions, shipmentId)
      .then((response) => {
        getRate();
      })
      .catch((err) => {
        setLoadingItem(false);
        toast.error(`Non ho salvato le opzioni`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const prepareChoiceRateObj = () => {
    let chosenRate = {
      id: id,
      shipmentId: shipment.shipmentId,
      contractServiceId: rateItem.contract.serviceId,
      labelTypeCode: labelTypeCode.code,
      rowId: rateItem.contract.rowId,
    };
    if (cashOnDeliveryCollectionMethod) {
      chosenRate.cashOnDeliveryCollectionMethod =
        cashOnDeliveryCollectionMethod.code;
    }

    return chosenRate;
  };

  const prepareAddressTranscodes = (originalAddress, addressTranscoded) => {
    const transcodes = [];
    if (
      addressTranscoded.Address &&
      addressTranscoded.Address != originalAddress.street
    ) {
      transcodes.push({
        matchLevelCode: "AddressType",
        carrierCode: rateItem.contract.carrierCode,
        originalValue: originalAddress.street,
        transcodedValue: addressTranscoded.Address,
      });
    }
    if (
      addressTranscoded.ProvinceState &&
      addressTranscoded.ProvinceState != originalAddress.subRegion
    ) {
      transcodes.push({
        matchLevelCode: "SubRegionType",
        carrierCode: rateItem.contract.carrierCode,
        originalValue: originalAddress.subRegion,
        transcodedValue: addressTranscoded.ProvinceState,
      });
    }
    if (
      addressTranscoded.Town &&
      addressTranscoded.Town != originalAddress.municipality
    ) {
      transcodes.push({
        matchLevelCode: "MunicipalityType",
        carrierCode: rateItem.contract.carrierCode,
        originalValue: originalAddress.municipality,
        transcodedValue: addressTranscoded.Town,
      });
    }
    return transcodes;
  };

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
    setPickupNormalized(null);
    setDeliveryNormalized(null);
    setNormalizedPickupAddresses(null);
    setNormalizedDeliveryAddresses(null);
    setSaveTranscodingEnabled(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdateRate(true);
    let newChosenOptions = [...chosenOptions];
    //Se checked rimuovo opzione da chosenOptions
    if (value === "true") {
      newChosenOptions = newChosenOptions.filter((elem) => elem.code !== name);
      setChosenOptions(newChosenOptions);
      return false;
    }
    //Se non è checked aggingo option a chosenOptions
    const option = rateItem.options.find((elem) => elem.code === name);
    setChosenOptions([...newChosenOptions, option]);
  };

  useEffect(() => {
    let CODMethodIsProvided = rateItem.costs?.find(
      (elem) => elem.code === "COD" && elem.price
    );

    if (
      (CODMethodIsProvided && !cashOnDeliveryCollectionMethod) ||
      !labelTypeCode ||
      loadingItem
    ) {
      setButtonEnabled(false);
      setSaveTranscodingEnabled(false);
    } else {
      setButtonEnabled(true);
      setSaveTranscodingEnabled(false);
    }
  }, [cashOnDeliveryCollectionMethod, labelTypeCode, loadingItem]);

  useEffect(() => {
    if (pickupNormalized || deliveryNormalized) {
      let pickupNormalizedIsValid = true;
      let deliveryNormalizedIsValid = true;
      if (
        !pickupNormalized &&
        normalizedPickupAddresses &&
        normalizedPickupAddresses.length > 0
      ) {
        pickupNormalizedIsValid = false;
      }
      if (
        !deliveryNormalized &&
        normalizedDeliveryAddresses &&
        normalizedDeliveryAddresses.length > 0
      ) {
        deliveryNormalizedIsValid = false;
      }
      if (
        pickupNormalizedIsValid &&
        deliveryNormalizedIsValid &&
        !loadingItem
      ) {
        setSaveTranscodingEnabled(true);
        return;
      }
    }
    setSaveTranscodingEnabled(false);
  }, [pickupNormalized, deliveryNormalized, loadingItem]);

  useEffect(() => {
    if (rateItem.options?.find((elem) => elem.code === "COD")) {
      getCashOnDeliveryCollectionMethods(rateItem.contract?.carrierCode);
    }
    getAttachmentTypes(rateItem.contract?.carrierServiceCode);

    if (rateItem.contract?.carrierCode === "GLS_IT") {
      setCheckAddressEnabled(true);
    }
  }, []);

  //Functions that return DOM componenents
  const getOptionComponents = () => {
    const options = [];

    const codIsPresentInRateItemOptions = rateItem.options?.find(
      (elem) => elem.code === "COD"
    )
      ? true
      : false;
    const codIsPresentInRateItemCosts = rateItem.costs?.find(
      (elem) => elem.code === "COD"
    )
      ? true
      : false;
    const codIsPresentInShipment = shipment.options?.find(
      (elem) => elem.code === "COD"
    )
      ? true
      : false;

    const insuranceIsPresentInRateItemOptions = rateItem.options?.find(
      (elem) => elem.code === "INSURANCE"
    )
      ? true
      : false;
    const insuranceIsPresentInRateItemCosts = rateItem.costs?.find(
      (elem) => elem.code === "INSURANCE"
    )
      ? true
      : false;
    const insuranceIsPresentInShipment = shipment.options?.find(
      (elem) => elem.code === "INSURANCE"
    )
      ? true
      : false;

    //Se COD non è presente nelle opzioni e costi della tariffa ma è presente nella spedizione -> Non disponibile
    if (
      !codIsPresentInRateItemOptions &&
      codIsPresentInShipment &&
      !codIsPresentInRateItemCosts
    ) {
      options.push(
        <div className="row mt-1" key={0}>
          <div className="col-5">
            <label className="d-block">Contrassegno</label>
          </div>
          <div className="col-5 text-end">
            <label className="text-danger">Non disponibile</label>
          </div>
        </div>
      );
    }

    //Se INSURANCE non è presente nelle opzioni e costi della tariffa ma è presente nella spedizione -> Non disponibile
    if (
      !insuranceIsPresentInRateItemOptions &&
      insuranceIsPresentInShipment &&
      !insuranceIsPresentInRateItemCosts
    ) {
      options.push(
        <div className="row mt-1" key={1}>
          <div className="col-5">
            <label className="d-block">Assicurazione</label>
          </div>
          <div className="col-5 text-end">
            <label className="text-danger">Non disponibile</label>
          </div>
        </div>
      );
    }

    rateItem.options?.forEach((elem, index) => {
      const isChecked = chosenOptions.find((cost) => cost.code === elem.code)
        ?.code
        ? true
        : false;

      options.push(
        <div className="row mt-1" key={index + 2}>
          <div className="col-5">
            <label className="d-block">{elem.name}</label>
          </div>
          <div className="col-5 text-end">
            {elem.price ? (
              <label>
                {elem.price.toFixed(2)} €{" "}
                {elem.time
                  ? `/ ${elem.time > 0 ? "+" + elem.time : elem.time} ore`
                  : ""}
              </label>
            ) : (
              <label className="text-success">Gratuito</label>
            )}
          </div>
          <div className="col-2 text-end">
            <label>
              <input
                onChange={handleInputChange}
                name={elem.code}
                type="checkbox"
                className="form-check-input form-check-input-success cursor-pointer"
                checked={isChecked}
                value={isChecked}
                disabled={elem.code === "COD" || elem.code === "INSURANCE"}
              />
            </label>
          </div>
        </div>
      );
    });

    if (options.length > 0) {
      options.unshift(
        <div className="row" key={options.length + 2}>
          <div className="col">
            <h6 className="mt-2 mb-1">Opzioni</h6>
          </div>
        </div>
      );
    }
    return options;
  };

  return rateItem.contract ? (
    <div
      className="h-100"
      style={{
        padding: "20px",
      }}
    >
      <Card className="h-100">
        <CardBody className="p-2">
          <div className="row mt-3">
            <div className="col text-center">
              {rateItem.contract.carrierCode ? (
                <img
                  title={rateItem.contract.carrierName}
                  src={rateItem.contract.carrierLogoUrl}
                  style={{ maxHeight: "50px" }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col text-center">
              <h4 className="fw-semibold mb-0">
                {rateItem.contract.carrierServiceName || ""}
              </h4>
            </div>
          </div>
        </CardBody>
        <CardFooter className="bg-light p-2" style={{ border: "none" }}>
          <div className="row text-center align-items-center ">
            <div className="col">
              <span
                id="parcelType"
                data-bs-placement="right"
                className="cursor-pointer mx-2"
              >
                {getParcelTypeBadge(
                  parcelTypesList.find(
                    (elem) => elem.code === shipment.parcelTypeCode
                  ),
                  false
                )}
              </span>
              <UncontrolledTooltip
                placement="right"
                target="parcelType"
                trigger="hover"
              >
                {shipment.parcelTypeCode &&
                  shipment.parcelCount &&
                  `${shipment.parcelCount} x ${
                    parcelTypesList.find(
                      (elem) => elem.code === shipment.parcelTypeCode
                    ).name
                  }`}
              </UncontrolledTooltip>
              <i
                className="ph-archive-box cursor-pointer mx-2"
                data-bs-placement="right"
                id={"weight_" + rateItem.contract.rowId}
              ></i>
              <UncontrolledTooltip
                placement="right"
                target={"weight_" + rateItem.contract.rowId}
                trigger="hover"
                style={{ minWidth: "375px", textAlign: "left" }}
              >
                <label className="fw-semibold">Peso tassato:</label>{" "}
                <label>
                  {`${rateItem.contract.taxedWeight?.toFixed(3) || "-"} kg`}
                </label>{" "}
                <span className="fs-xs">
                  (= il maggiore tra Peso reale e Peso volumetrico)
                </span>{" "}
                <br />
                <label className="fw-semibold">Peso reale:</label>{" "}
                <label>{shipment.weight.toFixed(3) + " kg"}</label> <br />
                <label className="fw-semibold">Peso volumetrico:</label>{" "}
                <label>{`${
                  rateItem.contract.volumetricWeight?.toFixed(3) || "-"
                } kg`}</label>{" "}
                <span className="fs-xs">(= Volume x Rapporto Peso/Volume)</span>{" "}
                <br />
                <label className="fw-semibold">
                  Rapporto Peso/Volume:
                </label>{" "}
                <label>
                  {`${rateItem.contract.serviceWeightVolumeRatio || "-"} kg/m3`}
                </label>{" "}
              </UncontrolledTooltip>
              <i
                className="ph-cube cursor-pointer mx-2"
                data-bs-placement="right"
                id="volume"
              ></i>
              <UncontrolledTooltip
                placement="right"
                target="volume"
                trigger="hover"
              >
                <label className="fw-semibold">Volume:</label>{" "}
                <label>
                  {shipment.volume
                    ? `${shipment.volume.toFixed(6)}`.replace(
                        /^0+(\d)|(\d)0+$/gm,
                        "$1$2"
                      ) + " m3"
                    : ""}
                </label>
              </UncontrolledTooltip>
              <span className="mx-2">
                {getShipmentTypeBadge(shipment.shipmentType)}
              </span>
            </div>
          </div>
          <div className="row text-center">
            <div className="col"></div>
          </div>
        </CardFooter>
        <CardBody
          className="h-100"
          hidden={!rateItem.costs && !rateItem.options}
        >
          {/* Costi */}
          {rateItem.costs && rateItem.costs.length > 0 && (
            <div className="row">
              <div className="col">
                <h6 className="mb-1">Costi</h6>
                {rateItem.costs.map((elem, index) => {
                  let option = rateItem.options?.find(
                    (option) => option.code === elem.code
                  );

                  if (!option) {
                    return (
                      <div className="row mt-1" key={index}>
                        <div className="col-5">
                          <label className="d-block">{elem.name}</label>
                        </div>
                        <div className="col-5 text-end">
                          {elem.price ? (
                            <label>
                              {elem.price.toFixed(2)} €{" "}
                              {elem.time
                                ? `/ ${
                                    elem.time > 0 && elem.code !== "NOLO"
                                      ? "+" + elem.time
                                      : elem.time
                                  } ore`
                                : ""}
                            </label>
                          ) : (
                            <label className="text-success">Gratuito</label>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
          {/* Opzioni */}
          {getOptionComponents()}
        </CardBody>
        <CardFooter className="align-items-end">
          {rateItem.options &&
            rateItem.options.find((elem) => elem.code === "COD") && (
              <div className="row  mb-2">
                <div className="col">
                  <Select
                    name="cashOnDeliveryCollectionMethodCode"
                    onChange={(option) =>
                      setCashOnDeliveryCollectionMethod(option)
                    }
                    defaultMenuIsOpen={false}
                    className="select"
                    options={orderBykey(
                      cashOnDeliveryCollectionMethods,
                      "name"
                    )}
                    value={cashOnDeliveryCollectionMethod}
                    getOptionValue={(option) => option["code"]}
                    getOptionLabel={(option) => option["name"]}
                    placeholder={"Seleziona metodo incasso contrassegno"}
                    maxMenuHeight={150}
                    isClearable
                  />
                </div>
              </div>
            )}
          <div className="row">
            <div className="col">
              <Select
                name="labelTypeCode"
                onChange={(option) => setLabelTypeCode(option)}
                defaultMenuIsOpen={false}
                className="select"
                options={attachmentTypes}
                value={labelTypeCode}
                getOptionValue={(option) => option["code"]}
                getOptionLabel={(option) => option["name"]}
                placeholder={"Seleziona tipologia etichetta"}
                maxMenuHeight={100}
                isClearable
              />
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col text-center">
              {updateRate ? (
                <button
                  type="button"
                  className="btn btn-light btn-md d-inline-block"
                  onClick={() => {
                    setLoadingAll(true);
                    deleteOptions();
                    setLabelTypeCode("");
                    setCashOnDeliveryCollectionMethod("");
                  }}
                >
                  {loadingItem ? (
                    <i className="ph-spinner spinner"></i>
                  ) : (
                    <span className="fw-semibold">AGGIORNA</span>
                  )}
                </button>
              ) : (
                <button
                  disabled={!buttonEnabled}
                  type="button"
                  className="btn btn-success btn-md d-inline-block"
                  onClick={chooseRateItem}
                >
                  {loadingItem ? (
                    <i className="ph-spinner spinner"></i>
                  ) : (
                    <div>
                      {rateItem.totalCost ? (
                        <div>
                          <h4 className="m-0">
                            {rateItem.totalCost.toFixed(2)} €{" "}
                          </h4>
                          {rateItem.totalTime > 0 && (
                            <span className="m-0 fw-light">{`Tempo di resa ${rateItem.totalTime} ore`}</span>
                          )}
                        </div>
                      ) : (
                        <span className="fw-light">
                          <b>SELEZIONA</b>
                        </span>
                      )}
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
          {rateItem.contract.carrierCode === "GLS_IT" && (
            <div className="row mt-2">
              <div className="col text-center">
                <label>
                  <span className="form-check-label me-2">
                    Verifica indirizzo
                  </span>
                  <input
                    name="editInAddressBook"
                    type="checkbox"
                    className="form-check-input form-check-input-success"
                    value={checkAddressEnabled}
                    checked={checkAddressEnabled}
                    onChange={() =>
                      setCheckAddressEnabled(!checkAddressEnabled)
                    }
                  />
                </label>
              </div>
            </div>
          )}
        </CardFooter>
      </Card>
      <Modal isOpen={modalSettings.open} className="modal-content">
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "checkAddress" && (
            <React.Fragment>
              <div
                className="border-0 alert-dismissible fade show alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <span className="fw-semibold">Attenzione!</span> Indirizzo del
                mittente e/o del destinatario non identificato dal vettore. A
                seguire gli indirizzi più simili.
              </div>

              {normalizedPickupAddresses?.length > 0 && (
                <div className="row mb-3">
                  <label className="form-label">Mittente:</label>
                  <div className="col">
                    <Select
                      name="pickupNormalized"
                      onChange={(option) => setPickupNormalized(option)}
                      defaultMenuIsOpen={false}
                      className="select"
                      options={normalizedPickupAddresses}
                      value={pickupNormalized}
                      getOptionValue={(option) => option["uuid"]}
                      getOptionLabel={(option) =>
                        (option.Address ?? "") +
                        " " +
                        (option.ZipCode ?? "") +
                        " " +
                        (option.Town ?? "") +
                        " " +
                        (option.ProvinceState ?? "") +
                        " " +
                        (option.Country ?? "")
                      }
                      placeholder={"Seleziona indirizzo"}
                      maxMenuHeight={100}
                      isClearable
                    />
                    <span className="span-1">
                      Indirizzo originale: {pickup.location.label}
                    </span>
                  </div>
                </div>
              )}
              {normalizedDeliveryAddresses?.length > 0 && (
                <div className="row mb-3">
                  <div className="col">
                    <label className="form-label">Destinatario:</label>
                    <Select
                      name="deliveryNormalized"
                      onChange={(option) => setDeliveryNormalized(option)}
                      defaultMenuIsOpen={false}
                      className="select"
                      options={normalizedDeliveryAddresses}
                      value={deliveryNormalized}
                      getOptionValue={(option) => option["uuid"]}
                      getOptionLabel={(option) => {
                        return (
                          (option.Address ?? "") +
                          " " +
                          (option.ZipCode ?? "") +
                          " " +
                          (option.Town ?? "") +
                          " " +
                          (option.ProvinceState ?? "") +
                          " " +
                          (option.Country ?? "")
                        );
                      }}
                      placeholder={"Seleziona indirizzo"}
                      maxMenuHeight={100}
                      isClearable
                    />
                    <span className="span-1">
                      Indirizzo originale: {delivery.location.label}
                    </span>
                  </div>
                </div>
              )}
              <div className="row mt-2 justify-content-between">
                <div className="d-flex justify-content-end">
                  <button className="btn" onClick={modalToggle}>
                    Annulla
                  </button>
                  <button
                    disabled={!saveTranscodingEnabled}
                    onClick={addressTranscoding}
                    type="button"
                    className="btn btn-success ms-3"
                  >
                    {loadingItem ? (
                      <i className="ph-spinner spinner"></i>
                    ) : (
                      "Salva"
                    )}
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </div>
  ) : (
    <div
      style={{
        padding: "20px",
      }}
    ></div>
  );
};

export default RateItem;
