import { API_BASE_URL } from "../../constants/index";
import HttpInterface from "../../helpers/HttpInterface";
import { defaultFetchParam, FetchParam } from "../../Models/FetchParam";
import { buildAPIUrl } from "../../utilities/api";

export class ShipmentsService {
  private httpProxy: HttpInterface;

  /**
   * Creates an instance of ShipmentsService.
   * Initializes an HTTP interface to perform API requests.
   */
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  /**
   * Forces the tracking update for a specific shipment.
   * Sends a GET request to the API endpoint to trigger tracking enforcement.
   *
   * @param {string} id - The ID of the shipment to force tracking for.
   * @returns {Promise<any>} - A Promise resolving to the API response.
   */
  forceTracking(id: string): Promise<any> {
    const requestOptions = {
      method: "GET",
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/shipments/${id}/force-tracking`,
      requestOptions
    );
  }
}
