import React, { useRef, useCallback } from "react";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { useListProps } from "./utilities/list";
import { Modal, ModalBody } from "reactstrap";
import { useIndexWarehouseArticlesContext } from "./providers/Index";
import Delete from "./Delete";
import { createModalConfig } from "../../Models/ModalConfig";
import { WarehouseArticle } from "../../Models/WarehouseArticle";
import PhysicalListItem from "./PhysicalListItem";
import DigitalListItem from "./DigitalListItem";
import { HandlerWarehouseArticleProvider } from "./providers/Handler";
import { Handler } from "./Handler";
import { DeleteWarehouseArticleProvider } from "./providers/Delete";

const AnyListProvider = ListProvider as any;
const AnyList = List as any;

interface WarehouseArticlesProps {
  action: string;
  shippingRequired: boolean;
}

const WarehouseArticles: React.FC<WarehouseArticlesProps> = ({
  action,
  shippingRequired,
}) => {
  const listRef = useRef<{ refresh: () => void } | null>(null);
  const listProps = useListProps(action, shippingRequired);
  const { modalConfig, setModalConfig, parentId, setSelectedItemId } =
    useIndexWarehouseArticlesContext();

  const renderItem = useCallback((item: WarehouseArticle, key: number) => {
    return (
      <ListItem
        key={key}
        item={item}
        action={action}
        shippingRequired={shippingRequired}
      />
    );
  }, []);

  const closeModal = () => {
    setModalConfig(createModalConfig());
  };

  return (
    <>
      <AnyListProvider
        path={listProps.path}
        filters={listProps.filters}
        parentId={parentId}
        callback={(data: any) => {
          if (data.length > 0) {
            setSelectedItemId(data.map((x: any) => x.article.id));
          }
        }}
      >
        <AnyList
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          renderItem={renderItem}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
        />
      </AnyListProvider>

      <Modal isOpen={modalConfig.isOpen} size={modalConfig.size}>
        <ModalBody className={modalConfig.className || ""}>
          {modalConfig.action === "create" && (
            <HandlerWarehouseArticleProvider
              parentId={parentId}
              callback={() => {
                listRef?.current?.refresh();
                closeModal();
              }}
              autosave={false}
              shippingRequired={shippingRequired}
            >
              <Handler />
            </HandlerWarehouseArticleProvider>
          )}
          {modalConfig.action === "delete" && (
            <DeleteWarehouseArticleProvider
              callback={() => {
                listRef?.current?.refresh();
                closeModal();
              }}
              data={modalConfig.data}
            >
              <Delete />
            </DeleteWarehouseArticleProvider>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default WarehouseArticles;

const ListItem: React.FC<{
  item: WarehouseArticle;
  action: string;
  shippingRequired: boolean;
}> = React.memo(({ item, action, shippingRequired }) => {
  return (
    <div className="list-item px-2">
      {shippingRequired ? (
        <PhysicalListItem item={item} action={action} />
      ) : (
        <DigitalListItem item={item} action={action} />
      )}
    </div>
  );
});
