import React from "react";
import { useDeleteShipmentContext } from "./providers/Delete";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";

export const Delete = () => {
  const { dictionary } = useAppContext();

  const context = useDeleteShipmentContext();
  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label className="label-4 text-center">
            {dictionary.actions.confirm_deletion}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-5 text-center">
            {context.shipments?.length > 1
              ? `${dictionary.actions.ask_confirm_deletion} ${
                  context.shipments?.length
                } ${dictionary.words.items.toLowerCase()}?`
              : `${dictionary.actions.ask_confirm_deletion}?`}
          </label>
          <label className="label-5 text-center">
            {dictionary.messages.this_action_is_irreversible}
          </label>
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonDelete
          className="w-50"
          onClick={() => context.deleteShipments()}
          disabled={context.shipmentsLoader}
          loading={context.shipmentsLoader}
        />
      </div>
    </React.Fragment>
  );
};
