import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

import InputBox from "../../Components/common/InputBox";
import { getAllCarriers } from "../../utilities/asyncSelectCallAPI";
import { getCarrierOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import {
  carrierIntegrationDefault,
  carrierIntegrationNormalizer,
  carrierIntegrationValidation,
  getCarrier,
  getCarrierCredentialLabels,
  manipulatesCredentialObjects,
} from "./utilities";
import { callErrorToast, orderBykey, valueIsEmpty } from "../../utilities";
import { CarrierConfigurationsService } from "../../services/contract/carrierConfigurations";
import { useAppContext } from "../../AppProvider";

const carrierConfigurationsService = new CarrierConfigurationsService();

const CreateEdit = ({ id, unmountCallback }) => {
  const { dictionary, accessTokenPayload } = useAppContext();

  const [carrierIntegration, setCarrierIntegration] = useState({
    ...carrierIntegrationDefault,
  });
  const [carrierCredentialLabels, setCarrierCredentialLabels] = useState([]);
  const [credentialsVisibility, setCredentialsVisibility] = useState(false);
  const [errors, setErrors] = useState({});

  const getCarrierIntegration = () => {
    carrierConfigurationsService
      .get(id)
      .then((response) => {
        getCarrierIntegrationCallback(response.data);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getCarrierIntegrationCallback = (carrierIntegration) => {
    Promise.all([
      getCarrier(carrierIntegration.carrierCode),
      getCarrierCredentialLabels(carrierIntegration.carrierCode),
    ])
      .then(([carrier, carrierCredentialLabels]) => {
        setCarrierIntegration({
          ...carrierIntegration,
          carrierCode: carrier || {},
          credentials: carrierIntegration.credentials || [],
        });
        setCarrierCredentialLabels(carrierCredentialLabels || []);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const create = (carrierIntegration) => {
    carrierConfigurationsService
      .create(carrierIntegration)
      .then((response) => {
        unmountCallback();
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const edit = (carrierIntegration) => {
    carrierConfigurationsService
      .edit(carrierIntegration)
      .then((response) => {
        unmountCallback();
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const handleSelectChange = (selector, value) => {
    if (value) {
      getCarrierCredentialLabels(value.value).then((response) => {
        setCarrierCredentialLabels(response);
      });

      delete errors[selector];
      setErrors(errors);
    }

    setCarrierIntegration({
      ...carrierIntegration,
      [selector]: value,
      credentials: [],
      name: value.name,
    });
  };

  const handleInputChange = (event) => {
    if (event.target.value) {
      delete errors[event.target.name];
      setErrors(errors);
    }
    setCarrierIntegration({
      ...carrierIntegration,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputCredentialChange = (event) => {
    const { name, value } = event.target;

    const credentials = manipulatesCredentialObjects(
      [...carrierIntegration.credentials],
      name,
      value
    );

    setCarrierIntegration({
      ...carrierIntegration,
      credentials,
    });
  };

  const saveCarrierIntegration = () => {
    const errors = carrierIntegrationValidation(carrierIntegration, dictionary);
    if (valueIsEmpty(errors)) {
      const normalized = carrierIntegrationNormalizer(
        carrierIntegration,
        accessTokenPayload.owner
      );
      if (id) {
        edit(normalized);
        return false;
      }
      create(normalized);
    }
    setErrors(errors);
  };

  useEffect(() => {
    if (id) {
      getCarrierIntegration();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="row align-items-start">
        <div className="col">
          <label className="form-label">{dictionary.registries.carrier}:</label>
          <AsyncSelect
            name="carrierCode"
            value={carrierIntegration.carrierCode}
            onSearch={handleSelectChange}
            loadOptions={(inputValue, pagination) => {
              return getAllCarriers(inputValue, pagination, {
                orderBy: "asc",
                selector: "name",
              });
            }}
            optionStyle={getCarrierOptionStyle}
            error={errors["carrierCode"]}
          />
        </div>
        <div className="col">
          <label className="form-label">{dictionary.registries.name}:</label>
          <InputBox
            name="name"
            value={carrierIntegration.name || ""}
            type="text"
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </div>
      </div>
      {carrierCredentialLabels.length > 0 && (
        <React.Fragment>
          <div className="row align-items-center mt-3">
            <div className="col">
              <label className="fs-4">{dictionary.words.credentials}</label>
            </div>
            <div className="col text-end">
              <i
                className={`${
                  credentialsVisibility ? "ph-eye-slash" : "ph-eye"
                } cursor-pointer`}
                onClick={() => setCredentialsVisibility(!credentialsVisibility)}
              ></i>
            </div>
          </div>
          <div className="row">
            {carrierCredentialLabels.map((label, index) => {
              const credential = carrierIntegration.credentials.find(
                (cred) => cred.rank === label.rank
              );
              return (
                <div className="col-6 mt-2" key={index}>
                  <label className="form-label">{label.value}:</label>
                  <InputBox
                    name={label.rank}
                    type={credentialsVisibility ? "text" : "password"}
                    value={credential?.value || ""}
                    onChange={handleInputCredentialChange}
                  />
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
      <div className="row mt-3 align-items-center">
        <div className="col">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (unmountCallback) {
                  unmountCallback();
                }
              }}
            >
              {dictionary.actions.cancel}
            </button>
            <Button
              type="button"
              className="btn ms-3"
              onClick={saveCarrierIntegration}
            >
              {dictionary.actions.save}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateEdit;
