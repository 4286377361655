import React, { useEffect } from "react";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShipmentsContext } from "./providers/Shipments";
import { useAppContext } from "../../AppProvider";
import { stringToColor } from "../../utilities";

const AverageDeliveryTimeForShipmentsPerService = ({ subtitle }) => {
  const context = useShipmentsContext();
  const { dictionary } = useAppContext();

  const data = {
    labels: context?.kpi?.map((s) => s.type.name) || [],
    datasets: [
      {
        data: context?.kpi?.map((s) => (s.average || 0).toFixed(2)) || [],
        backgroundColor:
          context?.kpi?.map((s) => stringToColor(s.type.code)) || [],
      },
    ],
  };

  return (
    context && (
      <div className="d-flex flex-column align-items-center justify-content-center">
        {context.kpiLoader ? (
          <FontAwesomeIcon icon={faLoader} spin size="4x" />
        ) : (
          <>
            <label className="label-4 mb-2">
              {dictionary.services.average_delivery_time_per_service}
            </label>
            {subtitle && <label className="label-2">{subtitle}</label>}
            {data.datasets[0].data.length > 0 ? (
              <>
                <Bar
                  className="mt-2"
                  data={data}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          stepSize: 0.5,
                          min: 0,
                        },
                        title: {
                          display: true, // Mostra la label
                          text: dictionary.times.working_days, // Testo della label asse Y
                        },
                      },
                    },
                  }}
                />
              </>
            ) : (
              <label className="label-3 mt-5">
                {dictionary.messages.no_results_found}
              </label>
            )}
          </>
        )}
      </div>
    )
  );
};

export { AverageDeliveryTimeForShipmentsPerService };
