import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBox,
  faCloud,
  faEye,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { CreateEditOrderDetailProvider } from "./providers/CreateEdit";
import { CreateEdit } from "./CreateEdit";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { Modal, ModalBody } from "reactstrap";
import { useIndexOrderDetailsContext } from "./providers/Index";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { useHandlerOrderContext } from "../Orders/providers/Handler";
import { DeleteOrderDetailProvider } from "./providers/Delete";
import Delete from "./Delete";
import { recalculateOrderValues } from "../Orders/utilities/index";
import noImageAvailable from "../../assets/images/no-image-available.png";
import { ProcessOrderDetailsProvider } from "./providers/Process";
import Process from "./Process";
import { BarcodeHandlerProvider } from "./providers/BarcodeHandler";
import BarcodeHandler from "./BarcodeHandler";

const OrderDetails = ({ renderHeader, renderFooter }) => {
  const context = useIndexOrderDetailsContext();
  const handlerOrderContext = useHandlerOrderContext();

  const listRef = useRef(null);
  const listProps = useListProps();

  const renderItem = (item, key) => {
    return (
      <ListItem
        item={item}
        index={key}
        action={context?.action}
        totalElements={listRef.current?.data?.totalElements}
      />
    );
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        parentId={context?.parentId}
        sorting={listProps.sorting}
        defaultPagination={listProps.defaultPagination}
        filters={listProps.filters}
        callback={(data) => {
          const newOrder = {
            ...handlerOrderContext.order,
            totalPriceVatIncluded:
              (context?.order?.totalPrice || 0) +
              data?.reduce(
                (t, { unitPrice, vat, discount = 0, quantity }) =>
                  t + ((unitPrice - discount) * quantity * vat) / 100,
                0
              ),
          };
          handlerOrderContext.editOrder(newOrder, false);
        }}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
          renderHeader={renderHeader}
          renderFooter={renderFooter}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
          actions={listProps.actions}
        />
      </ListProvider>

      <Modal isOpen={context.modal.open} size={context?.modal.size}>
        <ModalBody>
          {context?.modal.action === "create" && (
            <CreateEditOrderDetailProvider
              parentId={context?.parentId}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );
                  newOrder.orderDetails = res.totalElements;

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {context?.modal.action === "edit" && (
            <CreateEditOrderDetailProvider
              id={context.modal.data}
              parentId={context?.parentId}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );
                  newOrder.orderDetails = res.totalElements;

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {context?.modal.action === "process" && (
            <ProcessOrderDetailsProvider
              parentId={context?.parentId}
              action={context?.modal.action}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const createShipmentEnabled = res.content.every((item) => {
                    // Return false if shipping is required, there is no barcode, and a barcode should exist
                    return !(
                      item.article.shippingRequired &&
                      item.article.uniqueBarcodeItem &&
                      !item.barcode
                    );
                  });
                  handlerOrderContext.setCreateShipmentEnabled(
                    createShipmentEnabled
                  );
                });
              }}
            >
              <Process />
            </ProcessOrderDetailsProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteOrderDetailProvider
              data={context.modal.data}
              callback={() => {
                context?.setModal({ ...context.modal, open: false });
                listRef.current.refresh().then((res) => {
                  const newOrder = recalculateOrderValues(
                    {
                      ...handlerOrderContext.order,
                    },
                    res.content
                  );
                  newOrder.orderDetails = res.totalElements;

                  handlerOrderContext.editOrder(newOrder);
                });
              }}
            >
              <Delete />
            </DeleteOrderDetailProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderDetails;

const ListItem = React.memo(({ item, index, action }) => {
  const { dictionary } = useAppContext();

  const context = useIndexOrderDetailsContext();

  return action === "process" ? (
    <React.Fragment key={index}>
      <div className="list-item px-2 m-1">
        <div
          className=" d-grid grid-column align-items-start"
          style={{
            gridTemplateColumns: `0.5fr 2fr 0.5fr 2.5fr`,
          }}
        >
          <div className="my-auto mx-0">
            <img src={item.article.imageUrl || noImageAvailable} />
          </div>
          <div>
            <label className="label-2">{dictionary.registries.name}:</label>
            <label className={`label-3`}>{item.article?.name || "-"}</label>
          </div>
          <div>
            <label className="label-2">{dictionary.dimensions.quantity}:</label>
            <label className={`label-3`}>{item.quantity || "-"}</label>
          </div>
          <div>
            <BarcodeHandlerProvider item={item}>
              <BarcodeHandler />
            </BarcodeHandlerProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment key={index}>
      <div className="list-item px-2 m-1">
        <div
          className=" d-grid grid-column align-items-center"
          style={{
            gridTemplateColumns: `0.5fr 1.5fr 2.25fr 0.75fr 1fr 1fr 1fr 0.75fr 1.25fr 0.75fr 1.5fr`,
          }}
        >
          <div className="text-center">
            <img src={item.article.imageUrl || noImageAvailable} />
            {action !== "details" &&
              (item.article?.shippingRequired ? (
                <FontAwesomeIcon
                  icon={faBox}
                  title={dictionary.products.physical_good}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCloud}
                  title={dictionary.products.digital_good}
                />
              ))}
          </div>
          <div>
            <label className="label-2">{dictionary.products.barcodes}:</label>
            <label className="label-3">{item.barcode || "-"}</label>
          </div>
          <div>
            <label className="label-2">{dictionary.registries.name}:</label>
            <label className={`label-3`}>{item.article?.name || "-"}</label>
          </div>
          <div>
            <label className="label-2">{dictionary.dimensions.quantity}:</label>
            <label className={`label-3`}>{item.quantity || "-"}</label>
          </div>
          <div>
            <label className="label-2">
              {dictionary.dimensions.unit_weight}:
            </label>
            <label className={`label-3`}>
              {item.unitWeight ? `${item.unitWeight?.toFixed(3)} kg` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">
              {dictionary.dimensions.total_weight}:
            </label>
            <label className={`label-3`}>
              {item.totalWeight ? `${item.totalWeight?.toFixed(3)} kg` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary.words.unit_price}:</label>
            <label className={`label-3`}>
              {!isNaN(item.unitPrice) ? `${item.unitPrice?.toFixed(2)} €` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary.words.discount}:</label>
            <label className={`label-3`}>
              {!isNaN(item.discount) ? `${item.discount?.toFixed(2)} €` : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary.words.total_price}:</label>
            <label className={`label-3`}>
              {!isNaN(item.totalPrice)
                ? `${item.totalPrice?.toFixed(2)} €`
                : "-"}
            </label>
          </div>
          <div>
            <label className="label-2">{dictionary.words.vat}:</label>
            <label className={`label-3`}>
              {!isNaN(item.vat) ? `${item.vat?.toFixed(2)} %` : "-"}
            </label>
          </div>
          <div className="text-end">
            {action !== "details" && (
              <React.Fragment>
                <ButtonUtility
                  className="ms-1"
                  title={dictionary.actions.details}
                  onClick={() => {
                    context?.setModal({
                      open: true,
                      title: `${dictionary.actions.details}`,
                      action: action,
                      data: item.id,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </ButtonUtility>
                <ButtonDelete
                  className="ms-1"
                  onClick={() => {
                    context?.setModal({
                      open: true,
                      title: `${dictionary.actions.delete}`,
                      action: "delete",
                      data: [item.id],
                      size: "sm",
                    });
                  }}
                >
                  {" "}
                </ButtonDelete>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
