import React from "react";
import { useAppContext } from "../../AppProvider";
import { useTrackingContext } from "./providers/Tracking";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faLoader,
} from "../../utilities/fontawesomeIcons";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import Event from "../Tracking/Event";
import ButtonUtility from "../../Components/Buttons/Utility";

const Tracking: React.FC = () => {
  const { dictionary } = useAppContext();
  const { isOpen, data, loader, getData, shipment, retracking } =
    useTrackingContext();

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <div
            className="row align-items-center cursor-pointer"
            onClick={() => {
              if (shipment.id) {
                getData(!isOpen);
              }
            }}
          >
            <div className="col">
              <h5 className="m-0">{dictionary.words.tracking}</h5>
            </div>
            {shipment.id && (
              <div className="col text-end">
                {isOpen ? (
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faAngleUp}
                    title={dictionary.actions.reduce}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faAngleDown}
                    title={dictionary.actions.expand}
                  />
                )}
              </div>
            )}
          </div>
        </CardHeader>

        <Collapse
          isOpen={isOpen}
          className="accordion-collapse"
          aria-controls="true"
        >
          <CardBody>
            {loader ? (
              <div className="text-center mt-2">
                <FontAwesomeIcon icon={faLoader} spin size="2x" />
              </div>
            ) : (
              <>
                {shipment.forceTrackingEnabled && (
                  <div className="row">
                    <div className="col text-end">
                      <ButtonUtility onClick={retracking}>
                        {dictionary.words.retracking}
                      </ButtonUtility>
                    </div>
                  </div>
                )}
                {data.map((elem, i) => {
                  return (
                    <Event
                      key={i}
                      data={elem}
                      formatDate={"DD/MM/YYYY HH:mm"}
                      shipment={shipment}
                      isLastEvent={i === 0}
                    />
                  );
                })}
              </>
            )}
          </CardBody>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

export default Tracking;
