import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { callErrorToast, modalDefault } from "../../../utilities";
import { ShipmentsService } from "../../../services/shipment/shipments";
import { dictionary } from "../../../utilities/dictionary";

const shipmentsService = new ShipmentsService();

export const IndexShipmentsContext = createContext();

const IndexShipmentsProvider = ({ children }) => {
  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const [exportLoader, setExportLoader] = useState(false);
  const [exportError, setExportError] = useState(null);

  const prevExportError = useRef();

  const onExportCSV = (query, freeSearch) => {
    setExportLoader(true);
    shipmentsService
      .exportCSV(query, freeSearch)
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "shipments_export_" + Date.now();
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setExportLoader(false);
      })
      .catch((err) => {
        setExportError(err);
        setExportLoader(false);
      });
  };

  useEffect(() => {
    if (
      exportError &&
      JSON.stringify(prevExportError.current) !== JSON.stringify(exportError)
    ) {
      callErrorToast(exportError, dictionary);
    }
  }, [exportError]);

  return (
    <IndexShipmentsContext.Provider
      value={{ modal, setModal, onExportCSV, exportLoader }}
    >
      {children}
    </IndexShipmentsContext.Provider>
  );
};

const useIndexShipmentsContext = () => {
  return useContext(IndexShipmentsContext);
};

export { IndexShipmentsProvider, useIndexShipmentsContext };
