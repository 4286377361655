import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { createModalConfig, ModalConfig } from "../../../Models/ModalConfig"; // Model for modal configuration
import { LogisticIntegration } from "../../../Models/LogisticIntegration"; // Model for logistic integration
import { LogisticIntegrationsService } from "../../../services/logistic/logisticIntegrations"; // Service for logistic integrations
import { callErrorToast } from "../../../utilities"; // Utility function to display error toasts

// Create an instance of the LogisticIntegrationsService to interact with logistic integrations API
const logisticIntegrationsService = new LogisticIntegrationsService();

// Define the props for the provider component
interface IndexWarehousesProviderProps {
  children: ReactNode; // The children that will be rendered inside the provider
  callback?: () => void; // Optional callback function to be executed (if provided)
}

// Define the context value that will be provided to consumers
interface IndexWarehousesContextValue {
  modalConfig: ModalConfig; // The current modal configuration
  setModalConfig: Dispatch<SetStateAction<ModalConfig>>; // Function to update the modal configuration
  callback?: () => void; // Optional callback function
  logisticIntegrations: LogisticIntegration[]; // List of logistic integrations
}

// Create the context for managing warehouse state, with an initial value of undefined
const IndexWarehousesContext = createContext<
  IndexWarehousesContextValue | undefined
>(undefined);

/**
 * Provider component that manages the state of logistic integrations.
 * Provides state and functions to the context consumers.
 */
const IndexWarehousesProvider: React.FC<IndexWarehousesProviderProps> = ({
  children,
  callback,
}) => {
  // State to store the logistic integrations retrieved from the API
  const [logisticIntegrations, setLogisticIntegrations] = useState<
    LogisticIntegration[]
  >([]);

  // State to store and manage the modal configuration
  const [modalConfig, setModalConfig] = useState<ModalConfig>(
    createModalConfig()
  );

  /**
   * Function to retrieve logistic integrations from the backend.
   * Updates the state with the retrieved data.
   */
  const getLogisticIntegrations = (): void => {
    logisticIntegrationsService
      .all() // Calls the 'all' method of the service to fetch integrations
      .then((response) => {
        setLogisticIntegrations(response.data.content); // Updates state with the response data
      })
      .catch((error) => {
        callErrorToast(error); // Displays an error toast if the request fails
      });
  };

  /**
   * useEffect hook that runs once on mount to fetch logistic integrations.
   * It calls getLogisticIntegrations function to load the data.
   */
  useEffect(() => {
    getLogisticIntegrations(); // Calls the function to fetch logistic integrations
  }, []); // Empty dependency array means it will only run on mount

  // Returns the provider, passing the state and functions down to consumers
  return (
    <IndexWarehousesContext.Provider
      value={{ modalConfig, setModalConfig, callback, logisticIntegrations }}
    >
      {children}
    </IndexWarehousesContext.Provider>
  );
};

/**
 * Custom hook to access the context values.
 * Throws an error if used outside of the IndexWarehousesProvider.
 */
const useIndexWarehousesContext = (): IndexWarehousesContextValue => {
  const context = useContext(IndexWarehousesContext);
  if (!context) {
    throw new Error(
      "useIndexWarehousesContext must be used within an IndexWarehousesProvider"
    );
  }
  return context;
};

// Export the provider and custom hook for use in other parts of the application
export { IndexWarehousesProvider, useIndexWarehousesContext };
