import React, { useEffect } from "react";
import { Form, Row, Label, Button, Col } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import RegistryService from "../../services/registry";

const registryService = new RegistryService();

const Delete = ({ companyId, modalToogle, refreshTable }) => {
  const deleteCompany = () => {
    registryService
      .deleteCompanies(companyId)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success("Organizzazione eliminata correttamente", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        toast.error(
          "Si sono verificati dei problemi nel'eliminazione dell'organizzazione",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>Sei sicuro di voler eliminare questa organizzazione?</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToogle();
            refreshTable();
          }}
        >
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteCompany}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
