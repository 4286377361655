import React from "react";
import { Input } from "reactstrap";
import { useAppContext } from "../../../AppProvider";

const TableBody = ({
  rows,
  prepareRow,
  statusOfTableRows,
  selectMultipleRows,
  onChangeStatusRow,
  numberOfColumn,
}) => {
  const { dictionary } = useAppContext();
  const colSpan = numberOfColumn + (selectMultipleRows ? 1 : 0);
  return (
    <>
      <tbody>
        {(!rows || rows.length === 0) && (
          <tr colSpan={colSpan}>
            <td colSpan={colSpan}>
              <span className="d-block text-center my-3">
                {dictionary.messages.no_results_found}
              </span>
            </td>
          </tr>
        )}
        {rows?.map((row) => {
          prepareRow(row);
          let classNameForSelectedRow = "";
          let rowStatus = statusOfTableRows?.find((element) => {
            return element.id === row.original.id;
          });
          if (rowStatus && rowStatus.value === true)
            classNameForSelectedRow = "table-success";
          return (
            <tr className={classNameForSelectedRow} key={row.getRowProps().key}>
              {selectMultipleRows && (
                <td
                  style={{ width: "60px" }}
                  className={"pinning pinning-left"}
                >
                  <div className={"form-check-input"}>
                    <Input
                      type={"checkbox"}
                      checked={rowStatus?.value}
                      onChange={(e) => {
                        onChangeStatusRow([
                          { id: row.original.id, value: e.target.checked },
                        ]);
                      }}
                    />
                  </div>
                </td>
              )}
              {row.cells.map((cell) => {
                return (
                  <td
                    key={cell.id}
                    {...cell.getCellProps()}
                    className={`${cell.column.cellClass || ""}${
                      cell.column.pinning
                        ? " pinning pinning-" + cell.column.pinning
                        : ""
                    }`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
};

export default TableBody;
