import { API_BASE_URL } from "../../constants/index"; // Base URL for the API
import HttpInterface from "../../helpers/HttpInterface"; // Helper class for making HTTP requests
import { defaultFetchParam, FetchParam } from "../../Models/FetchParam"; // Fetch parameters model and default values
import { CreateStockReleaseShipment } from "../../Models/StockReleaseShipment";
import { buildAPIUrl } from "../../utilities/api"; // Utility function to build the API URL with query parameters

export class StockReleasesService {
  private httpProxy: HttpInterface; // Instance of the HttpInterface to make HTTP requests

  // Constructor initializes the httpProxy with the HttpInterface
  constructor() {
    this.httpProxy = new HttpInterface(); // Creates an instance of the HttpInterface to handle requests
  }

  /**
   * Fetches all warehouses from the logistic service API.
   *
   * @param params The query parameters for the API request. Defaults to `defaultFetchParam` if not provided.
   * @returns A promise that resolves with the API response containing the list of warehouses.
   */
  all(params: FetchParam = defaultFetchParam, parentId: string): Promise<any> {
    const requestOptions = {
      method: "GET", // HTTP method for the request
    };

    // Builds the complete API URL using the base URL, endpoint, and parameters
    return this.httpProxy.fetch(
      buildAPIUrl(
        `${API_BASE_URL}/shipment-service/stock-release-shipments`,
        params,
        parentId
      ),
      requestOptions
    );
  }

  get(id: string): Promise<any> {
    const requestOptions = {
      method: "GET", // HTTP method for the request
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/stock-release-shipments/${id}`,
      requestOptions
    );
  }

  /**
   * Creates a new warehouse in the logistic service API.
   *
   * @param warehouse The details of the warehouse to create.
   * @returns A promise that resolves with the API response confirming the creation of the warehouse.
   */
  create(stockRelease: CreateStockReleaseShipment): Promise<any> {
    const requestOptions = {
      method: "POST", // HTTP method for the request
      body: JSON.stringify(stockRelease), // Sends the warehouse data as JSON in the request body
    };

    // Sends a POST request to create the new warehouse
    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/stock-release-shipments`,
      requestOptions
    );
  }
}
