import React from "react";
import { useAppContext } from "../../../../AppProvider";
import SelectAlbx from "../../Selects/Select";

export const SelectFilter = ({ filter, value, onChange, isDisabled }) => {
  const { dictionary } = useAppContext();
  return (
    <SelectAlbx
      name={filter.fieldName}
      onChange={(options, event) => {
        if (options) {
          options = Array.isArray(options) ? options : [options];
        } else {
          options = [];
        }
        onChange(event.name, options);
      }}
      options={filter.options}
      isMulti={filter.isMulti}
      value={value}
      placeholder={dictionary.actions.select}
      optionStyle={filter.optionStyle}
      closeMenuOnSelect={!filter.isMulti}
      isClearable={true}
      isDisabled={isDisabled}
    />
  );
};
