import React, { useCallback } from "react";
import { useAppContext } from "../../AppProvider";
import { UncontrolledTooltip } from "reactstrap";
import { useIndexWarehouseArticlesContext } from "./providers/Index";
import { ModalConfig } from "../../Models/ModalConfig";
import { WarehouseArticle } from "../../Models/WarehouseArticle";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import moment from "moment";

interface PhysicalItemListProps {
  item: WarehouseArticle;
  action: string;
}

export const PhysicalListItem: React.FC<PhysicalItemListProps> = ({
  item,
  action,
}) => {
  const { dictionary } = useAppContext();
  const { setModalConfig } = useIndexWarehouseArticlesContext();

  const openModal = useCallback(
    (
      action: ModalConfig["action"],
      data?: string[],
      size: ModalConfig["size"] = "sm",
      className = "",
      title = ""
    ) => {
      setModalConfig({
        isOpen: true,
        action,
        size,
        data,
        className,
        title,
      });
    },
    [setModalConfig]
  );

  return (
    <React.Fragment>
      <div
        className="d-grid grid-column text-center mb-1"
        style={{
          gridTemplateColumns: `auto`,
        }}
      >
        <div className="bg-color-4">
          <div>
            <label className="label-3">{item.article.name}</label>
          </div>
        </div>
      </div>
      <div
        className="d-grid grid-column text-center"
        style={{
          gridTemplateColumns: `auto`,
        }}
      >
        <div className="d-flex flex-column justify-content-between">
          <div className="bg-color-4">
            <div>
              <label className="label-1">{dictionary.status.coming_soon}</label>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-2">
                  {dictionary.status.processing}
                </label>
              </div>
              <div>
                <label className="label-2">
                  {dictionary.status.completed_pm}
                </label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-2">{dictionary.words.total}</label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-3">
                  {item.incomingInfo.processing}
                </label>
              </div>
              <div>
                <label className="label-3">{item.incomingInfo.completed}</label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-3">{item.incomingInfo.total}</label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-3"> </label>
              </div>
              <div>
                <label className="label-3"> </label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-3"> &nbsp;</label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-end">
          <div>
            <div>
              <label className="label-1 mb-1">
                {dictionary.words.estimated_pm}
              </label>
            </div>
          </div>
          <div>
            <div>
              <label
                className="label-1 text-decoration-underline cursor-pointer"
                id={"effective_" + item.id}
              >
                {dictionary.words.effective_pm}{" "}
              </label>
              <UncontrolledTooltip
                placement="right"
                target={"effective_" + item.id}
              >
                {dictionary.dates.last_update_date}:{" "}
                {moment(
                  item.warehouseInfo.effectiveInfo.lastEffectiveDate
                ).format("DD/MM/YYYY HH:mm:ss")}
              </UncontrolledTooltip>
            </div>
          </div>
        </div>
        <div>
          <div
            className="d-grid grid-column bg-color-4"
            style={{
              gridTemplateColumns: `1fr`,
            }}
          >
            <div>
              <label className="label-1">{dictionary.status.in_stock}</label>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-2">
                  {dictionary.status.committed_pm}
                </label>
              </div>
              <div>
                <label
                  className="label-2 text-decoration-underline cursor-pointer"
                  id={"blocked_" + item.id}
                >
                  {dictionary.status.blocked_pm}{" "}
                </label>
                <UncontrolledTooltip
                  placement="bottom"
                  target={"blocked_" + item.id}
                >
                  {dictionary.status.not_available_for_sale}
                </UncontrolledTooltip>
              </div>
              <div>
                <label className="label-2">
                  {dictionary.status.availables}
                </label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-2">{dictionary.words.total}</label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-3">
                  {item.warehouseInfo.expectedInfo.committed}
                </label>
              </div>
              <div>
                <label className="label-3">
                  {item.warehouseInfo.expectedInfo.blocked}
                </label>
              </div>
              <div>
                <label className="label-3">
                  {item.warehouseInfo.expectedInfo.available}
                </label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-3">
                  {item.warehouseInfo.expectedInfo.total}
                </label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
              }}
            >
              <div></div>
              <div>
                <label className="label-3">
                  {item.warehouseInfo.effectiveInfo.blocked}
                </label>
              </div>
              <div>
                <label className="label-3">
                  {item.warehouseInfo.effectiveInfo.available}
                </label>
              </div>
              <div className="bg-color-4"></div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-color-4">
            <div>
              <label className="label-1">{dictionary.status.sold_pm}</label>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-2">{dictionary.status.lost_pm}</label>
              </div>
              <div>
                <label
                  id={"out_of_delivery_" + item.id}
                  className="label-2 text-decoration-underline cursor-pointer"
                >
                  {dictionary.status.out_of_delivery}
                </label>
                <UncontrolledTooltip
                  placement="bottom"
                  target={"out_of_delivery_" + item.id}
                >
                  {dictionary.status.out_of_delivery}{" "}
                  {dictionary.registries.at_customer}
                </UncontrolledTooltip>
              </div>
              <div>
                <label
                  id={"delivered_" + item.id}
                  className="label-2 text-decoration-underline cursor-pointer"
                >
                  {dictionary.status.delivered_pm}{" "}
                </label>
                <UncontrolledTooltip
                  placement="bottom"
                  target={"delivered_" + item.id}
                >
                  {dictionary.status.delivered_pm}{" "}
                  {dictionary.registries.at_customer}
                </UncontrolledTooltip>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-2">{dictionary.words.total}</label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-3">{item.soldInfo.lost}</label>
              </div>
              <div>
                <label className="label-3">{item.soldInfo.onDelivery}</label>
              </div>
              <div>
                <label className="label-3">{item.soldInfo.delivered}</label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-3">{item.soldInfo.total}</label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
              }}
            >
              <div></div>
              <div></div>
              <div></div>
              <div className="bg-color-4">
                <label> </label>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-end">
          <div>
            <div>
              <label className="label-1 mb-1">{dictionary.words.new}</label>
            </div>
          </div>
          <div>
            <div>
              <label className="label-1">{dictionary.words.used_pm} </label>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="bg-color-4">
            <div>
              <label className="label-1">{dictionary.status.outgoing}</label>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-2">
                  {dictionary.status.processing}
                </label>
              </div>
              <div>
                <label className="label-2">
                  {dictionary.status.completed_pm}
                </label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-2">{dictionary.words.total}</label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-3">
                  {item.outgoingInfo.newInfo.processing}
                </label>
              </div>
              <div>
                <label className="label-3">
                  {item.outgoingInfo.newInfo.completed}
                </label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-3">
                  {item.outgoingInfo.newInfo.total}
                </label>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-grid grid-column"
              style={{
                gridTemplateColumns: `1fr 1fr 0.5fr`,
              }}
            >
              <div>
                <label className="label-3">
                  {item.outgoingInfo.usedInfo.processing}
                </label>
              </div>
              <div>
                <label className="label-3">
                  {item.outgoingInfo.usedInfo.completed}
                </label>
              </div>
              <div className="bg-color-4 text-center">
                <label className="label-3">
                  {item.outgoingInfo.usedInfo.total}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {action !== "details" && (
            <React.Fragment>
              <ButtonDelete
                className="ms-1"
                onClick={() => {
                  openModal(
                    "delete",
                    [item.id],
                    "sm",
                    "",
                    dictionary.products.delete_product
                  );
                }}
              >
                {" "}
              </ButtonDelete>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PhysicalListItem;
