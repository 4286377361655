import React, { useState, useEffect } from "react";
import useFetch from "../../customHooks/useFetch";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardFooter,
} from "reactstrap";
import {
  buildQuerySort,
  buildQuerySearch,
  isColorDark,
  modalSettingsDefault,
  stringToColor,
  getUniqueSelectors,
} from "../../utilities";
import { Loader } from "../../Components/common/Loader";
import TablePagination from "../../Components/common/tableAdvanced/TablePagination";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { getAllCarriers } from "../../utilities/asyncSelectCallAPI";
import { getCarrierOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import CreateEdit from "./CreateEdit";
import Delete from "./Delete";
import { useAppContext } from "../../AppProvider";

const CarriersIntegrations = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({
    ...modalSettingsDefault,
  });
  const [pagination, setPagination] = useState({
    page: 0,
    size: 8,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);

  const { dictionary } = useAppContext();

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/contract-service/carrier-configurations?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title={dictionary.integrations.integrations}
          breadcrumbItem={dictionary.registries.carriers}
        />
      )}

      <div className="content" id="container">
        <Loader show={loading} />

        <Card>
          <CardBody>
            <div className="row align-items-end">
              <div className="col-3">
                <label className="form-label">
                  {dictionary.registries.carrier}
                </label>
                <AsyncSelect
                  name="carrierCode"
                  onSearch={onSearch}
                  loadOptions={(inputValue, pagination) => {
                    return getAllCarriers(inputValue, pagination, {
                      orderBy: "asc",
                      selector: "name",
                    });
                  }}
                  optionStyle={getCarrierOptionStyle}
                  isMulti={true}
                />
              </div>
              <div className="col">
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: dictionary.integrations.new_integration,
                      icon: "ph-plus",
                      action: "create",
                      open: !modalSettings.open,
                    })
                  }
                >
                  {dictionary.integrations.new_integration}{" "}
                  <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>
            <div className="row align-items-stretch mt-3">
              {data.content ? (
                data.content.map((data, index) => {
                  const ownerName =
                    data.ownerId === accessTokenPayload.owner
                      ? data.owner.name
                      : dictionary.words.inherited;
                  const bgColor = stringToColor(ownerName);
                  return (
                    <div className="col-3 mb-3" key={index}>
                      <Card
                        className="h-100 position-relative"
                        style={{ minHeight: 150 }}
                      >
                        <CardHeader className="text-center">
                          {data.used && (
                            <i
                              title={dictionary.words.used}
                              className="ph ph-link position-absolute top-0 end-0 m-3 cursor-pointer"
                            ></i>
                          )}
                          <img src={data.carrierLogo} height={30} />
                        </CardHeader>
                        <CardBody className="text-center">
                          <label className="w-100">
                            <b>{data.name}</b>
                          </label>
                          <div
                            className={`badge ${
                              isColorDark(bgColor) ? "text-white" : "text-dark"
                            } mt-2`}
                            style={{
                              backgroundColor: bgColor,
                              whiteSpace: "normal",
                            }}
                          >
                            {ownerName}
                          </div>
                        </CardBody>
                        <CardFooter className="bg-white text-center">
                          <button
                            title={dictionary.actions.edit}
                            type="button"
                            className="btn btn-flat-dark btn-icon rounded-pill"
                            onClick={() => {
                              setModalSettings({
                                ...modalSettings,
                                title: dictionary.actions.edit,
                                icon: "ph-note-pencil",
                                action: "edit",
                                open: !modalSettings.open,
                                id: data.id,
                              });
                            }}
                            disabled={data.ownerId !== accessTokenPayload.owner}
                          >
                            <i className="ph-note-pencil"></i>
                          </button>
                          <button
                            title={dictionary.actions.delete}
                            type="button"
                            className="btn btn-flat-danger btn-icon rounded-pill ms-2"
                            onClick={() =>
                              setModalSettings({
                                ...modalSettings,
                                title: dictionary.actions.delete,
                                icon: "ph-trash",
                                open: !modalSettings.open,
                                action: "delete",
                                id: data.id,
                              })
                            }
                            disabled={
                              data.used ||
                              data.ownerId !== accessTokenPayload.owner
                            }
                          >
                            <i className="ph-trash"></i>
                          </button>
                        </CardFooter>
                      </Card>
                    </div>
                  );
                })
              ) : (
                <div className="row">
                  <div className="col">
                    <label className="text-center">
                      {dictionary.messages.no_results_found}
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col">
                <TablePagination
                  data={data}
                  onPaginationChange={handlePagination}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modalSettings.open}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action == "create" && (
            <CreateEdit
              unmountCallback={() => {
                modalToggle();
                refreshData();
              }}
            />
          )}
          {modalSettings.action == "edit" && (
            <CreateEdit
              id={modalSettings.id}
              unmountCallback={() => {
                modalToggle();
                refreshData();
              }}
            />
          )}
          {modalSettings.action == "delete" && (
            <Delete
              id={modalSettings.id}
              unmountCallback={() => {
                modalToggle();
                refreshData();
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CarriersIntegrations;
