import { API_BASE_URL } from "../../constants/index"; // Base URL for the API
import HttpInterface from "../../helpers/HttpInterface"; // Helper class for making HTTP requests
import { defaultFetchParam, FetchParam } from "../../Models/FetchParam"; // Fetch parameters model and default values
import { buildAPIUrl } from "../../utilities/api"; // Utility function to build the API URL with query parameters

export class ArticlesService {
  private httpProxy: HttpInterface; // Instance of the HttpInterface to make HTTP requests

  // Constructor initializes the httpProxy with the HttpInterface
  constructor() {
    this.httpProxy = new HttpInterface(); // Creates an instance of the HttpInterface to handle requests
  }

  /**
   * Fetches all articles from the logistic service API.
   *
   * @param params The query parameters for the API request. Defaults to `defaultFetchParam` if not provided.
   * @returns A promise that resolves with the API response containing all articles.
   */
  all(params: FetchParam = defaultFetchParam): Promise<any> {
    const requestOptions = {
      method: "GET", // HTTP method for the request
    };

    // Builds the complete API URL using the base URL, endpoint, and parameters
    return this.httpProxy.fetch(
      buildAPIUrl(`${API_BASE_URL}/logistic-service/articles`, params),
      requestOptions
    );
  }

  /**
   * Deletes a article from the logistic service API.
   *
   * @param id The unique identifier of the article to delete.
   * @returns A promise that resolves with the API response confirming the deletion.
   */
  delete(id: string): Promise<any> {
    const requestOptions = {
      method: "DELETE", // HTTP method for the request
    };

    // Sends a DELETE request to remove the specific article by its ID
    return this.httpProxy.fetch(
      `${API_BASE_URL}/logistic-service/articles/${id}`,
      requestOptions
    );
  }
}
