import React from "react";
import { CarrierConfigurationsService } from "../../services/contract/carrierConfigurations";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";

const carrierConfigurationsService = new CarrierConfigurationsService();

const Delete = ({ id, unmountCallback }) => {
  const { dictionary } = useAppContext();

  const deleteCarrierConfiguration = () => {
    carrierConfigurationsService
      .delete(id)
      .then((response) => {
        if (unmountCallback) {
          unmountCallback(true);
        }
        toast.success(dictionary.messages.item_deleted_successfully, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>{dictionary.messages.ask_to_delete}</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (unmountCallback) {
              unmountCallback(false);
            }
          }}
        >
          {dictionary.actions.cancel}
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={deleteCarrierConfiguration}
        >
          {dictionary.actions.delete}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
