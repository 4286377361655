import { useAppContext } from "../../../AppProvider";

export const useListProps = () => {
  const { dictionary } = useAppContext();

  return {
    path: `/shipment-service/cod-collection-shipments`,
    banner: {
      enabled: false,
    },
    omnisearch: {
      enabled: true,
      placeholder: dictionary.references.internal_reference,
    },
  };
};
