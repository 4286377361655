import React from "react";
import { useAppContext } from "../../AppProvider";
import { useDeleteWarehouseArticleContext } from "./providers/Delete";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { useIndexWarehouseArticlesContext } from "./providers/Index";

const Delete: React.FC = () => {
  const { dictionary } = useAppContext();
  const { modalConfig } = useIndexWarehouseArticlesContext();
  const { data, callback, loader, deleteWarehouseArticles } =
    useDeleteWarehouseArticleContext();

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <h3 className="text-center">
            {modalConfig.title || dictionary.actions.delete}
          </h3>
          <label>
            {data?.length && data.length > 1
              ? dictionary.messages.ask_to_delete_selected
              : dictionary.messages.ask_to_delete}
          </label>
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (callback) {
              callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonDelete
          className="w-50"
          onClick={() => deleteWarehouseArticles()}
          disabled={loader}
          loading={loader}
        >
          {dictionary.actions.delete}
        </ButtonDelete>
      </div>
    </React.Fragment>
  );
};

export default Delete;
