import React, { createContext, useState, useContext, useEffect } from "react";
import { IncomingGoodsService } from "../../../services/logistic/incomingGoods";
import { callErrorToast } from "../../../utilities";
import { dictionary } from "../../../utilities/dictionary";

const incomingGoodsService = new IncomingGoodsService();

export const DeleteIncomingGoodContext = createContext();

const DeleteIncomingGoodProvider = ({ children, data, callback }) => {
  const [incomingGoods, setIncomingGood] = useState(data);
  const [incomingGoodsError, setIncomingGoodsError] = useState(null);
  const [incomingGoodsLoader, setIncomingGoodsLoader] = useState(false);

  const deleteIncomingGoods = () => {
    setIncomingGoodsLoader(true);
    const promises = incomingGoods.map((elem) => {
      return incomingGoodsService.delete(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setIncomingGoodsLoader(false);
        callback();
      })
      .catch((err) => {
        setIncomingGoodsError(err);
      });
  };

  useEffect(() => {
    if (incomingGoodsError) {
      callErrorToast(incomingGoodsError, dictionary);
    }
  }, [incomingGoodsError]);

  return (
    <DeleteIncomingGoodContext.Provider
      value={{
        incomingGoods,
        incomingGoodsLoader,
        deleteIncomingGoods,

        callback,
      }}
    >
      {children}
    </DeleteIncomingGoodContext.Provider>
  );
};

const useIncomingGoodContext = () => {
  return useContext(DeleteIncomingGoodContext);
};

export { DeleteIncomingGoodProvider, useIncomingGoodContext };
