import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Label, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { ZonesService } from "../../../services/registry-service/zones";
import { LocationsService } from "../../../services/registry-service/locations";
import { locationNormalizerForZone } from "../../../utilities";
import { LocationAutocomplete } from "../../../Components/common/LocationAutocomplete";
import { LocationListElement } from "./LocationListElement";
import { Loader } from "../../../Components/common/Loader";
import { useAppContext } from "../../../AppProvider";
import { callErrorToast } from "../../../utilities";

const zonesService = new ZonesService();
const locationsService = new LocationsService();

const Create = ({ modalToogle, refreshTable, pagination }) => {
  const locationRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [includedLocation, setIncludedLocation] = useState([]);
  const [excludedLocation, setExcludedLocation] = useState([]);

  const { dictionary } = useAppContext();

  const deleteIncludedLocation = (location) => {
    const locations = includedLocation.filter(
      (elem) => elem.value !== location.value
    );
    setIncludedLocation(locations);
  };

  const deleteExcludedLocation = (location) => {
    const locations = excludedLocation.filter(
      (elem) => elem.value !== location.value
    );
    setExcludedLocation(locations);
  };

  const saveLocation = (idZone, location, isIncluded) => {
    return new Promise((resolve, reject) => {
      locationsService
        .create(location)
        .then((locationResponse) => {
          zonesService
            .createZoneLocation(idZone, {
              locationId: locationResponse.id,
              included: isIncluded,
            })
            .then((zoneLocationResponse) => {
              resolve([locationResponse, zoneLocationResponse]);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  const create = (body) => {
    setIsLoading(true);
    zonesService
      .createZone(body)
      .then((response) => {
        const promiseLocationIncluded = includedLocation.map((location) =>
          saveLocation(response.id, locationNormalizerForZone(location), true)
        );
        const promiseLocationExluded = excludedLocation.map((location) =>
          saveLocation(response.id, locationNormalizerForZone(location), false)
        );

        const allPromises = Promise.all([
          ...promiseLocationIncluded,
          ...promiseLocationExluded,
        ]);

        allPromises
          .then(() => {
            setIsLoading(false);
            toast.success(dictionary.messages.save_successful, {
              position: toast.POSITION.TOP_CENTER,
            });
            modalToogle();
            refreshTable(pagination);
          })
          .catch((err) => {
            setIsLoading(false);
            callErrorToast(err, dictionary);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        callErrorToast(err, dictionary);
      });
  };

  const handleLocationChange = (place) => {
    setSelectedLocation({
      ...place,
      normalized: locationNormalizerForZone(place),
    });
  };

  const isValidLocation = (location) => {
    const includedFiltered = includedLocation.filter((l) => {
      return l.value === location.value;
    });
    const exludedFiltered = excludedLocation.filter((l) => {
      return l.value === location.value;
    });

    return includedFiltered.length === 0 && exludedFiltered.length === 0;
  };

  const addToExludedList = () => {
    if (selectedLocation && isValidLocation(selectedLocation)) {
      setExcludedLocation([...excludedLocation, selectedLocation]);
    } else {
      toast.error(dictionary.messages.element_already_present_or_invalid, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const addToIncludedList = () => {
    if (selectedLocation && isValidLocation(selectedLocation)) {
      setIncludedLocation([...includedLocation, selectedLocation]);
    } else {
      toast.error(dictionary.messages.element_already_present_or_invalid, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      pub: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(dictionary.messages.mandatory_field),
      description: Yup.string(),
    }),
    onSubmit: (values) => {
      create(values);
    },
  });

  return (
    <React.Fragment>
      <Loader
        show={isLoading}
        message={dictionary.messages.saving_in_progress}
      />
      <Form onSubmit={validation.handleSubmit}>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.registries.name} <span className="text-danger">*</span>
          </Label>
          <Col lg={9}>
            <Input
              type="text"
              name="name"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.registries.description}
          </Label>
          <Col lg={9}>
            <Input
              type="text"
              name="description"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={
                validation.touched.description && validation.errors.description
                  ? true
                  : false
              }
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">
                {validation.errors.description}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Label className="form-check float-end">
              <Input
                name="pub"
                type="checkbox"
                className="form-check-input form-check-input-success"
                value={validation.pub}
                checked={validation.pub}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              <span className="form-check-label">
                {dictionary.locations.public_zone}
              </span>
            </Label>
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.actions.research}
          </Label>
          <Col lg={9}>
            <LocationAutocomplete
              onLocationChange={handleLocationChange}
              locationRef={locationRef}
              name="location"
              placeholder="es. Italia"
            ></LocationAutocomplete>
          </Col>
        </Row>
        <Row className="mb-3 d-flex flex-row">
          <Col sm={6} className="justify-content-start d-flex flex-column">
            <Button
              type="button"
              color="success"
              outline
              onClick={addToIncludedList}
            >
              {dictionary.actions.add_to}{" "}
              {dictionary.locations.included_locations.toLowerCase()}
            </Button>
            {includedLocation.map((location) => {
              return (
                <LocationListElement
                  key={location.value}
                  location={location}
                  icon="ph-check-circle"
                  iconColor={"green"}
                  handleDelete={deleteIncludedLocation}
                />
              );
            })}
          </Col>
          <Col sm={6} className=" d-flex flex-column">
            <Button
              type="button"
              color="danger"
              outline
              onClick={addToExludedList}
            >
              {dictionary.actions.add_to}{" "}
              {dictionary.locations.excluded_locations.toLowerCase()}
            </Button>
            {excludedLocation.map((location) => {
              return (
                <LocationListElement
                  key={location.value}
                  location={location}
                  icon="ph-prohibit-inset"
                  iconColor={"red"}
                  handleDelete={deleteExcludedLocation}
                />
              );
            })}
          </Col>
        </Row>
        <div className="d-flex justify-content-end ">
          <Button type="submit" className="btn btn-primary ms-3">
            {dictionary.actions.save}{" "}
            <i className="ph-paper-plane-tilt ms-2"></i>
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default Create;
