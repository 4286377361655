import React, { createContext, useContext, useEffect, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";
import { callErrorToast } from "../../../utilities";

const DraftOrderContext = createContext();

const ordersService = new OrdersService();

const DraftOrdersProvider = ({ children, data, callback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);

  const draftOrders = () => {
    setOrdersLoader(true);
    const promises = orders.map((elem) => {
      return ordersService.draft(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setOrdersLoader(false);
        if (callback) {
          callback(response);
        }
      })
      .catch((err) => {
        setOrdersError(err);
      });
  };

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <DraftOrderContext.Provider
      value={{
        orders,
        ordersError,
        ordersLoader,
        draftOrders,

        callback,
      }}
    >
      {children}
    </DraftOrderContext.Provider>
  );
};

const useDraftOrderContext = () => {
  return useContext(DraftOrderContext);
};

export { DraftOrdersProvider, useDraftOrderContext };
