import React from "react";
import { CollectionsService } from "../../services/shipment/collections";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { callErrorToast, callSuccessToast } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const collectionsService = new CollectionsService();

const Book = ({ id, unmountCallback }) => {
  const { dictionary } = useAppContext();

  const bookCollection = () => {
    collectionsService
      .book(id)
      .then((response) => {
        if (unmountCallback) {
          unmountCallback(true);
        }
        callSuccessToast({ status: 200 }, dictionary);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <form>
        <label>{dictionary.collections.ask_book_collection}</label>
        <div className="row mt-3">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (unmountCallback) {
                  unmountCallback(false);
                }
              }}
            >
              {dictionary.actions.cancel}
            </button>
            <Button onClick={bookCollection} type="button" className="btn ms-3">
              {dictionary.actions.book}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Book;
