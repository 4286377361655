import React from "react";
import InputBox from "./InputBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const InputBoxIcon = ({
  value,
  name,
  type = "text",
  placeholder,
  className = "",
  onChange,
  readOnly = false,
  disabled = false,
  error = "",
  min,
  max,
  precision = 2,
  dataId,
  icon = faSearch,
  position = "left",
  required = false,
}) => {
  return (
    <div
      className={`input-box-icon ${position} w-100 ${error ? "invalid" : ""}`}
    >
      <FontAwesomeIcon icon={icon} className="icon" />
      <InputBox
        type={type}
        name={name}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        error={error}
        min={min}
        max={max}
        precision={precision}
        dataId={dataId}
        required={required}
      />
    </div>
  );
};

export default InputBoxIcon;
