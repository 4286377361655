import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { isEqual } from "lodash";
import { WarehouseArticlesService } from "../../../services/logistic/warehouseArticles.ts";
import { callErrorToast } from "../../../utilities/utilities";
import {
  convertWarehouseArticleToCreate,
  convertWarehouseArticleToUpdate,
  WarehouseArticle,
} from "../../../Models/WarehouseArticle";

// Create an instance of the WarehouseArticlesService to handle API calls
const warehouseArticlesService = new WarehouseArticlesService();

interface HandlerWarehouseArticleProviderProps {
  id?: string; // Optional ID of the warehouse article (if editing)
  parentId: string; // ID of the parent entity for the warehouse article
  children: ReactNode; // React children components that will be wrapped by the provider
  callback?: () => void; // Optional function to execute after a successful action
  autosave: boolean; // Whether changes should be automatically saved
  shippingRequired: boolean; // Indicates if shipping is required for this article
}

interface HandlerWarehouseArticleContextValue {
  data: Partial<WarehouseArticle>; // The warehouse article data being managed
  error: ErrorResponse | null; // Stores any errors encountered during API calls
  loader: boolean; // Indicates whether an API request is in progress
  edit: (
    warehouseArticle: WarehouseArticle,
    property: keyof WarehouseArticle | null,
    save: boolean
  ) => void; // Function to edit/update the warehouse article
  onSubmit: (id: string) => void; // Function to submit (create or update) the warehouse article
  shippingRequired: boolean; // Indicates if shipping is required
  callback?: () => void; // Optional function to execute after a successful action
}

interface ErrorResponse {
  response?: {
    data: Partial<WarehouseArticle>;
  };
}

// Create the context with an initial value of undefined
const HandlerWarehouseContext = createContext<
  HandlerWarehouseArticleContextValue | undefined
>(undefined);

// Provider component that manages the warehouse article creation/editing process
const HandlerWarehouseArticleProvider: React.FC<
  HandlerWarehouseArticleProviderProps
> = ({
  id,
  parentId,
  children,
  autosave = false,
  callback,
  shippingRequired,
}) => {
  const [data, setData] = useState<Partial<WarehouseArticle>>({}); // State to store warehouse article data
  const [loader, setLoader] = useState<boolean>(true); // State to track loading status
  const [error, setError] = useState<ErrorResponse | null>(null); // State to store potential errors
  const prevError = useRef<ErrorResponse>(null); // Stores previous error to prevent redundant updates

  /**
   * Fetches a warehouse article by its ID and updates the state.
   * @param id - The ID of the warehouse article to retrieve.
   */
  const getWarehouseArticle = (id: string): void => {
    setLoader(true);
    warehouseArticlesService
      .get(id)
      .then((res) => {
        setLoader(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Handles submission of the warehouse article.
   * If an ID exists, the article is updated; otherwise, a new one is created.
   * @param id - The ID of the warehouse article (if updating).
   */
  const onSubmit = (id: string): void => {
    if (id) {
      edit(data as WarehouseArticle, null, true);
      return;
    }
    create();
  };

  /**
   * Creates a new warehouse article using the provided data.
   */
  const create = (): void => {
    setLoader(true);
    const warehouseToCreate = convertWarehouseArticleToCreate(
      data as WarehouseArticle,
      parentId
    );

    warehouseArticlesService
      .create(warehouseToCreate)
      .then(() => {
        setLoader(false);
        if (callback) {
          callback(); // Execute callback if provided
        }
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Edits an existing warehouse article.
   * @param warehouseArticle - The warehouse article object being edited.
   * @param property - The specific property being edited (optional).
   * @param save - Whether to immediately save the changes.
   */
  const edit = (
    warehouseArticle: WarehouseArticle,
    property: keyof WarehouseArticle | null,
    save: boolean
  ): void => {
    if (property) {
      removeError(property); // Remove any existing validation error for the edited property
    }
    if (save || autosave) {
      setLoader(true);
      const warehouseUpdated =
        convertWarehouseArticleToUpdate(warehouseArticle);
      warehouseArticlesService
        .edit(warehouseUpdated)
        .then(() => {
          setLoader(false);
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setLoader(false); // Ensure loading state is reset even if an error occurs
          setError(err); // Store the error for error handling
        });
      return;
    }
    setData(warehouseArticle); // Update local state without saving
  };

  /**
   * Removes a validation error for a specific property.
   * @param property - The property for which the error should be removed.
   */
  const removeError = (property: keyof WarehouseArticle) => {
    if (error && error.response) {
      const { data } = error.response;
      if (data && property in data) {
        const { [property]: _, ...newData } = data;
        setError({ response: { data: newData } });
        prevError.current = { response: { data: newData } };
      }
    }
  };

  /**
   * Effect to display an error toast whenever an error occurs.
   */
  useEffect(() => {
    if (
      error &&
      typeof error === "object" &&
      !isEqual(prevError.current, error)
    ) {
      prevError.current = error;
      callErrorToast(error);
    }
  }, [error]);

  /**
   * Effect to fetch warehouse article data when the component mounts.
   * If an `id` is provided, fetches the corresponding article; otherwise, disables loading.
   */
  useEffect(() => {
    if (id) {
      getWarehouseArticle(id);
      return;
    }
    setLoader(false);
  }, []);

  return (
    <HandlerWarehouseContext.Provider
      value={{
        data,
        loader,
        error,
        shippingRequired,
        edit,
        onSubmit,
        callback,
      }}
    >
      {children}
    </HandlerWarehouseContext.Provider>
  );
};

/**
 * Custom hook to access the HandlerWarehouseContext.
 * Ensures that the context is used only within a HandlerWarehouseArticleProvider.
 */
const useHandlerContext = (): HandlerWarehouseArticleContextValue => {
  const context = useContext(HandlerWarehouseContext);
  if (!context) {
    throw new Error(
      "useHandlerWarehouseArticleContext must be used within a HandlerWarehouseArticleProvider"
    );
  }
  return context;
};

// Export the provider and custom hook for use in other components
export { HandlerWarehouseArticleProvider, useHandlerContext };
