import { useState, useRef } from "react"; // Import React hooks
import debounce from "lodash.debounce"; // Import lodash debounce for delayed API calls
import Select, { GroupBase, MultiValue, SingleValue } from "react-select"; // Import React-Select components for customizable select input
import { FormFeedback } from "reactstrap"; // Import FormFeedback for error messages
import { useAppContext } from "../../../AppProvider"; // Import application context to access dictionary for messages
import { useStyle } from "../utilities"; // Import custom styles based on error state

interface SyncSelectProps<T extends Record<string, any>> {
  name: string; // Name of the select field
  value: T | T[] | null; // Selected value(s)
  optionStyle?: (option: T) => React.ReactNode | string; // Optional function to style the options
  onChange: (name: string, value: T | T[] | null) => void; // Callback to handle search and selection
  loadOptions?: () => Promise<{ data?: T[] }>; // Function to load options
  options?: T[];
  isMulti?: boolean; // Flag to allow multi-selection
  error?: string; // Error message to display
  optionValue?: keyof T; // The key in the option object for the option value
  optionLabel?: keyof T; // The key in the option object for the option label
  isClearable?: boolean; // Flag to allow clearing the selection
  placeholder?: string; // Placeholder text for the input field
  isDisabled?: boolean; // Flag to disable the select input
  closeMenuOnSelect?: boolean; // Flag to close the menu when an option is selected
  noOptionsMessage?: string; // Custom message to display when no options are available
}

const SyncSelect = <T extends Record<string, any>>({
  name,
  value,
  optionStyle,
  onChange,
  loadOptions,
  options,
  isMulti = false,
  error = "",
  optionValue = "value",
  optionLabel = "label",
  isClearable = true,
  placeholder = "Select",
  isDisabled = false,
  closeMenuOnSelect = true,
  noOptionsMessage = "",
}: SyncSelectProps<T>) => {
  // State to manage the options, loading state, search value, and pagination
  const [allOptions, setAllOptions] = useState<T[]>(options || []); // Stores the options to display
  const [loading, setLoading] = useState<boolean>(false); // Flag to indicate loading state

  const { dictionary } = useAppContext(); // Access dictionary for internationalization (e.g., "no results found" message)
  const styles = useStyle<T>(error); // Custom styles based on error state

  const getOptions = useRef(async (callback: typeof loadOptions) => {
    if (callback) {
      setLoading(true); // Set loading state before fetching data
      try {
        const response = await callback(); // Call the loadOptions function
        const formattedOptions: T[] = (response.data || []).map((elem: T) => ({
          ...elem,
          value: elem[optionValue] as T[keyof T], // Map the value field
          label: elem[optionLabel] as T[keyof T], // Map the label field
        }));
        setAllOptions(formattedOptions); // Set the fetched options
      } catch (error) {
        console.error("Error loading options", error); // Handle any errors during the API call
      } finally {
        setLoading(false); // Set loading state to false once the fetch is complete
      }
    }
  }).current;

  return (
    <div style={{ width: "100%" }}>
      <Select<T, typeof isMulti, GroupBase<T>>
        maxMenuHeight={200} // Maximum height for the dropdown menu
        isMulti={isMulti} // Allow multi-selection
        options={allOptions} // List of options to display
        closeMenuOnSelect={closeMenuOnSelect} // Close the dropdown after selecting an option
        noOptionsMessage={() =>
          noOptionsMessage || dictionary.messages.no_results_found
        } // Message displayed when no options are found
        getOptionValue={(option) => option[optionValue] as string} // Key for the option's value
        getOptionLabel={(option) => option[optionLabel] as string} // Key for the option's label
        formatOptionLabel={(option) =>
          optionStyle ? optionStyle(option) : (option[optionLabel] as string)
        } // Custom styling for options
        isClearable={isClearable} // Allow clearing the selection
        placeholder={placeholder} // Placeholder text for the input field
        onChange={(selected) => {
          // Handle selection change
          const newValue = isMulti
            ? (selected as MultiValue<T>).map((option) => ({ ...option }))
            : (selected as SingleValue<T>) || null;
          onChange(name, newValue); // Trigger the onSearch callback with the selected value
        }}
        isSearchable={true}
        onMenuOpen={() => {
          // Trigger search when the menu opens if searchOnOpen is true
          if (loadOptions) {
            getOptions(loadOptions);
          }
        }}
        onMenuClose={() => {
          if (loadOptions) {
            setAllOptions([]);
          }
        }}
        isLoading={loading} // Show loading spinner
        className={error ? "is-invalid" : ""} // Add invalid class if there's an error
        value={value} // Set the current selected value
        isDisabled={isDisabled} // Disable the select input if needed
        styles={styles} // Apply custom styles
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}{" "}
    </div>
  );
};

export default SyncSelect; // Export the component
