import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Label, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AddressBookService } from "../../services/registry-service/addressbook";
import { LocationsService } from "../../services/registry-service/locations";
import { LocationAutocomplete } from "../../Components/common/LocationAutocomplete";
import { locationNormalizer } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const addressBookService = new AddressBookService();
const locationsService = new LocationsService();

const defaultLocation = {
  countryIsoCode3: "",
  houseNumber: "",
  postalCode: "",
  street: "",
  municipality: "",
  district: "",
  subRegion: "",
  subRegionName: "",
  label: "",
  region: "",
  regionName: "",
  unit: "",
  languageCode: "IT" || null,
  matchLevelCode: "",
  latitude: "",
  longitude: "",
};

const Create = ({ modalToogle, refreshTable, params }) => {
  const { dictionary } = useAppContext();
  const [location, setLocation] = useState({ ...defaultLocation });
  const selectLocationsRef = useRef();

  const createLocation = () => {
    locationsService
      .create(validationLocation.values)
      .then((response) => {
        let addressBook = validationAddressBook.values;
        addressBook.locationId = response.id;
        addressBook.myAddress = params.myAddress;
        addressBook.ownerId = params.companyId;
        createAddressBook(addressBook);
      })
      .catch((err) => {
        toast.error(dictionary.messages.save.location_error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const createAddressBook = (addressBook) => {
    addressBookService
      .create(addressBook)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success(dictionary.messages.save.successful, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        toast.error(dictionary.messages.save.addressbook_error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleSelectAddressChange = (option, event) => {
    const optionNormalized = locationNormalizer(option);
    const location = optionNormalized ? { ...optionNormalized } : {};
    setLocation(location);
  };

  const validationAddressBook = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      contactName: "",
      email: "",
      phone: "",
      sdi: "",
      vatNumber: "",
      externalReference: "",
      mobilePhone: "",
      priv: false,
      ztl: false,
      gdo: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(dictionary.messages.mandatory_field),
      contactName: Yup.string().required(dictionary.messages.mandatory_field),
      email: Yup.string().matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        dictionary.messages.enter_a_valid_email
      ),
    }),
    onSubmit: (values) => {
      validationLocation.handleSubmit();
    },
  });

  const validationLocation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...location,
    },
    validationSchema: Yup.object({
      countryIsoCode3: Yup.string().required(
        dictionary.messages.mandatory_field
      ),
    }),
    onSubmit: (values) => {
      createLocation();
    },
  });

  return (
    <React.Fragment>
      <Form onSubmit={validationAddressBook.handleSubmit}>
        <div className="row">
          <div className="col">
            <label className="form-label">
              {dictionary.registries.company_name}:
            </label>
            <Input
              type="text"
              name="name"
              className="form-control"
              onChange={validationAddressBook.handleChange}
              onBlur={validationAddressBook.handleBlur}
              value={validationAddressBook.values.name || ""}
              invalid={
                validationAddressBook.touched.name &&
                validationAddressBook.errors.name
                  ? true
                  : false
              }
            />
            {validationAddressBook.touched.name &&
            validationAddressBook.errors.name ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.name}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">{dictionary.registries.name}:</label>
            <Input
              type="text"
              name="contactName"
              className="form-control"
              placeholder="Mario Rossi"
              onChange={validationAddressBook.handleChange}
              onBlur={validationAddressBook.handleBlur}
              value={validationAddressBook.values.contactName || ""}
              invalid={
                validationAddressBook.touched.contactName &&
                validationAddressBook.errors.contactName
                  ? true
                  : false
              }
            />
            {validationAddressBook.touched.contactName &&
            validationAddressBook.errors.contactName ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.contactName}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label className="form-label">{dictionary.registries.email}:</label>
            <Input
              name="email"
              className="form-control"
              placeholder="mario.rossi@email.it"
              onChange={validationAddressBook.handleChange}
              onBlur={validationAddressBook.handleBlur}
              value={validationAddressBook.values.email || ""}
              invalid={
                validationAddressBook.touched.email &&
                validationAddressBook.errors.email
                  ? true
                  : false
              }
            />
            {validationAddressBook.touched.email &&
            validationAddressBook.errors.email ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.email}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">{dictionary.registries.phone}:</label>
            <Input
              name="phone"
              className="form-control"
              placeholder="0119696384"
              onChange={validationAddressBook.handleChange}
              onBlur={validationAddressBook.handleBlur}
              value={validationAddressBook.values.phone || ""}
              invalid={
                validationAddressBook.touched.phone &&
                validationAddressBook.errors.phone
                  ? true
                  : false
              }
            />
            {validationAddressBook.touched.phone &&
            validationAddressBook.errors.phone ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.phone}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label className="form-label">SDI:</label>
            <Input
              name="sdi"
              className="form-control"
              onChange={validationAddressBook.handleChange}
              onBlur={validationAddressBook.handleBlur}
              value={validationAddressBook.values.sdi || ""}
              invalid={
                validationAddressBook.touched.sdi &&
                validationAddressBook.errors.sdi
                  ? true
                  : false
              }
            />
            {validationAddressBook.touched.sdi &&
            validationAddressBook.errors.sdi ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.sdi}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">
              {dictionary.registries.vat_number}:
            </label>
            <Input
              name="vatNumber"
              className="form-control"
              placeholder="86334519757"
              onChange={validationAddressBook.handleChange}
              onBlur={validationAddressBook.handleBlur}
              value={validationAddressBook.values.vatNumber || ""}
              invalid={
                validationAddressBook.touched.vatNumber &&
                validationAddressBook.errors.vatNumber
                  ? true
                  : false
              }
            />
            {validationAddressBook.touched.vatNumber &&
            validationAddressBook.errors.vatNumber ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.vatNumber}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">
              {dictionary.references.external_reference}:
            </label>
            <Input
              name="externalReference"
              className="form-control"
              placeholder="EXTREF"
              onChange={validationAddressBook.handleChange}
              onBlur={validationAddressBook.handleBlur}
              value={validationAddressBook.values.externalReference || ""}
              invalid={
                validationAddressBook.touched.externalReference &&
                validationAddressBook.errors.externalReference
                  ? true
                  : false
              }
            />
            {validationAddressBook.touched.externalReference &&
            validationAddressBook.errors.externalReference ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.externalReference}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label className="form-check mb-2">
              <Input
                name="priv"
                type="checkbox"
                className="form-check-input form-check-input-success"
                onChange={validationAddressBook.handleChange}
                onBlur={validationAddressBook.handleBlur}
                value={validationAddressBook.values.priv || false}
                checked={validationAddressBook.values.priv}
                invalid={
                  validationAddressBook.touched.priv &&
                  validationAddressBook.errors.priv
                    ? true
                    : false
                }
              />
              <span className="form-check-label">
                {dictionary.registries.private}
              </span>
            </label>
            {validationAddressBook.touched.priv &&
            validationAddressBook.errors.priv ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.priv}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-check mb-2">
              <Input
                type="checkbox"
                name="gdo"
                className="form-check-input form-check-input-success"
                onChange={validationAddressBook.handleChange}
                onBlur={validationAddressBook.handleBlur}
                value={validationAddressBook.values.gdo || false}
                checked={validationAddressBook.values.gdo}
                invalid={
                  validationAddressBook.touched.gdo &&
                  validationAddressBook.errors.gdo
                    ? true
                    : false
                }
              />
              <span className="form-check-label">GDO</span>
            </label>
            {validationAddressBook.touched.gdo &&
            validationAddressBook.errors.gdo ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.gdo}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-check mb-2">
              <Input
                type="checkbox"
                name="ztl"
                className="form-check-input form-check-input-success"
                onChange={validationAddressBook.handleChange}
                onBlur={validationAddressBook.handleBlur}
                value={validationAddressBook.values.ztl || false}
                checked={validationAddressBook.values.ztl}
                invalid={
                  validationAddressBook.touched.ztl &&
                  validationAddressBook.errors.ztl
                    ? true
                    : false
                }
              />
              <span className="form-check-label">ZTL</span>
            </label>
            {validationAddressBook.touched.ztl &&
            validationAddressBook.errors.ztl ? (
              <FormFeedback type="invalid">
                {validationAddressBook.errors.ztl}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col">
            <Input
              type="hidden"
              name="countryIsoCode3"
              className="form-control"
              placeholder="Via Roma"
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.countryIsoCode3 || ""}
              invalid={
                validationLocation.touched.countryIsoCode3 &&
                validationLocation.errors.countryIsoCode3
                  ? true
                  : false
              }
            />
            <LocationAutocomplete
              onLocationChange={handleSelectAddressChange}
              locationRef={selectLocationsRef}
              placeholder={dictionary.actions.search}
            />
            {validationLocation.touched.countryIsoCode3 &&
            validationLocation.errors.countryIsoCode3 ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.countryIsoCode3}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label className="form-label">{dictionary.locations.street}:</label>
            <Input
              type="text"
              name="street"
              className="form-control"
              placeholder="Via Roma"
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              disabled={!validationLocation.values.countryIsoCode3}
              value={validationLocation.values.street || ""}
              invalid={
                validationLocation.touched.street &&
                validationLocation.errors.street
                  ? true
                  : false
              }
            />
            {validationLocation.touched.street &&
            validationLocation.errors.street ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.street}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col-2">
            <label className="form-label">
              {dictionary.locations.house_number}:
            </label>
            <Input
              type="text"
              name="houseNumber"
              className="form-control"
              placeholder="1"
              disabled={!validationLocation.values.countryIsoCode3}
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.houseNumber || ""}
              invalid={
                validationLocation.touched.houseNumber &&
                validationLocation.errors.houseNumber
                  ? true
                  : false
              }
            />
            {validationLocation.touched.houseNumber &&
            validationLocation.errors.houseNumber ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.houseNumber}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label className="form-label">{dictionary.locations.unit}:</label>
            <Input
              type="text"
              name="unit"
              className="form-control"
              placeholder="A"
              disabled={!validationLocation.values.countryIsoCode3}
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.unit || ""}
              invalid={
                validationLocation.touched.unit &&
                validationLocation.errors.unit
                  ? true
                  : false
              }
            />
            {validationLocation.touched.unit &&
            validationLocation.errors.unit ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.unit}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">
              {dictionary.locations.postal_code}:
            </label>
            <Input
              type="text"
              name="postalCode"
              className="form-control"
              placeholder="10123"
              disabled={!validationLocation.values.countryIsoCode3}
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.postalCode || ""}
              invalid={
                validationLocation.touched.postalCode &&
                validationLocation.errors.postalCode
                  ? true
                  : false
              }
            />
            {validationLocation.touched.postalCode &&
            validationLocation.errors.postalCode ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.postalCode}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">
              {dictionary.locations.district}
            </label>
            <Input
              type="text"
              name="district"
              className="form-control"
              placeholder="Centro"
              disabled
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.district || ""}
              invalid={
                validationLocation.touched.district &&
                validationLocation.errors.district
                  ? true
                  : false
              }
            />
            {validationLocation.touched.district &&
            validationLocation.errors.district ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.district}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">{dictionary.locations.city}</label>
            <Input
              type="text"
              name="municipality"
              className="form-control"
              placeholder="Torino"
              disabled
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.municipality || ""}
              invalid={
                validationLocation.touched.municipality &&
                validationLocation.errors.municipality
                  ? true
                  : false
              }
            />
            {validationLocation.touched.municipality &&
            validationLocation.errors.municipality ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.municipality}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label className="form-label">
              {" "}
              {dictionary.locations.province}:
            </label>
            <Input
              type="text"
              name="subRegionName"
              className="form-control"
              placeholder="Torino"
              disabled
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.subRegionName || ""}
              invalid={
                validationLocation.touched.subRegionName &&
                validationLocation.errors.subRegionName
                  ? true
                  : false
              }
            />
            {validationLocation.touched.subRegionName &&
            validationLocation.errors.subRegionName ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.subRegionName}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">{dictionary.locations.region}:</label>
            <Input
              type="text"
              name="regionName"
              className="form-control"
              placeholder="Piemonte"
              disabled
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.regionName || ""}
              invalid={
                validationLocation.touched.regionName &&
                validationLocation.errors.regionName
                  ? true
                  : false
              }
            />
            {validationLocation.touched.regionName &&
            validationLocation.errors.regionName ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.regionName}
              </FormFeedback>
            ) : null}
          </div>
          <div className="col">
            <label className="form-label">
              {dictionary.locations.country}:
            </label>
            <Input
              type="text"
              name="countryName"
              className="form-control"
              placeholder="Italia"
              disabled
              onChange={validationLocation.handleChange}
              onBlur={validationLocation.handleBlur}
              value={validationLocation.values.countryName || ""}
              invalid={
                validationLocation.touched.countryName &&
                validationLocation.errors.countryName
                  ? true
                  : false
              }
            />
            {validationLocation.touched.countryName &&
            validationLocation.errors.countryName ? (
              <FormFeedback type="invalid">
                {validationLocation.errors.countryName}
              </FormFeedback>
            ) : null}
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end mt-3">
          <button type="submit" className="btn btn-success ms-3">
            {dictionary.actions.save}{" "}
            <i className="ph-paper-plane-tilt ms-2"></i>
          </button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default Create;
