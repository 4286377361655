import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Collapse,
} from "reactstrap";
import Select from "react-select";
import { contractServiceRuleTypesList } from "../../constants";
import { useAppContext } from "../../AppProvider";

export const defaultRule = {
  ruleType: { code: "FIXED_WEIGHT", name: "Fisso per peso" },
  weightFrom: "",
  weightTo: "",
  price: "",
  stepWeight: "",
};

const Rule = ({ index, item, editRule, removeRule }) => {
  const [rule, setRule] = useState(item);
  const { dictionary } = useAppContext();

  const handleInputChange = (event) => {
    let { name, value, type } = event.target;
    if (value < 0) {
      value = "";
    }

    value = value.replaceAll(",", ".");
    editRule({ ...rule, [name]: value });
  };

  const handleRuleTypeSelectChange = (option, event) => {
    editRule({
      ...rule,
      stepWeight: "",
      [event.name]: option,
    });
  };

  useEffect(() => {
    setRule({ ...item });
  }, [item]);

  return (
    <div className="row align-items-end mb-1">
      <div className="col">
        <Select
          options={contractServiceRuleTypesList}
          name="ruleType"
          onChange={handleRuleTypeSelectChange}
          defaultMenuIsOpen={false}
          className="select"
          value={rule.ruleType || ""}
          defaultValue={{
            code: "FIXED_WEIGHT",
            value: dictionary.services.fixed_weight,
          }}
          getOptionValue={(option) => option["code"]}
          getOptionLabel={(option) => option["name"]}
        />
      </div>
      <div className="col">
        <div className="row">
          <div className="col">
            <Input
              type="text"
              name="weightFrom"
              placeholder="min"
              value={`${rule.weightFrom}` || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="col">
            <Input
              type="text"
              name="weightTo"
              placeholder="max"
              value={`${rule.weightTo}` || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="col">
        <Input
          disabled={rule.ruleType.code === "FIXED_WEIGHT"}
          type="text"
          name="stepWeight"
          placeholder=""
          value={`${rule.stepWeight ? rule.stepWeight : ""}`}
          onChange={handleInputChange}
        />
      </div>
      <div className="col">
        <Input
          placeholder=""
          type="text"
          name="price"
          value={`${rule.price}` || ""}
          onChange={handleInputChange}
        />
      </div>
      <div className="col-1 text-end">
        <button
          title={dictionary.actions.delete}
          type="button"
          className="btn btn-flat-danger btn-icon rounded-pill ms-2"
          onClick={() => removeRule(rule)}
        >
          <i className="ph-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default Rule;
