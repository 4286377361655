import React from "react";
import { CollectionsService } from "../../services/shipment/collections";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { callErrorToast, callSuccessToast } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const collectionsService = new CollectionsService();

const Delete = ({ collections, unmountCallback }) => {
  const { dictionary } = useAppContext();

  const deleteCollections = () => {
    const promises = collections.map((elem) => {
      return collectionsService.delete(elem.id);
    });
    Promise.all(promises)
      .then((response) => {
        unmountCallback();
        callSuccessToast({ status: 200 }, dictionary);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <form>
        <div className="row mb-3">
          <div className="col">
            <label>
              {collections.length > 1
                ? dictionary.collections.ask_delete_collections
                : dictionary.collections.ask_delete_collection}
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-link"
            onClick={() => {
              if (unmountCallback) {
                unmountCallback(false);
              }
            }}
          >
            {dictionary.actions.cancel}
          </button>
          <Button className="btn btn-danger ms-3" onClick={deleteCollections}>
            {dictionary.actions.delete}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Delete;
