import moment from "moment";
import { valueIsEmpty } from "../../utilities";
import { CarrierConfigurationsService } from "../../services/contract/carrierConfigurations";
import { useAppContext } from "../../AppProvider";

const carrierConfigurationsService = new CarrierConfigurationsService();

//#region Defaults
export const contractDefault = {
  name: "",
  description: "",
  type: "",
  externalReference: "",
  validityFrom: new Date(),
  validityTo: new Date(new Date().getFullYear(), 11, 31),
  shipmentType: { code: "EXPRESS", label: "Espressa" },
};
//#endregion

//#region Utilities
export const contractNormalizer = (contract, ownerId) => {
  contract.validityFrom = moment(contract.validityFrom, "DD/MM/YYYY").startOf(
    "day"
  );
  contract.validityTo = moment(contract.validityTo, "DD/MM/YYYY").startOf(
    "day"
  );
  contract.carrierConfigurationId = contract.carrierConfiguration.id;
  contract.type = contract.type.value;
  contract.shipmentType = contract.shipmentType.code;
  contract.ownerId = ownerId;
  contract.carrierCode = contract.carrierConfiguration.carrierCode;
  return contract;
};

export const contractValidation = (contract) => {
  const { dictionary } = useAppContext();

  let errors = {};
  var {
    name,
    carrierConfiguration,
    validityFrom,
    validityTo,
    type,
    shipmentType,
  } = contract;

  if (valueIsEmpty(name)) errors["name"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(carrierConfiguration))
    errors["carrierConfiguration"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(moment(validityFrom)))
    errors["validityFrom"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(moment(validityTo)))
    errors["validityTo"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(type)) errors["type"] = dictionary.messages.mandatory_field;
  if (valueIsEmpty(shipmentType))
    errors["shipmentType"] = dictionary.messages.mandatory_field;
  return errors;
};
//#endregion

//#region API Calls
export const getCarrierConfiguration = (id) => {
  return new Promise((resolve, reject) => {
    carrierConfigurationsService
      .get(id)
      .then((response) => {
        resolve({
          ...response.data,
          value: response.data.id,
          label: response.data.name,
          logoUrl: response.data.carrierLogo,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#endregion
