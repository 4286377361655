import React, { createContext, useContext, ReactNode, useEffect } from "react";

interface ProcessOrderDetailsProviderProps {
  children: ReactNode;
  callback?: () => void;
  parentId: string;
  action: string;
}

interface ProcessOrderDetailsContextValue {
  callback?: () => void;
  parentId: string;
  action: string;
}

const ProcessOrderDetailsContext = createContext<
  ProcessOrderDetailsContextValue | undefined
>(undefined);

const ProcessOrderDetailsProvider: React.FC<
  ProcessOrderDetailsProviderProps
> = ({ children, callback, parentId, action }) => {
  useEffect(() => {
    setTimeout(() => {
      const allInputs = document.querySelectorAll<HTMLInputElement>(
        'input[name="barcode"]'
      );

      for (let i = 0; i < allInputs.length; i++) {
        if (!allInputs[i].value.trim()) {
          allInputs[i].focus();
          break;
        }
      }
    }, 500);
  }, []);

  return (
    <ProcessOrderDetailsContext.Provider value={{ callback, parentId, action }}>
      {children}
    </ProcessOrderDetailsContext.Provider>
  );
};

const useProcessOrderDetailsContext = (): ProcessOrderDetailsContextValue => {
  const context = useContext(ProcessOrderDetailsContext);
  if (!context) {
    throw new Error(
      "useProcessOrderDetailsContext must be used within an ProcessOrderDetailsProvider"
    );
  }
  return context;
};

export { ProcessOrderDetailsProvider, useProcessOrderDetailsContext };
