import { AxiosError } from "axios";
import { toast } from "react-toastify";
import it from "./dictionaries/it.json";
import en from "./dictionaries/en.json";
import fr from "./dictionaries/fr.json";

const dictionaries: Record<string, any> = { it, en, fr };
/**
 * Returns the dictionary for the user's selected language.
 *
 * This function retrieves the preferred language from `localStorage` or
 * `navigator.language`. If the language is not supported, English is used as a fallback.
 *
 * @returns {object} The dictionary corresponding to the user's language.
 */
export const getDictionary = (): Record<string, any> => {
  const userLanguage =
    localStorage.getItem("language") || navigator.language || "en";
  const normalizedLanguage = userLanguage.split("-")[0];
  return dictionaries[normalizedLanguage] || dictionaries["en"];
};

/**
 * Retrieves the error message associated with a specific field in an HTTP error response.
 *
 * This function checks if the provided error is an instance of `AxiosError` and,
 * if so, extracts the error message for the specified field from the API response.
 *
 * @param {unknown} error - The error received from the HTTP request.
 * @param {string} field - The name of the field for which to retrieve the error message.
 * @returns {string | undefined} The error message for the specified field, or `undefined` if not available.
 */
export const getFieldError = (
  error: unknown,
  field: string
): string | undefined => {
  if (error && typeof error === "object") {
    //BE error
    if ("isAxiosError" in error) {
      return (error as AxiosError<{ [key: string]: string }>).response?.data?.[
        field
      ];
    }
    //FE error
    return (error as { response: { data: Record<string, string> } }).response
      .data[field];
  }

  return undefined;
};

/**
 * Displays an error toast notification based on the provided error object.
 *
 * This function processes different types of errors and extracts meaningful messages
 * to display as toast notifications. It handles network errors, HTTP status codes (400, 404, 500),
 * and any unexpected errors. If a dictionary object is provided, it uses localized error messages.
 *
 * @param err - The error object, which can be of any type (preferably an object with `code` and `response` properties).
 * @param dictionary - An optional dictionary object containing localized messages. Defaults to the current language from `dictionaries`.
 *
 * @example
 * ```ts
 * try {
 *   await fetchData();
 * } catch (error) {
 *   callErrorToast(error);
 * }
 * ```
 */
export const callErrorToast = (
  err: unknown,
  dictionary: any = dictionaries[
    localStorage.getItem("language") || navigator.language || "en"
  ]
) => {
  let message = "";

  if (typeof err === "object" && err !== null) {
    const errorObj = err as {
      code?: string;
      response?: { status?: number; data?: any; message?: string };
    };

    if (errorObj.code === "ERR_NETWORK") {
      message = dictionary.messages.network_error;
    } else {
      switch (errorObj.response?.status) {
        case 400:
          message =
            "400 - Bad Request - " +
            (errorObj.response.data
              ? JSON.stringify(errorObj.response.data)
              : errorObj.response.message || "");
          break;
        case 404:
          message = "404 - Not Found";
          break;
        case 500:
          message = "500 - Internal Server Error";
          break;
        default:
          message = `Internal Web Error: ${JSON.stringify(err)}`;
      }
    }
  } else {
    message = `Unexpected Error: ${JSON.stringify(err)}`;
  }

  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};
