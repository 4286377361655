import React, { useState, useEffect } from "react";
import ProfileDropdown from "./ProfileDropdown";
import { CompaniesService } from "../../services/registry/companies";
import { callErrorToast } from "../../utilities";
import Logo from "../../assets/images/logo-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../AppProvider";
import LanguageDropdown from "./LanguageDropdown";

const companiesService = new CompaniesService();

const Header = (props) => {
  const { dictionary, accessTokenPayload } = useAppContext();

  const [company, setCompany] = useState(null);

  const getCompany = () => {
    companiesService
      .getCompany(accessTokenPayload.owner)
      .then((response) => {
        setCompany(response.data || null);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <React.Fragment>
      <div className="d-block d-lg-none navbar shadow p-0">
        {/* <div className="container-fluid"> */}
        <div className="row align-items-center">
          <div className="col">
            <div
              className="hamburger"
              onClick={() => props.toggleMenu(!props.isSidebarOpen)}
            >
              <div className={props.isSidebarOpen ? "line open" : "line"}></div>
              <div className={props.isSidebarOpen ? "line open" : "line"}></div>
              <div className={props.isSidebarOpen ? "line open" : "line"}></div>
            </div>
          </div>
          <div className="col">
            <img src={Logo} width={100} className="d-block mx-auto" />
          </div>
          <div className="col text-end">
            <ProfileDropdown company={company} />
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="d-none d-lg-block navbar navbar-expand-lg shadow p-0">
        <div className="container-fluid">
          <div className="navbar-collapse justify-content-center flex-lg-1 order-2 order-lg-1 collapse"></div>
          <div
            className={`d-none d-lg-block page-name ${
              props.isSidebarOpen ? "open" : ""
            }`}
          >
            {props.pageIcon && (
              <FontAwesomeIcon
                size="lg"
                icon={props.pageIcon}
                className="me-2"
              />
            )}
            <label className="text-uppercase">{props.pageName}</label>
          </div>
          <ul className="nav flex-row justify-content-end order-1 order-lg-2 align-items-center">
            <LanguageDropdown />
            <ProfileDropdown company={company} />
            {/* <WalletDropdown company={company} /> */}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
