import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { AttachmentsService } from "../../../services/registry/attachments";
import { callErrorToast } from "../../../utilities";
import { dictionary } from "../../../utilities/dictionary";

export const PrintShipmentContext = createContext();

const attachmentsService = new AttachmentsService();

const PrintShipmentProvider = ({ children, data, callback }) => {
  const [shipments, setShipments] = useState(data);
  const [shipmentsError, setShipmentsError] = useState(null);
  const [shipmentsLoader, setShipmentsLoader] = useState(false);
  const prevShipmentsError = useRef();

  const printShipments = () => {
    setShipmentsLoader(true);
    attachmentsService
      .exportPDF(data)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "shipments_export_pdf_" + Date.now();
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        setShipmentsLoader(false);

        callback();
      })
      .catch((err) => {
        setShipmentsLoader(false);
        setShipmentsError(err);
      });
  };

  useEffect(() => {
    if (
      shipmentsError &&
      JSON.stringify(prevShipmentsError.current) !==
        JSON.stringify(shipmentsError)
    ) {
      callErrorToast(shipmentsError, dictionary);
    }
  }, [shipmentsError]);

  return (
    <PrintShipmentContext.Provider
      value={{
        shipments,
        shipmentsError,
        shipmentsLoader,
        printShipments,

        callback,
      }}
    >
      {children}
    </PrintShipmentContext.Provider>
  );
};

const usePrintShipmentContext = () => {
  return useContext(PrintShipmentContext);
};

export { PrintShipmentProvider, usePrintShipmentContext };
