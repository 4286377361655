import React from "react";
import { useAppContext } from "../../AppProvider";
import { dictionary } from "../../utilities/dictionary";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { useDeleteShipmentsInOrdersContext } from "./providers/DeleteShipment";

const DeleteShipments = () => {
  const { dictionary } = useAppContext();

  const context = useDeleteShipmentsInOrdersContext();

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label className="label-4 text-center">
            {dictionary.actions.confirm_deletion}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-5 text-center">
            {context.orders?.length > 1
              ? `${dictionary.messages.ask_confirm_deletion} ${
                  context.orders?.length
                } ${dictionary.words.items.toLowerCase()}?`
              : `${dictionary.messages.ask_confirm_deletion}?`}
          </label>
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonDelete
          className="w-50"
          onClick={() => context.deleteShipments()}
          disabled={context.ordersLoader}
          loading={context.ordersLoader}
        />
      </div>
    </React.Fragment>
  );
};

export default DeleteShipments;
