import React, { createContext, useState, useContext, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { BrandSettingsService } from "./services/registry/brandSettings";
import { CompaniesService } from "./services/registry/companies";
import API from "./helpers/API";

import it from "./utilities/dictionaries/it.json";
import en from "./utilities/dictionaries/en.json";
import fr from "./utilities/dictionaries/fr.json";

const brandSettingsService = new BrandSettingsService();
const companiesService = new CompaniesService();

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { accessTokenPayload } = useOidcAccessToken();
  const api = new API();
  const dictionaries = { it, en, fr };

  const [brandSettings, setBrandSettings] = useState();
  const [owner, setOwner] = useState();
  const [domain, setDomain] = useState(api.getURL());
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || navigator.language.slice(0, 2) || "en"
  );
  const [scrollTop, setScrollTop] = useState(0);
  const [contentRef, setContentRef] = useState(null);

  const getBrandSettings = () => {
    brandSettingsService.getBrandSettingPublic().then((res) => {
      if (res.data) {
        setBrandSettings(res.data);
      }
    });
  };

  const getOwner = () => {
    companiesService.getCompany(accessTokenPayload?.owner).then((res) => {
      if (res.data) {
        setOwner(res.data);
      }
    });
  };

  const onChangeLanguage = (language) => {
    localStorage.setItem("language", language);
    setLanguage(language);
  };

  useEffect(() => {
    if (brandSettings?.slug) {
      setDomain(`${brandSettings?.slug}.${api.getURLShort()}`);
    }
  }, [brandSettings]);

  useEffect(() => {
    getBrandSettings();
    if (accessTokenPayload) {
      getOwner();
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        accessTokenPayload,
        brandSettings,
        owner,
        dictionary: dictionaries[language] || dictionaries["en"],
        onChangeLanguage,
        language,
        domain,
        scrollTop,
        setScrollTop,
        contentRef,
        setContentRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
