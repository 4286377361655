import React, { useState, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  buildQuerySort,
  buildQuerySearch,
  modalSettingsDefault,
  getUniqueSelectors,
  orderBykey,
  callErrorToast,
  getShipmentLabel,
  getContractTypeBadge,
  contractTypes,
} from "../../utilities";
import { columns } from "./utilities";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import useFetch from "../../customHooks/useFetch";
import { ContractsService } from "../../services/contract/contracts";
import CreateEdit from "./CreateEdit";
import Delete from "./Delete";
import { useAppContext } from "../../AppProvider";
import { getAllCarriersFromCarrierConfigurations } from "../../utilities/asyncSelectCallAPI";
import { getCarrierFromCarrierIntegrationOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import moment from "moment";
import { shipmentTypesList } from "../../constants";
import { Link } from "react-router-dom";

const contractService = new ContractsService();

const Contracts = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);
  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  const { dictionary, owner } = useAppContext();

  const handleContractState = (contractId, contractState) => {
    contractService
      .editState(contractId, !contractState)
      .then((response) => {
        refreshData();
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/contract-service/contracts?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  const allColumns = orderBykey(
    [
      {
        position: 1,
        Header: dictionary.words.active,
        Cell: ({ row }) => {
          if (accessTokenPayload.owner === row.original.ownerId) {
            return (
              <div className="form-check form-switch">
                <input
                  readOnly
                  onClick={() =>
                    handleContractState(row.original.id, row.original.active)
                  }
                  checked={row.original.active}
                  type="radio"
                  className="form-check-input form-check-input-info cursor-pointer"
                />
              </div>
            );
          }
          return (
            <div className="form-check form-switch disabled">
              <input
                disabled
                checked={row.original.active}
                type="radio"
                className="form-check-input form-check-input-info cursor-pointer"
              />
            </div>
          );
        },
      },
      {
        position: 2,
        Header: dictionary.registries.owner,
        accessor: "owner.name",
        cellClass: "text-nowrap",
        Cell: ({ row, cell }) => {
          if (row.original.ownerId === accessTokenPayload.owner) {
            return <span className="badge bg-success">{cell.value}</span>;
          }
          return (
            <span className="badge bg-primary">
              {dictionary.words.inherited}
            </span>
          );
        },
        filter: {
          active: true,
          name: "ownerId",
          type: "select",
          options: [
            {
              value: accessTokenPayload.owner,
              label: owner?.name,
            },
          ],
          col: 3,
        },
      },
      {
        position: 3,
        Header: dictionary.registries.carrier,
        accessor: "carrier.name",
        filter: {
          active: true,
          name: "carrierCode",
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCarriersFromCarrierConfigurations(
              searchValue,
              pagination,
              {
                orderBy: "asc",
                selector: "name",
              }
            );
          },
          searchKey: "carrierCode",
          multi: true,
          optionStyle: getCarrierFromCarrierIntegrationOptionStyle,
          col: 3,
        },
        Cell: ({ cell }) => {
          const row = cell.row.original;
          if (row.carrierCode) {
            return <img width={50} src={row.carrier.logoUrl} />;
          }
          return "-";
        },
      },
      {
        position: 4,
        Header: dictionary.registries.name,
        accessor: "name",
        sortable: true,
        filter: {
          active: true,
          name: "name",
          type: "search",
        },
      },
      {
        position: 5,
        Header: dictionary.registries.description,
        accessor: "description",
        sortable: false,
      },
      {
        position: 6,
        Header: dictionary.times.validity_from,
        accessor: "validityFrom",
        sortable: true,
        Cell: ({ row }) => {
          const validityFrom = moment(row.values.validityFrom);
          const validityTo = moment(row.values.validityTo);
          const today = moment();

          if (today.isBefore(validityFrom) || today.isAfter(validityTo)) {
            return (
              <span className="text-danger">
                {validityFrom.format("DD/MM/YYYY")}
              </span>
            );
          }

          return validityFrom.format("DD/MM/YYYY");
        },
      },
      {
        position: 7,
        Header: dictionary.times.validity_to,
        accessor: "validityTo",
        sortable: true,
        Cell: ({ row }) => {
          const validityFrom = moment(row.values.validityFrom);
          const validityTo = moment(row.values.validityTo);
          const today = moment();

          if (today.isAfter(validityTo) || today.isBefore(validityFrom)) {
            return (
              <span className="text-danger">
                {validityTo.format("DD/MM/YYYY")}
              </span>
            );
          }

          return validityTo.format("DD/MM/YYYY");
        },
      },
      {
        position: 8,
        Header: dictionary.shipments.shipment_typology,
        accessor: "shipmentType",
        sortable: false,
        Cell: ({ row }) => getShipmentLabel(row.values.shipmentType),
        filter: {
          active: true,
          name: "shipmentType",
          type: "select",
          options: orderBykey(
            shipmentTypesList.map((shipmentType) => {
              return {
                value: shipmentType.code,
                label: getShipmentLabel(shipmentType.code),
              };
            }),
            "label"
          ),
          multi: false,
        },
      },
      {
        position: 9,
        Header: dictionary.contracts.contract_typology,
        accessor: "type",
        sortable: false,
        Cell: ({ row }) => getContractTypeBadge(row.values.type),
        filter: {
          active: true,
          name: "type",
          type: "select",
          options: contractTypes,
          multi: false,
        },
      },
      {
        position: 10,
        Header: dictionary.services.services,
        accessor: "services",
        sortable: false,
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <Link
                className="text-center"
                to={`/contract-services?contractId=${row.original.id}`}
              >
                {row.original.totalServices}
              </Link>
            </div>
          );
        },
      },
      {
        Header: dictionary.words.actions,
        pinning: "right",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className="btn border-0 text-body"
                data-bs-toggle="dropdown"
              >
                <i className="ph-list"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                {accessTokenPayload.owner === original.ownerId && (
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: dictionary.actions.edit,
                        icon: "ph-note-pencil",
                        open: !modalSettings.open,
                        action: "edit",
                        id: original.id,
                      });
                    }}
                  >
                    <i className="ph-note-pencil me-2"></i>
                    {dictionary.actions.edit}
                  </DropdownItem>
                )}
                {accessTokenPayload.owner === original.ownerId && (
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: dictionary.actions.duplicate,
                        icon: "ph-users",
                        open: !modalSettings.open,
                        action: "clone",
                        id: original.id,
                      });
                    }}
                  >
                    <i className="ph-users me-2"></i>
                    {dictionary.actions.duplicate}
                  </DropdownItem>
                )}
                {accessTokenPayload?.owner !== original.ownerId && (
                  <DropdownItem disabled>
                    <i className="ph-magnifying-glass me-2"></i>
                    {dictionary.actions.details}
                  </DropdownItem>
                )}
                {accessTokenPayload.owner === original.ownerId && (
                  <DropdownItem
                    className="text-danger"
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: dictionary.actions.delete,
                        icon: "ph-trash",
                        open: !modalSettings.open,
                        action: "delete",
                        id: original.id,
                      });
                    }}
                  >
                    <i className="ph-trash me-2"></i>
                    {dictionary.actions.delete}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    "position"
  );

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Contratti" breadcrumbItem="Elenco Contratti" />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Nuovo contratto",
                      icon: "ph-plus",
                      action: "create",
                      open: !modalSettings.open,
                    })
                  }
                >
                  Nuovo contratto <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>
            <TableAdvanced
              hasFilter={true}
              columns={allColumns}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              omnisearch={{
                active: false,
                onSearch: setFreeSearch,
                placeholder: "Nome",
              }}
              showLoader={loading}
            />
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modalSettings.open}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "create" && (
            <CreateEdit unmountCallback={modalToggle} />
          )}
          {modalSettings.action === "edit" && (
            <CreateEdit id={modalSettings.id} unmountCallback={modalToggle} />
          )}
          {modalSettings.action === "clone" && (
            <CreateEdit
              id={modalSettings.id}
              unmountCallback={modalToggle}
              isClone={true}
            />
          )}
          {modalSettings.action === "delete" && (
            <Delete id={modalSettings.id} unmountCallback={modalToggle} />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default Contracts;
