import React from "react";
import { useHandlerContext } from "./providers/Handler";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useAppContext } from "../../AppProvider";
import AsyncSelect from "../../Components/Selects/AsyncSelect/AsyncSelect";
// import { getGenericOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFieldError } from "../../utilities/utilities";
import { getAllArticles } from "../../Components/Selects/AsyncSelect/api";
import { FetchParam } from "../../Models/FetchParam";
import { faLoader } from "../../utilities/fontawesomeIcons";
import { WarehouseArticle } from "../../Models/WarehouseArticle";
import { useIndexWarehouseArticlesContext } from "./providers/Index";
import { Article } from "../../Models/Article";

export const Handler = () => {
  const { dictionary } = useAppContext();
  const { data, error, loader, shippingRequired, edit, onSubmit, callback } =
    useHandlerContext();
  const { selectedItemId } = useIndexWarehouseArticlesContext();

  return (
    <React.Fragment>
      <div className="row align-items-center mb-2">
        <div className="col">
          <h3 className="text-center">
            {!data?.id ? dictionary.words.new_sm : dictionary.actions.edit}{" "}
            {dictionary.products.product}
          </h3>
        </div>
      </div>
      {loader ? (
        <div className="text-center mt-2">
          <FontAwesomeIcon icon={faLoader} spin size="2x" />
        </div>
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <label className="label-2">{dictionary.products.product}</label>
              <AsyncSelect
                name="article"
                optionValue="id"
                optionLabel="name"
                value={data.article ?? null}
                onSearch={(name: string, value: Article | Article[] | null) => {
                  edit(
                    {
                      ...data,
                      [name]: value,
                    } as WarehouseArticle,
                    "articleId" as keyof WarehouseArticle,
                    false
                  );
                }}
                loadOptions={(inputValue, pagination) => {
                  const params: FetchParam = {
                    inputValue,
                    pagination,
                    sorting: { selector: "name", orderBy: "asc" },
                    search: [
                      {
                        selector: "id",
                        value: selectedItemId,
                        content: false,
                      },
                      {
                        selector: "name",
                        value: inputValue,
                      },
                      {
                        selector: "shippingRequired",
                        value: shippingRequired,
                        equals: true,
                      },
                    ],
                  };
                  return getAllArticles(params);
                }}
                // optionStyle={getGenericOptionStyle}
                isClearable={false}
                error={getFieldError(error, "articleId")}
                placeholder={dictionary.actions.select}
              />
            </div>
          </div>
          <div className="d-flex mt-4">
            <ButtonUtility
              className="w-50 me-2"
              onClick={() => {
                if (callback) {
                  callback();
                }
              }}
            >
              {dictionary.actions.cancel}
            </ButtonUtility>
            <ButtonConfirm
              className="w-50"
              onClick={onSubmit}
              disabled={loader}
              loading={loader}
            >
              {dictionary.actions.save}
            </ButtonConfirm>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
