import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { callErrorToast } from "../../../utilities"; // Function to handle and display errors
import { WarehouseArticlesService } from "../../../services/logistic/warehouseArticles.ts";

// Create an instance of the WarehouseArticlesService to handle API calls
const warehouseArticlesService = new WarehouseArticlesService();

// Define the props for the DeleteWarehouseArticleProvider component
interface DeleteWarehouseArticleProviderProps {
  children: ReactNode; // React children components that will be wrapped by the provider
  data?: string[]; // List of warehouse article IDs to be deleted (optional, default is empty array)
  callback?: () => void; // Optional function to execute after deletion
}

// Define the context value that will be shared with consumers
interface DeleteWarehouseArticleContextValue {
  loader: boolean; // Indicates whether a deletion request is in progress
  deleteWarehouseArticles: () => void; // Function to trigger the deletion process
  data: string[]; // List of warehouse article IDs
  callback?: () => void; // Optional function to execute after deletion
}

// Create the context with an initial value of undefined
const DeleteWarehouseArticleContext = createContext<
  DeleteWarehouseArticleContextValue | undefined
>(undefined);

// Provider component that manages the deletion process
const DeleteWarehouseArticleProvider: React.FC<
  DeleteWarehouseArticleProviderProps
> = ({ children, data = [], callback }) => {
  const [error, setError] = useState<unknown>(null); // State to store potential errors
  const [loader, setLoader] = useState<boolean>(false); // State to track loading status

  /**
   * Function to delete all warehouse articles in the `data` list.
   * Calls the WarehouseArticlesService to delete each warehouse article and updates the loader state.
   */
  const deleteWarehouseArticles = (): void => {
    setLoader(true); // Set loading state to true before making API calls

    // Create an array of promises by calling the delete method for each warehouse article ID
    const promises = data.map((id) => warehouseArticlesService.delete(id));

    // Execute all delete requests concurrently
    Promise.all(promises)
      .then(() => {
        setLoader(false); // Set loading state to false once deletion is complete

        if (callback) {
          callback(); // Execute the callback function if provided
        }
      })
      .catch((err) => {
        setLoader(false); // Ensure loading state is reset even if an error occurs
        setError(err); // Store the error for error handling
      });
  };

  /**
   * Effect to display an error toast when an error occurs.
   * Triggers when the `error` state changes.
   */
  useEffect(() => {
    if (error) {
      callErrorToast(error); // Display the error using a toast notification
    }
  }, [error]);

  // Provide the loader state and delete function to consumers
  return (
    <DeleteWarehouseArticleContext.Provider
      value={{ loader, deleteWarehouseArticles, data, callback }}
    >
      {children}
    </DeleteWarehouseArticleContext.Provider>
  );
};

/**
 * Custom hook to access the DeleteWarehouseArticleContext.
 * Ensures that the context is used only within a DeleteWarehouseArticleProvider.
 */
const useDeleteWarehouseArticleContext =
  (): DeleteWarehouseArticleContextValue => {
    const context = useContext(DeleteWarehouseArticleContext);

    if (!context) {
      throw new Error(
        "useDeleteWarehouseArticleContext must be used within a DeleteWarehouseArticleProvider"
      );
    }

    return context;
  };

// Export the provider and custom hook for use in other components
export { DeleteWarehouseArticleProvider, useDeleteWarehouseArticleContext };
