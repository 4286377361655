import React, { useEffect } from "react";
import { Form, Row, Label, Button, Col } from "reactstrap";
import { toast } from "react-toastify";
import { AddressBookService } from "../../services/registry-service/addressbook";
import { useAppContext } from "../../AppProvider";
import { callErrorToast } from "../../utilities";

const addressBookService = new AddressBookService();

const Delete = ({ addressBookId, modalToogle, refreshTable, entity }) => {
  const { dictionary } = useAppContext();

  const deleteAddressBook = () => {
    addressBookService
      .delete(addressBookId)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success(dictionary.messages.item_deleted_successfully, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>{dictionary.messages.ask_to_delete}</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToogle();
          }}
        >
          {dictionary.messages.cancel}
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteAddressBook}>
          {dictionary.messages.delete}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
