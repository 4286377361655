import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHandlerOrderContext } from "./providers/Handler";
import { useAppContext } from "../../AppProvider";
import {
  faArrowLeft,
  faArrowRight,
  faEdit,
  faEuro,
  faICursor,
  faInfoCircle,
  faLoader,
  faPercentage,
  faCheck,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import Select from "../../Components/common/Selects/Select";
import {
  getAllCashOnDeliveryCollectionMethods,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import { getWarehouseOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { useTypes } from "../../utilities/types";
import OrderDetails from "../OrderDetails";
import { IndexOrderDetailsProvider } from "../OrderDetails/providers/Index";
import ContactHandler from "../Contacts/ContactHandler";
import { ContactHandlerProvider } from "../Contacts/providers/Handler";
import {
  isDisabledConfirm,
  isDisabledDelete,
  isDisabledDeleteShipments,
  isDisabledDraft,
  isDisabledProcess,
  isDisabledReturnDelivered,
} from "./utilities/list";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { Modal, ModalBody, UncontrolledTooltip } from "reactstrap";
import { ConfirmOrdersProvider } from "./providers/Confirm";
import { DeleteOrdersProvider } from "./providers/Delete";
import { ProcessOrdersProvider } from "./providers/Process";
import Confirm from "./Confirm";
import Delete from "./Delete";
import Process from "./Process";
import Rate from "./Rate";
import moment from "moment";
import InputBoxIcon from "../../Components/common/InputBoxIcon";
import { DeleteShipmentsInOrdersProvider } from "./providers/DeleteShipment";
import DeleteShipments from "./DeleteShipments";
import InputBox from "../../Components/common/InputBox";
import { DraftOrdersProvider } from "./providers/Draft";
import Draft from "./Draft";
import { ReturnDeliveredOrdersProvider } from "./providers/ReturnDelivered";
import ReturnDelivered from "./ReturnDelivered";

const OrderHandler = () => {
  const action = window.location.pathname.split("/")[2];
  const { id } = useParams();
  const navigate = useNavigate();
  const types = useTypes();
  const { dictionary, accessTokenPayload } = useAppContext();

  let context = useHandlerOrderContext();

  let title = `${
    dictionary.words.new_sm
  } ${dictionary.orders.order.toLowerCase()}`;
  switch (action) {
    case "clone":
      title = `${
        dictionary.actions.duplicate
      } ${dictionary.orders.order.toLowerCase()}`;
      break;
    case "edit":
      title = `${
        dictionary.actions.edit
      } ${dictionary.orders.order.toLowerCase()}`;
      break;
    case "details":
      title = `${
        dictionary.actions.details
      } ${dictionary.orders.order.toLowerCase()}`;
      break;
  }

  const handleChange = (name, value, order = { ...context?.order }) => {
    if (action !== "details") {
      const newOrder = {
        ...order,
        [name]: value,
      };

      context?.editOrder(newOrder);
      context?.removeError(name);
    }
  };

  const renderOrderDetailsFooter = (data) => {
    const totalPriceVatIncluded =
      (context?.order?.totalPrice || 0) +
      data.content?.reduce(
        (t, { unitPrice, vat, discount = 0, quantity }) =>
          t + ((unitPrice - discount) * quantity * vat) / 100,
        0
      );

    const totalQuantity = data.content?.reduce(
      (t, { quantity }) => t + quantity,
      0
    );

    return (
      <React.Fragment>
        <div
          className=" d-grid grid-column align-items-end ps-2"
          style={{
            gridTemplateColumns: `0.5fr 1.5fr 2.25fr 0.75fr 1fr 1fr 1fr 0.75fr 1.25fr 0.75fr 1.5fr`,
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div>
            <label className="label-2">
              {dictionary.dimensions.total_quantity}:
            </label>
            <label className="label-3">{totalQuantity}</label>
          </div>
          <div></div>
          <div>
            <label className="label-2">
              {dictionary.dimensions.total_weight}:
            </label>
            <label className="label-3">
              {context?.order?.totalWeight
                ? `${context?.order?.totalWeight.toFixed(3)} kg`
                : "-"}
            </label>
          </div>
          <div></div>
          <div></div>
          <div>
            <label className="label-2">
              {dictionary.words.total_price} ({dictionary.words.vat}{" "}
              {dictionary.words.excluding_fs}):
            </label>
            <label className="label-3">
              {context?.order?.totalPrice >= 0
                ? (context?.order?.totalPrice ?? 0).toFixed(2) + " €"
                : "-"}
            </label>
          </div>
          <div></div>
          <div>
            <label className="label-2">
              {dictionary.words.total_price} ({dictionary.words.vat}{" "}
              {dictionary.words.including_fs})
            </label>
            <label className="label-3">
              {totalPriceVatIncluded >= 0
                ? totalPriceVatIncluded?.toFixed(2) + " €"
                : "-"}
            </label>
          </div>
        </div>
        <hr></hr>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (action === "create") {
      context?.createOrder();
      return;
    } else if (action === "clone") {
      context?.cloneOrder();
      return;
    }
    context?.getOrder(id);
  }, []);

  const status = types.orderStatus.find(
    (s) => s.value === context?.order?.lastStatus
  );
  const shipment = context?.order?.shipments?.[0]?.lastStatusName
    ? context?.order?.shipments[0]
    : null;

  return (
    <React.Fragment>
      <div className="row align-items-center mb-2">
        <div className="col">
          <h2 className="m-0">{title}</h2>
        </div>
        <div className="col-3">
          <div
            style={{
              backgroundColor:
                context?.order.lastStatus === "SHIPPED" && shipment
                  ? shipment.lastStatusColor
                  : status?.color,
            }}
            className={`status w-auto`}
          >
            <span className={`name`}>
              {context?.order.lastStatus === "SHIPPED" && shipment
                ? types.shipmentStatus.find(
                    (s) => s.value === shipment.lastStatusCode
                  )?.label
                : status?.label}
            </span>{" "}
            {shipment?.deliveryDate && (
              <>
                <FontAwesomeIcon
                  id={"deliveryDate_"}
                  className="cursor-pointer"
                  icon={faInfoCircle}
                />{" "}
                <UncontrolledTooltip
                  placement="bottom"
                  target={"deliveryDate_"}
                >
                  {moment(shipment.deliveryDate).format("DD/MM/YYYY HH:mm")}
                </UncontrolledTooltip>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <div className="box-container h-100 mb-2">
            {context?.orderLoader ? (
              <div className="row">
                <div className="col text-center">
                  <FontAwesomeIcon icon={faLoader} spin size="2x" />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <h3 className="m-0">{dictionary.words.general_informations}</h3>
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.references.customer_reference}
                    </label>
                    <InputBoxIcon
                      name="externalReference"
                      value={context?.order?.externalReference || ""}
                      placeholder={action !== "details" ? "EXTREF123" : ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.externalReference
                      }
                      icon={faICursor}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.references.logistic_reference}
                    </label>
                    <InputBoxIcon
                      name="logisticReference"
                      value={context?.order?.logisticReference || ""}
                      placeholder={action !== "details" ? "-" : ""}
                      type="text"
                      readOnly={true}
                      disabled={true}
                      icon={faICursor}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.references.allabox_reference}
                    </label>
                    <InputBoxIcon
                      name="internalReference"
                      value={context?.order?.internalReference || ""}
                      placeholder={action !== "details" ? "EXTREF123" : ""}
                      type="text"
                      readOnly={true}
                      disabled={true}
                      icon={faICursor}
                    />
                  </div>
                </div>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.registries.warehouse}
                    </label>
                    <AsyncSelect
                      name="warehouse"
                      optionValue="id"
                      optionLabel="name"
                      value={context?.order?.warehouse}
                      onSearch={(selector, value) => {
                        handleChange(selector, value);
                      }}
                      loadOptions={(inputValue, pagination) => {
                        return getAllWarehouses(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getWarehouseOptionStyle}
                      isClearable={false}
                      isDisabled={action === "details"}
                      error={context?.orderError?.response?.data?.warehouse}
                      placeholder={action !== "details" ? "Select" : ""}
                    />
                  </div>
                </div>
                <h3 className="m-0 mt-2">
                  {dictionary.words.additional_informations}
                </h3>
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `auto`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.words.payment_method}
                    </label>
                    <InputBoxIcon
                      name="paymentMethod"
                      value={context?.order.paymentMethod || ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      icon={faICursor}
                      error={context?.orderError?.response?.data?.paymentMethod}
                    />
                  </div>
                </div>
                <h3 className="m-0 mt-2">
                  {dictionary.shipments.shipment_informations}
                </h3>
                <div
                  className="d-grid grid-column align-items-end mt-2"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.dimensions.goods_value} (
                      {dictionary.words.vat} {dictionary.words.excluding_fs}){" "}
                      <FontAwesomeIcon
                        id={"goodsValue_"}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />
                    </label>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"goodsValue_"}
                    >
                      {dictionary.orders.calculated_logistics_goods_value}
                    </UncontrolledTooltip>
                    <InputBoxIcon
                      name="goodsValue"
                      value={context?.order.goodsValue || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      icon={faEuro}
                      error={context?.orderError?.response?.data?.goodsValue}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.dimensions.insurance_value} (
                      {dictionary.words.vat} {dictionary.words.excluding_fs}){" "}
                      <FontAwesomeIcon
                        id={"insuranceValue_"}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />
                    </label>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"insuranceValue_"}
                    >
                      {dictionary.orders.calculated_logistics_insurance_value}
                    </UncontrolledTooltip>
                    <InputBoxIcon
                      name="insuranceValue"
                      value={context?.order.insuranceValue || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.insuranceValue
                      }
                      icon={faEuro}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary.dimensions.cash_on_delivery_value} (
                      {dictionary.words.vat} {dictionary.words.including_fs}){" "}
                      <FontAwesomeIcon
                        id={"cash_on_delivery_"}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />
                    </label>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"cash_on_delivery_"}
                    >
                      {
                        dictionary.orders
                          .calculated_logistics_cash_on_delivery_value
                      }
                    </UncontrolledTooltip>
                    <InputBoxIcon
                      name="cashOnDeliveryValue"
                      value={context?.order.cashOnDeliveryValue || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.cashOnDeliveryValue
                      }
                      icon={faEuro}
                      min={1}
                      required={false}
                    />
                  </div>
                </div>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.words.currency}
                    </label>
                    <Select
                      name="currency"
                      onChange={(option) => {
                        handleChange("currency", option);
                      }}
                      defaultMenuIsOpen={false}
                      isDisabled={action === "details"}
                      className="select"
                      options={types.currencies2}
                      value={context?.order?.currency}
                    />
                  </div>
                  {context?.order?.cashOnDeliveryValue > 0 && (
                    <div>
                      <label className="label-2">
                        {dictionary.words.cash_on_delivery_method}
                      </label>
                      <AsyncSelect
                        name="cashOnDeliveryCollectionMethodCode"
                        optionValue="code"
                        optionLabel="name"
                        value={
                          context?.order?.cashOnDeliveryCollectionMethodCode
                        }
                        onSearch={(selector, value) =>
                          handleChange(selector, value)
                        }
                        loadOptions={(inputValue, pagination) => {
                          return getAllCashOnDeliveryCollectionMethods(
                            inputValue,
                            pagination,
                            {
                              orderBy: "asc",
                              selector: "name",
                            }
                          );
                        }}
                        isDisabled={action === "details"}
                        isClearable={false}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr`,
                  }}
                >
                  {context?.order?.shipments?.[0] && (
                    <div>
                      <label className="label-2">{dictionary.words.note}</label>
                      <InputBox
                        name="note"
                        value={context?.order?.shipments?.[0].note || ""}
                        placeholder={""}
                        type="textarea"
                        icon={faICursor}
                        onChange={(e) => {
                          context?.updateShipmentNote(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>

                <h3 className="m-0 mt-2">
                  {dictionary.words.billing_informations}
                </h3>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary.shipments.shipment_price} (
                      {dictionary.words.vat} {dictionary.words.excluding_fs})
                    </label>
                    <InputBoxIcon
                      name="shippingCost"
                      value={context?.order.shippingCost || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) => {
                        const { value, name } = e.target;

                        const newOrder = {
                          ...context?.order,
                          cashOnDeliveryValue:
                            (context?.order.totalPriceVatIncluded || 0) +
                            parseFloat(value || 0) * 1.22,
                        };
                        handleChange(name, value, newOrder);
                      }}
                      disabled={action === "details"}
                      error={context?.orderError?.response?.data?.shippingCost}
                      icon={faEuro}
                    />
                  </div>
                  <div>
                    <label className="label-2">{dictionary.words.vat}</label>
                    <InputBoxIcon
                      name="vatRegime"
                      value={"22"}
                      type="text"
                      readOnly={true}
                      icon={faPercentage}
                      disabled={true}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="col">
          <div className="box-container h-100 mb-2">
            {context?.orderLoader ? (
              <div className="row">
                <div className="col text-center">
                  <FontAwesomeIcon icon={faLoader} spin size="2x" />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <ContactHandlerProvider
                  service="logistic"
                  id={context?.order?.delivery?.id}
                  callback={(contact) => {
                    const newOrder = { ...context?.order };
                    newOrder.delivery = contact;
                    newOrder.deliveryId = contact.id;
                    context?.editOrder(newOrder);
                  }}
                >
                  <ContactHandler
                    action={action}
                    billingSettings={{
                      enabled: true,
                      id: context?.order?.billing?.id,
                      onChange: (billingContact) => {
                        if (action !== "details") {
                          const newOrder = { ...context?.order };
                          newOrder.billing = context?.order?.delivery;
                          newOrder.billingId = context?.order?.delivery.id;

                          if (billingContact) {
                            newOrder.billing = billingContact;
                            newOrder.billingId = billingContact.id;
                          }
                          context?.editOrder(newOrder);
                        }
                      },
                    }}
                    title={
                      <h3 className="m-0">{dictionary.registries.receiver}</h3>
                    }
                  />
                </ContactHandlerProvider>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      {context?.order?.id && (
        <IndexOrderDetailsProvider
          parentId={context?.order?.id}
          action={action}
        >
          <OrderDetails renderFooter={renderOrderDetailsFooter} />
        </IndexOrderDetailsProvider>
      )}

      <div className="row mt-3">
        <div className="col">
          {
            <Link to={`/orders`} className="button-1 button-1-transparent">
              <FontAwesomeIcon icon={faArrowLeft} /> {dictionary.words.back}
            </Link>
          }
        </div>
        <div className="col text-end">
          {context?.order && !isDisabledDraft([context?.order]) && (
            <button
              className="button-1 button-1-transparent me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: `${dictionary.orders.edit_order}`,
                  action: "draft",
                  data: [context?.order?.id],
                  size: "sm",
                });
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
              {dictionary.actions.edit}
            </button>
          )}
          {context?.order && !isDisabledReturnDelivered([context?.order]) && (
            <button
              className="button-1 button-1-transparent me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: `${dictionary.status.return_delivered}`,
                  action: "returnDelivered",
                  data: [context?.order],
                  size: "sm",
                });
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
              {dictionary.status.return_delivered}
            </button>
          )}
          {context?.order && !isDisabledProcess([context?.order]) && (
            <ButtonConfirm
              className="me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: `${
                    dictionary.actions.process
                  } ${dictionary.orders.order.toLowerCase()}`,
                  action: "process",
                  data: [context?.order?.id],
                  size: "sm",
                });
              }}
            >
              {dictionary.actions.process}
            </ButtonConfirm>
          )}
          {context?.order && !isDisabledConfirm([context?.order]) && (
            <ButtonConfirm
              className="me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary.actions.confirm,
                  action: "confirm",
                  data: [context?.order?.id],
                  size: "sm",
                });
              }}
            >
              {dictionary.actions.confirm}
            </ButtonConfirm>
          )}
          {context?.order && !isDisabledDelete([context?.order]) && (
            <ButtonDelete
              className="ms-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary.actions.confirm_deletion,
                  action: "delete",
                  data: [context?.order],
                  size: "sm",
                });
              }}
            />
          )}
          {context?.order && !isDisabledDeleteShipments([context?.order]) && (
            <ButtonDelete
              className="me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary.actions.confirm_deletion,
                  action: "deleteShipment",
                  data: [context?.order],
                  size: "sm",
                });
              }}
            >
              {dictionary.shipments.delete_shipment}
            </ButtonDelete>
          )}
          {context?.createShipmentEnabled &&
            accessTokenPayload.owner ===
              "7f8625b9-973b-4c66-80af-346b49cde2e7" && (
              <ButtonConfirm
                className="me-1"
                onClick={() => {
                  context?.setModal({
                    open: true,
                    title: `${dictionary.words.new_sf} ${dictionary.shipments.shipment}`,
                    action: "createShipment",
                    order: context?.order,
                    size: "xl",
                  });
                }}
              >
                {`${dictionary.words.new_sf} ${dictionary.shipments.shipment}`}
              </ButtonConfirm>
            )}
          {context?.order?.shipments?.[0] && (
            <Link
              target="_blank"
              className={`button-1 button-1-dark`}
              to={"/shipments/details/" + context?.order?.shipments?.[0].id}
            >
              {dictionary.shipments.go_to_shipment}
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          )}
        </div>
      </div>

      <Modal isOpen={context?.modal.open} size={context?.modal.size}>
        <ModalBody>
          {context?.modal.action === "confirm" && (
            <ConfirmOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  navigate("/orders/details/" + id, { replace: true });
                }
                context.setModal({ open: false });
              }}
            >
              <Confirm />
            </ConfirmOrdersProvider>
          )}
          {context?.modal.action === "draft" && (
            <DraftOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  navigate("/orders/edit/" + id, { replace: true });
                }
                context.setModal({ open: false });
              }}
            >
              <Draft />
            </DraftOrdersProvider>
          )}
          {context?.modal.action === "process" && (
            <ProcessOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  context?.getOrder();
                }
                context.setModal({ open: false });
              }}
            >
              <Process />
            </ProcessOrdersProvider>
          )}
          {context?.modal.action === "returnDelivered" && (
            <ReturnDeliveredOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  context?.getOrder();
                }
                context.setModal({ open: false });
              }}
            >
              <ReturnDelivered />
            </ReturnDeliveredOrdersProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  navigate("/orders", { replace: true });
                }
                context.setModal({ open: false });
              }}
            >
              <Delete />
            </DeleteOrdersProvider>
          )}

          {context?.modal.action === "createShipment" && (
            <Rate
              callback={() => {
                context?.setModal({ open: false });
                context?.setCreateShipmentEnabled(false);
                context?.getOrder();
              }}
            />
          )}
          {context?.modal.action === "deleteShipment" && (
            <DeleteShipmentsInOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  window.location.href = "/orders/details/" + id;
                }
                context.setModal({ open: false });
              }}
            >
              <DeleteShipments />
            </DeleteShipmentsInOrdersProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderHandler;
