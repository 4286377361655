import React, { useState, useEffect, useRef } from "react";
import {
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { percentageTargetTypes, surchargeTypes } from "../../constants";
import AsyncSelect from "react-select/async";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it"; // Importa la localizzazione italiana

import { CarriersService } from "../../services/registry-service/carriers";
import { ZonesService } from "../../services/registry-service/zones";
import { callErrorToast, orderBykey } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const zonesService = new ZonesService();
const carriersService = new CarriersService();

const CreateEditSurcharge = ({ data, dataType, carrierCode, addOrEdit }) => {
  const surchargeFormRef = useRef();
  const selectPercentageTarget = useRef();
  const [type, setType] = useState("");
  const [carrierSurcharges, setCarrierSurcharges] = useState([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [zones, setZones] = useState([]);
  const [limitShpZoneFrom, setLimitShpZoneFrom] = useState({});
  const [limitShpZoneTo, setLimitShpZoneTo] = useState({});
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const { dictionary } = useAppContext();

  const getCarrierSurcharge = () => {
    carriersService
      .getCarrierSurcharges(carrierCode)
      .then((response) => {
        if (response) {
          const surcharges = [];
          const options = [];

          response.map((elem) => {
            if (elem.onDemand === false && elem.canBeRated === true) {
              surcharges.push(elem);
            } else if (elem.onDemand === true && elem.canBeRated === true) {
              options.push(elem);
            }
          });
          setCarrierSurcharges(surcharges);
          setCarrierOptions(options);
        }
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getAllZones = (inputValue) => {
    const filters = {
      freeSearch: inputValue,
    };
    return zonesService
      .getZones(filters)
      .then((response) => {
        if (inputValue) {
          return response.content;
        }
        setZones(response.content || []);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getZone = (id, param) => {
    zonesService
      .getZone(id)
      .then((response) => {
        if (param === "limitShpZoneIdFrom") {
          setLimitShpZoneFrom(response || {});
        }
        if (param === "limitShpZoneIdTo") {
          setLimitShpZoneTo(response || {});
        }
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const loadOptions = (inputValue) => {
    if (inputValue) {
      return getAllZones(inputValue);
    }
  };

  const toggleTab = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const validation = useFormik({
    initialValues: {
      name: "",
      active: true,
      blocking: false,
      //   contractServiceId: "",
      surchargeCode: "",
      contributeToFuelSurcharge: false,
      deliveryHours: "",
      fixedPrice: "",
      goodValueRound: "",
      insuranceValueRound: "",
      //   onDemand: true,
      //   rated: true,
      limitParcelHeightFrom: "",
      limitParcelHeightTo: "",
      limitParcelLengthFrom: "",
      limitParcelLengthTo: "",
      limitParcelPerimetralFrom: "",
      limitParcelPerimetralTo: "",
      limitParcelVolumeFrom: "",
      limitParcelVolumeTo: "",
      limitParcelWeightFrom: "",
      limitParcelWeightTo: "",
      limitParcelWidthFrom: "",
      limitParcelWidthTo: "",
      limitShpCashOnDeliveryValueFrom: "",
      limitShpCashOnDeliveryValueTo: "",
      limitShpGoodsValueFrom: "",
      limitShpGoodsValueTo: "",
      limitShpInsuranceValueFrom: "",
      limitShpInsuranceValueTo: "",
      limitShpParcelNumberFrom: "",
      limitShpParcelNumberTo: "",
      limitShpVolumeFrom: "",
      limitShpVolumeTo: "",
      limitShpWeightFrom: "",
      limitShpWeightTo: "",
      limitShpZoneIdFrom: "",
      limitShpZoneIdTo: "",
      minPriceValue: "",
      maxPriceValue: "",
      parcelNumberReferenceUnit: "",
      percentagePrice: "",
      percentageTarget: "",
      timeReferenceUnit: "",
      volumeReferenceUnit: "",
      volumeValueRound: "",
      weightReferenceUnit: "",
      weightValueRound: "",
      startingDate: "",
      endingDate: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(dictionary.messages.mandatory_field),
      percentageTarget: Yup.string().when("percentagePrice", {
        is: (value) => value && parseFloat(value) > 0,
        then: (schema) => schema.required(dictionary.messages.mandatory_field),
        otherwise: (schema) => schema,
      }),
    }),
    onSubmit: (values) => {
      values.surcharge = {};
      if (type === "SURCHARGE") {
        values.surcharge.onDemand = false;
      } else if (type === "OPTION") {
        values.surcharge.onDemand = true;
      }

      values.surcharge.canBeRated = true;

      for (var key in values) {
        var value = values[key];
        if (value === null || value === undefined || value === "") {
          delete values[key];
        }
      }

      addOrEdit(values);
    },
  });

  useEffect(() => {
    if (data) {
      setType(type);
      if (data.limitShpZoneIdFrom) {
        getZone(data.limitShpZoneIdFrom, "limitShpZoneIdFrom");
      }
      if (data.limitShpZoneIdTo) {
        getZone(data.limitShpZoneIdTo, "limitShpZoneIdTo");
      }
      validation.setValues({ ...validation.values, ...data });
    }
  }, [data]);

  useEffect(() => {
    setType(dataType);
  }, [dataType]);

  useEffect(() => {
    getCarrierSurcharge(carrierCode);
    getAllZones();
  }, []);

  return (
    <Form onSubmit={validation.handleSubmit} ref={surchargeFormRef}>
      <div className="row align-items-end">
        <div className="col-3">
          <Label className=" col-form-label">{dictionary.words.typology}</Label>
          <Select
            onChange={(value) => {
              validation.setFieldValue("surchargeCode", value.code);
              validation.setFieldValue("name", value.name);
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            defaultMenuIsOpen={false}
            className="select"
            options={
              type === "SURCHARGE"
                ? orderBykey(carrierSurcharges, "name")
                : type === "OPTION"
                ? orderBykey(carrierOptions, "name")
                : []
            }
            value={
              type === "SURCHARGE"
                ? carrierSurcharges.find(
                    (elem) => elem.code === validation.values.surchargeCode
                  )
                : type === "OPTION"
                ? carrierOptions.find(
                    (elem) => elem.code === validation.values.surchargeCode
                  )
                : ""
            }
          />
          <Input
            type="hidden"
            name="surchargeCode"
            value={validation.values.surchargeCode || ""}
            invalid={
              validation.touched.surchargeCode &&
              validation.errors.surchargeCode
                ? true
                : false
            }
          />
          {validation.touched.surchargeCode &&
          validation.errors.surchargeCode ? (
            <FormFeedback type="invalid">
              {validation.errors.surchargeCode}
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-3">
          <Label className="col-form-label ">
            {dictionary.registries.name}
          </Label>
          <Input
            type="text"
            name="name"
            placeholder="Nome del servizio"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </div>
        <div className="col-2">
          <Label className="col-form-label ">
            {dictionary.dates.delivery_time}
          </Label>
          <Input
            type="text"
            name="deliveryHours"
            placeholder=""
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.deliveryHours || ""}
            invalid={
              validation.touched.deliveryHours &&
              validation.errors.deliveryHours
                ? true
                : false
            }
          />
          {validation.touched.deliveryHours &&
          validation.errors.deliveryHours ? (
            <FormFeedback type="invalid">
              {validation.errors.deliveryHours}
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-2">
          <Label className="col-form-label ">
            {dictionary.dates.start_date}
          </Label>
          <Flatpickr
            className={`form-control date-buttons ${
              validation.errors.startingDate && "is-invalid"
            }`}
            placeholder="Seleziona giorno"
            options={{
              dateFormat: "d/m/Y",
              firstDayOfWeek: 1,
              locale: Italian,
              allowInput: true,
            }}
            onChange={(date, dateString) => {
              validation.setFieldValue("startingDate", date[0] || "");
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                validation.setFieldValue("startingDate", "");
              }
            }}
            value={validation.values.startingDate || ""}
          />
          {validation.touched.startingDate && validation.errors.startingDate ? (
            <FormFeedback type="invalid">
              {validation.errors.startingDate}
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-2">
          <Label className="col-form-label ">{dictionary.dates.end_date}</Label>
          <Flatpickr
            className={`form-control date-buttons ${
              validation.errors.endingDate && "is-invalid"
            }`}
            placeholder="Seleziona giorno"
            options={{
              dateFormat: "d/m/Y",
              firstDayOfWeek: 1,
              locale: Italian,
              allowInput: true,
            }}
            onChange={(date, dateString) => {
              validation.setFieldValue("endingDate", date[0] || "");
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                validation.setFieldValue("endingDate", "");
              }
            }}
            value={validation.values.endingDate || ""}
          />
          {validation.touched.endingDate && validation.errors.endingDate ? (
            <FormFeedback type="invalid">
              {validation.errors.endingDate}
            </FormFeedback>
          ) : null}
        </div>
      </div>
      <div className="row mt-3 align-items-end">
        <div className="col-3">
          <Label className="col-form-label ">
            {dictionary.words.fixed_price}
          </Label>
          <Input
            type="text"
            name="fixedPrice"
            placeholder=""
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={
              validation.values.fixedPrice
                ? `${validation.values.fixedPrice}`.replace(",", ".")
                : ""
            }
            invalid={
              validation.touched.fixedPrice && validation.errors.fixedPrice
                ? true
                : false
            }
          />
          {validation.touched.fixedPrice && validation.errors.fixedPrice ? (
            <FormFeedback type="invalid">
              {validation.errors.fixedPrice}
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-3">
          <Label className="col-form-label ">
            {dictionary.words.percentage}
          </Label>
          <Input
            type="text"
            name="percentagePrice"
            placeholder=""
            className="form-control"
            onChange={(e) => {
              validation.setFieldValue("percentagePrice", e.target.value);
              if (!e.target.value) {
                validation.setFieldValue("percentageTarget", null);
                selectPercentageTarget.current.clearValue();
              }
            }}
            onBlur={validation.handleBlur}
            value={
              validation.values.percentagePrice
                ? `${validation.values.percentagePrice}`.replace(",", ".")
                : ""
            }
            invalid={
              validation.touched.percentagePrice &&
              validation.errors.percentagePrice
                ? true
                : false
            }
          />
          {validation.touched.percentagePrice &&
          validation.errors.percentagePrice ? (
            <FormFeedback type="invalid">
              {validation.errors.percentagePrice}
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-3">
          <Label className="col-form-label ">
            {dictionary.words.referring_to}
          </Label>
          <Select
            onChange={(value) => {
              validation.setFieldValue("percentageTarget", value?.code || "");
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            defaultMenuIsOpen={false}
            className="select"
            options={percentageTargetTypes}
            value={percentageTargetTypes.find(
              (elem) => elem.code === validation.values.percentageTarget
            )}
            isDisabled={!validation.values.percentagePrice}
            isClearable={true}
            ref={selectPercentageTarget}
          />
          <Input
            type="hidden"
            name="percentageTarget"
            value={validation.values.percentageTarget || ""}
            invalid={
              validation.touched.percentageTarget &&
              validation.errors.percentageTarget
                ? true
                : false
            }
          />
          {validation.touched.percentageTarget &&
          validation.errors.percentageTarget ? (
            <FormFeedback type="invalid">
              {validation.errors.percentageTarget}
            </FormFeedback>
          ) : null}
        </div>
        <div className="col">
          <div className="row">
            <Label className="text-center">{dictionary.words.price} (€)</Label>
            <div className="col-6">
              <Input
                type="text"
                name="minPriceValue"
                placeholder="min"
                className="form-control"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.minPriceValue
                    ? `${validation.values.minPriceValue}`.replace(",", ".")
                    : ""
                }
                invalid={
                  validation.touched.minPriceValue &&
                  validation.errors.minPriceValue
                    ? true
                    : false
                }
              />
              {validation.touched.minPriceValue &&
              validation.errors.minPriceValue ? (
                <FormFeedback type="invalid">
                  {validation.errors.minPriceValue}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-6">
              <Input
                type="text"
                name="maxPriceValue"
                placeholder="max"
                className="form-control"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.maxPriceValue
                    ? `${validation.values.maxPriceValue}`.replace(",", ".")
                    : ""
                }
                invalid={
                  validation.touched.maxPriceValue &&
                  validation.errors.maxPriceValue
                    ? true
                    : false
                }
              />
              {validation.touched.maxPriceValue &&
              validation.errors.maxPriceValue ? (
                <FormFeedback type="invalid">
                  {validation.errors.maxPriceValue}
                </FormFeedback>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Nav
        tabs
        className="nav nav-tabs nav-tabs-underline border-bottom-0 mt-4"
      >
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({
              active: currentActiveTab === "1",
            })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            {dictionary.shipments.shipment_limits}
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({
              active: currentActiveTab === "2",
            })}
            onClick={() => {
              toggleTab("2");
            }}
          >
            {dictionary.parcels.packages_2_limits}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={currentActiveTab}>
        <TabPane tabId="1">
          <div className="row mt-3">
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.weight} (kg)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpWeightFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpWeightFrom
                        ? `${validation.values.limitShpWeightFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpWeightFrom &&
                      validation.errors.limitShpWeightFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpWeightFrom &&
                  validation.errors.limitShpWeightFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpWeightFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpWeightTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpWeightTo
                        ? `${validation.values.limitShpWeightTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpWeightTo &&
                      validation.errors.limitShpWeightTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpWeightTo &&
                  validation.errors.limitShpWeightTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpWeightTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.volume} (m3)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpVolumeFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpVolumeFrom
                        ? `${validation.values.limitShpVolumeFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpVolumeFrom &&
                      validation.errors.limitShpVolumeFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpVolumeFrom &&
                  validation.errors.limitShpVolumeFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpVolumeFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpVolumeTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpVolumeTo
                        ? `${validation.values.limitShpVolumeTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpVolumeTo &&
                      validation.errors.limitShpVolumeTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpVolumeTo &&
                  validation.errors.limitShpVolumeTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpVolumeTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.parcels.number_of_packages_2}
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpParcelNumberFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpParcelNumberFrom
                        ? `${validation.values.limitShpParcelNumberFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpParcelNumberFrom &&
                      validation.errors.limitShpParcelNumberFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpParcelNumberFrom &&
                  validation.errors.limitShpParcelNumberFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpParcelNumberFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpParcelNumberTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpParcelNumberTo
                        ? `${validation.values.limitShpParcelNumberTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpParcelNumberTo &&
                      validation.errors.limitShpParcelNumberTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpParcelNumberTo &&
                  validation.errors.limitShpParcelNumberTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpParcelNumberTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.goods_value} (€)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpGoodsValueFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpGoodsValueFrom
                        ? `${validation.values.limitShpGoodsValueFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpGoodsValueFrom &&
                      validation.errors.limitShpGoodsValueFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpGoodsValueFrom &&
                  validation.errors.limitShpGoodsValueFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpGoodsValueFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpGoodsValueTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpGoodsValueTo
                        ? `${validation.values.limitShpGoodsValueTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpGoodsValueTo &&
                      validation.errors.limitShpGoodsValueTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpGoodsValueTo &&
                  validation.errors.limitShpGoodsValueTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpGoodsValueTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.cash_on_delivery_value} (€)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpCashOnDeliveryValueFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpCashOnDeliveryValueFrom
                        ? `${validation.values.limitShpCashOnDeliveryValueFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpCashOnDeliveryValueFrom &&
                      validation.errors.limitShpCashOnDeliveryValueFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpCashOnDeliveryValueFrom &&
                  validation.errors.limitShpCashOnDeliveryValueFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpCashOnDeliveryValueFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpCashOnDeliveryValueTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpCashOnDeliveryValueTo
                        ? `${validation.values.limitShpCashOnDeliveryValueTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpCashOnDeliveryValueTo &&
                      validation.errors.limitShpCashOnDeliveryValueTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpCashOnDeliveryValueTo &&
                  validation.errors.limitShpCashOnDeliveryValueTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpCashOnDeliveryValueTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.insurance_value} (€)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpInsuranceValueFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpInsuranceValueFrom
                        ? `${validation.values.limitShpInsuranceValueFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpInsuranceValueFrom &&
                      validation.errors.limitShpInsuranceValueFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpInsuranceValueFrom &&
                  validation.errors.limitShpInsuranceValueFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpInsuranceValueFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitShpInsuranceValueTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitShpInsuranceValueTo
                        ? `${validation.values.limitShpInsuranceValueTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitShpInsuranceValueTo &&
                      validation.errors.limitShpInsuranceValueTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpInsuranceValueTo &&
                  validation.errors.limitShpInsuranceValueTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpInsuranceValueTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <Label className="text-center">
                  {dictionary.locations.zones}
                </Label>
                <div className="col-6">
                  <AsyncSelect
                    placeholder={limitShpZoneFrom.name}
                    loadOptions={loadOptions}
                    name="limitShpZoneIdFrom"
                    value={percentageTargetTypes.find(
                      (elem) =>
                        elem.code === validation.values.limitShpZoneIdFrom
                    )}
                    noOptionsMessage={() => (
                      <div>{dictionary.messages.no_results_found}</div>
                    )}
                    getOptionValue={(option) => option["id"]}
                    getOptionLabel={(option) => option["name"]}
                    onChange={(value) => {
                      validation.setFieldValue("limitShpZoneIdFrom", value.id);
                    }}
                    defaultOptions={zones}
                    maxMenuHeight={150}
                  />
                  <Input
                    type="hidden"
                    name="limitShpZoneIdFrom"
                    value={validation.values.limitShpZoneIdFrom || ""}
                    invalid={
                      validation.touched.limitShpZoneIdFrom &&
                      validation.errors.limitShpZoneIdFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpZoneIdFrom &&
                  validation.errors.limitShpZoneIdFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpZoneIdFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <AsyncSelect
                    placeholder={limitShpZoneTo.name}
                    loadOptions={loadOptions}
                    name="limitShpZoneIdTo"
                    value={percentageTargetTypes.find(
                      (elem) => elem.code === validation.values.limitShpZoneIdTo
                    )}
                    noOptionsMessage={() => (
                      <div>{dictionary.messages.no_results_found}</div>
                    )}
                    getOptionValue={(option) => option["id"]}
                    getOptionLabel={(option) => option["name"]}
                    onChange={(value) => {
                      validation.setFieldValue("limitShpZoneIdTo", value.id);
                    }}
                    defaultOptions={zones}
                    maxMenuHeight={150}
                  />
                  <Input
                    type="hidden"
                    name="limitShpZoneIdTo"
                    value={validation.values.limitShpZoneIdTo || ""}
                    invalid={
                      validation.touched.limitShpZoneIdTo &&
                      validation.errors.limitShpZoneIdTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitShpZoneIdTo &&
                  validation.errors.limitShpZoneIdTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitShpZoneIdTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="row mt-3">
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.width} (cm)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelWidthFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelWidthFrom
                        ? `${validation.values.limitParcelWidthFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelWidthFrom &&
                      validation.errors.limitParcelWidthFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelWidthFrom &&
                  validation.errors.limitParcelWidthFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelWidthFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelWidthTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelWidthTo
                        ? `${validation.values.limitParcelWidthTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelWidthTo &&
                      validation.errors.limitParcelWidthTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelWidthTo &&
                  validation.errors.limitParcelWidthTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelWidthTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.length} (cm)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelLengthFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelLengthFrom
                        ? `${validation.values.limitParcelLengthFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelLengthFrom &&
                      validation.errors.limitParcelLengthFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelLengthFrom &&
                  validation.errors.limitParcelLengthFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelLengthFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelLengthTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelLengthTo
                        ? `${validation.values.limitParcelLengthTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelLengthTo &&
                      validation.errors.limitParcelLengthTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelLengthTo &&
                  validation.errors.limitParcelLengthTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelLengthTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.height} (cm)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelHeightFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelHeightFrom
                        ? `${validation.values.limitParcelHeightFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelHeightFrom &&
                      validation.errors.limitParcelHeightFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelHeightFrom &&
                  validation.errors.limitParcelHeightFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelHeightFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelHeightTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelHeightTo
                        ? `${validation.values.limitParcelHeightTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelHeightTo &&
                      validation.errors.limitParcelHeightTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelHeightTo &&
                  validation.errors.limitParcelHeightTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelHeightTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.weight} (kg)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelWeightFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelWeightFrom
                        ? `${validation.values.limitParcelWeightFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelWeightFrom &&
                      validation.errors.limitParcelWeightFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelWeightFrom &&
                  validation.errors.limitParcelWeightFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelWeightFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelWeightTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelWeightTo
                        ? `${validation.values.limitParcelWeightTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelWeightTo &&
                      validation.errors.limitParcelWeightTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelWeightTo &&
                  validation.errors.limitParcelWeightTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelWeightTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.volume} (m3)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelVolumeFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelVolumeFrom
                        ? `${validation.values.limitParcelVolumeFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelVolumeFrom &&
                      validation.errors.limitParcelVolumeFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelVolumeFrom &&
                  validation.errors.limitParcelVolumeFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelVolumeFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelVolumeTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelVolumeTo
                        ? `${validation.values.limitParcelVolumeTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelVolumeTo &&
                      validation.errors.limitParcelVolumeTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelVolumeTo &&
                  validation.errors.limitParcelVolumeTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelVolumeTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <Label className="text-center">
                  {dictionary.dimensions.perimetral} (cm)
                </Label>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelPerimetralFrom"
                    placeholder="min"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelPerimetralFrom
                        ? `${validation.values.limitParcelPerimetralFrom}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelPerimetralFrom &&
                      validation.errors.limitParcelPerimetralFrom
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelPerimetralFrom &&
                  validation.errors.limitParcelPerimetralFrom ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelPerimetralFrom}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-6">
                  <Input
                    type="text"
                    name="limitParcelPerimetralTo"
                    placeholder="max"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.limitParcelPerimetralTo
                        ? `${validation.values.limitParcelPerimetralTo}`.replace(
                            ",",
                            "."
                          )
                        : ""
                    }
                    invalid={
                      validation.touched.limitParcelPerimetralTo &&
                      validation.errors.limitParcelPerimetralTo
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limitParcelPerimetralTo &&
                  validation.errors.limitParcelPerimetralTo ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limitParcelPerimetralTo}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
      <div className="d-flex justify-content-end mt-3">
        <Button type="submit" className="btn btn-primary ms-3">
          {dictionary.actions.save}
        </Button>
      </div>
    </Form>
  );
};

export default CreateEditSurcharge;
