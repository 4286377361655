import React from "react";
import { useAppContext } from "../../AppProvider";
import InputBoxIcon from "../../Components/Inputs/InputIcon/InputIcon";
import { useBarcodeHandlerContext } from "./providers/BarcodeHandler";
import { getFieldError } from "../../utilities/utilities";
import { faPenSquare } from "../../utilities/fontawesomeIcons";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BarcodeHandler: React.FC = () => {
  const { dictionary } = useAppContext();
  const { data, error, inputRef, editBarcode, deleteBarcode } =
    useBarcodeHandlerContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const allInputs = Array.from(
        document.querySelectorAll<HTMLInputElement>('input[name="barcode"]')
      );

      const currentIndex = allInputs.findIndex(
        (input) => input === inputRef.current
      );

      const nextEmptyInput = allInputs
        .slice(currentIndex + 1)
        .find((input) => !input.value.trim());

      if (nextEmptyInput) {
        nextEmptyInput.focus();
        return;
      }

      inputRef.current?.blur();
    }
  };

  return (
    <>
      <label className="label-2">{dictionary.products.barcode}:</label>

      {data?.article?.uniqueBarcodeItem ? (
        <>
          <div
            className=" d-grid grid-column align-items-start"
            style={{
              gridTemplateColumns: `2fr auto`,
            }}
          >
            <InputBoxIcon
              ref={inputRef}
              name="barcode"
              value={data?.barcode}
              disabled={!!data?.barcode}
              onChange={(e) => {
                if (e.target.value) {
                  editBarcode(data.id as string, e.target.value as string);
                }
              }}
              isValid={
                inputRef.current?.getAttribute("data-is-valid") ||
                (data?.barcode && !getFieldError(error, "barcode")
                  ? "true"
                  : "false")
              }
              onKeyDown={handleKeyDown}
              error={getFieldError(error, "barcode")}
            />
            <ButtonUtility
              className="ms-1"
              title={dictionary.actions.edit}
              onClick={() => {
                deleteBarcode(data.id as string);
              }}
              disabled={!data?.barcode}
            >
              <FontAwesomeIcon icon={faPenSquare} />
            </ButtonUtility>
          </div>
        </>
      ) : (
        <label className={`label-3`}>{data?.article?.barcode || "-"}</label>
      )}
    </>
  );
};

export default BarcodeHandler;
