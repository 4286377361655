export interface Pagination {
  page: number;
  size: number;
}

export const defaultPagination: Pagination = {
  page: 0,
  size: 10,
};

export interface PaginatedResponse<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
}
