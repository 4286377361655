import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIndexWarehouseArticlesContext } from "../providers/Index";

export const isDisabledDelete = (selectedRows) => !selectedRows.length > 0;

export const useListProps = (action, shippingRequired) => {
  const { dictionary } = useAppContext();

  const { setModalConfig } = useIndexWarehouseArticlesContext();

  return {
    path: "/logistic-service/warehouse-article",
    create: {
      enabled: action != "details",
      action: () => {
        setModalConfig({
          isOpen: true,
          title: `${
            dictionary.actions.add
          } ${dictionary.products.product.toLowerCase()}`,
          action: "create",
          size: "md",
        });
      },
      title: `${
        dictionary.actions.add
      } ${dictionary.products.product.toLowerCase()}`,
      icon: faPlus,
    },
    pagination: { enabled: true },
    selectRowsEnabled: { enabled: action !== "details" },
    actionBar: { enabled: action !== "details", fixed: false },
    sorting: [
      {
        orderBy: "desc",
        column: "article.name",
        icon: faArrowDownShortWide,
        label: dictionary.registries.name,
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "article.name",
        icon: faArrowUpShortWide,
        label: dictionary.registries.name,
        isDefault: false,
      },
    ],
    banner: {
      enabled: false,
    },
    title: shippingRequired ? (
      <h3 className="m-0">{dictionary.products.physical_goods}</h3>
    ) : (
      <h3 className="m-0">{dictionary.products.digital_goods}</h3>
    ),
    omnisearch: {
      enabled: false,
      placeholder: dictionary.references.references,
    },
    defaultDate: {
      enabled: false,
    },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          setModalConfig({
            isOpen: true,
            title: dictionary.actions.confirm_deletion,
            action: "delete",
            size: "sm",
            data: selectedRows.map((item) => item.id),
          }),
        isDisabled: isDisabledDelete,
        label: dictionary.actions.delete_selected,
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
    filters: {
      list: [
        {
          fieldName: "article.shippingRequired",
          value: [
            {
              value: shippingRequired ? "true" : "false",
              isBool: true,
            },
          ],
          type: "select",
          hidden: true,
        },
      ],
      preserve: false,
      name: "",
    },
  };
};
