import React, { useState, useEffect } from "react";
import { Form, Row, Col, Label, Input, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import RegistryService from "../../services/registry";
import { useAppContext } from "../../AppProvider";
import { callErrorToast } from "../../utilities";

const registryService = new RegistryService();

const CreateAfiliate = ({ modalToogle, refreshTable, userOwnerId }) => {
  const [companyOwner, setCompanyOwner] = useState(null);
  const { dictionary } = useAppContext();

  useEffect(() => {
    registryService
      .getCompany(userOwnerId)
      .then((response) => {
        setCompanyOwner(response);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  }, []);

  const create = (body) => {
    registryService
      .createCompany(body)
      .then((response) => {
        refreshTable();
        modalToogle();
        //Probabilmente bisogna fare una funzione nel padre (notify) con il componente Toast nel padre
        toast.success(dictionary.messages.save_successful, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "AFILIATE",
      name: "",
      internalReference: "",
      vatNumber: "",
      externalReference: "",
      logoUrl: "",
    },
    validationSchema: Yup.object({
      type: Yup.string(),
      name: Yup.string().required(dictionary.messages.mandatory_field),
      // internalReference:Yup.string(),
      vatNumber: Yup.number(),
      externalReference: Yup.string(),
      logoUrl: Yup.string()
        .max(500)
        .matches(
          /\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i,
          dictionary.messages.enter_a_valid_url
        ),
    }),
    onSubmit: (values) => {
      create(values);
    },
  });

  return (
    <React.Fragment>
      <Form onSubmit={validation.handleSubmit}>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.registries.company}:
          </Label>
          <Col lg={9}>
            <h3>{companyOwner?.name}</h3>
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.registries.company_name}{" "}
            <span className="text-danger">*</span>
          </Label>
          <Col lg={9}>
            <Input
              type="hidden"
              name="type"
              className="form-control"
              value={"AFILIATE"}
            />
            <Input
              type="text"
              name="name"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.registries.vat_number}
          </Label>
          <Col lg={9}>
            <Input
              type="text"
              name="vatNumber"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.vatNumber || ""}
              invalid={
                validation.touched.vatNumber && validation.errors.vatNumber
                  ? true
                  : false
              }
            />
            {validation.touched.vatNumber && validation.errors.vatNumber ? (
              <FormFeedback type="invalid">
                {validation.errors.vatNumber}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.references.external_reference}
          </Label>
          <Col lg={9}>
            <Input
              type="text"
              name="externalReference"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.externalReference || ""}
              invalid={
                validation.touched.externalReference &&
                validation.errors.externalReference
                  ? true
                  : false
              }
            />
            {validation.touched.externalReference &&
            validation.errors.externalReference ? (
              <FormFeedback type="invalid">
                {validation.errors.externalReference}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        {/* <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            Referente interno
          </Label>
          <Col lg={9}>
            <Input
              type="text"
              name="internalReference"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.internalReference || ""}
              invalid={
                validation.touched.internalReference && validation.errors.internalReference
                  ? true
                  : false
              }
            />
            {validation.touched.internalReference && validation.errors.internalReference ? (
              <FormFeedback type="invalid">
                {validation.errors.internalReference}
              </FormFeedback>
            ) : null}
          </Col>
        </Row> */}
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3">
            {dictionary.registries.logo_url}
          </Label>
          <Col lg={9}>
            <Input
              type="text"
              name="logoUrl"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.logoUrl || ""}
              invalid={
                validation.touched.logoUrl && validation.errors.logoUrl
                  ? true
                  : false
              }
            />
            {validation.touched.logoUrl && validation.errors.logoUrl ? (
              <FormFeedback type="invalid">
                {validation.errors.logoUrl}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <div className="card-footer d-flex justify-content-end">
          <Button type="submit" className="btn btn-primary ms-3">
            {dictionary.actions.save}{" "}
            <i className="ph-paper-plane-tilt ms-2"></i>
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CreateAfiliate;
