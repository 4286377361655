import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import moment from "moment";

export const useListProps = () => {
  const { dictionary } = useAppContext();

  return {
    path: "/registry-service/exports",
    sorting: [
      {
        orderBy: "desc",
        column: "creationDate",
        icon: faArrowDownShortWide,
        label: dictionary.dates.creation_date,
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "creationDate",
        icon: faArrowUpShortWide,
        label: dictionary.dates.creation_date,
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary.words.my_exports,
    omnisearch: {
      enabled: false,
      placeholder: dictionary.references.references,
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary.dates.creation_date,
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    filters: {
      name: "export",
      preserve: false,
      list: [
        {
          fieldName: "creationUser",
          label: dictionary.users.creation_user,
          type: "text",
          preciseSearch: false,
          placeholder: "user@allabox.it",
          primary: true,
        },
        {
          fieldName: "type",
          label: dictionary.words.typology,
          type: "text",
          preciseSearch: false,
          placeholder: "teamSystem",
          primary: true,
        },
        {
          fieldName: "description",
          label: dictionary.registries.description,
          type: "text",
          preciseSearch: false,
          placeholder: "MyExport",
          primary: true,
        },
      ],
    },
  };
};
