import React from "react";
import { Row, Label, Button, Col } from "reactstrap";
import { toast } from "react-toastify";

import { ZonesService } from "../../../services/registry-service/zones";
import { useAppContext } from "../../../AppProvider";
import { callErrorToast } from "../../../utilities";

const zonesService = new ZonesService();

const Delete = ({ zoneId, modalToogle, refreshTable, pagination }) => {
  const { dictionary } = useAppContext();

  const deleteZone = () => {
    zonesService
      .deleteZone(zoneId)
      .then((response) => {
        refreshTable(pagination);
        modalToogle();
        //Probabilmente bisogna fare una funzione nel padre (notify) con il componente Toast nel padre
        toast.success(dictionary.messages.save_successful, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>{dictionary.messages.ask_to_delete}</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToogle();
            refreshTable(pagination);
          }}
        >
          {dictionary.actions.cancel}
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteZone}>
          {dictionary.actions.save}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
