import React from "react";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const shipmentsService = new ShipmentsService();

const Confirm = ({ id, callback }) => {
  const { dictionary } = useAppContext();

  const confirmShipment = () => {
    shipmentsService
      .confirm(id)
      .then((response) => {
        if (callback) {
          callback(true);
        }
        toast.success(dictionary.shipments.shipment_confirmed_correctly, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <form>
        <label>
          {dictionary.shipments.shipment_tracking_activated}{" "}
          {dictionary.messages.are_you_sure_you_want_to_continue}
        </label>
        <div className="row mt-3">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (callback) {
                  callback(false);
                }
              }}
            >
              {dictionary.actions.cancel}
            </button>
            <Button
              onClick={confirmShipment}
              type="button"
              className="btn ms-3"
            >
              {dictionary.actions.confirm}
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Confirm;
