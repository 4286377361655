import { API_BASE_URL } from "../../constants/index";
import { buildUrl } from "../../utilities/index";
import HttpInterface from "../../helpers/HttpInterface";

export class ShipmentsService {
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  all(params = {}) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      buildUrl(`${API_BASE_URL}/shipment-service/shipments`, params),
      requestOptions
    );
  }

  get(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}`,
      requestOptions
    );
  }

  delete(id) {
    const requestOptions = {
      method: "DELETE",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}`,
      requestOptions
    );
  }

  book(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}/book`,
      requestOptions
    );
  }

  confirm(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}/confirm`,
      requestOptions
    );
  }

  exportCSV(search = "", freeSearch = "") {
    const requestOptions = {
      method: "GET",
    };
    return this.httpProxy.fetch(
      `${API_BASE_URL}/shipment-service/shipments/export/csv?search=${search}&freeSearch=${freeSearch}`,
      requestOptions,
      { Accept: "text/csv" }
    );
  }

  createOptions(id) {
    const requestOptions = {
      method: "POST",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}/options`,
      requestOptions
    );
  }

  deleteOptions(id) {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}/options`,
      requestOptions
    );
  }

  updateNotes(id, note) {
    const requestOptions = {
      method: "PUT",
      body: note,
    };
    return this.httpProxy.fetch(
      API_BASE_URL + `/shipment-service/shipments/${id}/update-notes`,
      requestOptions,
      { "Content-Type": "text/plain", Accept: "text/plain" }
    );
  }
}
