import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "reactstrap";
import {
  faCircleCheck,
  faCopy,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";

export const TooltipCopy = ({
  children,
  target = "",
  placement = "bottom",
  textToCopy,
}) => {
  const { dictionary } = useAppContext();

  const [isCopied, setIsCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsCopied(false);
    }
  }, [isOpen]);

  return (
    <>
      {textToCopy ? (
        <>
          <div id={target} onClick={() => textToCopy && copyText()}>
            {children}
          </div>
          <Tooltip
            fade={false}
            placement={placement}
            target={target}
            isOpen={isOpen}
            toggle={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isCopied ? (
              <>
                <FontAwesomeIcon icon={faCircleCheck} />{" "}
                {dictionary.actions.copied}
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCopy} /> {dictionary.actions.copy}
              </>
            )}
          </Tooltip>
        </>
      ) : (
        children
      )}
    </>
  );
};
