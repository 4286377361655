import React from "react";
import { useListContext } from "./Providers/List";
import { useAppContext } from "../../../AppProvider";
import SelectAlbx from "../Selects/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sorting = ({ className }) => {
  const context = useListContext();
  const { dictionary } = useAppContext();
  return (
    context &&
    context.sorting.length > 0 && (
      <div className={className}>
        <span className="span-1 me-1">{dictionary.actions.sort_by}</span>
        <SelectAlbx
          onChange={(option) => {
            context.setSort([option]);
          }}
          options={context.sorting}
          value={context.sort[0]}
          getOptionValue={(option) => option["column"]}
          getOptionLabel={(option) => option["label"]}
          optionStyle={(option) => {
            return (
              <React.Fragment>
                {option.icon && <FontAwesomeIcon icon={option.icon} />}{" "}
                {option.label}
              </React.Fragment>
            );
          }}
        />
      </div>
    )
  );
};

export { Sorting };
