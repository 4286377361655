import React, { useEffect } from "react";
import { Form, Row, Label, Button, Col } from "reactstrap";
import { toast } from "react-toastify";
import ParcelTemplatesService from "../../services/shipment-service/parcelTemplates";
import { callErrorToast, callSuccessToast } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const parcelTemplatesService = new ParcelTemplatesService();

const Delete = ({ parcelTemplateId, modalToogle, refreshTable }) => {
  const { dictionary } = useAppContext();

  const deleteParcelTemplate = () => {
    parcelTemplatesService
      .delete(parcelTemplateId)
      .then((response) => {
        refreshTable();
        modalToogle();
        callSuccessToast(response, dictionary);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>{dictionary.messages.ask_to_delete}</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            refreshTable();
            modalToogle();
          }}
        >
          {dictionary.actions.cancel}
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteParcelTemplate}>
          {dictionary.actions.delete}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
