import React from "react";
import { useAppContext } from "../../AppProvider";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useProcessOrderDetailsContext } from "./providers/Process";
import { IndexOrderDetailsProvider } from "./providers/Index";
import OrderDetails from ".";
import { PaginatedResponse } from "../../Models/Pagination";
import { OrderDetail } from "../../Models/OrderDetail";
import ButtonConfirm from "../../Components/Buttons/Confirm";

const Process: React.FC = () => {
  const { dictionary } = useAppContext();
  const { parentId, action, callback } = useProcessOrderDetailsContext();

  const renderOrderDetailsHeader = (data: PaginatedResponse<OrderDetail>) => {
    return (
      <React.Fragment>
        <div className="list-item px-2 m-1">
          <div
            className=" d-grid grid-column align-items-start"
            style={{
              gridTemplateColumns: `0.5fr 2fr 1fr 2fr`,
            }}
          >
            <div className="my-auto mx-0"></div>
            <div></div>
            <div>
              <label className="label-2">
                {dictionary.dimensions.total_quantity}:
              </label>
              <label className="label-3">
                {data.content?.reduce((t, { quantity }) => t + quantity, 0)}
              </label>
            </div>
          </div>
        </div>
        <hr className="my-1"></hr>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <h3 className="text-center">
            {dictionary.actions.process} {dictionary.products.products}
          </h3>
        </div>
      </div>
      <IndexOrderDetailsProvider
        callback={callback}
        parentId={parentId}
        action={action}
      >
        <OrderDetails
          renderHeader={renderOrderDetailsHeader}
          renderFooter={null}
        />
      </IndexOrderDetailsProvider>
      <div className="d-flex mt-2">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (callback) {
              callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50 me-2"
          onClick={() => {
            if (callback) {
              callback();
            }
          }}
        ></ButtonConfirm>
      </div>
    </React.Fragment>
  );
};

export default Process;
