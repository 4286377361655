import React, { createContext, useState, useContext } from "react";
import { modalDefault } from "../../../utilities";

export const IndexCodCollectionsContext = createContext();

const IndexCodCollectionsProvider = ({ children }) => {
  const [modal, setModal] = useState({
    ...modalDefault,
  });

  return (
    <IndexCodCollectionsContext.Provider
      value={{
        modal,
        setModal,
      }}
    >
      {children}
    </IndexCodCollectionsContext.Provider>
  );
};

const useIndexCodCollectionsContext = () => {
  return useContext(IndexCodCollectionsContext);
};

export { IndexCodCollectionsProvider, useIndexCodCollectionsContext };
