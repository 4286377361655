import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import InputBox, { InputBoxProps } from "../Input/Input";
import { faICursor } from "../../../utilities/fontawesomeIcons";
import { forwardRef } from "react";

/**
 * Props interface for the InputBox component.
 */
export interface InputBoxIconProps extends InputBoxProps {
  position?: string;
  icon?: FontAwesomeIconProps;
}

const InputBoxIcon = forwardRef<HTMLInputElement, InputBoxIconProps>(
  (
    {
      value,
      name,
      type = "text",
      placeholder,
      className = "",
      onChange,
      readOnly = false,
      disabled = false,
      error = "",
      isValid,
      min,
      max,
      precision = 2,
      dataId,
      onKeyDown,
      required = false,
      position = "left",
      icon = faICursor,
    },
    ref
  ) => {
    return (
      <div
        className={`input-box-icon ${position} w-100 ${error ? "invalid" : ""}`}
      >
        <FontAwesomeIcon icon={icon} className="icon" />
        <InputBox
          ref={ref}
          type={type}
          name={name}
          className={className}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
          error={error}
          isValid={isValid}
          min={min}
          max={max}
          precision={precision}
          dataId={dataId}
          required={required}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  }
);

export default InputBoxIcon;
