import React, { useEffect } from "react";
import { Form, Row, Label, Button, Col } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import RegistryService from "../../services/registry";
import { callErrorToast } from "../../utilities";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";

const registryService = new RegistryService();

const Delete = ({ companyId, modalToogle, refreshTable }) => {
  const { dictionary } = useAppContext();

  const deleteCompany = () => {
    registryService
      .deleteCompanies(companyId)
      .then((response) => {
        refreshTable();
        modalToogle();
        toast.success(dictionary.messages.item_deleted_successfully, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>{dictionary.messages.ask_to_delete}</label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToogle();
            refreshTable();
          }}
        >
          {dictionary.actions.save}
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteCompany}>
          {dictionary.actions.delete}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Delete;
