import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { useContext } from "react";
import { DetailsOrderContext } from "./providers/Details";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import OrderDetails from "../OrderDetails";
import moment from "moment";
import { Loader } from "../../Components/common/Loader";
import { useIncomingGoodContext } from "./providers/CreateEdit";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import IncomingGoodDetails from "../IncomingGoodDetails";
import { useAppContext } from "../../AppProvider";

export const Details = () => {
  const { id } = useParams();
  const { dictionary } = useAppContext();

  const context = useIncomingGoodContext();

  useEffect(() => {
    if (id) {
      context.getIncomingGood();
    }
  }, []);

  return (
    <React.Fragment>
      <BreadcrumbItem
        title={dictionary.words.logistic}
        breadcrumbItem={dictionary.actions.details}
      />
      <div className="content" id="container">
        {context.incomingGoodLoader && (
          <Loader show={context.incomingGoodLoader} />
        )}
        <Card>
          <CardBody>
            <h5>{dictionary.words.general_informations}</h5>
            <div className="row">
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.registries.carrier}
                </label>
                <label>
                  {context.incomingGood?.carrier?.logoUrl && (
                    <img
                      className="me-1"
                      width={60}
                      src={context.incomingGood?.carrier?.logoUrl}
                    />
                  )}
                  {context.incomingGood?.carrier?.name || "-"}
                </label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.references.internal_reference}
                </label>
                <label>{context.incomingGood?.internalReference || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.references.carrier_reference}
                </label>
                <label>{context.incomingGood?.carrierReference || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.references.sender_reference}
                </label>
                <label>{context.incomingGood?.senderReference || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.references.receiver_reference}
                </label>
                <label>{context.incomingGood?.receiverReference || "-"}</label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <label className="fw-semibold d-block">
                  {dictionary.words.pickup_warehouse}
                </label>
                <label>
                  {context.incomingGood?.pickupWarehouse
                    ? `${context.incomingGood?.pickupWarehouse.name} - ${context.incomingGood?.pickupWarehouse?.location?.label}`
                    : "-"}
                </label>
              </div>
              <div className="col-3">
                <label className="fw-semibold d-block">
                  {dictionary.words.delivery_warehouse}:
                </label>
                <label>
                  {context.incomingGood?.deliveryWarehouse
                    ? `${context.incomingGood?.deliveryWarehouse.name} - ${context.incomingGood?.deliveryWarehouse?.location?.label}`
                    : "-"}
                </label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.dates.creation_date}
                </label>
                {context.incomingGood?.creationDate
                  ? moment(context.incomingGood?.creationDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.dates.departure_date}
                </label>
                {context.incomingGood?.departureDate
                  ? moment(context.incomingGood?.departureDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.dates.expected_delivery_date}
                </label>
                {context.incomingGood?.expectedDeliveryDate
                  ? moment(context.incomingGood?.expectedDeliveryDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.dates.effective_delivery_date}
                </label>
                {context.incomingGood?.effectiveDeliveryDate
                  ? moment(context.incomingGood?.effectiveDeliveryDate).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.products.goods_typology}
                </label>
                <label>
                  <FontAwesomeIcon
                    icon={context.incomingGood?.goodsType?.icon}
                    size="lg"
                  />{" "}
                  {context.incomingGood?.goodsType?.label || "-"}
                </label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.words.note} ({dictionary.registries.sender})
                </label>
                <label>{context.incomingGood?.senderNote || "-"}</label>
              </div>
              <div className="col">
                <label className="fw-semibold d-block">
                  {dictionary.words.note} ({dictionary.registries.receiver})
                </label>
                <label>{context.incomingGood?.receiverNote || "-"}</label>
              </div>
            </div>
            {context?.incomingGood?.id && (
              <div className="row mt-3">
                <div className="col">
                  <IncomingGoodDetails
                    parentId={context?.incomingGood?.id}
                    viewOnly={true}
                  />
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};
