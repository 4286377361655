import React from "react";
import Moment from "react-moment";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  callErrorToast,
  getCompanyBadge,
  getParcelTypeBadge,
  getShipmentType,
  getShipmentTypeBadge,
  orderBykey,
  stringToColor,
} from "../../utilities";
import { parcelTypesList, shipmentTypesList } from "../../constants";
import CashOnDeliveryIcon from "../../assets/images/cashOnDeliveryIcon.png";
import InsuranceIcon from "../../assets/images/insuranceIcon.png";
import CollectionAssignedIcon from "../../assets/images/collectionAssignedIcon.png";

import {
  getAllCarriers,
  getAllCompanies,
  getAllStatusCodes,
} from "../../utilities/asyncSelectCallAPI";
import {
  getCarrierOptionStyle,
  getCompanyOptionStyle,
  getParcelTypeOptionStyle,
  getShipmentStatusOptionStyle,
  getShipmentTypeOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import { euCountries } from "../../utilities";
import API from "../../helpers/API";
import { useAppContext } from "../../AppProvider";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useTypes } from "../../utilities/types";

//#region Massive actions

const shipmentStatusValidForClose = ["BOOKED"];
const shipmentStatusValidForPrint = ["BOOKED", "CONFIRMED"];
const shipmentStatusValidForDelete = ["BOOKED", "DRAFT"];
const shipmentStatusValidForCollect = ["BOOKED", "CONFIRMED"];

export const canShipmentBeClosed = (shipment) => {
  return shipmentStatusValidForClose.includes(shipment.statusCode);
};

export const canShipmentBeCollect = (shipment, shipments) => {
  return (
    //Controllo sullo stato della spedizione
    shipmentStatusValidForCollect.includes(shipment.statusCode) &&
    //Controllo che il vettore delle spedizioni selezionate sia lo stesso
    shipments.every((item) => item.carrierCode === shipment.carrierCode) &&
    shipment.collectionCreationEnabled &&
    !shipment.collectionAssigned
  );
};

export const canShipmentBePrinted = (shipment) => {
  return shipmentStatusValidForPrint.includes(shipment.statusCode);
};

export const canShipmentBeDeleted = (shipment) => {
  return shipmentStatusValidForDelete.includes(shipment.statusCode);
};

export const canShipmentBeCollectioned = (shipment) => {
  return shipmentStatusValidForDelete.includes(shipment.statusCode);
};

export const canAllShipmentBeDeleted = (shipments) => {
  if (!shipments || shipments.length === 0) return false;
  const selectedShipmentCanBeDeleted = shipments.map((x) =>
    canShipmentBeDeleted(x)
  );
  return selectedShipmentCanBeDeleted.every((x) => x);
};

export const canAllShipmentBeClosed = (shipments) => {
  if (!shipments || shipments.length === 0) return false;
  const selectedShipmentCanBeClosed = shipments.map((x) =>
    canShipmentBeClosed(x)
  );
  return selectedShipmentCanBeClosed.every((x) => x);
};

export const canAllShipmentBeCollect = (shipments) => {
  if (!shipments || shipments.length === 0) return false;
  const selectedShipmentCanBeClosed = shipments.map((x) =>
    canShipmentBeCollect(x, shipments)
  );
  return selectedShipmentCanBeClosed.every((x) => x);
};

export const canAllShipmentBePrinted = (shipments) => {
  if (!shipments || shipments.length === 0) return false;
  const selectedShipmentCanBePrinted = shipments.map((x) =>
    canShipmentBePrinted(x)
  );
  return selectedShipmentCanBePrinted.every((x) => x);
};

export const canAllShipmentBeCollectioned = (shipments) => {
  if (!shipments || shipments.length === 0) return false;
  const selectedShipmentCanBeCollectioned = shipments.map((x) =>
    canShipmentBeCollectioned(x)
  );
  return selectedShipmentCanBeCollectioned.every((x) => x);
};

export const allSelectedShipmentsCanBeDeleted = (rows, statusRows) => {
  const idSelected = statusRows?.filter((x) => x.value).map((x) => x.id);
  const shipmentSelected = rows?.filter((x) => idSelected.includes(x.id));
  return canAllShipmentBeDeleted(shipmentSelected);
};

export const allSelectedShipmentsCanBeClosed = (rows, statusRows) => {
  const idSelected = statusRows?.filter((x) => x.value).map((x) => x.id);
  const shipmentSelected = rows?.filter((x) => idSelected.includes(x.id));
  return canAllShipmentBeClosed(shipmentSelected);
};

export const allSelectedShipmentsCanBePrinted = (rows, statusRows) => {
  const idSelected = statusRows?.filter((x) => x.value).map((x) => x.id);
  const shipmentSelected = rows?.filter((x) => idSelected.includes(x.id));
  return canAllShipmentBePrinted(shipmentSelected);
};

export const allSelectedShipmentsCanBeCollect = (rows, statusRows) => {
  const idSelected = statusRows?.filter((x) => x.value).map((x) => x.id);
  const shipmentSelected = rows?.filter((x) => idSelected.includes(x.id));
  return canAllShipmentBeCollect(shipmentSelected);
};
//#endregion

//#region columns
export const getColumns = () => {
  const api = new API();
  const appContext = useAppContext();
  const domain = appContext?.brandSettings?.slug
    ? `${appContext?.brandSettings?.slug}.${api.getURLShort()}`
    : api.getURL();
  const { shipmentStatus } = useTypes();

  return [
    {
      position: 1,
      Header: "Stato",
      accessor: "statusName",
      style: { minWidth: "12rem" },
      filter: {
        active: true,
        name: "status.code",
        type: "async-select",
        loadOptions: (searchValue, pagination) => {
          return getAllStatusCodes(
            searchValue,
            pagination,
            {
              orderBy: "asc",
              selector: "name",
            },
            shipmentStatus
          );
        },
        multi: true,
        optionStyle: getShipmentStatusOptionStyle,
        col: 3,
      },
      Cell: ({ cell }) => {
        const row = cell.row.original;
        return (
          <div className="d-flex align-items-center">
            <span
              style={{ backgroundColor: row.statusColor || "#939393" }}
              className="badge"
            >
              {row.statusName}
            </span>
            {row.deliveryDate && (
              <>
                <FontAwesomeIcon
                  id={`tooltip_${row.id}`}
                  icon={faInfoCircle}
                  className="cursor-pointer ms-1"
                />
                <UncontrolledTooltip target={`tooltip_${row.id}`}>
                  {
                    <Moment
                      date={row.deliveryDate}
                      format="DD/MM/YYYY HH:mm:ss"
                    />
                  }
                </UncontrolledTooltip>
              </>
            )}
          </div>
        );
      },
    },
    {
      position: 2,
      Header: "Vettore",
      accessor: "carrierCode",
      filter: {
        active: true,
        name: "contractService.contract.carrierCode",
        type: "async-select",
        loadOptions: (searchValue, pagination) => {
          return getAllCarriers(searchValue, pagination, {
            orderBy: "asc",
            selector: "name",
          });
        },
        multi: true,
        optionStyle: getCarrierOptionStyle,
        col: 3,
      },
      Cell: ({ cell }) => {
        const row = cell.row.original;
        if (row.carrierCode) {
          return <img width={50} src={row.carrierLogo} />;
        }
        return "-";
      },
    },
    {
      position: 3,
      Header: "Servizio",
      accessor: "carrierServiceName",
      Cell: ({ cell }) => {
        const row = cell.row.original;
        if (row.carrierServiceCode) {
          return (
            <span
              style={{
                backgroundColor: stringToColor(row.carrierServiceCode),
              }}
              className="badge"
            >
              {row.carrierServiceName}
            </span>
          );
        }

        return "-";
      },
    },
    {
      position: 4,
      Header: "Creazione",
      accessor: "creationDate",
      sortable: true,
      filter: {
        active: true,
        name: "creationDate",
        type: "datepicker",
        col: 2,
      },
      Cell: ({ row }) => {
        return (
          <Moment
            date={row.original.creationDate}
            format="DD/MM/YYYY HH:mm:ss"
          />
        );
      },
    },
    {
      position: 5,
      Header: "Tipologia collo",
      hiddenHeader: true,
      accessor: "parcelTypeName",
      filter: {
        active: true,
        name: "parcelType.code",
        type: "select",
        options: parcelTypesList.map((type) => {
          return {
            value: type.code,
            label: type.name,
          };
        }),
        multi: true,
        optionStyle: getParcelTypeOptionStyle,
        col: 2,
      },
      Cell: ({ row }) => {
        const { parcelTypeCode, parcelTypeName } = row.original;
        return getParcelTypeBadge({
          code: parcelTypeCode,
          name: parcelTypeName,
        });
      },
    },
    {
      position: 6,
      Header: "Tipologia spedizione",
      accessor: "shipmentType",
      filter: {
        active: true,
        name: "shipmentType",
        type: "select",
        options: orderBykey(
          shipmentTypesList.map((shipmentType) => {
            return {
              value: shipmentType.code,
              label: getShipmentType(shipmentType.code),
            };
          }),
          "label"
        ),
        multi: true,
        optionStyle: getShipmentTypeOptionStyle,
        col: 2,
      },
      Cell: ({ row }) => {
        return getShipmentTypeBadge(row.original.shipmentType);
      },
    },
    {
      position: 7,
      Header: "Rif. interno",
      accessor: "internalReference",
      cellClass: "text-nowrap",
      Cell: ({ row }) => {
        return (
          <Link
            target="_blank"
            to={`https://${domain}/tracking/${row.original.internalReference}`}
          >
            {row.original.internalReference}
          </Link>
        );
      },
    },
    {
      position: 8,
      Header: "Rif. esterno",
      cellClass: "text-nowrap",
      accessor: "externalReference",
      Cell: ({ row }) => {
        const { externalReference, senderExternalReference } = {
          ...row.original,
        };
        return externalReference ? (
          <Link
            target="_blank"
            to={`https://${domain}/tracking${
              senderExternalReference ? `/${senderExternalReference}` : ""
            }/${encodeURIComponent(externalReference)}`}
          >
            {row.original.externalReference}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      position: 9,
      Header: "LDV",
      cellClass: "text-nowrap",
      accessor: "carrierReference",
      Cell: ({ row }) => {
        return row.original.carrierReference ? (
          <Link
            target="_blank"
            to={`https://${domain}/tracking/${row.original.carrierReference}`}
          >
            {row.original.carrierReference}
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      position: 10,
      Header: "Mittente",
      accessor: "senderName",
      cellClass: "text-nowrap",
      filter: {
        active: true,
        name: "senderId",
        type: "async-select",
        //searchValue = valore ricercato nella select; pagination
        loadOptions: (searchValue, pagination) => {
          return getAllCompanies(
            searchValue,
            pagination,
            {
              orderBy: "asc",
              selector: "name",
            }
            // [{ selector: "type", value: "CUSTOMER", preciseSearch: true }]
          );
        },
        multi: true,
        optionStyle: getCompanyOptionStyle,
        col: 5,
      },
    },
    {
      position: 11,
      Header: "Destinatario",
      cellClass: "text-nowrap",
      accessor: "deliveryName",
      filter: {
        active: true,
        name: "delivery.name",
        type: "search",
        preciseSearch: true,
      },
      col: 3,
    },
    {
      position: 12,
      Header: "Utente di creazione",
      accessor: "creationUser",
      col: 3,
      Cell: ({ row }) => {
        const { original } = row;
        return original.creationUser || "-";
      },
    },
    {
      position: 13,
      Header: "COD",
      accessor: "cashOnDeliveryValue",
      filter: {
        active: false,
        name: "cashOnDeliveryValue",
        type: "select",
        options: [
          { value: true, label: "SI" },
          { value: false, label: "NO" },
        ],
      },
      Cell: ({ row }) => {
        const cashOnDeliveryValue = row.original.cashOnDeliveryValue;
        return cashOnDeliveryValue ? (
          <img
            width={30}
            src={CashOnDeliveryIcon}
            title={`Contrassegno: ${cashOnDeliveryValue.toFixed(2)} ${
              row.original.cashOnDeliveryValueCurrency
            } ${row.original.cashOnDeliveryCollectionMethodName || ""}`}
          />
        ) : (
          "-"
        );
      },
      col: 3,
    },
    {
      position: 14,
      Header: "Ins.",
      accessor: "insuranceValue",
      filter: {
        active: false,
        name: "cashOnDeliveryValue",
        type: "select",
        options: [
          { value: true, label: "SI" },
          { value: false, label: "NO" },
        ],
      },
      Cell: ({ row }) => {
        const insuranceValue = row.original.insuranceValue;
        return insuranceValue ? (
          <img
            width={30}
            src={InsuranceIcon}
            title={`Assicurazione: ${insuranceValue.toFixed(2)} ${
              row.original.insuranceValueCurrency
            }`}
          />
        ) : (
          "-"
        );
      },
      col: 3,
    },
    {
      position: 15,
      Header: "Ritiro",
      accessor: "collectionAssigned",
      Cell: ({ row }) => {
        const collectionAssigned = row.original.collectionAssigned;
        return collectionAssigned ? (
          <img
            width={30}
            src={CollectionAssignedIcon}
            title="Ritiro prenotato"
          />
        ) : (
          "-"
        );
      },
      col: 3,
    },
  ];
};
//#endregion

//#region constants
export const defaultQuerySearch = [
  {
    and: [
      {
        greater_than_or_equal: {
          selector: "creationDate",
          args: moment().subtract(1, "months").startOf("day").format(),
        },
      },
      {
        less_than_or_equal: {
          selector: "creationDate",
          args: moment().endOf("day").format(),
        },
      },
    ],
  },
];

export const internalStatusNotes = {
  CONFIRMED: "La spedizione è stata inoltrata al trasportatore",
  BOOKED: "La spedizione è stata prenotata",
  DRAFT: "La spedizione è in bozza",
  DELETED: "La spedizione è stata cancellata",
};
//#endregion

export const getClassForMultiActionElement = (statusOfTableRows) => {
  let labelClass = "";
  let iconClass = "invisible";
  if (!statusOfTableRows || statusOfTableRows.length === 0)
    return { labelClass, iconClass };
  if (statusOfTableRows.some((element) => element.value)) {
    //almeno un valore selezionato
    labelClass = "scale-from-one-to-zero";
    iconClass = "scale-from-zero-to-one scale-animation-delay";
  } else {
    iconClass = "scale-from-one-to-zero";
    labelClass = "scale-from-zero-to-one scale-animation-delay";
  }
  return { labelClass, iconClass };
};

export const needsCustomData = (shipment) => {
  if (
    shipment.pickup &&
    shipment.pickup.location.countryIsoCode3 &&
    shipment.delivery &&
    shipment.delivery.location.countryIsoCode3
  ) {
    const pickupIsInEU = euCountries.includes(
      shipment.pickup.location.countryIsoCode3
    );
    const deliveryIsInEU = euCountries.includes(
      shipment.delivery.location.countryIsoCode3
    );
    const isParcelNotDoc = shipment.parcelType.code !== "DOC";
    const isCrossUEShipment =
      (pickupIsInEU && !deliveryIsInEU) || (!pickupIsInEU && deliveryIsInEU);
    return isParcelNotDoc && isCrossUEShipment;
  }
  return false;
};

export const customDataItemCheck = (item) => {
  let errors = [];
  if (!item.quantity > 0)
    errors.push({ field: "quantity", value: "Il campo deve essere popolato" });
  if (!item.unitWeight > 0)
    errors.push({
      field: "unitWeight",
      value: "Il campo deve essere popolato",
    });
  if (!item.unitPrice > 0)
    errors.push({ field: "unitPrice", value: "Il campo deve essere popolato" });
  if (!item.description?.length)
    errors.push({
      field: "description",
      value: "Il campo deve essere popolato",
    });
  if (!item.hsCode?.length)
    errors.push({ field: "hsCode", value: "Il campo deve essere popolato" });
  if (!item.category?.code)
    errors.push({ field: "category", value: "Il campo deve essere popolato" });
  if (!item.unitPriceCurrency?.code)
    errors.push({
      field: "unitPriceCurrency",
      value: "Il campo deve essere popolato",
    });
  if (!item.countryIsoCode?.code)
    errors.push({
      field: "countryIsoCode",
      value: "Il campo deve essere popolato",
    });

  if (errors.length > 0) {
    return { uuid: item.uuid, errors };
  }
  return null;
};
