import React from "react";
import { UsersService } from "../../services/user/users";
import { toast } from "react-toastify";
import { callErrorToast } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const usersService = new UsersService();

const EnabledDisabled = ({ enabled, userId, refreshTable }) => {
  const { dictionary } = useAppContext();

  const enabledDisabled = () => {
    usersService
      .enabledDisabled(userId, !enabled)
      .then((response) => {
        refreshTable();
        if (enabled) {
          toast.success(dictionary.messages.save_successful, {
            position: toast.POSITION.TOP_CENTER,
          });
          return false;
        }
        toast.success(dictionary.messages.save_successful, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };
  return (
    <div className="form-check form-switch mb-2">
      <input
        title={enabled ? dictionary.actions.desable : dictionary.actions.enable}
        readOnly
        onClick={enabledDisabled}
        checked={enabled}
        type="radio"
        className="form-check-input form-check-input-info cursor-pointer"
      />
    </div>
  );
};
export default EnabledDisabled;
