import React, { useState, useEffect } from "react";
import { Loader } from "./Loader";
import { toast } from "react-toastify";
import { ButtonConfirm } from "./Buttons/ButtonConfirm";
import { useAppContext } from "../../AppProvider";
import { faDownload } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ZPLViewer = ({ url, reference }) => {
  const { dictionary } = useAppContext();
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");

  const generateLabel = async (zplCode) => {
    try {
      const response = await fetch(
        `https://api.labelary.com/v1/printers/8dpmm/labels/4x6/`,
        {
          method: "POST",
          body: zplCode,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accept: "application/pdf",
          },
        }
      );

      if (!response.ok) {
        toast.error(`Errore durante la conversione del file ZPL`, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const blob = await response.blob();
      setPdfBlob(blob);

      // Creiamo un URL per il blob e lo memorizziamo
      const url = URL.createObjectURL(blob);
      setPdfBlobUrl(url);
    } catch (error) {
      toast.error(`Errore durante la conversione del file ZPL`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          toast.error(`Errore durante il recupero del file`, {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }
        const content = await response.text();
        generateLabel(content);
      } catch (error) {
        toast.error(`Errore durante il recupero del file`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };

    if (url) {
      fetchData();
    }
  }, [url]);

  const handleDownload = () => {
    if (pdfBlob) {
      const a = document.createElement("a");
      a.href = pdfBlobUrl;
      a.download = reference || "download"; // Imposta il nome del file
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div>
      {pdfBlobUrl ? (
        <div className="text-center">
          {/* Embed PDF with forced download */}
          <iframe
            src={pdfBlobUrl}
            style={{ width: "100%", height: "500px" }}
            title="Anteprima PDF"
          ></iframe>

          <ButtonConfirm icon={false} className="mt-3" onClick={handleDownload}>
            <FontAwesomeIcon icon={faDownload} /> {dictionary.actions.print} PDF
          </ButtonConfirm>
        </div>
      ) : (
        <Loader show={true} />
      )}
    </div>
  );
};

export default ZPLViewer;
