import moment from "moment";

export const allSelectedIncomingGoodsCanBeDeleted = (
  incomingGoodSelectedIds = [],
  incomingGoods = []
) => {
  const idSelected = incomingGoodSelectedIds
    ?.filter((x) => x.value)
    .map((x) => x.id);
  const incomingGoodsSelected = incomingGoods.filter((x) =>
    idSelected.includes(x.id)
  );
  return canAllIncomingGoodsBeDeleted(incomingGoodsSelected);
};

export const canIncomingGoodBeDeleted = (incomingGood) => {
  return true;
};

export const canAllIncomingGoodsBeDeleted = (incomingGoods) => {
  if (!incomingGoods || incomingGoods.length === 0) return false;
  const selectedIncomingGoodCanBeDeleted = incomingGoods.map((x) =>
    canIncomingGoodBeDeleted(x)
  );
  return selectedIncomingGoodCanBeDeleted.every((x) => x);
};

export const incomingGoodNormalizer = (incomingGood, accessTokenPayload) => {
  incomingGood.ownerId = incomingGood.ownerId || accessTokenPayload.owner;
  incomingGood.carrierCode = incomingGood.carrier?.code;
  incomingGood.goodsType = incomingGood.goodsType?.value;
  incomingGood.pickupWarehouseId = incomingGood.pickupWarehouse?.id;
  incomingGood.deliveryWarehouseId = incomingGood.deliveryWarehouse?.id;
  incomingGood.expectedDeliveryDate = incomingGood.expectedDeliveryDate
    ? moment(incomingGood.expectedDeliveryDate).format("YYYY-MM-DDTHH:mm:ssZ")
    : moment().add(1, "days").startOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
  incomingGood.departureDate = incomingGood.departureDate
    ? moment(incomingGood.departureDate).format("YYYY-MM-DDTHH:mm:ssZ")
    : null;
  incomingGood.effectiveDeliveryDate = incomingGood.effectiveDeliveryDate
    ? moment(incomingGood.effectiveDeliveryDate).format("YYYY-MM-DDTHH:mm:ssZ")
    : null;
  return incomingGood;
};
