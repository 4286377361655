import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users/Index";
import Shipments from "../pages/Shipments";
import Customers from "../pages/Customers";
import Organizations from "../pages/Organizations";
import Affiliates from "../pages/Afiliates";
import CreateEditShipment from "../pages/Shipments/createEdit";
import DetailsShipment from "../pages/Shipments/details";
import ShipmentRate from "../pages/Shipments/rate";
import Zones from "../pages/Locations/Zones";
import { Upload as UploadZone } from "../pages/Locations/Zones/Upload";
import { UploadZoneProvider } from "../pages/Locations/providers/Upload";
import Addresses from "../pages/Addresses";
import AddressBook from "../pages/AddressBook";
import Contracts from "../pages/Contracts";
import ContractServices from "../pages/ContractServices";
import CreateEditContractService from "../pages/ContractServices/createEdit";
import ParcelTemplates from "../pages/ParcelTemplates";
import Tracking from "../pages/Tracking";
import Collections from "../pages/Collections";
import User from "../pages/Profiles/User";
import CarriersIntegrations from "../pages/CarriersIntegrations";
import LandingPage from "../pages/LandingPage";
import StoresIntegrations from "../pages/StoresIntegrations";
import LogisticIntegrations from "../pages/LogisticIntegrations";
import Articles from "../pages/Articles/index.tsx";
import ArticleDetails from "../pages/Articles/Details";
import { CreateEdit as CreateEditArticle } from "../pages/Articles/CreateEdit";
import Orders from "../pages/Orders";
import { CreateEditArticleProvider } from "../pages/Articles/providers/CreateEdit";
import { OrderHandlerProvider } from "../pages/Orders/providers/Handler";
import { Warehouses } from "../pages/Warehouses";
import IncomingGoods from "../pages/IncomingGoods";
import { CreateEditIncomingGoodProvider } from "../pages/IncomingGoods/providers/CreateEdit";
import { CreateEdit as CreateEditIncomingGood } from "../pages/IncomingGoods/CreateEdit";
import { Details as DetailsIncomingGood } from "../pages/IncomingGoods/Details";
import {
  faHouse,
  faMapLocationDot,
  faTruckFast,
  faFileContract,
  faPuzzle,
  faAddressCard,
  faUser,
  faUsers,
  faHandHoldingBox,
  faBoxes,
  faAddressBook,
  faInventory,
  faBagsShopping,
  faWarehouse,
  faFileImport,
  faMoneyCheckDollarPen,
  faFileExport,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { IndexShipmentsProvider } from "../pages/Shipments/providers/Index";
import { IndexCodCollectionsProvider } from "../pages/CODCollections/providers/Index";
import CODCollections from "../pages/CODCollections";
import CODCollectionHandler from "../pages/CODCollections/CODCollectionHandler";
import { DetailsCodCollectionProvider } from "../pages/CODCollections/providers/Details";
import Exports from "../pages/Export";
import { IndexExportsProvider } from "../pages/Export/providers/Index";
import Manifest from "../pages/Manifest";
import { IndexOrdersProvider } from "../pages/Orders/providers/Index";
import OrderHandler from "../pages/Orders/OrderHandler";
import DDTShipment from "../pages/DDTShipment";
import { DDTProvider } from "../pages/DDTShipment/providers/Index";
import { IndexWarehousesProvider } from "../pages/Warehouses/providers/Index";
import { HandlerWarehouseProvider } from "../pages/Warehouses/providers/Handler";
import { Handler } from "../pages/Warehouses/Handler";
import { IndexArticlesProvider } from "../pages/Articles/providers/Index";

export const getAuthProtetedRoutes = (dictionary) => {
  return [
    {
      path: "/dashboard",
      element: <Dashboard />,
      name: "Homepage",
      icon: faHouse,
      template: true,
    },
    //#region localities
    {
      path: "/locations/zones",
      element: <Zones />,
      name: dictionary.locations.zones,
      icon: faMapLocationDot,
      template: true,
    },
    {
      path: "/locations/zones/upload",
      element: (
        <UploadZoneProvider autosave={true}>
          <UploadZone />
        </UploadZoneProvider>
      ),
      name: dictionary.locations.import_zone,
      icon: faMapLocationDot,
      template: true,
    },
    //#endregion
    //#region authentications
    {
      path: "/user-profile",
      element: <User />,
      name: dictionary.users.user_profile,
      icon: faUser,
      template: true,
    },
    {
      path: "/users",
      element: <Users />,
      name: dictionary.users.users,
      icon: faUsers,
      template: true,
    },
    //#endregion
    //#region shipments
    {
      path: "/shipments",
      element: (
        <IndexShipmentsProvider>
          <Shipments />
        </IndexShipmentsProvider>
      ),
      name: dictionary.shipments.shipments,
      icon: faTruckFast,
      template: true,
    },
    {
      path: "/shipments/create",
      element: <CreateEditShipment />,
      name: dictionary.shipments.new_shipment,
      icon: faTruckFast,
      template: true,
    },
    {
      path: "/shipments/edit/:id",
      element: <CreateEditShipment />,
      name: dictionary.shipments.edit_shipment,
      icon: faTruckFast,
      template: true,
    },
    {
      path: "/shipments/clone/:id",
      element: <CreateEditShipment />,
      name: dictionary.shipments.duplicate_shipment,
      icon: faTruckFast,
      template: true,
    },
    {
      path: "/shipments/return/:id",
      element: <CreateEditShipment />,
      name: dictionary.shipments.shipment_return,
      icon: faTruckFast,
      template: true,
    },
    {
      path: "/shipments/details/:id",
      element: <DetailsShipment />,
      name: dictionary.shipments.details_shipment,
      icon: faTruckFast,
      template: true,
    },
    {
      path: "/shipments/rate/:id",
      element: <ShipmentRate />,
      name: dictionary.rating.choose_rate,
      icon: faTruckFast,
      template: true,
    },
    {
      path: "/shipments/manifest",
      element: <Manifest />,
      template: false,
    },
    {
      path: "/shipments/ddt/:id",
      element: (
        <DDTProvider>
          <DDTShipment />
        </DDTProvider>
      ),
      template: false,
    },
    {
      path: "/collections",
      element: <Collections />,
      name: dictionary.collections.collections,
      icon: faHandHoldingBox,
      template: true,
    },
    {
      path: "/parcel-templates",
      element: <ParcelTemplates />,
      name: dictionary.parcels.packages_2_templates,
      icon: faBoxes,
      template: true,
    },
    //#endregion
    //#region COD Collections
    {
      path: "/cod-collections",
      element: (
        <IndexCodCollectionsProvider>
          <CODCollections />
        </IndexCodCollectionsProvider>
      ),
      name: dictionary.cod_collections.cod_collections,
      icon: faMoneyCheckDollarPen,
      template: true,
    },
    {
      path: "/cod-collections/details/:id",
      element: (
        <DetailsCodCollectionProvider>
          <CODCollectionHandler />
        </DetailsCodCollectionProvider>
      ),
      name: dictionary.cod_collections.cod_collections,
      icon: faMoneyCheckDollarPen,
      template: true,
    },
    //#endregion
    //#region Contracts
    {
      path: "/contracts",
      element: <Contracts />,
      name: dictionary.contracts.contracts,
      icon: faFileContract,
      template: true,
    },
    {
      path: "/contract-services",
      element: <ContractServices />,
      name: dictionary.services.services,
      icon: faFileContract,
      template: true,
    },
    {
      path: "/contract-services/create",
      element: <CreateEditContractService />,
      name: dictionary.services.new_service,
      icon: faFileContract,
      template: true,
    },
    {
      path: "/contract-services/edit/:id",
      element: <CreateEditContractService />,
      name: dictionary.services.edit_service,
      icon: faFileContract,
      template: true,
    },
    {
      path: "/contract-services/clone/:id",
      element: <CreateEditContractService />,
      name: dictionary.services.duplicate_service,
      icon: faFileContract,
      template: true,
    },
    //#endregion
    //#region registry
    {
      path: "/customers",
      element: <Customers />,
      name: dictionary.registries.customers,
      icon: faAddressCard,
      template: true,
    },
    {
      path: "/affiliates",
      element: <Affiliates />,
      name: dictionary.registries.affiliates,
      icon: faAddressCard,
      template: true,
    },
    {
      path: "/organizations",
      element: <Organizations />,
      name: dictionary.registries.organizations,
      icon: faAddressCard,
      template: true,
    },
    {
      path: "/addresses",
      element: <Addresses />,
      name: dictionary.locations.addresses,
      icon: faAddressBook,
      template: true,
    },
    {
      path: "/address-book",
      element: <AddressBook />,
      name: dictionary.contacts.address_book,
      icon: faAddressBook,
      template: true,
    },
    //#endregion
    //#region integrations
    {
      path: "/carriers-integrations",
      element: <CarriersIntegrations />,
      name: dictionary.integrations.carriers_integrations,
      icon: faPuzzle,
      template: true,
    },
    {
      path: "/stores-integrations",
      element: <StoresIntegrations />,
      name: dictionary.integrations.stores_integrations,
      icon: faPuzzle,
      template: true,
    },
    {
      path: "/logistic-integrations",
      element: <LogisticIntegrations />,
      name: dictionary.integrations.logistics_integrations,
      icon: faPuzzle,
      template: true,
    },
    //#endregion
    //#region logistic
    {
      path: "/articles",
      element: (
        <IndexArticlesProvider>
          <Articles />
        </IndexArticlesProvider>
      ),
      name: dictionary.products.products,
      icon: faInventory,
      template: true,
    },
    {
      path: "/articles/create",
      element: (
        <CreateEditArticleProvider autosave={true}>
          <CreateEditArticle />
        </CreateEditArticleProvider>
      ),
      name: dictionary.products.new_product,
      icon: faInventory,
      template: true,
    },
    {
      path: "/articles/edit/:id",
      element: (
        <CreateEditArticleProvider autosave={true}>
          <CreateEditArticle />
        </CreateEditArticleProvider>
      ),
      name: dictionary.products.edit_product,
      icon: faInventory,
      template: true,
    },
    {
      path: "/articles/details/:id",
      element: <ArticleDetails />,
      name: dictionary.products.detail_product,
      icon: faInventory,
      template: true,
    },
    {
      path: "/orders",
      element: (
        <IndexOrdersProvider>
          <Orders />
        </IndexOrdersProvider>
      ),
      name: dictionary.orders.orders,
      icon: faBagsShopping,
      template: true,
    },
    {
      path: "/orders/create",
      element: (
        <OrderHandlerProvider autosave={true}>
          <OrderHandler />
        </OrderHandlerProvider>
      ),
      name: dictionary.orders.new_order,
      icon: faBagsShopping,
      template: true,
    },
    {
      path: "/orders/edit/:id",
      element: (
        <OrderHandlerProvider autosave={true}>
          <OrderHandler />
        </OrderHandlerProvider>
      ),
      name: dictionary.orders.edit_order,
      icon: faBagsShopping,
      template: true,
    },
    {
      path: "/orders/clone/:id",
      element: (
        <OrderHandlerProvider autosave={true}>
          <OrderHandler />
        </OrderHandlerProvider>
      ),
      name: dictionary.orders.duplicate_order,
      icon: faBagsShopping,
      template: true,
    },
    {
      path: "/orders/details/:id",
      element: (
        <OrderHandlerProvider>
          <OrderHandler />
        </OrderHandlerProvider>
      ),
      name: dictionary.orders.detail_order,
      icon: faBagsShopping,
      template: true,
    },
    {
      path: "/warehouses",
      element: (
        <IndexWarehousesProvider>
          <Warehouses />
        </IndexWarehousesProvider>
      ),
      name: "Magazzini",
      icon: faWarehouse,
      template: true,
    },
    {
      path: "/warehouses/create",
      element: (
        <HandlerWarehouseProvider autosave={true}>
          <Handler />
        </HandlerWarehouseProvider>
      ),
      name: dictionary.registries.warehouses,
      icon: faBagsShopping,
      template: true,
    },
    {
      path: "/warehouses/edit/:id",
      element: (
        <HandlerWarehouseProvider autosave={true}>
          <Handler />
        </HandlerWarehouseProvider>
      ),
      name: dictionary.registries.warehouses,
      icon: faBagsShopping,
      template: true,
    },
    {
      path: "/incoming-goods",
      element: <IncomingGoods />,
      name: dictionary.incoming_goods.incoming_goods,
      icon: faFileImport,
      template: true,
    },
    {
      path: "/incoming-goods/create",
      element: (
        <CreateEditIncomingGoodProvider autosave={true}>
          <CreateEditIncomingGood />
        </CreateEditIncomingGoodProvider>
      ),
      name: dictionary.incoming_goods.incoming_goods,
      icon: faFileImport,
      template: true,
    },
    {
      path: "/incoming-goods/edit/:id",
      element: (
        <CreateEditIncomingGoodProvider autosave={true}>
          <CreateEditIncomingGood />
        </CreateEditIncomingGoodProvider>
      ),
      name: dictionary.incoming_goods.incoming_goods,
      icon: faFileImport,
      template: true,
    },
    {
      path: "/incoming-goods/details/:id",
      element: (
        <CreateEditIncomingGoodProvider>
          <DetailsIncomingGood />
        </CreateEditIncomingGoodProvider>
      ),
      name: dictionary.incoming_goods.incoming_goods,
      icon: faFileImport,
      template: true,
    },
    //#endregion
    //#region exports
    {
      path: "/exports",
      element: (
        <IndexExportsProvider>
          <Exports />
        </IndexExportsProvider>
      ),
      name: dictionary.words.exports,
      icon: faFileExport,
      template: true,
    },
    //#endregion
  ];
};

const publicRoutes = [
  { path: "/tracking", element: <Tracking />, header: true },
  { path: "/tracking/:reference1", element: <Tracking />, header: true },
  {
    path: "/tracking/:reference1/:reference2",
    element: <Tracking />,
    header: true,
  },
  { path: "/", element: <LandingPage /> },
  { path: "*", element: <LandingPage /> },
];

export { publicRoutes };
