import { useState, useRef } from "react"; // Import React hooks
import debounce from "lodash.debounce"; // Import lodash debounce for delayed API calls
import Select, { GroupBase, MultiValue, SingleValue } from "react-select"; // Import React-Select components for customizable select input
import { FormFeedback } from "reactstrap"; // Import FormFeedback for error messages
import { useAppContext } from "../../../AppProvider"; // Import application context to access dictionary for messages
import { useStyle } from "../utilities"; // Import custom styles based on error state

interface Pagination {
  page: number; // Current page number
  size: number; // Number of items per page
  isFirst: boolean; // Flag to indicate if it's the first page
  isLast: boolean; // Flag to indicate if it's the last page
}

interface AsyncSelectProps<T extends Record<string, any>> {
  name: string; // Name of the select field
  value: T | T[] | null; // Selected value(s)
  optionStyle?: (option: T) => React.ReactNode | string; // Optional function to style the options
  onSearch: (name: string, value: T | T[] | null) => void; // Callback to handle search and selection
  loadOptions: (
    inputValue: string, // Value entered in the search input
    pagination: Pagination // Pagination object for loading more results
  ) => Promise<{ data?: { content?: T[]; last?: boolean } }>; // Function to load options based on search query and pagination
  isMulti?: boolean; // Flag to allow multi-selection
  error?: string; // Error message to display
  optionValue?: keyof T; // The key in the option object for the option value
  optionLabel?: keyof T; // The key in the option object for the option label
  isClearable?: boolean; // Flag to allow clearing the selection
  placeholder?: string; // Placeholder text for the input field
  isDisabled?: boolean; // Flag to disable the select input
  closeMenuOnSelect?: boolean; // Flag to close the menu when an option is selected
  searchOnOpen?: boolean; // Flag to trigger a search when the menu opens
  noOptionsMessage?: string; // Custom message to display when no options are available
}

const AsyncSelect = <T extends Record<string, any>>({
  name,
  value,
  optionStyle,
  onSearch,
  loadOptions,
  isMulti = false,
  error = "",
  optionValue = "value",
  optionLabel = "label",
  isClearable = true,
  placeholder = "Select",
  isDisabled = false,
  closeMenuOnSelect = true,
  searchOnOpen = true,
  noOptionsMessage = "",
}: AsyncSelectProps<T>) => {
  // State to manage the options, loading state, search value, and pagination
  const [options, setOptions] = useState<T[]>([]); // Stores the options to display
  const [loading, setLoading] = useState<boolean>(false); // Flag to indicate loading state
  const [searchValue, setSearchValue] = useState<string>(""); // Stores the search input value
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: 10,
    isFirst: false,
    isLast: false,
  }); // Manages pagination state

  const { dictionary } = useAppContext(); // Access dictionary for internationalization (e.g., "no results found" message)
  const styles = useStyle<T>(error); // Custom styles based on error state

  // Debounced function to handle search input and call loadOptions with delay
  const debouncedSearch = useRef(
    debounce(
      async (
        inputValue: string,
        pag: Pagination,
        callback: typeof loadOptions
      ) => {
        setLoading(true); // Set loading state before fetching data
        try {
          const response = await callback(inputValue, pag); // Call the loadOptions function
          const resultArray =
            response.data?.content ||
            (Array.isArray(response.data) && response.data) ||
            []; // Get the list of options from the response
          const formattedOptions: T[] = resultArray.map((elem: T) => ({
            ...elem,
            value: elem[optionValue] as T[keyof T], // Map the value field
            label: elem[optionLabel] as T[keyof T], // Map the label field
          }));
          setOptions(formattedOptions); // Set the fetched options
          setSearchValue(inputValue); // Set the search value
          setPagination({
            ...pag,
            isFirst: !response.data?.last, // Determine if it's the first page
            isLast: response.data?.last ?? true, // Determine if it's the last page
          });
        } catch (error) {
          console.error("Error loading options", error); // Handle any errors during the API call
        } finally {
          setLoading(false); // Set loading state to false once the fetch is complete
        }
      },
      300 // Delay of 300ms before the actual API call is triggered
    )
  ).current;

  return (
    <div style={{ width: "100%" }}>
      <Select<T, typeof isMulti, GroupBase<T>>
        maxMenuHeight={200} // Maximum height for the dropdown menu
        isMulti={isMulti} // Allow multi-selection
        options={options} // List of options to display
        closeMenuOnSelect={closeMenuOnSelect} // Close the dropdown after selecting an option
        noOptionsMessage={() =>
          noOptionsMessage || dictionary.messages.no_results_found
        } // Message displayed when no options are found
        getOptionValue={(option) => option[optionValue] as string} // Key for the option's value
        getOptionLabel={(option) => option[optionLabel] as string} // Key for the option's label
        formatOptionLabel={(option) =>
          optionStyle ? optionStyle(option) : (option[optionLabel] as string)
        } // Custom styling for options
        isClearable={isClearable} // Allow clearing the selection
        placeholder={placeholder} // Placeholder text for the input field
        onChange={(selected) => {
          // Handle selection change
          const newValue = isMulti
            ? (selected as MultiValue<T>).map((option) => ({ ...option }))
            : (selected as SingleValue<T>) || null;
          onSearch(name, newValue); // Trigger the onSearch callback with the selected value
        }}
        onInputChange={(inputValue, event) => {
          // Handle input change
          if (event.action === "input-change") {
            debouncedSearch(
              inputValue,
              { ...pagination, page: 0 },
              loadOptions
            );
          }
        }}
        filterOption={() => true} // Always return true to allow all options
        onMenuOpen={() => {
          // Trigger search when the menu opens if searchOnOpen is true
          if (searchOnOpen) {
            debouncedSearch("", pagination, loadOptions);
          }
        }}
        onMenuClose={() => {
          // Reset state when the menu closes
          setPagination({ page: 0, size: 10, isFirst: false, isLast: false });
          setOptions([]);
          setSearchValue("");
          setLoading(false);
        }}
        onMenuScrollToBottom={() => {
          // Load more options when the user scrolls to the bottom of the dropdown
          if (pagination.isLast || loading) return;

          setLoading(true); // Set loading state to true
          const newPagination = { ...pagination, page: pagination.page + 1 };

          loadOptions(searchValue, newPagination).then((response) => {
            const resultArray = response.data?.content || [];
            if (resultArray.length === 0) {
              setPagination({ ...newPagination, isLast: true }); // No more data to load
            } else {
              setOptions([...options, ...resultArray]); // Append new options
              setPagination({
                ...newPagination,
                isLast: response.data?.last ?? true, // Update pagination state
              });
            }
            setLoading(false); // Set loading state to false
          });
        }}
        isLoading={loading} // Show loading spinner
        className={error ? "is-invalid" : ""} // Add invalid class if there's an error
        value={value} // Set the current selected value
        isDisabled={isDisabled} // Disable the select input if needed
        styles={styles} // Apply custom styles
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}{" "}
      {/* Display error message */}
    </div>
  );
};

export default AsyncSelect; // Export the component
