import React, { useRef, useCallback, useMemo } from "react";

import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { Modal, ModalBody } from "reactstrap";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useIndexWarehousesContext } from "./providers/Index";
import { Link } from "react-router-dom";
import { IndexWarehouseArticlesProvider } from "../WarehouseArticles/providers/Index";
import WarehouseArticles from "../WarehouseArticles";
import Delete from "./Delete";
import { useTypes } from "../../utilities/types";
import { createModalConfig, ModalConfig } from "../../Models/ModalConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTree, faEye, faTrash } from "../../utilities/fontawesomeIcons";
import { Warehouse } from "../../Models/Warehouse";
import { DeleteWarehouseProvider } from "./providers/Delete";

const AnyListProvider = ListProvider as any;
const AnyList = List as any;

export const Warehouses: React.FC = () => {
  const { dictionary } = useAppContext();
  const listRef = useRef<{ refresh: () => void } | null>(null);
  const listProps = useListProps();
  const { modalConfig, setModalConfig } = useIndexWarehousesContext();

  const renderItem = useCallback((item: Warehouse, key: number) => {
    return <ListItem key={key} item={item} />;
  }, []);

  const closeModal = () => {
    setModalConfig(createModalConfig());
  };

  return (
    <>
      <AnyListProvider path={listProps.path} filters={listProps.filters}>
        <AnyList
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          renderItem={renderItem}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
        />
      </AnyListProvider>

      <Modal isOpen={modalConfig.isOpen} size={modalConfig.size}>
        <ModalBody className={modalConfig.className || ""}>
          {modalConfig.action === "articles" && modalConfig?.data?.[0] && (
            <>
              <IndexWarehouseArticlesProvider parentId={modalConfig?.data[0]}>
                <WarehouseArticles action="details" shippingRequired={true} />
              </IndexWarehouseArticlesProvider>
              <IndexWarehouseArticlesProvider parentId={modalConfig?.data[0]}>
                <WarehouseArticles action="details" shippingRequired={false} />
              </IndexWarehouseArticlesProvider>
              <div className="row m-0 mt-3 bg-color-3 p-1">
                <div className="col">
                  <ButtonUtility
                    onClick={() => {
                      listRef.current?.refresh();
                      closeModal();
                    }}
                  >
                    {dictionary.actions.cancel}
                  </ButtonUtility>
                </div>
              </div>
            </>
          )}
          {modalConfig.action === "delete" && (
            <DeleteWarehouseProvider
              data={modalConfig.data}
              callback={() => {
                listRef.current?.refresh();
                closeModal();
              }}
            >
              <Delete />
            </DeleteWarehouseProvider>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Warehouses;

const ListItem: React.FC<{ item: Warehouse }> = React.memo(({ item }) => {
  const { dictionary, accessTokenPayload } = useAppContext();
  const context = useIndexWarehousesContext();
  const { ownership } = useTypes();

  const ownershipIcon = useMemo(
    () =>
      item.logisticIntegration.ownerId !== accessTokenPayload.owner
        ? ownership.find((o) => o.value === "inherited")
        : ownership.find((o) => o.value === "own"),
    [item, accessTokenPayload.owner, ownership]
  );

  const openModal = useCallback(
    (
      action: ModalConfig["action"],
      data?: string[],
      size: ModalConfig["size"] = "sm",
      className = "",
      title = ""
    ) => {
      context?.setModalConfig({
        isOpen: true,
        action,
        size,
        data,
        className,
        title,
      });
    },
    [context]
  );

  return (
    <div className="list-item px-2">
      <div
        className="d-grid grid-column align-items-center"
        style={{ gridTemplateColumns: "auto 1fr 2fr 3fr 1fr" }}
      >
        <div>
          {ownershipIcon && (
            <FontAwesomeIcon
              icon={ownershipIcon.icon}
              title={ownershipIcon.label}
            />
          )}
        </div>
        <div className="text-center">
          {item.logisticIntegration?.logistic?.logoUrl && (
            <img
              src={item.logisticIntegration.logistic.logoUrl}
              title={item.logisticIntegration.logistic.name}
              alt="Logo"
            />
          )}
        </div>
        <div>
          <label className="label-2">{dictionary.registries.name}:</label>
          <label className="label-3">{item.name || "-"}</label>
        </div>
        <div>
          <label className="label-2">{dictionary.locations.address}:</label>
          <label className="label-3">{item.location?.label || "-"}</label>
        </div>
        <div className="text-end">
          <button
            className="button-1 button-1-light button-1-icon me-1"
            title={dictionary.products.products}
            onClick={() => openModal("articles", [item.id], "xl", "bg-color-4")}
          >
            <FontAwesomeIcon icon={faListTree} />
          </button>
          <Link
            className="button-1 button-1-light button-1-icon me-1"
            to={`/warehouses/edit/${item.id}`}
            title={dictionary.actions.view}
          >
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <button
            className="button-1 button-1-danger button-1-icon me-1"
            title={dictionary.actions.delete}
            onClick={() => {
              if (
                accessTokenPayload.descendants?.includes(
                  item.logisticIntegration.ownerId
                )
              ) {
                openModal(
                  "delete",
                  [item.id],
                  "sm",
                  "",
                  dictionary.actions.delete
                );
              }
            }}
            disabled={
              !accessTokenPayload.descendants?.includes(
                item.logisticIntegration.ownerId
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    </div>
  );
});
