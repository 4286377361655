import React, { useState, useEffect } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { useAppContext } from "../../../AppProvider";

const TablePagination = ({ data, onPaginationChange }) => {
  const { dictionary } = useAppContext();
  const [isFirstPage, setIsFirstPage] = useState(null);
  const [isLastPage, setIsLastPage] = useState(null);
  const [searchPage, setSearchPage] = useState(1);

  const onChangeInInput = (value) => {
    let page = Math.max(0, Number(value) - 1);
    if (page > data?.totalPages - 1) page = data?.totalPages - 1;
    onPaginationChange({ page, number: page + 1 });
  };

  const gotoPage = (page) => {
    onPaginationChange({ page });
  };

  const previousPage = () => {
    onPaginationChange({ page: data.number - 1 });
  };
  const nextPage = () => {
    onPaginationChange({ page: data.number + 1 });
  };

  useEffect(() => {
    setIsFirstPage(data.first);
    setIsLastPage(data.last);
    setSearchPage(data.number + 1);
  }, [data]);

  return (
    <>
      {data?.content && data?.content.length > 0 && (
        <Row className="justify-content-md-end justify-content-center align-items-center p-3">
          <Col>
            <h6 className="text-mute">
              {dictionary.words.results_from} {data.pageable.offset + 1} a{" "}
              {data.pageable.offset + data.numberOfElements} di{" "}
              {data.totalElements}
            </h6>
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={isFirstPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={isFirstPage}
              >
                {"Previous"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            {dictionary.words.page}{" "}
            <strong>
              {data?.number + 1} {dictionary.words.on} {data?.totalPages}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 100 }}
              max={data?.totalPages}
              value={searchPage}
              onChange={(e) => setSearchPage(e.target.value)}
              onBlur={(e) => {
                let value = parseInt(e.target.value) || 1;
                if (value < 1) {
                  value = 1;
                } else if (value > data.totalPages) {
                  value = data.totalPages;
                }
                setSearchPage(value);
                onChangeInInput(value);
              }}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button color="primary" onClick={nextPage} disabled={isLastPage}>
                {"Next"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(data?.totalPages - 1)}
                disabled={isLastPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TablePagination;
