import React, { createContext, useState, useContext } from "react";

import {
  callErrorToast,
  callSuccessToast,
  modalDefault,
} from "../../../utilities";
import { OrdersService } from "../../../services/logistic/orders";
import { useAppContext } from "../../../AppProvider";

export const IndexOrdersContext = createContext();

const ordersService = new OrdersService();

const IndexOrdersProvider = ({ children }) => {
  const { dictionary } = useAppContext();

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const [orderLoader, setOrderLoader] = useState(false);

  const processNext = (warehouseId, callback) => {
    setOrderLoader(true);
    ordersService
      .processNext(warehouseId)
      .then((res) => {
        setOrderLoader(false);
        if (res.data) {
          callback();
          const newTab = window.open();
          newTab.opener = null;
          newTab.location = `${window.location.origin}/orders/details/${res.data}`;
          return;
        }
        callSuccessToast(
          {
            status: res.status,
            message: dictionary.orders.no_orders_to_work,
          },
          dictionary
        );
      })
      .catch((err) => {
        setOrderLoader(false);
        callErrorToast(err);
      });
  };

  return (
    <IndexOrdersContext.Provider
      value={{
        orderLoader,

        modal,
        setModal,

        processNext,
      }}
    >
      {children}
    </IndexOrdersContext.Provider>
  );
};

const useIndexOrdersContext = () => {
  return useContext(IndexOrdersContext);
};

export { IndexOrdersProvider, useIndexOrdersContext };
