export const incomingGoodDetailNormalizer = (
  incomingGoodDetail,
  accessTokenPayload,
  parentId
) => {
  incomingGoodDetail.ownerId =
    incomingGoodDetail.ownerId || accessTokenPayload.owner;
  incomingGoodDetail.articleId = incomingGoodDetail.article?.id;
  incomingGoodDetail.articleVariantId = incomingGoodDetail.articleVariant?.id;
  incomingGoodDetail.incomingGoodsId = parentId;
  return incomingGoodDetail;
};
