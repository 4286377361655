import { Location } from "./Location";
import { LogisticIntegration } from "./LogisticIntegration";

// Interface for GET (same as you have)
export interface Warehouse {
  id: string;
  creationDate?: Date;
  creationUser?: string;
  lastModifiedDate?: Date;
  lastModifiedUser?: string;
  name?: string;
  location?: Location;
  logisticIntegration: LogisticIntegration;
  earlyPicking?: boolean;
}

// Interface for POST (to create a new Warehouse)
export interface CreateWarehouse {
  logisticIntegrationId: string;
  name?: string;
}

// Interface for PUT (to update an existing Warehouse)
export interface UpdateWarehouse {
  id: string;
  name?: string | null; // Optional in case we don't want to change the name
  locationId?: string; // Optional for partial updates
  logisticIntegrationId: string; // Optional
}

// Convert function to update
export const convertWarehouseToUpdate = (
  warehouse: Warehouse
): UpdateWarehouse => {
  return {
    id: warehouse.id,
    locationId: warehouse?.location?.id,
    logisticIntegrationId: warehouse.logisticIntegration.id,
    name: warehouse.name || null,
  };
};
