export const normalizer = (order, accessTokenPayload) => {
  order.sourceIntegrationId = order.sourceIntegration?.id;
  order.ownerId = order.ownerId || accessTokenPayload.owner;
  order.deliveryId = order.delivery?.id;
  order.billingId = order.billing?.id;
  order.warehouseId = order.warehouse?.id;
  order.totalWeight = order.totalWeight || null;
  order.totalPrice = order.totalPrice >= 0 ? order.totalPrice : null;
  order.insuranceValue = order.insuranceValue || null;
  order.goodsValue = order.goodsValue || null;
  order.cashOnDeliveryValue = order.cashOnDeliveryValue || null;
  order.cashOnDeliveryCollectionMethodCode = order.cashOnDeliveryValue
    ? order.cashOnDeliveryCollectionMethodCode?.code ||
      order.cashOnDeliveryCollectionMethodCode ||
      "CASH"
    : null;
  order.currency = order.currency?.value || order.currency;
  if (order.sourceIntegrationId) {
    order.sourceType = "STORE";
  }
  return order;
};

export const recalculateOrderValues = (currentOrder, orderDetails) => {
  if (orderDetails) {
    currentOrder.totalWeight = orderDetails.reduce(
      (total, item) => total + (item.totalWeight || 0),
      0
    );

    currentOrder.totalPrice =
      orderDetails.reduce((total, item) => total + (item.totalPrice || 0), 0) ||
      0;

    const totalPriceVatIncluded =
      currentOrder.totalPrice +
      orderDetails.reduce(
        (t, { unitPrice, vat, discount = 0, quantity }) =>
          t + ((unitPrice - discount) * quantity * vat) / 100,
        0
      );
    currentOrder.totalPriceVatIncluded =
      totalPriceVatIncluded >= 0 ? totalPriceVatIncluded : null;

    currentOrder.insuranceValue = orderDetails.reduce(
      (total, item) =>
        total +
        (item.article.shippingRequired && item.totalPrice > 0
          ? item.totalPrice
          : 0),
      0
    );

    currentOrder.goodsValue = orderDetails.reduce(
      (total, item) =>
        total +
        (item.article.shippingRequired && item.totalPrice > 0
          ? item.totalPrice
          : 0),
      0
    );

    currentOrder.cashOnDeliveryValue =
      currentOrder.totalPriceVatIncluded +
      (currentOrder.shippingCost
        ? parseFloat(currentOrder.shippingCost) * 1.22
        : 0);
  } else {
    currentOrder.totalPrice = null;
    currentOrder.totalWeight = null;
    currentOrder.goodsValue = null;
    currentOrder.cashOnDeliveryValue = currentOrder.shippingCost
      ? parseFloat(currentOrder.shippingCost) * 1.22
      : 0;
    currentOrder.insuranceValue = null;
    currentOrder.totalPriceVatIncluded = null;
  }

  return currentOrder;
};
