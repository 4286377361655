import moment from "moment";
import { isColorDark } from "../../utilities";

export const eventsNormalizer = (events) => {
  const eventGroups = {};
  events.forEach((event) => {
    const day = moment(event.eventDate).format("DD/MM/YYYY");

    if (!eventGroups[day]) {
      eventGroups[day] = [];
    }

    eventGroups[day].push(event);
  });
  return eventGroups;
};

export const createTimelineStatusPoints = (lastEvent) => {
  let timelineStatusPoint = {};

  switch (lastEvent.status.code) {
    case "INFO_RCVD":
    case "TKN_DLV":
      timelineStatusPoint.BOOKED = true;
      break;
    case "IN_TRANSIT":
      timelineStatusPoint.BOOKED = true;
      timelineStatusPoint.IN_TRANSIT = true;
      break;
    case "OUT_OF_DLV":
    case "AVAIBLE":
      timelineStatusPoint.BOOKED = true;
      timelineStatusPoint.IN_TRANSIT = true;
      timelineStatusPoint.OUT_OF_DLV = true;
      break;
    case "DELIVERED":
      timelineStatusPoint.BOOKED = true;
      timelineStatusPoint.IN_TRANSIT = true;
      timelineStatusPoint.OUT_OF_DLV = true;
      timelineStatusPoint.DELIVERED = true;
      break;
    case "EXCEPTION":
    case "FAILED_DLV":
      timelineStatusPoint.BOOKED = true;
      timelineStatusPoint.IN_TRANSIT = true;
      timelineStatusPoint.OUT_OF_DLV = true;
      timelineStatusPoint.EXCEPTION = true;
      break;
  }

  return timelineStatusPoint;
};

export const getStyle = (primaryColor, secondaryColor) => {
  let style = {};
  style.timeline = `
    .timeline-horizontal-container ol::before {
      background-color: ${secondaryColor};
    }
    .timeline-horizontal-container li.active:after {
      border-color: ${secondaryColor};
      background-color: ${isColorDark(secondaryColor) ? "#fff" : "#000"}
    }`;
  style.buttons = `
    #search-button {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
    }
    #search-button:hover {
      background-color: white;
      color: ${primaryColor};
      border-color: ${primaryColor};
    }
    #show-all-events-button {
      background-color: ${secondaryColor};
      border-color: ${secondaryColor};
      color: ${primaryColor};
    }
    #show-all-events-button:hover {
      background-color: white;
      border-color: ${secondaryColor};
      color: ${primaryColor};
    }
    `;
  return style;
};
