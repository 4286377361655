import React from "react";
import { useHandlerContext } from "./providers/Handler";
import { useAppContext } from "../../AppProvider";
import AsyncSelect from "../../Components/Selects/AsyncSelect/AsyncSelect";
import InputIcon from "../../Components/Inputs/InputIcon/InputIcon";
// import { getGenericOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFieldError } from "../../utilities/utilities";
import { getAllLogisticIntegrations } from "../../Components/Selects/AsyncSelect/api";
import { FetchParam } from "../../Models/FetchParam";
import SearchLocality from "../../Components/Selects/SearchLocality/SearchLocality";
import {
  faICursor,
  faLoader,
  faArrowLeft,
} from "../../utilities/fontawesomeIcons";
import { Warehouse } from "../../Models/Warehouse";
import { LocationHandlerProvider } from "../Locations/providers/Handler";
import { IndexWarehouseArticlesProvider } from "../WarehouseArticles/providers/Index";
import WarehouseArticles from "../WarehouseArticles";
import { Link, useParams } from "react-router-dom";

export const Handler = () => {
  const { id } = useParams();
  const { dictionary } = useAppContext();
  const { data, action, error, loader, edit } = useHandlerContext();

  const titles: Record<string, string> = {
    create: dictionary.words.new_sm,
    clone: dictionary.actions.duplicate,
    edit: dictionary.actions.edit,
    details: dictionary.actions.details,
  };

  const handleChange = (name: string, value: unknown, save = true) => {
    if (action !== "details") {
      const newData = {
        ...data,
        [name]: value,
      };

      edit(newData as Warehouse, name as keyof Warehouse, save);
    }
  };

  return (
    <React.Fragment>
      <div className="row align-items-center mb-2">
        <div className="col">
          <h2 className="m-0">
            {titles[action]} {dictionary.registries.warehouse}
          </h2>
        </div>
      </div>
      {loader ? (
        <div className="text-center mt-2">
          <FontAwesomeIcon icon={faLoader} spin size="2x" />
        </div>
      ) : (
        <React.Fragment>
          <div className="row mb-2">
            <div className="col">
              <div className="box-container mb-2">
                <div className="row">
                  <div className="col">
                    <label className="label-2">
                      {dictionary.integrations.integration}
                    </label>
                    <AsyncSelect
                      name="logisticIntegration"
                      optionValue="id"
                      optionLabel="name"
                      value={data.logisticIntegration ?? null}
                      onSearch={handleChange}
                      loadOptions={(inputValue, pagination) => {
                        const params: FetchParam = {
                          inputValue,
                          pagination,
                          sorting: { selector: "name", orderBy: "asc" },
                        };
                        return getAllLogisticIntegrations(params);
                      }}
                      // optionStyle={getGenericOptionStyle}
                      isClearable={false}
                      isDisabled={action === "details"}
                      error={getFieldError(error, "logisticIntegration")}
                      placeholder={
                        action !== "details" ? dictionary.actions.select : ""
                      }
                    />
                  </div>
                  <div className="col">
                    <label className="label-2">
                      {dictionary.registries.name}
                    </label>
                    <InputIcon
                      name="name"
                      value={data.name || ""}
                      placeholder={"Magazzino A"}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      icon={faICursor}
                      error={error?.response?.data?.name}
                    />
                  </div>
                  <div className="col-5">
                    <label className="label-2">
                      {dictionary.locations.address}
                    </label>
                    <LocationHandlerProvider
                      service={"logistic"}
                      id={data.location?.id}
                      callback={(test: any) => {
                        if (!data?.location?.id) {
                          handleChange("location", test);
                        }
                        return;
                      }}
                    >
                      <SearchLocality
                        name="location"
                        onChange={(name, option) => {
                          handleChange(
                            name,
                            {
                              ...option.place,
                              id: data?.location?.id,
                            },
                            false
                          );
                        }}
                        value={data?.location}
                        placeholder="Via Roma, 10, 10121 Torino TO, Italia"
                      />
                    </LocationHandlerProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {id && (
            <React.Fragment>
              <div className="row mb-2">
                <IndexWarehouseArticlesProvider parentId={id}>
                  <WarehouseArticles action={action} shippingRequired={true} />
                </IndexWarehouseArticlesProvider>
              </div>
              <div className="row mb-2">
                <IndexWarehouseArticlesProvider parentId={id}>
                  <WarehouseArticles action={action} shippingRequired={false} />
                </IndexWarehouseArticlesProvider>
              </div>
            </React.Fragment>
          )}
          <div className="row">
            <div className="col">
              {
                <Link
                  to={`/warehouses`}
                  className="button-1 button-1-transparent"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> {dictionary.words.back}
                </Link>
              }
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
