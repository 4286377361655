import React, { useEffect } from "react";
import {
  faLoader,
  faLongArrowRight,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShipmentsContext } from "./providers/Shipments";
import { useAppContext } from "../../AppProvider";

const ReportCard = ({ title = "", subtitle = "", path = "#", filters }) => {
  const context = useShipmentsContext();
  const { dictionary } = useAppContext();

  return (
    context && (
      <div className="report">
        <div className="polygon"></div>
        <span className="type">{title.toUpperCase()}</span>
        <span className="period">{subtitle.toUpperCase()}</span>
        <span className="counter">
          {context.kpiLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            context.kpi?.total
          )}
        </span>
        <Link
          className="link text-end"
          to={path}
          onClick={() => {
            if (filters) {
              localStorage.setItem("page_filters", JSON.stringify(filters));
            }
          }}
        >
          {dictionary.actions.see_all}
          <FontAwesomeIcon icon={faLongArrowRight} size="1x" />
        </Link>
      </div>
    )
  );
};

export { ReportCard };
