import React, { useState, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  buildQuerySort,
  buildQuerySearch,
  modalSettingsDefault,
  getUniqueSelectors,
  orderBykey,
  getClassForMultiActionElement,
  incomingGoodStatus,
  getIncomingGoodStatusBadge,
} from "../../utilities";
import {
  allSelectedIncomingGoodCanBeDeleted,
  allSelectedIncomingGoodsCanBeDeleted,
  canAllIncomingGoodsBeDeleted,
  columns,
} from "./utilities";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import useFetch from "../../customHooks/useFetch";

import { Link } from "react-router-dom";
import { Delete } from "./Delete";
import { DeleteIncomingGoodProvider } from "./providers/Delete";
import { ConfirmIncomingGoodProvider } from "./providers/Confirm";
import { Confirm } from "./Confirm";
import { useTypes } from "../../utilities/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../AppProvider";
import {
  getCarrierOptionStyle,
  getIncomingGoodOptionStyle,
  getWarehouseArticleOptionStyle,
  getWarehouseOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import {
  getAllCarriers,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import moment from "moment";

const IncomingGoods = () => {
  const { dictionary } = useAppContext();
  const types = useTypes();
  console.log(dictionary);
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "desc",
      column: "departureDate",
    },
  ]);
  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [statusOfTableRows, setStatusOfTableRows] = useState([]);

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
    setStatusOfTableRows([]);
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/logistic-service/incoming-goods?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  const onChangeStatusRow = (changeStatusForRows) => {
    setStatusOfTableRows((previousStatusOfTableRows) => {
      changeStatusForRows.forEach((changedRow) => {
        const existingRow = previousStatusOfTableRows.find(
          (row) => row.id === changedRow.id
        );
        if (existingRow) {
          existingRow.value = changedRow.value;
        } else {
          previousStatusOfTableRows.push(changedRow);
        }
      });
      return [...previousStatusOfTableRows];
    });
  };

  const onChangeStatusAllRows = (val) => {
    const newSelectedRow = data.content.map((x) => {
      return { id: x.id, value: val };
    });
    setStatusOfTableRows(newSelectedRow);
  };

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  const allColumns = orderBykey(
    [
      {
        position: 1,
        Header: dictionary.words.status,
        accessor: "status",
        filter: {
          active: true,
          name: "status",
          type: "select",
          options: incomingGoodStatus,
          multi: true,
          optionStyle: getIncomingGoodOptionStyle,
          col: 3,
        },
        Cell: ({ cell }) => {
          return getIncomingGoodStatusBadge(cell.value);
        },
      },
      {
        position: 2,
        Header: dictionary.registries.carrier,
        accessor: "carrierCode",
        Cell: ({ cell }) => {
          return cell.row.original.carrier?.logoUrl ? (
            <img
              title={cell.row.original.carrier.name}
              width={50}
              src={cell.row.original.carrier.logoUrl}
            />
          ) : (
            ""
          );
        },
        filter: {
          active: true,
          name: "carrierCode",
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCarriers(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          multi: true,
          optionStyle: getCarrierOptionStyle,
          col: 3,
        },
      },
      {
        position: 3,
        Header: dictionary.references.carrier_reference,
        accessor: "carrierReference",
      },
      // {
      //   position: 4,
      //   Header: dictionary.references.sender_reference,
      //   accessor: "senderReference",
      // },
      // {
      //   position: 5,
      //   Header: dictionary.references.receiver_reference,
      //   accessor: "receiverReference",
      // },
      {
        position: 6,
        Header: dictionary.registries.pickup_warehouse,
        accessor: "pickupWarehouse.name",
        filter: {
          active: true,
          name: "pickupWarehouse.id",
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllWarehouses(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          multi: true,
          optionStyle: getWarehouseOptionStyle,
          col: 5,
        },
      },
      {
        position: 7,
        Header: dictionary.registries.delivery_warehouse,
        accessor: "deliveryWarehouse.name",
        filter: {
          active: true,
          name: "deliveryWarehouse.id",
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllWarehouses(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          multi: true,
          optionStyle: getWarehouseOptionStyle,
          col: 5,
        },
      },
      {
        position: 8,
        Header: dictionary.dates.departure_date,
        accessor: "departureDate",
        sortable: true,
        filter: {
          active: true,
          name: "departureDate",
          type: "datepicker",
          col: 2,
        },
        Cell: ({ cell }) => {
          return cell.row.original.departureDate
            ? moment(cell.row.original.departureDate).format("DD/MM/YYYY")
            : "";
        },
      },
      {
        position: 9,
        Header: dictionary.dates.expected_delivery_date,
        accessor: "expectedDeliveryDate",
        Cell: ({ cell }) => {
          return cell.row.original.expectedDeliveryDate
            ? moment(cell.row.original.expectedDeliveryDate).format(
                "DD/MM/YYYY"
              )
            : "";
        },
      },
      {
        position: 10,
        Header: dictionary.dates.effective_delivery_date,
        accessor: "effectiveDeliveryDate",
        Cell: ({ cell }) => {
          return cell.row.original.effectiveDeliveryDate
            ? moment(cell.row.original.effectiveDeliveryDate).format(
                "DD/MM/YYYY"
              )
            : "";
        },
      },
      {
        position: 11,
        Header: dictionary.products.goods_typology,
        accessor: "goodsType",
        filter: {
          active: true,
          name: "goodsType",
          type: "select",
          options: types.goods,
          multi: true,
          // optionStyle: getIncomingGoodOptionStyle,
          col: 2,
        },
        Cell: ({ cell }) => {
          const type = types.goods.find((g) => g.value === cell.value);
          return (
            type && (
              <div className="text-center">
                <FontAwesomeIcon
                  icon={type.icon}
                  title={type.label}
                  size="lg"
                />
              </div>
            )
          );
        },
      },
      {
        Header: (
          <>
            <div
              className={
                "multi-action-label scale-animation " +
                getClassForMultiActionElement(statusOfTableRows).labelClass
              }
            >
              {dictionary.words.actions}
            </div>

            <UncontrolledDropdown
              className={
                getClassForMultiActionElement(statusOfTableRows).dropDownClass
              }
            >
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className={"btn border-0 text-body"}
                data-bs-toggle="dropdown"
              >
                <i
                  className={
                    "ph-fill ph-list text-primary fw-bolder multi-action-icon scale-animation " +
                    getClassForMultiActionElement(statusOfTableRows).iconClass
                  }
                ></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                <DropdownItem
                  href="#"
                  className={"text-danger"}
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: dictionary.actions.delete_selected,
                      icon: "ph-trash",
                      open: !modalSettings.open,
                      incomingGoods: [
                        ...data.content
                          .filter((incomingGood) =>
                            statusOfTableRows
                              .filter((element) => element.value)
                              .map((x) => x.id)
                              .includes(incomingGood.id)
                          )
                          .map((incomingGood) => incomingGood.id),
                      ],
                      action: "delete",
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  {dictionary.actions.delete_selected}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
        pinning: "right",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className="btn border-0 text-body"
                data-bs-toggle="dropdown"
              >
                <i className="ph-list"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                {original.status === "DRAFT" && (
                  <>
                    <DropdownItem
                      href={`/incoming-goods/edit/${row.original.id}`}
                    >
                      <i className="ph-note-pencil me-2"></i>
                      {dictionary.actions.edit}
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        setModalSettings({
                          ...modalSettings,
                          title: dictionary.actions.confirm,
                          icon: "ph-check",
                          open: !modalSettings.open,
                          action: "confirm",
                          incomingGoods: [original.id],
                        });
                      }}
                    >
                      <i className="ph-check me-2"></i>
                      {dictionary.actions.confirm}
                    </DropdownItem>
                    <DropdownItem
                      className="text-danger"
                      href="#"
                      onClick={() => {
                        setModalSettings({
                          ...modalSettings,
                          title: dictionary.actions.delete,
                          icon: "ph-trash",
                          open: !modalSettings.open,
                          action: "delete",
                          incomingGoods: [original.id],
                        });
                      }}
                    >
                      <i className="ph-trash me-2"></i>
                      {dictionary.actions.delete}
                    </DropdownItem>
                  </>
                )}
                <DropdownItem
                  href={`/incoming-goods/details/${row.original.id}`}
                >
                  <i className="ph-magnifying-glass me-2"></i>
                  {dictionary.actions.details}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    "position"
  );

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title={dictionary.incoming_goods.incoming_goods} />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Link
                  to={"/incoming-goods/create"}
                  className="nav-link float-end"
                  data-key="t-basic-action"
                >
                  <Button
                    color="light"
                    type="button"
                    className="btn btn-outline-success btn-sm"
                  >
                    {dictionary.words.new}{" "}
                    {dictionary.incoming_goods.incoming_goods}{" "}
                    <i className="ph-plus ms-2"></i>
                  </Button>
                </Link>
              </div>
            </div>
            <TableAdvanced
              selectMultipleRows={false}
              statusOfTableRows={statusOfTableRows}
              onChangeStatusRow={onChangeStatusRow}
              onChangeStatusAllRows={onChangeStatusAllRows}
              hasFilter={true}
              columns={allColumns}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              omnisearch={{
                active: true,
                onSearch: setFreeSearch,
                placeholder: dictionary.references.references,
              }}
              showLoader={loading}
            />
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modalSettings.open}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "delete" && (
            <DeleteIncomingGoodProvider
              data={modalSettings.incomingGoods}
              callback={modalToggle}
            >
              <Delete />
            </DeleteIncomingGoodProvider>
          )}
          {modalSettings.action === "confirm" && (
            <ConfirmIncomingGoodProvider
              data={modalSettings.incomingGoods}
              callback={modalToggle}
            >
              <Confirm />
            </ConfirmIncomingGoodProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default IncomingGoods;
