import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIndexStockReleaseShipmentsContext } from "../providers/Index";

export const useListProps = (action, shippingRequired) => {
  const { dictionary } = useAppContext();
  const { setModalConfig } = useIndexStockReleaseShipmentsContext();

  return {
    path: "/shipment-service/stock-release-shipments",
    create: {
      enabled: false,
    },
    pagination: { enabled: true },
    selectRowsEnabled: { enabled: false },
    actionBar: { enabled: false, fixed: false },
    sorting: [],
    banner: {
      enabled: false,
    },
    // title: shippingRequired ? (
    //   <h3 className="m-0">{dictionary.products.physical_goods}</h3>
    // ) : (
    //   <h3 className="m-0">{dictionary.products.digital_goods}</h3>
    // ),
    omnisearch: {
      enabled: false,
    },
    defaultDate: {
      enabled: false,
    },
    massiveActions: [],
    filters: {
      list: [],
      //   list: [
      //     {
      //       fieldName: "article.shippingRequired",
      //       value: [
      //         {
      //           value: shippingRequired ? "true" : "false",
      //           isBool: true,
      //         },
      //       ],
      //       type: "select",
      //       hidden: true,
      //     },
      //   ],
      //   preserve: false,
      //   name: "",
    },
  };
};
