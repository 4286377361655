import axios from "axios";
// import AuthService from "../services/authentication";

export default class HttpInterface {
  _checkStatus(response, isLogin) {
    if (!response) {
      throw {
        error: "NO RESPONSE FROM BACKEND",
      };
    }
    return response;
  }
  _checkError(error) {
    return error;
  }

  fetch(url, options, headers = {}, responseType = "json") {
    let defaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language":
        localStorage.getItem("language") || navigator.language || "en",
    };
    let call;
    switch (options.method) {
      case "GET":
        call = axios.get(url, {
          responseType: responseType,
          headers: { ...defaultHeaders, ...headers },
        });
        break;
      case "POST":
        call = axios.post(url, options.body, {
          responseType: responseType,
          headers: { ...defaultHeaders, ...headers },
        });
        break;
      case "PUT":
        call = axios.put(url, options.body, {
          headers: { ...defaultHeaders, ...headers },
        });
        break;
      case "DELETE":
        call = axios.delete(url, {
          headers: { ...defaultHeaders, ...headers },
        });
        break;
      case "HEAD":
        call = axios.head(url, { headers: { ...defaultHeaders, ...headers } });
        break;
      default:
        call = axios.get(url, { headers: { ...defaultHeaders, ...headers } });
        break;
    }
    return call
      .then((response) => this._checkStatus(response))
      .catch((error) => {
        throw this._checkError(error);
      });
  }
}
/*
axios.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = `token ${token}`;
    }
    return config;
  }
);
*/
