import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { createModalConfig, ModalConfig } from "../../../Models/ModalConfig";

interface IndexWarehouseArticlesProviderProps {
  children: ReactNode; // React children components that will be wrapped by the provider
  callback?: () => void; // Optional function to execute after an action
  parentId: string; // The parent entity ID related to the warehouse articles
}

interface IndexWarehouseArticlesContextValue {
  modalConfig: ModalConfig; // Configuration settings for modals
  setModalConfig: Dispatch<SetStateAction<ModalConfig>>; // Function to update modal configuration
  callback?: () => void; // Optional function to execute after an action
  parentId: string; // Parent entity ID
  selectedItemId: string[]; // List of selected warehouse article IDs
  setSelectedItemId: Dispatch<SetStateAction<string[]>>; // Function to update selected article IDs
}

// Create the context with an initial value of undefined
const IndexWarehouseArticlesContext = createContext<
  IndexWarehouseArticlesContextValue | undefined
>(undefined);

// Provider component that manages modal configuration and selected warehouse articles
const IndexWarehouseArticlesProvider: React.FC<
  IndexWarehouseArticlesProviderProps
> = ({ children, callback, parentId }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>(
    createModalConfig()
  ); // State for managing modal configuration
  const [selectedItemId, setSelectedItemId] = useState<string[]>([]); // State for storing selected warehouse article IDs

  return (
    <IndexWarehouseArticlesContext.Provider
      value={{
        modalConfig,
        setModalConfig,
        callback,
        parentId,
        selectedItemId,
        setSelectedItemId,
      }}
    >
      {children}
    </IndexWarehouseArticlesContext.Provider>
  );
};

/**
 * Custom hook to access the IndexWarehouseArticlesContext.
 * Ensures that the context is used only within an IndexWarehouseArticlesProvider.
 */
const useIndexWarehouseArticlesContext =
  (): IndexWarehouseArticlesContextValue => {
    const context = useContext(IndexWarehouseArticlesContext);
    if (!context) {
      throw new Error(
        "useIndexWarehouseArticlesContext must be used within an IndexWarehouseArticlesProvider"
      );
    }
    return context;
  };

// Export the provider and custom hook for use in other components
export { IndexWarehouseArticlesProvider, useIndexWarehouseArticlesContext };
