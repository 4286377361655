import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ShipmentsService } from "../../../services/shipment/shipments";
import { callErrorToast } from "../../../utilities";

export const UpdateNoteContext = createContext();

const shipmentsService = new ShipmentsService();

const UpdateNoteProvider = ({ children, data, callback }) => {
  const [shipment, setShipment] = useState(null);
  const [shipmentError, setShipmentError] = useState(null);
  const [shipmentLoader, setShipmentLoader] = useState(false);
  const prevShipmentError = useRef();

  const getShipment = () => {
    setShipmentLoader(true);
    shipmentsService
      .get(data[0])
      .then((res) => {
        setShipment(res.data);
        setShipmentLoader(false);
      })
      .catch((err) => {
        setShipmentLoader(false);
        setShipmentError(err);
      });
  };

  const editShipment = (shipment) => {
    setShipment(shipment);
  };

  const save = () => {
    setShipmentLoader(true);
    shipmentsService
      .updateNotes(shipment.id, shipment.note)
      .then((response) => {
        setShipmentLoader(false);
        callback();
      })
      .catch((err) => {
        setShipmentLoader(false);
        setShipmentError(err);
      });
  };

  useEffect(() => {
    if (
      shipmentError &&
      JSON.stringify(prevShipmentError.current) !==
        JSON.stringify(shipmentError)
    ) {
      callErrorToast(shipmentError);
    }
  }, [shipmentError]);

  useEffect(() => {
    getShipment();
  }, []);

  return (
    <UpdateNoteContext.Provider
      value={{
        shipment,
        shipmentError,
        shipmentLoader,
        editShipment,
        save,

        callback,
      }}
    >
      {children}
    </UpdateNoteContext.Provider>
  );
};

const useUpdateNoteContext = () => {
  return useContext(UpdateNoteContext);
};

export { UpdateNoteProvider, useUpdateNoteContext };
