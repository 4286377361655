import React from "react";
import packageJson from "../../../package.json";
import moment from "moment";
import pdf from "../../assets/documents/termsAndConditions.pdf";
import { useAppContext } from "../../AppProvider";

const Footer = () => {
  const { dictionary } = useAppContext();
  return (
    <React.Fragment>
      <div className="footer">
        <div className="footer-content">
          <div>&copy; {moment().year()} Allabox</div>
          <div>v. {packageJson.version}</div>
          <div>
            <a
              href="https://www.iubenda.com/privacy-policy/89207242"
              className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe me-1"
              title="Privacy Policy "
            >
              Privacy Policy
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/89207242/cookie-policy"
              className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe me-1"
              title="Cookie Policy "
            >
              Cookie Policy
            </a>
            <a href={pdf} target="_blank">
              {dictionary.words.terms_and_conditions}
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
