import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import {
  convertWarehouseToUpdate,
  CreateWarehouse,
  Warehouse,
} from "../../../Models/Warehouse";
import { isEqual } from "lodash";
import { WarehousesService } from "../../../services/logistic/warehouses";
import { LogisticIntegrationsService } from "../../../services/logistic/logisticIntegrations";
import { useNavigate, useParams } from "react-router-dom";
import { callErrorToast } from "../../../utilities/utilities";
import { useAppContext } from "../../../AppProvider";

// Instantiate service classes for API calls
const warehousesService = new WarehousesService();
const logisticIntegrationsService = new LogisticIntegrationsService();

/**
 * Props for the HandlerWarehouseProvider component.
 */
interface HandlerWarehouseProviderProps {
  children: ReactNode; // React children components that will be wrapped by the provider
  callback?: () => void; // Optional function executed after an update
  autosave: boolean; // Determines whether warehouse updates are saved automatically
}

/**
 * Context value that will be shared with consumers.
 */
interface HandlerWarehouseContextValue {
  data: Partial<Warehouse>; // The warehouse data being managed
  error: ErrorResponse | null; // Error response if any request fails
  loader: boolean; // Indicates whether an operation is in progress
  getWarehouse: (id: string) => void; // Function to retrieve warehouse details
  action: string; // The current action (e.g., "create", "edit")
  edit: (
    warehouse: Warehouse,
    property: keyof Warehouse,
    save?: boolean
  ) => void; // Function to update warehouse details
}

/**
 * Error response structure from API calls.
 */
interface ErrorResponse {
  response?: {
    data: Partial<Warehouse>;
  };
}

// Create the context with an initial value of undefined
const HandlerWarehouseContext = createContext<
  HandlerWarehouseContextValue | undefined
>(undefined);

/**
 * Provider component for managing warehouse-related data and operations.
 * Handles retrieval, creation, and modification of warehouses.
 */
const HandlerWarehouseProvider: React.FC<HandlerWarehouseProviderProps> = ({
  children,
  autosave = false,
  callback,
}) => {
  const { id } = useParams(); // Get the warehouse ID from URL parameters
  const navigate = useNavigate();
  const { dictionary } = useAppContext();
  const action = window.location.pathname.split("/")[2]; // Extracts action from the URL

  // State management
  const [data, setData] = useState<Partial<Warehouse>>({});
  const [loader, setLoader] = useState<boolean>(true);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const prevError = useRef<ErrorResponse>(null);

  /**
   * Fetches warehouse details by ID.
   * @param id - The warehouse ID.
   */
  const getWarehouse = (id: string): void => {
    setLoader(true);
    warehousesService
      .get(id)
      .then((res) => {
        setLoader(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  /**
   * Retrieves all available logistic integrations.
   * @returns A promise resolving to an array of logistic integrations.
   */
  const getLogisticIntegrations = (): Promise<any[]> => {
    return logisticIntegrationsService
      .all()
      .then((res) => res.data?.content || [])
      .catch((err) => {
        callErrorToast(err, dictionary);
        return [];
      });
  };

  /**
   * Creates a new warehouse and navigates to the edit page.
   */
  const create = (): void => {
    getLogisticIntegrations().then((res) => {
      if (res.length > 0) {
        const warehouse: CreateWarehouse = {
          logisticIntegrationId: res[0].id,
        };

        warehousesService
          .create(warehouse)
          .then((res) => {
            navigate(`/warehouses/edit/${res.data.id}`, { replace: true });
          })
          .catch((err) => {
            callErrorToast(err, dictionary);
          });

        return;
      }

      callErrorToast({}, dictionary);
    });
  };

  /**
   * Updates a warehouse's property and optionally saves it.
   * @param warehouse - The updated warehouse object.
   * @param property - The property being modified.
   * @param save - Whether to persist the change immediately.
   */
  const edit = (
    warehouse: Warehouse,
    property: keyof Warehouse,
    save = true
  ): void => {
    removeError(property);
    if (save && autosave) {
      const warehouseUpdated = convertWarehouseToUpdate(warehouse);
      warehousesService
        .edit(warehouseUpdated)
        .then(() => {
          setData(warehouse);
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setLoader(false);
          setError(err);
        });
      return;
    }
    setData(warehouse);
  };

  /**
   * Removes an error related to a specific property.
   * @param property - The property for which the error should be removed.
   */
  const removeError = (property: keyof Warehouse) => {
    if (error && error.response) {
      const { data } = error.response;
      if (data && property in data) {
        const { [property]: _, ...newData } = data;
        setError({ response: { data: newData } });
        prevError.current = { response: { data: newData } };
      }
    }
  };

  /**
   * Effect to handle error notifications.
   */
  useEffect(() => {
    if (
      error &&
      typeof error === "object" &&
      !isEqual(prevError.current, error)
    ) {
      prevError.current = error;
      callErrorToast(error);
    }
  }, [error]);

  /**
   * Effect to initialize warehouse data based on the current action.
   */
  useEffect(() => {
    if (action === "create") {
      create();
      return;
    } else if (action === "clone") {
      return;
    } else if (id) {
      getWarehouse(id);
    }
  }, []);

  return (
    <HandlerWarehouseContext.Provider
      value={{
        data,
        loader,
        error,
        getWarehouse,
        edit,
        action,
      }}
    >
      {children}
    </HandlerWarehouseContext.Provider>
  );
};

/**
 * Custom hook to access the HandlerWarehouseContext.
 * Ensures that the context is used only within a HandlerWarehouseProvider.
 */
const useHandlerContext = (): HandlerWarehouseContextValue => {
  const context = useContext(HandlerWarehouseContext);
  if (!context) {
    throw new Error(
      "useHandlerWarehouseContext must be used within a HandlerWarehouseProvider"
    );
  }
  return context;
};

// Export the provider and custom hook for use in other components
export { HandlerWarehouseProvider, useHandlerContext };
