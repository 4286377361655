import React, { useState, useRef } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

import { Link } from "react-router-dom";
import { Collapse, Modal, ModalBody, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faBagsShopping,
  faCalendar,
  faCheck,
  faClone,
  faCommentDots,
  faDownToBracket,
  faEdit,
  faExchangeAlt,
  faEye,
  faInfoCircle,
  faUser,
  faGavel,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import moment from "moment";

import { List } from "../../Components/common/List/List";
import { useIndexShipmentsContext } from "./providers/Index";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { getParcelTypeBadge } from "../../utilities/badges";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useTypes } from "../../utilities/types";
import { DeleteShipmentProvider } from "./providers/Delete";
import { Delete } from "./DeleteNew";
import CreateEdit from "../Collections/CreateEdit"; //Da aggiornare
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { PrintShipmentProvider } from "./providers/Print";
import { Print } from "./PrintNew";
import {
  isDisabledCollection,
  isDisabledConfirm,
  isDisabledDelete,
  isDisabledDownload,
  isDisabledReturn,
  isDisabledStockRelease,
  useListProps,
} from "./utilities/list";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { ConfirmShipmentProvider } from "./providers/Confirm";
import { Confirm } from "./ConfirmNew";
import CODIcon from "../../assets/icons/cod.svg";
import CODCollectedIcon from "../../assets/icons/cod_collection.svg";
import CODReturnedIcon from "../../assets/icons/cod_return.svg";
import { TooltipCopy } from "../../Components/common/Tooltips/TooltipCopy";
import { UpdateNoteProvider } from "./providers/UpdateNote";
import { UpdateNote } from "./UpdateNote";
import { Handler as HandlerStockReleaseShipment } from "../StockReleaseShipments/Handler";
import { HandlerStockReleaseShipmentProvider } from "../StockReleaseShipments/providers/Handler";

const Shipments = () => {
  const { accessTokenPayload } = useOidcAccessToken();

  const listRef = useRef(null);
  const listProps = useListProps();

  const context = useIndexShipmentsContext();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          exports={listProps.exports}
          renderItem={renderItem}
        />
      </ListProvider>

      <Modal isOpen={context.modal.open} size={context.modal.size}>
        <ModalBody>
          {context.modal.action === "confirm" && (
            <ConfirmShipmentProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <Confirm />
            </ConfirmShipmentProvider>
          )}
          {context.modal.action === "delete" && (
            <DeleteShipmentProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <Delete />
            </DeleteShipmentProvider>
          )}
          {context.modal.action === "print" && (
            <PrintShipmentProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <Print />
            </PrintShipmentProvider>
          )}
          {context.modal.action === "updateNote" && (
            <UpdateNoteProvider
              data={context.modal.data}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            >
              <UpdateNote />
            </UpdateNoteProvider>
          )}
          {context.modal.action == "collection" && (
            <CreateEdit
              userOwnerId={accessTokenPayload.owner}
              defaultContactId={context.modal.pickupId}
              shipmentIds={[...context.modal.data]}
              carrierCode={context.modal.carrierCode}
              unmountCallback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
            />
          )}
          {context.modal.action == "stockRelease" && (
            <HandlerStockReleaseShipmentProvider
              event={context.modal.data[0]}
              callback={() => {
                context.setModal({ open: false });
                listRef.current.refresh();
              }}
              action="create"
            >
              <HandlerStockReleaseShipment />
            </HandlerStockReleaseShipmentProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Shipments;

const ListItem = React.memo(({ item, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { dictionary, domain } = useAppContext();

  const context = useIndexShipmentsContext();

  const { currencies, shipmentStatus } = useTypes();

  return (
    <React.Fragment key={index}>
      <div
        className="list-item px-2"
        style={{ borderLeft: `5px ${item.statusColor} solid` }}
      >
        <div
          className="d-grid align-items-center"
          style={{
            gridAutoFlow: "column",
            gridTemplateColumns: `0.25fr 1.25fr 2.5fr 2.25fr 0.75fr 1.5fr 1.25fr `,
            gap: "0.5rem",
          }}
        >
          <div className="text-center">
            {getParcelTypeBadge(item.parcelTypeCode, "xl")}
          </div>
          <div>
            <TooltipCopy
              target={"internalReference_" + index}
              textToCopy={item.internalReference}
            >
              <label className="label-4 cursor-pointer">
                {item.internalReference}
              </label>
            </TooltipCopy>
            <span className="span-1">
              {item.creationUser && (
                <>
                  <FontAwesomeIcon
                    id={"creationUser_" + index}
                    className="cursor-pointer"
                    icon={faUser}
                  />{" "}
                  <UncontrolledTooltip
                    placement="right"
                    target={"creationUser_" + index}
                  >
                    {item.creationUser}
                  </UncontrolledTooltip>
                </>
              )}
              {moment(item.creationDate).format("DD/MM/YYYY")} |{" "}
              {moment(item.creationDate).format("HH:mm")}
            </span>
          </div>
          <div
            className="bg-grey d-grid grid-column"
            style={{
              gridTemplateColumns: `1fr 1fr`,
            }}
          >
            <div>
              <label className="label-2">
                {dictionary.references.carrier_reference}:
              </label>
              <TooltipCopy
                target={"carrier_reference_" + index}
                textToCopy={item.carrierReference}
              >
                <label
                  className={`label-3 text-break ${
                    item.carrierReference ? "cursor-pointer" : ""
                  }`}
                >
                  {item.carrierReference || "-"}
                </label>
              </TooltipCopy>
            </div>
            <div>
              <label className="label-2">
                {dictionary.references.external_reference}:
              </label>
              <TooltipCopy
                target={"external_reference_" + index}
                textToCopy={item.externalReference}
              >
                <label
                  className={`label-3 text-break ${
                    item.externalReference ? "cursor-pointer" : ""
                  }`}
                >
                  {item.externalReference || "-"}
                </label>
              </TooltipCopy>
            </div>
          </div>
          <div>
            <label className="label-2">{dictionary.registries.receiver}:</label>
            <label className="label-3">{item.deliveryName || "-"}</label>
          </div>
          <div className="text-center">
            {item.carrierLogo && (
              <img src={item.carrierLogo} title={item.carrierName} />
            )}
          </div>
          <div>
            <Link
              target={item.carrierReference ? "_blank" : ""}
              className={
                item.carrierReference ? "cursor-pointer" : "cursor-default"
              }
              to={
                item.carrierReference
                  ? `https://${domain}/tracking/${item.carrierReference}`
                  : "#"
              }
            >
              <div
                style={{ backgroundColor: item.statusColor }}
                className={`status`}
              >
                <span className={`name`}>
                  {
                    shipmentStatus.find((s) => s.value === item.statusCode)
                      .label
                  }
                </span>{" "}
                {item.deliveryDate && (
                  <>
                    <FontAwesomeIcon
                      id={"deliveryDate_" + index}
                      className="cursor-pointer"
                      icon={faInfoCircle}
                    />{" "}
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"deliveryDate_" + index}
                    >
                      {moment(item.deliveryDate).format("DD/MM/YYYY HH:mm")}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </Link>
          </div>
          <div className="text-end">
            <ButtonUtility
              title="Visualizza note"
              onClick={() => {
                context.setModal({
                  open: true,
                  action: "updateNote",
                  data: [item.id],
                });
              }}
              className={`button-1-icon me-1 ${item.note && "border-color-2"}`}
            >
              <FontAwesomeIcon icon={faCommentDots} />
            </ButtonUtility>
            {item.orderId && (
              <Link
                className="button-1 button-1-light button-1-icon me-1"
                to={`/orders/details/${item.orderId}`}
                title={dictionary.orders.order}
                target="_blank"
              >
                <FontAwesomeIcon icon={faBagsShopping} />
              </Link>
            )}
            {item.statusCode !== "DRAFT" && (
              <Link
                className="button-1 button-1-light button-1-icon me-1"
                to={`/shipments/details/${item.id}`}
                title={dictionary.actions.details}
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            )}
            {item.statusCode === "DRAFT" && (
              <Link
                className="button-1 button-1-light button-1-icon me-1"
                to={`/shipments/edit/${item.id}`}
                title={dictionary.actions.edit}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            )}
            {isOpen ? (
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faAngleUp}
                onClick={() => setIsOpen(!isOpen)}
                title={dictionary.actions.reduce}
              />
            ) : (
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={faAngleDown}
                onClick={() => setIsOpen(!isOpen)}
                title={dictionary.actions.expand}
              />
            )}
          </div>
        </div>
        <Collapse
          isOpen={isOpen}
          className="accordion-collapse"
          aria-controls="true"
        >
          <div className="row m-0 mt-2">
            <div className="col">
              <label className="label-2">{dictionary.registries.sender}:</label>
              <label className="label-3">{item.senderName || "-"}</label>
            </div>
            <div className="col-3">
              <label className="label-2">{dictionary.services.service}:</label>
              <label className="label-3">
                {item.carrierServiceName || "-"}
              </label>
            </div>
            <div className="col-2">
              <label className="label-2">{dictionary.words.price}:</label>
              <label className="label-3">
                {item.ratingCost ? `${item.ratingCost.toFixed(2)}€` : "-"}
              </label>
            </div>
            <div className="col-2">
              <label className="label-2">{dictionary.words.typology}:</label>
              <label className="label-3">
                {dictionary.contracts[item.shipmentType.toLowerCase()] || "-"}
              </label>
            </div>
            <div className="col-1">
              <label className="label-2">
                {dictionary.options.cash_on_delivery}:
              </label>
              {item.cashOnDeliveryValue ? (
                <React.Fragment>
                  <label className="label-3">
                    <img
                      id={"cashOnDelivery_" + index}
                      className="icon cursor-pointer"
                      src={
                        item.codCollected && item.codLiquidated
                          ? CODReturnedIcon
                          : item.codCollected && !item.codLiquidated
                          ? CODCollectedIcon
                          : CODIcon
                      }
                    />{" "}
                    {item.cashOnDeliveryValue.toFixed(2)}{" "}
                    {(item.cashOnDeliveryValueCurrency &&
                      currencies.find(
                        (e) => e.value === item.cashOnDeliveryValueCurrency
                      )?.label) ||
                      ""}
                  </label>
                  {item.cashOnDeliveryCollectionMethodCode && (
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"cashOnDelivery_" + index}
                    >
                      <label className="label-2 white">
                        {item.cashOnDeliveryCollectionMethodCode}
                      </label>
                      {item.codCollectedDate && (
                        <label className="label-2 white">
                          {dictionary.dates.collected_date}: <br />
                          {moment(item.codCollectedDate).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </label>
                      )}
                      {item.codLiquidatedDate && (
                        <label className="label-2 white">
                          {dictionary.dates.liquidated_date}: <br />
                          {moment(item.codLiquidatedDate).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </label>
                      )}
                    </UncontrolledTooltip>
                  )}
                </React.Fragment>
              ) : (
                "-"
              )}
            </div>
            <div className="col-1">
              <label className="label-2">{dictionary.options.insurance}:</label>
              {item.insuranceValue ? (
                <React.Fragment>
                  <label className="label-3">
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    {item.insuranceValue.toFixed(2)}{" "}
                    {(item.insuranceValueCurrency &&
                      currencies.find(
                        (e) => e.value === item.insuranceValueCurrency
                      )?.label) ||
                      ""}
                  </label>
                </React.Fragment>
              ) : (
                "-"
              )}
            </div>
            <div className="col-1">
              <label className="label-2">
                {dictionary.collections.collection}:
              </label>
              <label className="label-3">
                {item.collectionAssigned ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  "-"
                )}
              </label>
            </div>
          </div>
          <hr className="m-2"></hr>
          <div className="row m-0">
            <div className="col-7">
              {!isDisabledConfirm([item]) && (
                <ButtonConfirm
                  className="me-1"
                  onClick={() => {
                    context.setModal({
                      open: true,
                      title: dictionary.shipments.confirm_shipment,
                      action: "confirm",
                      data: [item.id],
                    });
                  }}
                >
                  {dictionary.actions.confirm}
                </ButtonConfirm>
              )}
              {!isDisabledDownload([item]) && (
                <ButtonUtility
                  className="ms-1"
                  onClick={() => {
                    context.setModal({
                      open: true,
                      title: dictionary.actions.confirm_download,
                      action: "print",
                      data: [item.id],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faDownToBracket} />{" "}
                  {dictionary.shipments.print_label}
                </ButtonUtility>
              )}
              {!isDisabledCollection([item]) && (
                <ButtonUtility
                  className="ms-1"
                  onClick={() => {
                    context.setModal({
                      open: true,
                      title: dictionary.collections.book_collection,
                      action: "collection",
                      data: [item.id],
                      carrierCode: item.carrierCode,
                      pickupId: item.pickupId,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCalendar} />{" "}
                  {dictionary.collections.book_collection}
                </ButtonUtility>
              )}
              {!isDisabledStockRelease([item]) && (
                <ButtonUtility
                  className="ms-1"
                  onClick={() => {
                    context.setModal({
                      open: true,
                      title: dictionary.collections.book_collection,
                      action: "stockRelease",
                      data: [
                        {
                          shipmentId: item.id,
                          carrierCode: item.carrierCode,
                          internalReference: item.internalReference,
                          carrierReference: item.carrierReference,
                          note: item.statusEventNote,
                          utc: true,
                        },
                      ],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faGavel} />{" "}
                  {dictionary.shipments.stock_release}
                </ButtonUtility>
              )}
            </div>
            <div className="col text-end">
              {!isDisabledReturn([item]) && (
                <Link
                  target="_blank"
                  to={`/shipments/return/${item.id}`}
                  className="button-1 button-1-light"
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />{" "}
                  {dictionary.contracts.express_return_ms}
                </Link>
              )}
              <Link
                target="_blank"
                to={`/shipments/clone/${item.id}`}
                className="ms-1 button-1 button-1-light"
              >
                <FontAwesomeIcon icon={faClone} />{" "}
                {dictionary.actions.duplicate}
              </Link>
              {!isDisabledDelete([item]) && (
                <ButtonDelete
                  className="ms-1"
                  onClick={() => {
                    context.setModal({
                      open: true,
                      title: dictionary.actions.confirm_deletion,
                      action: "delete",
                      data: [item.id],
                      size: "sm",
                    });
                  }}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
});
