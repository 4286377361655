import {
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { useIndexWarehousesContext } from "../providers/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { getAllLogisticIntegrations } from "../../../utilities/asyncSelectCallAPI";
import { getLogisticIntegrationOptionStyle } from "../../../Components/common/AsyncSelect/utilities";

export const useListProps = () => {
  const navigate = useNavigate();

  const { owner, dictionary } = useAppContext();

  const context = useIndexWarehousesContext();

  return {
    path: `/logistic-service/warehouses`,
    banner: {
      enabled: true,
    },
    pagination: { enabled: true },
    title: dictionary.registries.warehouses,
    create: {
      enabled: context?.logisticIntegrations?.length > 0,
      action: () => {
        navigate("/warehouses/create");
      },
      title: `${
        dictionary.actions.add
      } ${dictionary.registries.warehouse.toLowerCase()}`,
      icon: faPlus,
    },
    selectRowsEnabled: { enabled: true },
    actionBar: { enabled: true },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModalConfig({
            isOpen: true,
            title: dictionary.actions.delete_selected,
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),

        label: dictionary.actions.delete_selected,
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
    filters: {
      name: "warehouses",
      preserve: false,
      list: [
        {
          fieldName: "logisticIntegration.ownerId",
          label: dictionary.registries.owner,
          type: "select",
          options: [
            {
              value: owner?.id,
              label: owner?.name,
            },
          ],
          isMulti: true,
          primary: true,
          col: 3,
        },
        {
          fieldName: "logisticIntegration.id",
          label: dictionary.integrations.integration,
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllLogisticIntegrations(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          isMulti: true,
          optionStyle: getLogisticIntegrationOptionStyle,
          primary: true,
          col: 3,
        },
      ],
    },
  };
};
