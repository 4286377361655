import { convertFromJson } from "fiql-query-builder"; // Converts query objects to FIQL format
import { FetchParam } from "../Models/FetchParam"; // Defines fetch parameters structure
import { SearchFilter } from "../Models/SearchFilter"; // Defines the structure of search filters

/**
 * Interface representing the structure of previous query parameters.
 */
interface PrevParam {
  equals?: { selector: string; args: string }; // Condition for equality
  not_equals?: { selector: string; args: string }; // Condition for inequality
  in?: { selector: string; args: string }; // Condition for inclusion
  out?: { selector: string; args: string }; // Condition for exclusion
  and?: [
    { greater_than_or_equal?: { selector: string; args: string } }, // Greater than or equal condition
    { less_than_or_equal?: { selector: string; args: string } } // Less than or equal condition
  ];
}

/**
 * Normalizes a parameter value to a standard structure, including its type.
 *
 * @param paramValue - The value to be normalized. It can be a string, number, boolean, object, or an array.
 * @returns An object containing the normalized value and its corresponding type.
 */
const normalizeParamValue = (
  paramValue: string | number | boolean | object | Array<object>
): { value: any; type: string } => {
  // Handle primitive types (string, number, boolean)
  if (["string", "number", "boolean"].includes(typeof paramValue)) {
    return { value: paramValue, type: typeof paramValue };
  }

  // Handle arrays
  if (Array.isArray(paramValue) && paramValue.length > 0) {
    const allDates = paramValue.every(
      (item) => !isNaN(new Date(item as string).getTime()) // Check if all items in the array are dates
    );

    if (allDates) {
      const [start, end] = paramValue;
      return {
        value: [
          new Date(start as string).toISOString(),
          new Date(end as string).toISOString(),
        ],
        type: "dates", // Array of dates
      };
    }
    return {
      value: `(${paramValue.map((x) => x.value || x).join(",")})`, // Array of values
      type: "array",
    };
  }

  // Handle objects with a "value" property
  if (paramValue && typeof paramValue === "object" && "value" in paramValue) {
    const { value } = paramValue as any;
    switch (value) {
      case "NULL":
        return { value: "<null>", type: "null" }; // Null value
      case "NOT_NULL":
        return { value: "<null>", type: "not_null" }; // Not null value
      case "TRUE":
        return { value: true, type: "boolean" }; // Boolean true
      case "FALSE":
        return { value: false, type: "boolean" }; // Boolean false
      default:
        return { value, type: "object" }; // Object value
    }
  }

  // Default case for unknown types
  return { value: undefined, type: "unknown" };
};

/**
 * Generates query parameters based on the provided parameter name, value, and additional options.
 *
 * @param param - The search filter parameter to be processed.
 * @param prevParams - An array of previous parameters to which the new parameter will be added.
 * @returns An updated list of query parameters including the new parameter.
 */
export const getQueryParams = (
  param: SearchFilter,
  prevParams: PrevParam[]
): PrevParam[] => {
  const { selector, value, equals = true, content, precise = false } = param;
  const params = [...prevParams]; // Clone the existing parameters array
  let newParam: PrevParam | null = null; // Initialize to null to prevent unnecessary assignments

  const normalizedParam = normalizeParamValue(value); // Normalize the input parameter value

  // Construct the new parameter based on its type
  switch (normalizedParam.type) {
    case "boolean":
      newParam = {
        [equals ? "equals" : "not_equals"]: {
          selector: selector,
          args: normalizedParam.value,
        },
      };
      break;
    case "string":
    case "number":
    case "object":
      newParam = {
        [equals ? "equals" : "not_equals"]: {
          selector: selector,
          args: `'${
            precise ? normalizedParam.value : `*${normalizedParam.value}*`
          }'`, // Handle exact match or partial match
        },
      };
      break;
    case "dates":
      newParam = {
        and: [
          {
            greater_than_or_equal: {
              selector: selector,
              args: normalizedParam.value[0] ?? null, // Use null if undefined
            },
          },
          {
            less_than_or_equal: {
              selector: selector,
              args: normalizedParam.value[1] ?? null, // Use null if undefined
            },
          },
        ],
      };
      break;
    case "array":
      newParam = {
        [content ? "in" : "out"]: {
          selector: selector,
          args: normalizedParam.value,
        },
      };
      break;
    case "null":
      newParam = {
        equals: { selector: selector, args: "<null>" },
      };
      break;
    case "not_null":
      newParam = {
        not_equals: { selector: selector, args: "<null>" },
      };
      break;
    default:
      return params; // Skip unrecognized parameter types
  }

  // Avoid duplicates by converting parameters to JSON strings and storing them in a Set
  const serializedParams = new Set(
    params.map((param) => JSON.stringify(param))
  );
  serializedParams.add(JSON.stringify(newParam)); // Add the new parameter

  // Convert back to objects and return the updated list of parameters
  return Array.from(serializedParams).map((p) => JSON.parse(p));
};

/**
 * Builds a query search string using the provided query array.
 * @param query - An array of query parameters.
 * @returns Encoded search string.
 */
export const buildQuerySearch = (query: any[] = []): string => {
  return encodeURIComponent(convertFromJson({ and: [...query] })); // Convert to FIQL query format and encode
};

/**
 * Builds a query sort string using the provided sort array.
 * @param sort - An array of sorting parameters.
 * @returns A concatenated sort query string.
 */
export const buildQuerySort = (
  sort: { column: string; orderBy: string }[] = []
): string => {
  return sort.map((elem) => `&sort=${elem.column},${elem.orderBy}`).join(""); // Construct the sort query string
};

/**
 * Builds a complete URL with the given base URL and parameters.
 * @param url - The base URL.
 * @param params - The parameters object including pagination, sorting, search, and parentId.
 * @returns A fully constructed URL with query parameters.
 */
export const buildAPIUrl = (
  url: string,
  params: FetchParam,
  parentId?: string
): string => {
  const { pagination, sorting, search, inputValue, freeSearch } = params;
  let selectors: PrevParam[] = [];

  if (search && !freeSearch) {
    search.forEach((elem) => {
      selectors = getQueryParams(elem, selectors); // Process search filters
    });
  }

  return (
    `${url}?page=${pagination?.page || 0}&size=${pagination?.size || 20}` +
    (sorting ? `&sort=${sorting.selector},${sorting.orderBy || "asc"}` : "") +
    (selectors.length > 0 ? `&search=${buildQuerySearch(selectors)}` : "") +
    (parentId ? `&parentId=${parentId}` : "") + // Append parentId if provided
    (freeSearch && inputValue ? `&freeSearch=${inputValue}` : "") // Append freeSearch if provided
  );
};

/**
 * Prepares and returns a modified version of the given fetch parameters.
 * It ensures that the search array includes an entry for the provided `inputValue`, if present.
 *
 * @param {FetchParam} fetchParam - The fetch parameters containing inputValue, pagination, sorting, and optional search filters.
 * @returns {FetchParam} - A new object with the updated search criteria, preserving other properties.
 */
export const prepareFetchParam = (fetchParam: FetchParam) => {
  const { inputValue, sorting, search = [] } = fetchParam;

  return {
    ...fetchParam,
    search: inputValue
      ? [...search, { selector: sorting.selector, value: inputValue }] // Add search filter based on inputValue
      : search,
  };
};
