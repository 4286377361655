import React, { createContext, useState, useContext, useEffect } from "react";
import { IncomingGoodDetailsService } from "../../../services/logistic/incomingGoodDetails";
import { callErrorToast } from "../../../utilities";
import { dictionary } from "../../../utilities/dictionary";

const incomingGoodDetailsService = new IncomingGoodDetailsService();

export const DeleteIncomingGoodDetailContext = createContext();

const DeleteIncomingGoodDetailProvider = ({ children, data, callback }) => {
  const [incomingGoodDetails, setIncomingGoodDetails] = useState(data);
  const [incomingGoodDetailsError, setIncomingGoodDetailsError] =
    useState(null);
  const [incomingGoodDetailsLoader, setIncomingGoodDetailsLoader] =
    useState(false);

  const deleteIncomingGoodDetails = () => {
    setIncomingGoodDetailsLoader(true);
    const promises = incomingGoodDetails.map((elem) => {
      return incomingGoodDetailsService.delete(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setIncomingGoodDetailsLoader(false);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setIncomingGoodDetailsError(err);
      });
  };

  useEffect(() => {
    if (incomingGoodDetailsError) {
      callErrorToast(incomingGoodDetailsError, dictionary);
    }
  }, [incomingGoodDetailsError]);

  return (
    <DeleteIncomingGoodDetailContext.Provider
      value={{
        incomingGoodDetails,
        incomingGoodDetailsLoader,
        deleteIncomingGoodDetails,

        callback,
      }}
    >
      {children}
    </DeleteIncomingGoodDetailContext.Provider>
  );
};

const useIncomingGoodDetailContext = () => {
  return useContext(DeleteIncomingGoodDetailContext);
};

export { DeleteIncomingGoodDetailProvider, useIncomingGoodDetailContext };
