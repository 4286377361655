import React, { useRef, useCallback, useMemo } from "react";

import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { useAppContext } from "../../AppProvider";
import { isDisabledDelete, useListProps } from "./utilities/list";
import { Modal, ModalBody } from "reactstrap";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { Link } from "react-router-dom";
import { IndexWarehouseArticlesProvider } from "../WarehouseArticles/providers/Index";
import WarehouseArticles from "../WarehouseArticles";
import Delete from "./Delete";
import { useTypes } from "../../utilities/types";
import { createModalConfig, ModalConfig } from "../../Models/ModalConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "../../utilities/fontawesomeIcons";
import { Article } from "../../Models/Article";
import { useIndexArticlesContext } from "./providers/Index";
import { DeleteArticleProvider } from "./providers/Delete";

const AnyListProvider = ListProvider as any;
const AnyList = List as any;

export const Articles: React.FC = () => {
  const { dictionary } = useAppContext();
  const listRef = useRef<{ refresh: () => void } | null>(null);
  const listProps = useListProps();
  const { modalConfig, setModalConfig } = useIndexArticlesContext();

  const renderItem = useCallback((item: Article, key: number) => {
    return <ListItem key={key} item={item} />;
  }, []);

  const closeModal = () => {
    setModalConfig(createModalConfig());
  };

  return (
    <>
      <AnyListProvider path={listProps.path} filters={listProps.filters}>
        <AnyList
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          renderItem={renderItem}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
        />
      </AnyListProvider>

      <Modal isOpen={modalConfig.isOpen} size={modalConfig.size}>
        <ModalBody className={modalConfig.className || ""}>
          {modalConfig.action === "articles" && modalConfig?.data?.[0] && (
            <>
              <IndexWarehouseArticlesProvider parentId={modalConfig?.data[0]}>
                <WarehouseArticles action="details" shippingRequired={true} />
              </IndexWarehouseArticlesProvider>
              <IndexWarehouseArticlesProvider parentId={modalConfig?.data[0]}>
                <WarehouseArticles action="details" shippingRequired={false} />
              </IndexWarehouseArticlesProvider>
              <div className="row m-0 mt-3 bg-color-3 p-1">
                <div className="col">
                  <ButtonUtility
                    onClick={() => {
                      listRef.current?.refresh();
                      closeModal();
                    }}
                  >
                    {dictionary.actions.cancel}
                  </ButtonUtility>
                </div>
              </div>
            </>
          )}
          {modalConfig.action === "delete" && (
            <DeleteArticleProvider
              data={modalConfig.data}
              callback={() => {
                listRef.current?.refresh();
                closeModal();
              }}
            >
              <Delete />
            </DeleteArticleProvider>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Articles;

const ListItem: React.FC<{ item: Article }> = React.memo(({ item }) => {
  const { dictionary, accessTokenPayload } = useAppContext();
  const context = useIndexArticlesContext();
  const { ownership, products, booleanOptions, domains } = useTypes();

  const icons = useMemo(() => {
    return {
      ownership:
        item.ownerId !== accessTokenPayload.owner
          ? ownership.find((o) => o.value === "inherited")
          : ownership.find((o) => o.value === "own"),
      shippingRequired: item.shippingRequired
        ? products.find((o) => o.value === "PHYSICAL")
        : products.find((o) => o.value === "DIGITAL"),
      uniqueBarcodeItem: item.uniqueBarcodeItem
        ? booleanOptions.find((o) => o.value === "TRUE")
        : booleanOptions.find((o) => o.value === "FALSE"),
      domain: item.pub
        ? domains.find((o) => o.value === "PUBLIC")
        : domains.find((o) => o.value === "PRIVATE"),
    };
  }, [item]);

  const openModal = useCallback(
    (
      action: ModalConfig["action"],
      data?: string[],
      size: ModalConfig["size"] = "sm",
      className = "",
      title = ""
    ) => {
      context?.setModalConfig({
        isOpen: true,
        action,
        size,
        data,
        className,
        title,
      });
    },
    [context]
  );

  return (
    <div
      className="list-item px-2"
      style={{ borderLeft: `5px ${icons.shippingRequired?.color} solid` }}
    >
      <div
        className="d-grid grid-column align-items-center"
        style={{
          gridTemplateColumns:
            "auto 0.5fr 2fr 1.5fr 0.75fr 0.25fr 0.5fr 0.75fr",
        }}
      >
        <div>
          {icons.shippingRequired && (
            <FontAwesomeIcon
              className="mx-1"
              icon={icons.shippingRequired.icon}
              title={icons.shippingRequired.label}
            />
          )}
        </div>
        <div className="text-center">
          {item.imageUrl && <img src={item.imageUrl} alt="Logo" />}
        </div>
        <div>
          <label className="label-2">{dictionary.registries.name}:</label>
          <label className="label-3">{item.name || "-"}</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.references.external_reference}:
          </label>
          <label className="label-3">{item.externalReference || "-"}</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary.products.unique_barcode}:
          </label>
          {icons.uniqueBarcodeItem && (
            <FontAwesomeIcon
              icon={icons.uniqueBarcodeItem.icon}
              title={icons.uniqueBarcodeItem.label}
              color={icons.uniqueBarcodeItem.color}
            />
          )}
        </div>
        <div className="text-center">
          {icons.domain && (
            <FontAwesomeIcon
              icon={icons.domain.icon}
              title={icons.domain.label}
            />
          )}
        </div>
        <div>
          <label className="label-2">{dictionary.status.availables}:</label>
          <label className="label-3">{item.totalAvailable || "-"}</label>
        </div>
        <div className="text-end">
          <Link
            className="button-1 button-1-light button-1-icon me-1"
            to={
              item.ownerId === accessTokenPayload.owner
                ? `/articles/edit/${item.id}`
                : `/articles/details/${item.id}`
            }
            title={
              item.ownerId === accessTokenPayload.owner
                ? dictionary.actions.edit
                : dictionary.actions.details
            }
          >
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <button
            title={
              item.ownerId === accessTokenPayload.owner
                ? dictionary.actions.delete
                : dictionary.messages.not_deletable_you_must_be_the_owner
            }
            disabled={item.ownerId !== accessTokenPayload.owner}
            className="button-1 button-1-danger button-1-icon"
            onClick={() => {
              if (item.ownerId === accessTokenPayload.owner) {
                openModal(
                  "delete",
                  [item.id],
                  "sm",
                  "",
                  dictionary.actions.delete
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    </div>
  );
});
