import React, { useState, useEffect, useRef } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Collapse,
  FormFeedback,
} from "reactstrap";

import {
  callErrorToast,
  contactTypes,
  contractTypes,
  locationNormalizer,
  valueIsEmpty,
  writeLabelAddress,
} from "../../utilities";
import { LocationAutocomplete } from "../../Components/common/LocationAutocomplete";
import { AddressBookService as AddressBookRegistryService } from "../../services/registry-service/addressbook";
import InputBox from "../../Components/common/InputBox";
import { useAppContext } from "../../AppProvider";

const addressBookRegistryService = new AddressBookRegistryService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  action: "",
};

export const defaultContact = {
  isNew: true,
  name: "Senza nome",
  email: "",
  phone: "",
  mobilePhone: "",
  sdi: "",
  vatNumber: "",
  pec: "",
  fiscalCode: "",
  priv: false,
  ztl: false,
  myAddress: false,
  gdo: false,
  location: {
    countryIsoCode3: "ITA",
    languageCode: "IT",
    matchLevelCode: "StreetType",
    countryName: "",
    houseNumber: "",
    postalCode: "",
    street: "",
    municipality: "",
    district: "",
    subRegion: "",
    subRegionName: "",
    label: "",
    region: "",
    regionName: "",
    unit: "",
    longitude: "",
    latitude: "",
  },
  createInAddressBook: false,
  editInAddressBook: false,
};

const Contact = ({
  data,
  edit,
  filters,
  setMode,
  mode, //true = addressBook; false = locationAutocomplete
  createAddressBookInRegistryEnabled,
  editAddressBookInRegistryEnabled,
  setErrors,
  errors,
  contactTypeEnabled,
  hiddenFields = {
    name: false,
    contactName: false,
    sdi: false,
    vatNumber: false,
    externalReference: false,
    pec: false,
    fiscalCode: false,
    email: false,
    phone: false,
    private: false,
    gdo: false,
    ztl: false,
  },
}) => {
  const [contact, setContact] = useState(data);
  const [addressChanged, setAddressChanged] = useState({});
  const [addressBook, setAddressBook] = useState([]);

  const [modalSettings, setModalSettings] = useState({
    ...modalSettingsDefault,
  });
  const [detailsCollapse, setDetailsCollapse] = useState(false);
  const [addressBookMode, setAddressBookMode] = useState(mode);
  const selectLocationAutocompleteRef = useRef();
  const selectAddressBookRef = useRef();

  const { dictionary } = useAppContext();

  const getAllAddressBook = (inputValue = "", presetContact) => {
    filters.freeSearch = inputValue;
    return addressBookRegistryService
      .getAll(filters)
      .then((response) => {
        if (presetContact) {
          const contact =
            response.content && response.content[0]
              ? { ...response.content[0] }
              : { ...defaultContact };
          edit(contact);
        }

        const addressBook = response.content
          ? response.content.map((elem) => {
              return { ...elem, contactType: "ADDRESSBOOK" };
            })
          : [];
        setAddressBook(addressBook || []);
        return addressBook || [];
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const editAddress = () => {
    setModalSettings({
      ...modalSettings,
      title: dictionary.actions.edit,
      icon: "ph-note-pencil",
      open: !modalSettings.open,
      action: "editAddress",
    });
    setAddressChanged({ ...contact?.location });
  };

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const resetContact = () => {
    if (errors) setErrors({});
    if (addressBookMode) {
      selectAddressBookRef.current.clearValue();
      return false;
    }
    selectLocationAutocompleteRef.current.clearValue();

    edit({ ...defaultContact });
  };

  const onClickAddressBookMode = () => {
    if (errors) setErrors({});
    setAddressBookMode((prevAddressBookMode) => !prevAddressBookMode);
    if (addressBookMode) {
      resetContact();
      return false;
    }

    if (!contact?.id) {
      getAllAddressBook("", true);
    }
  };

  const handleSelectDestinationChange = (option, event) => {
    const optionNormalized = locationNormalizer(option);
    const location = optionNormalized ? { ...optionNormalized } : {};
    edit({ ...contact, location });
    if (errors) {
      const newErrors = { ...errors };
      delete newErrors["location"];
      setErrors(newErrors);
    }
  };

  const handleSelectAddressBookChange = (option, event) => {
    const addressBook = option
      ? { ...option, isNew: false }
      : { ...defaultContact };
    edit(addressBook);
    if (errors) {
      setErrors({});
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    edit({ ...contact, [name]: value });
    if (errors) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleInputAddressChange = (event) => {
    const { name, value } = event.target;
    setAddressChanged((prevAddressChanged) => ({
      ...prevAddressChanged,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    const { name, value } = event.target;
    edit({ ...contact, [name]: !contact[name] });
  };

  const handleCreateAddressBookCheckboxChange = (event) => {
    if (contact?.createInAddressBook) {
      edit({
        ...contact,
        createInAddressBook: false,
      });
      return false;
    }

    edit({
      ...contact,
      createInAddressBook: true,
      editInAddressBook: false,
    });
  };

  const handleEditAddressBookCheckboxChange = () => {
    if (contact?.editInAddressBook) {
      edit({
        ...contact,
        editInAddressBook: false,
      });
      return false;
    }

    edit({
      ...contact,
      createInAddressBook: false,
      editInAddressBook: true,
    });
  };

  const onSubmitEditAddress = () => {
    edit({ ...contact, location: { ...addressChanged } });
    setAddressChanged({});
    setModalSettings({
      ...modalSettingsDefault,
    });
  };

  const loadAddressBookOptions = (inputValue, callback) => {
    if (inputValue) {
      return getAllAddressBook(inputValue);
    }
  };

  useEffect(() => {
    setContact(data);
  }, [data]);

  useEffect(() => {
    if (!valueIsEmpty(errors)) {
      setDetailsCollapse(true);
    }
  }, [errors]);

  useEffect(() => {
    setAddressBookMode(mode);
    if (setMode) {
      setMode(mode);
    }
  }, [mode]);

  useEffect(() => {
    if (setMode) {
      setMode(addressBookMode);
    }
  }, [addressBookMode]);

  const address = writeLabelAddress(contact?.location);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-11">
          {addressBookMode && (
            <React.Fragment>
              <AsyncSelect
                defaultOptions={addressBook}
                id="1"
                name="addressBook"
                ref={selectAddressBookRef}
                loadOptions={loadAddressBookOptions}
                noOptionsMessage={() => (
                  <span>{dictionary.messages.no_results_found}</span>
                )}
                getOptionValue={(option) => option["id"]}
                formatOptionLabel={(option) => {
                  if (contactTypeEnabled) {
                    return (
                      <div className="row align-items-center">
                        <div className="col">
                          <label className="me-2">
                            {option["name"] +
                              " - " +
                              writeLabelAddress(option.location)}
                          </label>
                          <div className="d-block">
                            {" "}
                            <span
                              style={{ backgroundColor: "#e6194B" }}
                              className="badge"
                            >
                              {contactTypes[option.contactType]}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return (
                    option["name"] + " - " + writeLabelAddress(option.location)
                  );
                }}
                value={contact?.id ? contact : null}
                isClearable
                placeholder={dictionary.contacts.search_address_in_address_book}
                onChange={handleSelectAddressBookChange}
                onMenuOpen={() => {
                  getAllAddressBook();
                }}
                onMenuClose={() => {
                  setAddressBook([]);
                }}
                className={`${
                  errors && errors["location"] ? "is-invalid" : ""
                }`}
              />
              {errors && errors["location"] && (
                <FormFeedback type="invalid">{errors["location"]}</FormFeedback>
              )}
            </React.Fragment>
          )}
          {!addressBookMode && (
            <React.Fragment>
              <LocationAutocomplete
                onLocationChange={handleSelectDestinationChange}
                locationRef={selectLocationAutocompleteRef}
                placeholder={dictionary.actions.search}
                className={`${
                  errors && errors["location"] ? "is-invalid" : ""
                }`}
              />
              {errors && errors["location"] && (
                <FormFeedback type="invalid">{errors["location"]}</FormFeedback>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="col-1 ps-0 text-center">
          <button
            title={
              addressBookMode
                ? dictionary.contacts.search_a_new_address
                : dictionary.contacts.search_address_in_address_book
            }
            type="button"
            className={
              addressBookMode ? "btn btn-dark p-1" : "btn btn-outline-dark p-1"
            }
            onClick={onClickAddressBookMode}
          >
            <i className="ph-address-book"></i>
          </button>
        </div>
      </div>
      <div className="row mt-1">
        {!hiddenFields.name && (
          <div className="col">
            <label className="form-label">
              {dictionary.registries.company_name}:
            </label>
            <InputBox
              name="name"
              value={contact?.name || ""}
              type="text"
              onChange={handleInputChange}
              error={errors && errors["name"]}
            />
          </div>
        )}
        {!hiddenFields.contactName && (
          <div className="col">
            <label className="form-label">{dictionary.registries.name}:</label>
            <InputBox
              name="contactName"
              value={contact?.contactName || ""}
              placeholder={"Mario Rossi"}
              type="text"
              onChange={handleInputChange}
              error={errors && errors["contactName"]}
            />
          </div>
        )}
      </div>
      <div className="row mt-2 accordion" id="detailsAccordion">
        <div className="col text-center">
          <a
            to="#"
            color="primary"
            type="button"
            onClick={() => setDetailsCollapse(!detailsCollapse)}
            style={{ cursor: "pointer", fontSize: "1rem" }}
            className="mb-1"
          >
            {dictionary.actions.edit}
          </a>
        </div>

        <Collapse isOpen={detailsCollapse} className="accordion-collapse">
          <div className="row mt-3">
            <div className="col">
              {dictionary.locations.address}:{" "}
              {address ? (
                <u className="fw-bold cursor-pointer" onClick={editAddress}>
                  {address}
                </u>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="row mt-3">
            {!hiddenFields.sdi && (
              <div className="col">
                <label className="form-label">SDI:</label>
                <InputBox
                  name="sdi"
                  value={contact?.sdi || ""}
                  placeholder={"SDI"}
                  type="text"
                  onChange={handleInputChange}
                  error={errors && errors["sdi"]}
                />
              </div>
            )}
            {!hiddenFields.vatNumber && (
              <div className="col">
                <label className="form-label">
                  {dictionary.registries.vat_number}:
                </label>
                <InputBox
                  name="vatNumber"
                  value={contact?.vatNumber || ""}
                  placeholder={"86334519757"}
                  type="positive-integer-number"
                  onChange={handleInputChange}
                  error={errors && errors["vatNumber"]}
                />
              </div>
            )}
            {!hiddenFields.externalReference && (
              <div className="col">
                <label className="form-label">
                  {dictionary.references.customer_reference}:
                </label>
                <InputBox
                  name="externalReference"
                  value={contact?.externalReference || ""}
                  placeholder={"EXTREF"}
                  type="text"
                  onChange={handleInputChange}
                />
              </div>
            )}
          </div>
          <div className="row mt-3">
            {!hiddenFields.email && (
              <div className="col">
                <label className="form-label">
                  {dictionary.registries.email}:
                </label>
                <InputBox
                  name="email"
                  value={contact?.email || ""}
                  placeholder={"allabox@email.it"}
                  type="text"
                  onChange={handleInputChange}
                  error={errors && errors["email"]}
                />
              </div>
            )}
            {!hiddenFields.phone && (
              <div className="col">
                <label className="form-label">
                  {dictionary.registries.phone}:
                </label>
                <InputBox
                  name="phone"
                  value={contact?.phone || ""}
                  placeholder={"0119699999"}
                  type="positive-integer-number"
                  onChange={handleInputChange}
                  error={errors && errors["phone"]}
                />
              </div>
            )}
          </div>
          <div className="row mt-3">
            {!hiddenFields.pec && (
              <div className="col">
                <label className="form-label">PEC:</label>
                <InputBox
                  name="pec"
                  value={contact?.pec || ""}
                  type="text"
                  onChange={handleInputChange}
                  error={errors && errors["pec"]}
                />
              </div>
            )}
            {!hiddenFields.fiscalCode && (
              <div className="col">
                <label className="form-label">
                  {dictionary.registries.fiscal_code}:
                </label>
                <InputBox
                  name="fiscalCode"
                  value={contact?.fiscalCode || ""}
                  type="text"
                  onChange={handleInputChange}
                  error={errors && errors["fiscalCode"]}
                />
              </div>
            )}
          </div>
          {/* <div className="row mt-3">
            <div className="col">
              <label className="form-label">Cellulare:</label>
              <InputBox
                name="mobilePhone"
                value={contact?.mobilePhone || ""}
                placeholder={"3399999999"}
                type="positive-integer-number"
                onChange={handleInputChange}
              />
            </div>
          </div> */}
          <div className="row mt-3">
            {!hiddenFields.private && (
              <div className="col">
                <label className="form-check mb-2">
                  <input
                    name="priv"
                    type="checkbox"
                    className="form-check-input form-check-input-success"
                    value={contact?.priv}
                    checked={contact?.priv}
                    onChange={handleCheckboxChange}
                  />
                  <span className="form-check-label">
                    {dictionary.registries.private}
                  </span>
                </label>
              </div>
            )}
            {!hiddenFields.gdo && (
              <div className="col">
                <label className="form-check mb-2">
                  <input
                    type="checkbox"
                    name="gdo"
                    className="form-check-input form-check-input-success"
                    value={contact?.gdo}
                    checked={contact?.gdo}
                    onChange={handleCheckboxChange}
                  />
                  <span className="form-check-label">GDO</span>
                </label>
              </div>
            )}
            {!hiddenFields.ztl && (
              <div className="col">
                <label className="form-check mb-2">
                  <input
                    type="checkbox"
                    name="ztl"
                    className="form-check-input form-check-input-success"
                    value={contact?.ztl}
                    checked={contact?.ztl}
                    onChange={handleCheckboxChange}
                  />
                  <span className="form-check-label">ZTL</span>
                </label>
              </div>
            )}
          </div>
          <hr className="my-2" />
          <div className="row align-items-center text-right">
            <div className="col">
              <button
                type="button"
                onClick={resetContact}
                className="btn btn-outline-light btn-sm"
              >
                {dictionary.actions.reset}
              </button>
            </div>

            <div className="col ">
              {createAddressBookInRegistryEnabled && (
                <div className="row">
                  <label className="d-flex align-items-center flex-row-reverse">
                    <input
                      name="createInAddressBook"
                      type="checkbox"
                      className="form-check-input form-check-input-success"
                      value={contact?.createInAddressBook || false}
                      checked={contact?.createInAddressBook || false}
                      onChange={handleCreateAddressBookCheckboxChange}
                    />
                    <span className="form-check-label me-2">
                      {dictionary.contacts.add_contact_in_address_book}
                    </span>
                  </label>
                </div>
              )}
              {!contact?.isNew &&
                addressBookMode &&
                editAddressBookInRegistryEnabled && (
                  <div className="row">
                    <label className="d-flex align-items-center flex-row-reverse">
                      <input
                        name="editInAddressBook"
                        type="checkbox"
                        className="form-check-input form-check-input-success"
                        value={contact?.editInAddressBook || false}
                        checked={contact?.editInAddressBook || false}
                        onChange={handleEditAddressBookCheckboxChange}
                      />
                      <span className="form-check-label me-2">
                        {dictionary.contacts.edit_contact_in_address_book}
                      </span>
                    </label>
                  </div>
                )}
            </div>
          </div>
        </Collapse>
      </div>

      <Modal isOpen={modalSettings.open} className="modal-content">
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col">
              <label className="form-label">
                {dictionary.locations.street}:
              </label>
              <InputBox
                type="text"
                name="street"
                className="form-control"
                placeholder="Via Roma"
                value={addressChanged.street || ""}
                onChange={handleInputAddressChange}
              />
            </div>
            <div className="col-2">
              <label className="form-label">
                {dictionary.locations.house_number}:
              </label>
              <InputBox
                type="text"
                name="houseNumber"
                className="form-control"
                placeholder="1"
                value={addressChanged.houseNumber || ""}
                onChange={handleInputAddressChange}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className="form-label">{dictionary.locations.unit}:</label>
              <InputBox
                type="text"
                name="unit"
                className="form-control"
                placeholder="A"
                value={addressChanged.unit || ""}
                onChange={handleInputAddressChange}
              />
            </div>
            <div className="col">
              <label className="form-label">
                {dictionary.locations.postal_code}:
              </label>
              <InputBox
                type="text"
                name="postalCode"
                className="form-control"
                placeholder="10123"
                value={addressChanged.postalCode || ""}
                onChange={handleInputAddressChange}
              />
            </div>
            <div className="col">
              <label className="form-label">
                {dictionary.locations.district}:
              </label>
              <InputBox
                type="text"
                name="district"
                className="form-control"
                placeholder="Centro"
                value={addressChanged.district || ""}
                disabled
              />
            </div>
            <div className="col">
              <label className="form-label">{dictionary.locations.city}:</label>
              <InputBox
                type="text"
                name="municipality"
                className="form-control"
                placeholder="Torino"
                value={addressChanged.municipality || ""}
                disabled
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className="form-label">
                {dictionary.locations.province}:
              </label>
              <InputBox
                type="text"
                name="subRegionName"
                className="form-control"
                placeholder="Torino"
                value={addressChanged.subRegionName || ""}
                disabled
              />
            </div>
            <div className="col">
              <label className="form-label">
                {dictionary.locations.region}:
              </label>
              <InputBox
                type="text"
                name="regionName"
                className="form-control"
                placeholder="Piemonte"
                value={addressChanged.regionName || ""}
                disabled
              />
            </div>
            <div className="col">
              <label className="form-label">
                {dictionary.locations.country}:
              </label>
              <InputBox
                type="text"
                name="countryIsoCode3"
                className="form-control"
                placeholder="Italia"
                value={addressChanged.countryIsoCode3 || ""}
                disabled
              />
            </div>
          </div>
          <hr />
          <div className="row mt-3">
            <div className="col">
              <button
                onClick={onSubmitEditAddress}
                type="button"
                className="btn btn-success float-end"
              >
                {dictionary.actions.save}{" "}
                <i className="ph-paper-plane-tilt ms-2"></i>
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Contact;
