import React, { useEffect, useRef } from "react";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import { useCreateEditOrderDetailContext } from "./providers/CreateEdit";
import { useHandlerOrderContext } from "../Orders/providers/Handler";
import { getAllWarehouseArticles } from "../../utilities/asyncSelectCallAPI";

import InputBox from "../../Components/common/InputBox";
import Select from "react-select";
import { Loader } from "../../Components/common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { generateNumberObjects } from "../../utilities";
import { getWarehouseArticleOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { useAppContext } from "../../AppProvider";

export const CreateEdit = () => {
  const { dictionary } = useAppContext();
  const context = useCreateEditOrderDetailContext();
  const handlerOrderContext = useHandlerOrderContext();

  // const barcodeInputRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    context.editOrderDetail({
      ...context.orderDetail,
      [name]: value,
    });
    context.removeError(name);
  };

  const handleSelectChange = (option, event) => {
    context.editOrderDetail({
      ...context.orderDetail,
      [event.name]: option,
    });
    context.removeError(event.name);
  };

  // const handleAsyncSelectChange = (selector, value) => {
  //   context.editOrderDetail({
  //     ...context.orderDetail,
  //     [selector]: value.article,
  //   });
  // };

  useEffect(() => {
    if (!context.id) {
      if (context.autosave) {
        context.createOrderDetail();
      }
    } else {
      context.getOrderDetail();
    }
  }, []);

  // useEffect(() => {
  //   if (barcodeInputRef.current) {
  //     barcodeInputRef.current.focus();
  //   }
  // }, [barcodeInputRef.current]);

  return (
    <React.Fragment>
      {context.orderDetailLoader ? (
        <Loader show={context.orderDetailLoader} />
      ) : (
        <>
          <div className="row">
            <div className="col-9">
              <label className="form-label">
                {dictionary.products.product}:
              </label>
              <AsyncSelect
                name="article"
                optionValue="id"
                optionLabel="name"
                placeholder={dictionary.actions.select}
                isClearable={false}
                value={context?.orderDetail?.article}
                onSearch={(name, option) => context?.setArticle(option.article)}
                loadOptions={(inputValue, pagination) => {
                  return getAllWarehouseArticles(
                    inputValue,
                    pagination,
                    {
                      orderBy: "asc",
                      selector: "article.name",
                    },
                    [],
                    handlerOrderContext?.order?.warehouse?.id
                  );
                }}
                optionStyle={getWarehouseArticleOptionStyle}
                isDisabled={context?.orderDetail?.id}
                error={context.orderDetailError?.response?.data?.articleId}
              />
            </div>
            <div className="col-3">
              <label className="form-label">
                {dictionary.dimensions.quantity}:
              </label>
              <Select
                name="quantity"
                onChange={handleSelectChange}
                defaultMenuIsOpen={false}
                className="select"
                options={generateNumberObjects(1, 50)}
                value={context.orderDetail?.quantity}
                getOptionValue={(option) => option["code"]}
                getOptionLabel={(option) => option["name"]}
                placeholder={dictionary.actions.select}
                isDisabled={
                  context.orderDetail?.id &&
                  context.orderDetail?.article.uniqueBarcodeItem
                }
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className="form-label">
                {dictionary.words.unit_price}:
              </label>
              <InputBox
                name="unitPrice"
                value={context.orderDetail?.unitPrice}
                placeholder={"1.5"}
                type="number"
                onChange={handleInputChange}
              />
            </div>
            {context.orderDetail?.unitPrice > 0 && (
              <div className="col">
                <label className="form-label">
                  {dictionary.words.discount}:
                </label>
                <InputBox
                  name="discount"
                  value={context.orderDetail?.discount}
                  placeholder={"1.5"}
                  type="positive-number"
                  min={1}
                  toFixed={2}
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div className="col">
              <label className="form-label">
                {dictionary.words.total_price}:
              </label>
              <InputBox
                name="totalPrice"
                value={context.orderDetail?.totalPrice || null}
                placeholder={"1.5"}
                type="number"
                disabled
              />
            </div>
            <div className="col">
              <label className="form-label">{dictionary.words.vat}:</label>
              <InputBox
                name="vat"
                value={context.orderDetail?.vat}
                placeholder={"22"}
                type="positive-number"
                toFixed={2}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {context?.article?.shippingRequired && (
            <div className="row mt-3">
              <div className="col-3">
                <label className="form-label">
                  {dictionary.dimensions.unit_weight}:
                </label>
                <InputBox
                  name="unitWeight"
                  value={context.orderDetail?.unitWeight}
                  placeholder={"1.5"}
                  type="positive-number"
                  precision={3}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-3">
                <label className="form-label">
                  {dictionary.dimensions.total_weight}:
                </label>
                <InputBox
                  name="totalWeight"
                  value={context.orderDetail?.totalWeight}
                  placeholder={"1.5"}
                  type="positive-number"
                  precision={3}
                  disabled
                />
              </div>
            </div>
          )}
        </>
      )}
      {!context.autosave && (
        <div className="row mt-3">
          <div className="col">
            <button
              className="btn btn-link p-0"
              onClick={() => context.callback(false)}
            >
              {dictionary.actions.cancel}
            </button>
          </div>
          <div className="col text-end">
            <button
              id="save"
              className="btn btn-outline-success btn-sm btn btn-light"
              onClick={() => context.createEditOrderDetail(context.id)}
              disabled={context.orderDetailLoader}
            >
              {context.orderDetailLoader ? (
                <FontAwesomeIcon icon={faLoader} spin />
              ) : (
                dictionary.actions.save
              )}
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
