import moment from "moment";
import { collectionStatusList } from "../../constants";
import {
  getCarrierOptionStyle,
  getShipmentStatusOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import { getAllCarriers } from "../../utilities/asyncSelectCallAPI";
import {
  checkIfIsBusinessDay,
  getNextWorkingDay,
  valueIsEmpty,
} from "../../utilities";
import { validation as addressBookValidation } from "../AddressBook/utilities";

const collectionStatusValidForDelete = ["DRAFT"];
const collectionStatusValidForEdit = ["DRAFT"];

export const calculateCollectionDate = (time) => {
  const currentTime = moment();
  if (time) {
    const isAfterCutoff = currentTime.isAfter(moment(time, "HH:mm"));

    if (isAfterCutoff || !checkIfIsBusinessDay()) {
      return getNextWorkingDay();
    }
  }
  // Altrimenti, se currentTime è prima di time e oggi è un giorno lavorativo
  return currentTime.toDate();
};

export const normalizer = (
  collection,
  carrier,
  carrierConfiguration,
  andBook
) => {
  let newCollection = { ...collection };
  newCollection.date = moment(newCollection.date, "DD/MM/YYYY").startOf("day");
  newCollection.addressBookId = newCollection.addressBook.id;
  newCollection.carrierCode =
    carrier?.code || carrierConfiguration?.carrierCode || null;
  if (carrierConfiguration) {
    newCollection.carrierConfigurationId = carrierConfiguration.id;
  }
  newCollection.book = andBook;
  return newCollection;
};

export const validation = (
  collection,
  carrierConfiguration,
  standAlone,
  dictionary
) => {
  let errors = {};
  const { numPackages, totalWeight } = collection;

  if (standAlone && valueIsEmpty(carrierConfiguration)) {
    errors["carrierConfiguration"] = dictionary.messages.mandatory_field;
  }

  if ("numPackages" in collection && !numPackages) {
    errors["numPackages"] = dictionary.messages.mandatory_field;
  }
  if ("totalWeight" in collection && !totalWeight) {
    errors["totalWeight"] = dictionary.messages.mandatory_field;
  }

  errors = {
    ...errors,
    ...addressBookValidation(collection.addressBook, dictionary),
  };

  return errors;
};

export const defaultCollection = {
  morningOpenTime: "09:00",
  morningCloseTime: "13:00",
  afternoonOpenTime: "14:00",
  afternoonCloseTime: "18:00",
  numPackages: 1,
  totalWeight: 1.0,
  externalReference: "",
};

export const allSelectedCollectionsCanBeDeleted = (
  collectionSelectedIds = [],
  collections = []
) => {
  const idSelected = collectionSelectedIds
    ?.filter((x) => x.value)
    .map((x) => x.id);
  const collectionsSelected = collections.filter((x) =>
    idSelected.includes(x.id)
  );
  return canAllCollectionsBeDeleted(collectionsSelected);
};

export const canCollectionBeDeleted = (collection) => {
  if (
    collection.status === "BOOKED" &&
    !collection.carrier.collectionDeleteEnabled
  ) {
    return false;
  }
  return true;
};

export const canAllCollectionsBeDeleted = (collections) => {
  if (!collections || collections.length === 0) return false;
  const selectedCollectionCanBeDeleted = collections.map((x) =>
    canCollectionBeDeleted(x)
  );
  return selectedCollectionCanBeDeleted.every((x) => x);
};
