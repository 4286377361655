import { API_BASE_URL } from "../../constants/index";
import HttpInterface from "../../helpers/HttpInterface";

export class OrderDetailsService {
  private httpProxy: HttpInterface;
  constructor() {
    this.httpProxy = new HttpInterface();
  }

  /**
   * Updates the barcode of a specific order detail.
   *
   * @param id - The unique identifier of the order detail.
   * @param barcode - The new barcode value to be assigned.
   * @returns A promise resolving to the response of the API call.
   */
  editBarcode(id: string, barcode: string): Promise<string> {
    const requestOptions = {
      method: "PUT",
      body: barcode,
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/logistic-service/order-details/${id}/barcode`,
      requestOptions
    );
  }

  /**
   * Deletes the barcode of a specific order detail.
   *
   * @param id - The unique identifier of the order detail.
   * @returns A promise resolving to the response of the API call.
   */
  deleteBarcode(id: string): Promise<string> {
    const requestOptions = {
      method: "DELETE",
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/logistic-service/order-details/${id}/barcode`,
      requestOptions
    );
  }
}
