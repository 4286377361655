import React from "react";
import { useAppContext } from "../../AppProvider";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { useReturnDeliveredOrderContext } from "./providers/ReturnDelivered";

const ReturnDelivered = () => {
  const { dictionary } = useAppContext();

  const context = useReturnDeliveredOrderContext();

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label className="label-4 text-center">
            {dictionary.orders.confirm_receipt_of_return}
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-5 text-center">
            {dictionary.orders.confirm_received_return}
          </label>
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={() => context.returnDeliveredOrders()}
          disabled={context.ordersLoader}
          loading={context.ordersLoader}
        />
      </div>
    </React.Fragment>
  );
};

export default ReturnDelivered;
