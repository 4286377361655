import React, { useState, useEffect } from "react";
import { useOidcUser } from "@axa-fr/react-oidc";
import BreadcrumbItem from "../../../Components/common/Breadcrumb";
import { convertFromJson } from "fiql-query-builder";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";

import { ZonesService } from "../../../services/registry-service/zones";

import Edit from "./Edit";
import Delete from "./Delete";

// import TableContainer from "../../../Components/common/TableContainer";
import Create from "./Create";
import TableAdvanced from "../../../Components/common/tableAdvanced/TableAdvanced";
import { getUniqueSelectors, buildQuerySort } from "../../../utilities";
import useFetch from "../../../customHooks/useFetch";
import { useAppContext } from "../../../AppProvider";
import { Link } from "react-router-dom";

const zonesService = new ZonesService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  id: null,
  action: "",
};

const Zones = () => {
  const { oidcUser } = useOidcUser();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  const { dictionary, owner } = useAppContext();

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const fixedCol = [
    {
      Header: dictionary.registries.owner,
      accessor: "owner.name",
      cellClass: "text-nowrap",
      Cell: ({ row, cell }) => {
        if (row.original.ownerId === owner?.id) {
          return <span className="badge bg-success">{cell.value}</span>;
        }
        return (
          <span className="badge bg-primary">{dictionary.words.inherited}</span>
        );
      },
      filter: {
        active: true,
        name: "ownerId",
        type: "select",
        options: [
          {
            value: owner?.id,
            label: owner?.name,
          },
        ],
        col: 3,
      },
    },
    {
      Header: dictionary.registries.name,
      accessor: "name",
      sortable: true,
      filter: {
        active: true,
        name: "name",
        type: "search",
      },
    },
    {
      Header: dictionary.registries.description,
      accessor: "description",
      filter: {
        active: true,
        name: "description",
        type: "search",
      },
    },
    {
      Header: "",
      accessor: "pub",
      Cell: ({ row }) => {
        const { original } = row;
        return original.pub ? (
          <span style={{ backgroundColor: "#628fee" }} className="badge">
            {dictionary.words.public}
          </span>
        ) : (
          <span style={{ backgroundColor: "#5fc64b" }} className="badge">
            {dictionary.words.private}
          </span>
        );
      },
    },
    {
      Header: dictionary.words.actions,
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              {oidcUser?.owner === original.ownerId && (
                <DropdownItem
                  href="#"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: `${
                        dictionary.actions.edit
                      } ${dictionary.locations.zone.toLowerCase()}`,
                      icon: "ph-note-pencil",
                      open: !modalSettings.open,
                      action: "edit",
                      id: original.id,
                    });
                  }}
                >
                  <i className="ph-note-pencil me-2"></i>
                  {dictionary.actions.edit}
                </DropdownItem>
              )}
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: `${
                      dictionary.actions.duplicate
                    } ${dictionary.locations.zone.toLowerCase()}`,
                    icon: "ph-users",
                    open: !modalSettings.open,
                    action: "clone",
                    id: original.id,
                  });
                }}
              >
                <i className="ph-users me-2"></i>
                {dictionary.actions.duplicate}
              </DropdownItem>
              {oidcUser?.owner === original.ownerId && (
                <DropdownItem
                  className="text-danger"
                  href="#"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: `${
                        dictionary.actions.delete
                      } ${dictionary.locations.zone.toLowerCase()}`,
                      icon: "ph-trash",
                      open: !modalSettings.open,
                      action: "delete",
                      id: [original.id],
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  {dictionary.actions.delete}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const defaultQuerySearch = [];

  const buildQuerySearch = (query = []) => {
    return encodeURIComponent(convertFromJson({ and: [...query] }));
  };

  const [queryParams, setQueryParams] = useState(defaultQuerySearch);
  const [querySearch, setQuerySearch] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/registry-service/zones?page=${pagination.page}&size=${pagination.size}${
      sort.length > 0 ? buildQuerySort(sort) : ""
    }${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, []);

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  useEffect(() => {
    if (!modalSettings.open) refreshData();
  }, [modalSettings]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title={dictionary.locations.locations}
          breadcrumbItem={dictionary.locations.zones}
        />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Link
                  className="btn btn-outline-success btn-sm btn btn-light w-auto"
                  to="/locations/zones/upload"
                >
                  {dictionary.actions.massive_import}
                </Link>
              </div>
              <div className="col">
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: `${
                        dictionary.actions.create
                      } ${dictionary.locations.new_zone.toLowerCase()}`,
                      icon: "ph-plus",
                      open: !modalSettings.open,
                      action: "create",
                    })
                  }
                >
                  {dictionary.actions.create}{" "}
                  {dictionary.locations.new_zone.toLowerCase()}
                  <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>

            {oidcUser && (
              <TableAdvanced
                hasFilter={true}
                columns={fixedCol}
                data={data}
                className="custom-header-css"
                tableClass={"table-xxs"}
                paginationOpt={pagination}
                onPaginationChange={handlePagination}
                sortingOpt={sort}
                onSortingChange={handleSorting}
                refreshTable={refreshData}
                onSearch={onSearch}
                onFreeSearch={onSearch}
                showLoader={loading}
              />
            )}

            <Modal
              fade
              isOpen={modalSettings.open}
              className="modal-content"
              direction="end"
              size={"lg"}
            >
              <ModalHeader toggle={modalToggle}>
                <span className="modal-title">
                  <i className={modalSettings.icon + " me-2"}></i>
                  {modalSettings.title}
                </span>
              </ModalHeader>
              <ModalBody>
                {modalSettings.action === "create" && (
                  <Create
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                    pagination={pagination}
                  />
                )}
                {modalSettings.action === "edit" && (
                  <Edit
                    zoneId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                    pagination={pagination}
                  />
                )}
                {modalSettings.action === "clone" && (
                  <Edit
                    zoneId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                    clone={true}
                    pagination={pagination}
                  />
                )}
                {modalSettings.action === "delete" && (
                  <Delete
                    zoneId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                    pagination={pagination}
                  />
                )}
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Zones;
