import React from "react";
import { useAppContext } from "../../AppProvider";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import InputBoxIcon from "../../Components/common/InputBoxIcon";
import { faICursor } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useUpdateNoteContext } from "./providers/UpdateNote";

export const UpdateNote = () => {
  const { dictionary } = useAppContext();

  const context = useUpdateNoteContext();
  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label className="label-4 text-center">
            {dictionary.words.internal_note} (
            {dictionary.messages.not_communicated_to_the_carrier})
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label className="label-2">{dictionary.words.note}</label>
          <InputBoxIcon
            name="note"
            value={context?.shipment?.note || ""}
            placeholder={""}
            type="textarea"
            icon={faICursor}
            onChange={(e) => {
              const { name, value } = e.target;
              context?.editShipment({ ...context?.shipment, [name]: value });
            }}
          />
        </div>
      </div>
      <div className="d-flex">
        <ButtonUtility
          className="w-50 me-2"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </ButtonUtility>
        <ButtonConfirm
          className="w-50"
          onClick={() => context.save()}
          disabled={context.shipmentLoader}
          loading={context.shipmentLoader}
        >
          {dictionary.actions.save}
        </ButtonConfirm>
      </div>
    </React.Fragment>
  );
};
