import React, { useCallback } from "react";
import { useAppContext } from "../../AppProvider";
import { UncontrolledTooltip } from "reactstrap";
import { useIndexWarehouseArticlesContext } from "./providers/Index";
import { ModalConfig } from "../../Models/ModalConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "../../utilities/fontawesomeIcons";
import { WarehouseArticle } from "../../Models/WarehouseArticle";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";

interface DigitalItemListProps {
  item: WarehouseArticle;
  action: string;
}

export const DigitalListItem: React.FC<DigitalItemListProps> = ({
  item,
  action,
}) => {
  const { dictionary } = useAppContext();
  const { setModalConfig } = useIndexWarehouseArticlesContext();

  const openModal = useCallback(
    (
      action: ModalConfig["action"],
      data?: string[],
      size: ModalConfig["size"] = "sm",
      className = "",
      title = ""
    ) => {
      setModalConfig({
        isOpen: true,
        action,
        size,
        data,
        className,
        title,
      });
    },
    [setModalConfig]
  );

  return (
    <div
      className="d-grid grid-column text-center"
      style={{
        gridTemplateColumns: `4fr 7fr auto`,
      }}
    >
      <div>
        <div
          className="d-grid grid-column bg-color-4"
          style={{
            gridTemplateColumns: `1fr`,
          }}
        >
          <label className="label-1">{dictionary.registries.name}</label>
        </div>
        <div
          className="d-grid grid-column"
          style={{
            gridTemplateColumns: `1fr`,
          }}
        >
          <label className="label-3">{item.article.name}</label>
        </div>
      </div>
      <div>
        <div className="bg-color-4">
          <div>
            <label className="label-1">{dictionary.status.sold_pm}</label>
          </div>
        </div>
        <div>
          <div
            className="d-grid grid-column"
            style={{
              gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
            }}
          >
            <div>
              <label className="label-2">{dictionary.status.lost_pm}</label>
            </div>
            <div>
              <label className="label-2">
                {dictionary.status.out_of_delivery}{" "}
                <FontAwesomeIcon
                  id={"out_of_delivery_" + item.id}
                  className="cursor-pointer"
                  icon={faInfoCircle}
                />{" "}
                <UncontrolledTooltip
                  placement="bottom"
                  target={"out_of_delivery_" + item.id}
                >
                  {dictionary.status.out_of_delivery}{" "}
                  {dictionary.registries.at_customer}
                </UncontrolledTooltip>
              </label>
            </div>
            <div>
              <label className="label-2">
                {dictionary.status.delivered_pm}{" "}
                <FontAwesomeIcon
                  id={"delivered_" + item.id}
                  className="cursor-pointer"
                  icon={faInfoCircle}
                />{" "}
                <UncontrolledTooltip
                  placement="bottom"
                  target={"delivered_" + item.id}
                >
                  {dictionary.status.delivered_pm}{" "}
                  {dictionary.registries.at_customer}
                </UncontrolledTooltip>
              </label>
            </div>
            <div className="bg-color-4 text-center">
              <label className="label-2">{dictionary.words.total}</label>
            </div>
          </div>
        </div>
        <div>
          <div
            className="d-grid grid-column"
            style={{
              gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
            }}
          >
            <div>
              <label className="label-3">{item.soldInfo.lost}</label>
            </div>
            <div>
              <label className="label-3">{item.soldInfo.onDelivery}</label>
            </div>
            <div>
              <label className="label-3">{item.soldInfo.delivered}</label>
            </div>
            <div className="bg-color-4 text-center">
              <label className="label-3">{item.soldInfo.total}</label>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        {action !== "details" && (
          <React.Fragment>
            <ButtonDelete
              className="ms-1"
              onClick={() => {
                openModal(
                  "delete",
                  [item.id],
                  "sm",
                  "",
                  dictionary.products.delete_product
                );
              }}
            >
              {" "}
            </ButtonDelete>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default DigitalListItem;
