import React from "react";
import { Button } from "reactstrap";
import { useIncomingGoodContext } from "./providers/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../AppProvider";

export const Delete = () => {
  const context = useIncomingGoodContext();
  const { dictionary } = useAppContext();

  return (
    <form>
      <div className="row mb-3">
        <div className="col">
          <label>
            {context.incomingGoods?.length > 1
              ? dictionary.messages.ask_to_delete_selected
              : dictionary.messages.ask_to_delete}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            if (context.callback) {
              context.callback();
            }
          }}
        >
          {dictionary.actions.cancel}
        </button>
        <Button
          className="btn btn-danger ms-3"
          onClick={() => context.deleteIncomingGoods()}
          disabled={context.incomingGoodsLoader}
        >
          {context.incomingGoodsLoader ? (
            <FontAwesomeIcon icon={faLoader} spin />
          ) : (
            dictionary.actions.delete
          )}
        </Button>
      </div>
    </form>
  );
};
