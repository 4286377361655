import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { callErrorToast, modalDefault, valueIsEmpty } from "../../../utilities";
import { LocationsService as RegistryLocationsService } from "../../../services/registry/locations";
import { LocationsService as LogisticLocationsService } from "../../../services/logistic/locations";
import { buildLocationLabel } from "../utilities/index";
import { dictionary } from "../../../utilities/dictionary";

const LocationHandlerContext = createContext();

const LocationHandlerProvider = ({
  children,
  id,
  autosave = false,
  callback,
  service = "registry",
}) => {
  let locationsService = new RegistryLocationsService();
  switch (service) {
    case "logistic":
      locationsService = new LogisticLocationsService();
  }

  const [modal, setModal] = useState({
    ...modalDefault,
  });

  const [location, setLocation] = useState({});
  const [locationError, setLocationError] = useState(null);
  const [locationLoader, setLocationLoader] = useState(id ? true : false);

  const prevLocationError = useRef();

  const getLocation = (id) => {
    locationsService
      .get(id)
      .then((res) => {
        setLocation(res.data);
        setLocationLoader(false);
      })
      .catch((err) => {
        setLocationError(err);
        setLocationLoader(false);
      });
  };

  const createEditLocation = (newLocation, save) => {
    if (autosave || save) {
      if (location.id) {
        editLocation(newLocation);
        return;
      }
      createLocation(newLocation);
    }
    setLocation(newLocation);
  };

  const createLocation = (location) => {
    setLocationLoader(true);

    location.label = buildLocationLabel(location);
    locationsService
      .create(location)
      .then((res) => {
        setLocation(res.data);
        if (callback) {
          callback(res.data);
        }
        setLocationLoader(false);
      })
      .catch((err) => {
        setLocationLoader(false);
        setLocationError(err);
      });
  };

  const editLocation = (location) => {
    setLocationLoader(true);
    location.label = buildLocationLabel(location);
    locationsService
      .edit(location)
      .then(() => {
        setLocation(location);
        if (callback) {
          callback(location);
        }
        setLocationLoader(false);
      })
      .catch((err) => {
        setLocationLoader(false);
        setLocationError(err);
      });
  };

  const removeError = (property) => {
    const newLocationError = { ...locationError };
    delete newLocationError.response?.data[property];
    if (!valueIsEmpty(newLocationError)) {
      prevLocationError.current = newLocationError;
      setLocationError(newLocationError);
    }
  };

  useEffect(() => {
    if (
      locationError &&
      JSON.stringify(prevLocationError.current) !==
        JSON.stringify(locationError)
    ) {
      callErrorToast(locationError, dictionary);
    }
  }, [locationError]);

  useEffect(() => {
    if (id) {
      getLocation(id);
      return;
    }
  }, [id]);

  return (
    <LocationHandlerContext.Provider
      value={{
        location,
        locationError,
        locationLoader,

        getLocation,
        createEditLocation,

        modal,
        setModal,
        removeError,
        callback,
        autosave,
      }}
    >
      {children}
    </LocationHandlerContext.Provider>
  );
};

const useLocationHandlerContext = () => {
  return useContext(LocationHandlerContext);
};

export { LocationHandlerProvider, useLocationHandlerContext };
