import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../AppProvider";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import InputBox from "../../Components/common/InputBox";
import { useLocationHandlerContext } from "./providers/Handler";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";

export const LocationHandler = ({ action }) => {
  const { dictionary } = useAppContext();

  const context = useLocationHandlerContext();

  const handleInputChange = (name, value) => {
    if (action !== "details") {
      const newLocation = {
        ...context?.location,
        [name]: value,
      };

      context?.createEditLocation(newLocation);
      context?.removeError(name);
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          {context?.locationLoader ? (
            <div className="row">
              <div className="col text-center">
                <FontAwesomeIcon icon={faLoader} spin size="2x" />
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div
                className="d-grid grid-column mt-2"
                style={{
                  gridTemplateColumns: `4fr 1fr`,
                }}
              >
                <div>
                  <label className="label-2">
                    {dictionary.locations.address}
                  </label>
                  <InputBox
                    name="street"
                    value={context.location?.street || ""}
                    placeholder={action !== "details" ? "Via Roma" : ""}
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={context.locationError?.response?.data?.street}
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary.locations.house_number}
                  </label>
                  <InputBox
                    name="houseNumber"
                    value={context.location?.houseNumber || ""}
                    placeholder={action !== "details" ? "1" : ""}
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={context.locationError?.response?.data?.houseNumber}
                  />
                </div>
              </div>
              <div
                className="d-grid grid-column mt-2"
                style={{
                  gridTemplateColumns: `1fr 1fr 1fr 1fr`,
                }}
              >
                <div>
                  <label className="label-2">{dictionary.locations.unit}</label>
                  <InputBox
                    name="unit"
                    value={context.location?.unit || ""}
                    placeholder={action !== "details" ? "A" : ""}
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={context.locationError?.response?.data?.unit}
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary.locations.postal_code}
                  </label>
                  <InputBox
                    name="postalCode"
                    value={context.location?.postalCode || ""}
                    placeholder={action !== "details" ? "10121" : ""}
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e.target.name, e.target.value);
                    }}
                    disabled={action === "details"}
                    error={context.locationError?.response?.data?.postalCode}
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary.locations.district}
                  </label>
                  <InputBox
                    name="district"
                    value={context.location?.district || ""}
                    placeholder={action !== "details" ? "Centro" : ""}
                    type="text"
                    disabled
                    error={context.locationError?.response?.data?.district}
                  />
                </div>
                <div>
                  <label className="label-2">{dictionary.locations.city}</label>
                  <InputBox
                    name="municipality"
                    value={context.location?.municipality || ""}
                    placeholder={action !== "details" ? "Torino" : ""}
                    type="text"
                    disabled
                    error={context.locationError?.response?.data?.municipality}
                  />
                </div>
              </div>
              <div
                className="d-grid grid-column mt-2"
                style={{
                  gridTemplateColumns: `1fr 1fr 1fr`,
                }}
              >
                <div>
                  <label className="label-2">
                    {dictionary.locations.province}
                  </label>
                  <InputBox
                    name="subRegionName"
                    value={context.location?.subRegionName || ""}
                    placeholder={action !== "details" ? "Torino" : ""}
                    type="text"
                    disabled
                    error={context.locationError?.response?.data?.subRegionName}
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary.locations.region}
                  </label>
                  <InputBox
                    name="regionName"
                    value={context.location?.regionName || ""}
                    placeholder={action !== "details" ? "Piemonte" : ""}
                    type="text"
                    disabled
                    error={context.locationError?.response?.data?.regionName}
                  />
                </div>
                <div>
                  <label className="label-2">
                    {dictionary.locations.country}
                  </label>
                  <InputBox
                    name="countryIsoCode3"
                    value={context.location?.countryIsoCode3 || ""}
                    placeholder={action !== "details" ? "ITA" : ""}
                    type="text"
                    disabled
                    error={
                      context.locationError?.response?.data?.countryIsoCode3
                    }
                  />
                </div>
              </div>
              {!context?.autosave && (
                <div className="d-flex mt-3">
                  <ButtonUtility
                    className="w-50 me-2"
                    onClick={() => {
                      if (context.callback) {
                        context.callback();
                      }
                    }}
                  >
                    {dictionary.actions.cancel}
                  </ButtonUtility>
                  <ButtonConfirm
                    className="w-50"
                    onClick={() => {
                      context?.createEditLocation(context?.location, true);
                    }}
                    disabled={context.locationLoader}
                    loading={context.locationLoader}
                  ></ButtonConfirm>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
