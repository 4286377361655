import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { OrderDetailsService } from "../../../services/logistic/orderDetails";
import { callErrorToast, valueIsEmpty } from "../../../utilities";
import { orderDetailNormalizer } from "../utilities";
import { OrdersService } from "../../../services/logistic/orders";
import { dictionary } from "../../../utilities/dictionary";

const orderDetailsService = new OrderDetailsService();
const ordersService = new OrdersService();

const CreateEditOrderDetailContext = createContext();

const CreateEditOrderDetailProvider = ({
  children,
  id,
  autosave = false,
  callback,
  parentId,
  action,
}) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [orderDetail, setOrderDetail] = useState();
  const [orderDetailError, setOrderDetailError] = useState(null);
  const [orderDetailLoader, setOrderDetailLoader] = useState(false);

  const [article, setArticle] = useState();

  const prevOrderDetailError = useRef();
  const prevArticle = useRef();

  const getOrderDetail = () => {
    setOrderDetailLoader(true);
    orderDetailsService
      .get(id)
      .then((res) => {
        const orderDetail = {
          ...res.data,
          quantity: {
            code: res.data?.quantity || 1,
            name: res.data?.quantity || 1,
          },
        };
        prevArticle.current = orderDetail.article;
        setArticle(orderDetail.article);
        setOrderDetail(orderDetail);
        setOrderDetailLoader(false);
      })
      .catch((err) => {
        setOrderDetailError(err);
      });
  };

  const createEditOrderDetail = (id) => {
    if (!id) {
      createOrderDetail(true);
      return false;
    }
    editOrderDetail(orderDetail, true);
  };

  const createOrderDetail = (save) => {
    setOrderDetailLoader(true);

    const orderDetailNormalized = orderDetailNormalizer(
      { ...orderDetail },
      accessTokenPayload,
      parentId,
      article?.id
    );

    if (article?.uniqueBarcodeItem) {
      const orderDetails = Array.from(
        { length: orderDetailNormalized.quantity },
        (elem) => ({
          ...orderDetailNormalized,
          totalPrice:
            orderDetailNormalized.totalPrice / orderDetailNormalized.quantity,
          totalWeight:
            orderDetailNormalized.totalWeight / orderDetailNormalized.quantity,
          quantity: 1,
        })
      );

      const promises = orderDetails.map((elem) => {
        return orderDetailsService.create(elem);
      });
      Promise.all(promises)
        .then((res) => {
          setOrderDetailLoader(false);
          setOrderDetail({
            ...res.data,
            quantity: {
              code: orderDetailNormalized.quantity || 1,
              name: orderDetailNormalized.quantity || 1,
            },
            vat: 22,
          });
          if (save && callback) {
            callback();
          }
        })
        .catch((err) => {
          setOrderDetailError(err);
          setOrderDetailLoader(false);
        });

      return false;
    }
    orderDetailsService
      .create(orderDetailNormalized)
      .then((res) => {
        setOrderDetail({
          ...res.data,
          quantity: {
            code: res.data?.quantity || 1,
            name: res.data?.quantity || 1,
          },
        });
        setOrderDetailLoader(false);

        if (save && callback) {
          callback();
        }
      })
      .catch((err) => {
        setOrderDetailError(err);
        setOrderDetailLoader(false);
      });
  };

  const editOrderDetail = (orderDetail, save) => {
    if (save || autosave) {
      const orderDetailNormalized = orderDetailNormalizer(
        { ...orderDetail },
        accessTokenPayload,
        parentId,
        article?.id
      );
      orderDetailsService
        .edit(orderDetailNormalized)
        .then((res) => {
          setOrderDetail({ ...orderDetail });
          setOrderDetailLoader(false);
          if (save && callback) {
            callback(true);
          }
        })
        .catch((err) => {
          setOrderDetailError(err);
          setOrderDetailLoader(false);
        });

      return false;
    }

    setOrderDetail({ ...orderDetail });
  };

  const removeError = (property) => {
    const newOrderDetailError = { ...orderDetailError };
    delete newOrderDetailError.response?.data[property];
    if (!valueIsEmpty(newOrderDetailError)) {
      prevOrderDetailError.current = newOrderDetailError;
      setOrderDetailError(newOrderDetailError);
    }
  };

  useEffect(() => {
    const articleIsChanged = prevArticle?.current?.id !== article?.id;
    const newOrderDetail = { ...orderDetail };
    newOrderDetail.unitPrice =
      (articleIsChanged && article?.price
        ? article.price
        : orderDetail?.unitPrice) || null;

    newOrderDetail.discount = null;
    if (newOrderDetail.unitPrice >= 0) {
      newOrderDetail.discount =
        (articleIsChanged && article?.discount
          ? article.discount
          : orderDetail?.discount) || null;
    }
    newOrderDetail.totalPrice = (() => {
      const result =
        (parseFloat(newOrderDetail.unitPrice ?? 0) -
          (newOrderDetail.discount > 0
            ? parseFloat(newOrderDetail.discount ?? 0)
            : 0)) *
        (newOrderDetail.quantity?.code ?? 0);
      return result || null;
    })();

    newOrderDetail.unitWeight = null;
    newOrderDetail.totalWeight = null;
    if (article?.shippingRequired) {
      newOrderDetail.unitWeight =
        (articleIsChanged && article?.weight
          ? article.weight
          : orderDetail?.unitWeight) || null;
      newOrderDetail.totalWeight =
        parseFloat(newOrderDetail.unitWeight ?? 0) *
          newOrderDetail.quantity?.code || null;
    }

    if (JSON.stringify(orderDetail) !== JSON.stringify(newOrderDetail)) {
      editOrderDetail(newOrderDetail);
    }
    prevArticle.current = article;
  }, [
    article,
    orderDetail?.unitPrice,
    orderDetail?.unitWeight,
    orderDetail?.discount,
    orderDetail?.quantity,
  ]);

  useEffect(() => {
    if (
      orderDetailError &&
      JSON.stringify(prevOrderDetailError.current) !==
        JSON.stringify(orderDetailError)
    ) {
      callErrorToast(orderDetailError, dictionary);
    }
  }, [orderDetailError]);

  useEffect(() => {
    if (!id) {
      setOrderDetail({ quantity: { code: 1, name: 1 }, vat: 22 });
    }
  }, []);

  return (
    <CreateEditOrderDetailContext.Provider
      value={{
        id,

        orderDetail,
        orderDetailError,
        orderDetailLoader,
        getOrderDetail,
        createOrderDetail,
        editOrderDetail,
        createEditOrderDetail,
        article,
        setArticle,

        removeError,
        callback,
        autosave,
        action,
      }}
    >
      {children}
    </CreateEditOrderDetailContext.Provider>
  );
};

const useCreateEditOrderDetailContext = () => {
  return useContext(CreateEditOrderDetailContext);
};

export { CreateEditOrderDetailProvider, useCreateEditOrderDetailContext };
