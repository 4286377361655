import React from "react";
import {
  getCompanyBadge,
  // getParcelTypeBadge,
  getShipmentTypeBadge,
  isColorDark,
  stringToColor,
} from "../../../utilities";
import {
  getLocationLevelBadge,
  getParcelTypeBadge,
} from "../../../utilities/badges";
import it from "../../../utilities/dictionaries/it.json";
import en from "../../../utilities/dictionaries/en.json";
import fr from "../../../utilities/dictionaries/fr.json";

const dictionaries = { it, en, fr };
const dictionary =
  dictionaries[localStorage.getItem("language") || navigator.language || "en"];

export const getWarehouseArticleOptionStyle = (option, key, label) => {
  return (
    <React.Fragment>
      <img
        src={option.article?.imageUrl || option.imageUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "40px" }}
      />
      {option.article?.label ||
        option.article?.name ||
        option.label ||
        option.name}
    </React.Fragment>
  );
};

export const getArticleOptionStyle = (option, key, label) => {
  return (
    <React.Fragment>
      <img
        src={option.imageUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "40px" }}
      />
      {option[label] || option.name}
    </React.Fragment>
  );
};

export const getCarrierOptionStyle = (option, key, label) => {
  return (
    <React.Fragment>
      <img
        src={option.logoUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option[label] || option.name}
    </React.Fragment>
  );
};

export const getCarrierFromCarrierIntegrationOptionStyle = (
  option,
  key,
  label
) => {
  return (
    <React.Fragment>
      <img
        src={option.carrierLogo}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option.carrierName}
    </React.Fragment>
  );
};

export const getStoreOptionStyle = (option, key, label) => {
  return (
    <React.Fragment>
      <img
        src={option.logoUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option[label]}
    </React.Fragment>
  );
};

export const getLogisticOptionStyle = (option, key, label) => {
  return (
    <React.Fragment>
      <img
        src={option.logoUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option[label]}
    </React.Fragment>
  );
};

export const getCarrierConfigurationOptionStyle = (option, dictionary) => {
  const bgColor = stringToColor(
    option?.owner?.name || option.ownerName || dictionary.words.inherited
  );
  return (
    <React.Fragment>
      <img
        src={option.carrierLogo || option.carrier?.logoUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option.label || option.name}

      <div
        className={`badge ${
          isColorDark(bgColor) ? "text-white" : "text-dark"
        } ms-2`}
        style={{
          backgroundColor: bgColor,
          whiteSpace: "normal",
        }}
      >
        {option.owner?.name || option.ownerName || dictionary.words.inherited}
      </div>
    </React.Fragment>
  );
};

export const getLogisticIntegrationOptionStyle = (option) => {
  return <React.Fragment>{option.label || option.name}</React.Fragment>;
};

export const getShipmentStatusOptionStyle = (option) => {
  return (
    <span
      style={{ backgroundColor: option.color || "#939393" }}
      className="badge"
    >
      {option.label}
    </span>
  );
};

export const getOrderStatusOptionStyle = (option) => {
  return (
    <span
      style={{ backgroundColor: option.color || "#939393" }}
      className="badge"
    >
      {option.label}
    </span>
  );
};

export const getIncomingGoodOptionStyle = (option) => {
  return (
    <span
      style={{ backgroundColor: option.color || "#939393" }}
      className="badge"
    >
      {option.label}
    </span>
  );
};

export const getParcelTypeOptionStyle = (option) => {
  return (
    <React.Fragment>
      {getParcelTypeBadge(option.value, "1x")} {option.label}
    </React.Fragment>
  );
};

export const getCompanyOptionStyle = (option) => {
  return (
    <React.Fragment>
      <img
        src={option.logoDarkUrl}
        className="me-2"
        style={{ maxHeight: "40px", maxWidth: "50px" }}
      />
      {getCompanyBadge(option.type)}
      {option.label}
      {option.externalReference && (
        <span style={{ fontSize: "10px" }}> ({option.externalReference})</span>
      )}{" "}
    </React.Fragment>
  );
};

export const getShipmentTypeOptionStyle = (option) => {
  return dictionary.contracts[option.value.toLowerCase()];
};

export const getStoreIntegrationOptionStyle = (option, dictionary) => {
  const bgColor = stringToColor(
    option?.owner?.name || dictionary.words.inherited
  );
  return (
    <React.Fragment>
      <img
        src={option.store.logoUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option.name}
      {bgColor && (
        <div
          className={`badge ${
            isColorDark(bgColor) ? "text-white" : "text-dark"
          } ms-2`}
          style={{
            backgroundColor: bgColor,
            whiteSpace: "normal",
          }}
        >
          {option.owner?.name || dictionary.words.inherited}
        </div>
      )}
    </React.Fragment>
  );
};

export const getSourceIntegrationOptionStyle = (option, dictionary) => {
  const bgColor = stringToColor(
    option?.owner?.name || dictionary.words.inherited
  );
  return (
    <React.Fragment>
      <img
        src={option.sourceLogoUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option.name}
      {bgColor && (
        <div
          className={`badge ${
            isColorDark(bgColor) ? "text-white" : "text-dark"
          } ms-2`}
          style={{
            backgroundColor: bgColor,
            whiteSpace: "normal",
          }}
        >
          {option.owner?.name || dictionary.words.inherited}
        </div>
      )}
    </React.Fragment>
  );
};

export const getWarehouseOptionStyle = (option) => {
  return (
    <React.Fragment>
      <img
        src={option.logisticIntegration?.logistic?.logoUrl}
        className="me-2"
        style={{ maxHeight: "50px", maxWidth: "50px" }}
      />
      {option.name}
    </React.Fragment>
  );
};

export const getAwsLocationOptionStyle = (option, locationLevels) => {
  const level = locationLevels.find(
    (l) => l.value === option.place.matchLevelCode
  );
  return (
    <React.Fragment>
      {option.label} {getLocationLevelBadge(level)}
    </React.Fragment>
  );
};
