import React, { useRef, useCallback } from "react";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { useListProps } from "./utilities/list";
import { Modal, ModalBody, UncontrolledTooltip } from "reactstrap";
import { useIndexStockReleaseShipmentsContext } from "./providers/Index";
import { createModalConfig, ModalConfig } from "../../Models/ModalConfig";
import { StockReleaseShipment } from "../../Models/StockReleaseShipment";
// import { HandlerStockReleaseShipmentProvider } from "./providers/Handler";
import { Handler } from "./Handler";
import { useAppContext } from "../../AppProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "../../utilities/fontawesomeIcons";
import ButtonDelete from "../../Components/Buttons/Delete";
import moment from "moment";

const AnyListProvider = ListProvider as any;
const AnyList = List as any;

interface StockReleaseShipmentsProps {
  action: string;
  shippingRequired: boolean;
}

const StockReleaseShipments: React.FC<StockReleaseShipmentsProps> = ({
  action,
}) => {
  const listRef = useRef<{ refresh: () => void } | null>(null);
  const listProps = useListProps();
  const { modalConfig, setModalConfig, parentId } =
    useIndexStockReleaseShipmentsContext();

  const renderItem = useCallback((item: StockReleaseShipment, key: number) => {
    return <ListItem key={key} item={item} action={action} />;
  }, []);

  const closeModal = () => {
    setModalConfig(createModalConfig());
  };

  return (
    <>
      <AnyListProvider
        path={listProps.path}
        filters={listProps.filters}
        parentId={parentId}
      >
        <AnyList
          ref={listRef}
          // title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          renderItem={renderItem}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
        />
      </AnyListProvider>

      {/* <Modal isOpen={modalConfig.isOpen} size={modalConfig.size}>
        <ModalBody className={modalConfig.className || ""}>
          {modalConfig.action === "create" && (
            <HandlerStockReleaseShipmentProvider
              parentId={parentId}
              callback={() => {
                listRef?.current?.refresh();
                closeModal();
              }}
              autosave={false}
              shippingRequired={shippingRequired}
            >
              <Handler />
            </HandlerStockReleaseShipmentProvider>
          )}
          {modalConfig.action === "delete" && (
            <DeleteStockReleaseShipmentProvider
              callback={() => {
                listRef?.current?.refresh();
                closeModal();
              }}
              data={modalConfig.data}
            >
              <Delete />
            </DeleteStockReleaseShipmentProvider>
          )}
        </ModalBody>
      </Modal> */}
    </>
  );
};

export default StockReleaseShipments;

const ListItem: React.FC<{
  item: StockReleaseShipment;
  action: string;
}> = React.memo(({ item }) => {
  const { dictionary } = useAppContext();

  return (
    <div className="list-item px-2">
      <div className="d-grid grid-column">
        <div>
          <label className="label-2">{dictionary.dates.creation_date}:</label>
          <label className="label-3">
            {item.creationDate &&
              moment(item.creationDate).format("DD/MM/YYYY HH:mm:ss")}
          </label>
        </div>
        <div>
          <label className="label-2">{dictionary.users.creation_user}:</label>
          <label className="label-3">{item.creationUser}</label>
        </div>
        <div>
          <label className="label-2">{dictionary.words.method_sm}:</label>
          <label className="label-3">
            {dictionary.stock_release_methods[
              item.stockReleaseCode.toLowerCase()
            ] || item.stockRelease?.name}
          </label>
        </div>
        {item.stockRelease.deliveryDateRequired && (
          <div>
            <label className="label-2">{dictionary.dates.delivery_date}:</label>
            <label className="label-3">
              {item.newDeliveryDate &&
                moment(item.newDeliveryDate).format("DD/MM/YYYY")}
            </label>
          </div>
        )}
        {item.stockRelease.addressRequired && (
          <div>
            <label className="label-2">{dictionary.registries.name}:</label>
            <label className="label-3">{item.newDelivery?.name}</label>
          </div>
        )}
        {item.stockRelease.addressRequired && (
          <div>
            <label className="label-2">{dictionary.locations.address}:</label>
            <label className="label-3">
              {item.newDelivery?.location?.label}
            </label>
          </div>
        )}
        {item.stockRelease.noteRequired && (
          <div>
            <label className="label-2">{dictionary.words.note}:</label>
            <label className="label-3">{item.note || "-"}</label>
          </div>
        )}
      </div>
    </div>
  );
});
