import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import moment from "moment";

export const useListProps = () => {
  const { dictionary } = useAppContext();
  return {
    path: "/shipment-service/cod-collections",
    sorting: [
      {
        orderBy: "desc",
        column: "collectionDate",
        icon: faArrowDownShortWide,
        label: dictionary.dates.collection_date,
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "collectionDate",
        icon: faArrowUpShortWide,
        label: dictionary.dates.collection_date,
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary.cod_collections.my_cod_collections,
    omnisearch: {
      enabled: true,
      placeholder: dictionary.references.references,
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary.dates.creation_date,
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    filters: {
      name: "cod_collections",
      preserve: false,
      list: [
        {
          fieldName: "collectionDate",
          label: dictionary.dates.collection_date,
          type: "datepicker",
          primary: true,
          col: 3,
        },
      ],
    },
  };
};
