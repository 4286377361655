import moment from "moment";
import { useTypes } from "../../utilities/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGavel } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../AppProvider";
import { modalSettingsDefault } from "../../utilities";
import { HandlerStockReleaseShipmentProvider } from "../StockReleaseShipments/providers/Handler";
import { Handler as HandlerStockReleaseShipment } from "../StockReleaseShipments/Handler";
import { Modal, ModalBody } from "reactstrap";
import React, { useState } from "react";
import { isDisabledStockRelease } from "../Shipments/utilities/list";
import { faEye } from "../../utilities/fontawesomeIcons";

const Event = ({ data, formatDate, shipment, isLastEvent }) => {
  const { shipmentStatus } = useTypes();
  const { dictionary } = useAppContext();

  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  const status = shipmentStatus.find(
    (s) => s.value === (data.status?.code || data.statusCode)
  );
  return (
    <React.Fragment>
      <div className="row my-3">
        <div className="col-4">
          <div className="event-status-container">
            <span
              className="event-status-stamp"
              style={{
                backgroundColor: status?.color,
              }}
            ></span>
            <label className="event-status-name">{status?.label}</label>
            {data.stock &&
              isLastEvent &&
              shipment &&
              !isDisabledStockRelease([shipment]) && (
                <FontAwesomeIcon
                  title={dictionary.shipments.stock_release}
                  className="p-1 cursor-pointer"
                  icon={faGavel}
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      open: !modalSettings.open,
                      action: "createStockRelease",
                    });
                  }}
                />
              )}
            {data.isStockRelease && shipment && (
              <FontAwesomeIcon
                title={dictionary.shipments.stock_release}
                className="p-1 cursor-pointer"
                icon={faEye}
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    open: !modalSettings.open,
                    action: "stockRelease",
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="col-2 text-end p-0">
          <span className="event-time">
            {moment(data.eventDate).format(formatDate)}
          </span>
        </div>
        <div className="col-6">
          <label className="event-note">{data.note}</label>
          <label className="event-location">{data.location}</label>
        </div>
      </div>

      <Modal isOpen={modalSettings.open} className="modal-content modal-lg">
        <ModalBody>
          {modalSettings.action === "stockRelease" && (
            <HandlerStockReleaseShipmentProvider
              id={data.id}
              event={{
                ...data,
                carrierCode:
                  shipment.contractService.carrierService.carrier.code,
                carrierReference: shipment.carrierReference,
              }}
              callback={() => {
                setModalSettings({
                  ...modalSettingsDefault,
                  open: !modalSettings.open,
                });
              }}
              action="details"
            >
              <HandlerStockReleaseShipment />
            </HandlerStockReleaseShipmentProvider>
          )}
          {modalSettings.action === "createStockRelease" && (
            <HandlerStockReleaseShipmentProvider
              event={{
                ...data,
                internalReference: shipment.internalReference,
                carrierReference: shipment.carrierReference,
              }}
              callback={() => {
                window.location.reload();
              }}
              action="create"
            >
              <HandlerStockReleaseShipment />
            </HandlerStockReleaseShipmentProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Event;
