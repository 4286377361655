import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

import { ContractServicesService } from "../../services/contract-service/contractServices";
import CompaniesService from "../../services/registry-service/companies";

const contractServicesService = new ContractServicesService();
const companiesService = new CompaniesService();

const contractServices = [
  { id: "1", name: "Servizio di Test TNT" },
  { id: "2", name: "Servizio di Test GLS" },
  { id: "3", name: "Servizio di Test DHL" },
];

const contractServicesInCompany = ["2"];

const ActiveServices = ({ companyId, modalToogle, refreshTable }) => {
  const [activeContractService, setActiveContractService] = useState([]);
  const [activeContractServiceInCompany, setActiveContractServiceInCompany] =
    useState(contractServicesInCompany);
  const [selectedFilter, setSelectedFilter] = useState(
    contractServicesInCompany
  );

  const getActiveContractServices = () => {
    const filters = {
      search: {
        "contract.type": "ACTIVE",
      },
    };
    contractServicesService
      .getAll(filters)
      .then((response) => {
        console.log("contractServicesService", response);
        let fakeResponse = response.content || contractServices;
        const activeContractService = fakeResponse.map((elem) => {
          return { value: elem.id, label: elem.name };
        });
        setActiveContractService(activeContractService);
      })
      .catch(() => {
        toast.error(
          "Si è verificato un errore nel caricamento servizi attivi",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const onFilterChange = (selectedFilter) => {
    setSelectedFilter(selectedFilter);
  };

  useEffect(() => {
    // console.log(newActiveContractService);
    // console.log(activeContractServiceSelected);
    // setSelectedFilter(activeContractServiceSelected);
    // setActiveContractService(newActiveContractService);
  }, [activeContractService]);

  useEffect(() => {
    console.log(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    getActiveContractServices();
  }, []);

  return (
    <React.Fragment>
      <form>
        <DualListBox
          canFilter
          filterCallback={(Optgroup, filterInput) => {
            if (filterInput === "") {
              return true;
            }
            return new RegExp(filterInput, "i").test(Optgroup.label);
          }}
          filterPlaceholder="Search..."
          options={activeContractService}
          selected={selectedFilter}
          onChange={onFilterChange}
          icons={{
            moveLeft: <span key="key1"> remove</span>,
            moveAllLeft: [<span key="key2">remove all</span>],
            moveRight: <span key="key3">add</span>,
            moveAllRight: [<span key="key4">add all</span>],
            moveDown: <span key="key5"></span>,
            moveUp: <span key="key6"></span>,
            moveTop: <span key="key7"></span>,
            moveBottom: <span key="key8"></span>,
          }}
        />
      </form>
    </React.Fragment>
  );
};

export default ActiveServices;
