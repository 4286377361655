import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faCalendar,
  faCheck,
  faChevronDoubleRight,
  faDownload,
  faDownToBracket,
  faFileCsv,
  faLoader,
  faMagnifyingGlass,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIndexOrdersContext } from "../providers/Index";
import {
  getAllCompanies,
  getAllWarehouses,
} from "../../../utilities/asyncSelectCallAPI";
import {
  getCompanyOptionStyle,
  getOrderStatusOptionStyle,
  getWarehouseOptionStyle,
} from "../../../Components/common/AsyncSelect/utilities";
import { useTypes } from "../../../utilities/types";
import moment from "moment";

const statusValidForDelete = ["DRAFT", "CONFIRMED"];
const statusValidForConfirm = ["DRAFT"];
const statusValidForProcess = ["CONFIRMED"];
const statusValidForDraft = ["CONFIRMED"];
const statusValidForReturnSender = ["SHIPPED"];

export const isDisabledDelete = (selectedRows) => {
  const { accessTokenPayload } = useAppContext();

  return !selectedRows.every(
    (item) =>
      statusValidForDelete.includes(item?.lastStatus) &&
      item.ownerId === accessTokenPayload.owner
  );
};

export const isDisabledConfirm = (selectedRows) =>
  !selectedRows.every(
    (item) =>
      statusValidForConfirm.includes(item?.lastStatus) &&
      item.delivery?.id &&
      item.orderDetails > 0 &&
      item.totalPrice >= 0
  );

export const isDisabledDraft = (selectedRows) => {
  const { accessTokenPayload } = useAppContext();

  return !selectedRows.every(
    (item) =>
      statusValidForDraft.includes(item?.lastStatus) &&
      item.ownerId === accessTokenPayload.owner
  );
};

export const isDisabledReturnDelivered = (selectedRows) => {
  const { accessTokenPayload } = useAppContext();

  return !selectedRows.every(
    (item) =>
      statusValidForReturnSender.includes(item?.lastStatus) &&
      item.shipments?.[0]?.lastStatusCode === "RET_SENDER" &&
      item.warehouse?.logisticIntegration?.ownerId === accessTokenPayload.owner
  );
};

export const isDisabledProcess = (selectedRows) => {
  const { accessTokenPayload } = useAppContext();
  return !selectedRows.every(
    (item) =>
      statusValidForProcess.includes(item?.lastStatus) &&
      item.warehouse?.logisticIntegration?.ownerId === accessTokenPayload.owner
  );
};

export const isDisabledDeleteShipments = (selectedRows) => {
  const { accessTokenPayload } = useAppContext();
  return !selectedRows.every(
    (item) =>
      item.shipments?.length > 0 &&
      !item.shipments[0].lastStatusCode &&
      item.warehouse?.logisticIntegration?.ownerId === accessTokenPayload.owner
  );
};

export const useListProps = (listRef) => {
  const { accessTokenPayload } = useAppContext();

  const navigate = useNavigate();

  const { dictionary } = useAppContext();

  const context = useIndexOrdersContext();

  const types = useTypes();

  return {
    path: "/logistic-service/orders",
    create: {
      enabled: true,
      action: () => {
        navigate("/orders/create");
      },
      title: `${dictionary.words.new_sm} ${dictionary.orders.order}`,
      icon: faPlus,
    },
    sorting: [
      {
        orderBy: "desc",
        column: "creationDate",
        icon: faArrowDownShortWide,
        label: dictionary.dates.creation_date,
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "creationDate",
        icon: faArrowUpShortWide,
        label: dictionary.dates.creation_date,
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary.orders.my_orders,
    omnisearch: {
      enabled: true,
      placeholder: dictionary.references.references,
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary.dates.creation_date,
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    actions: [
      {
        onClick: (filters) => {
          if (filters?.["warehouse.id"]?.[0]) {
            context?.processNext(
              filters["warehouse.id"][0]?.id,
              listRef.current.refresh
            );
          }
        },
        label: `${
          dictionary.actions.process
        } ${dictionary.words.next_sm.toLowerCase()} ${dictionary.orders.order.toLowerCase()}`,
        icon: context?.orderLoader ? (
          <FontAwesomeIcon spin icon={faLoader} />
        ) : (
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        ),
        className: "button-1 button-1-transparent",
        hidden:
          accessTokenPayload.owner !== "7f8625b9-973b-4c66-80af-346b49cde2e7", //allabox senders
        loading: context?.orderLoader,
        title: dictionary.actions.select,
      },
    ],
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.orders.export_for_activation,
            action: "exportForActivation",
            data: selectedRows.map((item) => item.id),
          }),
        label: dictionary.orders.export_for_activation,
        icon: <FontAwesomeIcon icon={faDownload} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.orders.export_for_invoicing,
            action: "exportForInvoicing",
            data: selectedRows.map((item) => item.id),
          }),
        label: dictionary.orders.export_for_invoicing,
        icon: <FontAwesomeIcon icon={faDownload} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.actions.confirm_selected_pm,
            action: "confirm",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledConfirm,
        label: dictionary.actions.confirm_selected_pm,
        icon: <FontAwesomeIcon icon={faCheck} />,
        className: "button-1 button-1-dark",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary.actions.confirm_deletion,
            action: "delete",
            data: selectedRows.map((item) => item),
          }),

        isDisabled: isDisabledDelete,
        label: dictionary.actions.delete_selected,
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
    filters: {
      name: "orders",
      preserve: false,
      list: [
        {
          fieldName: "ownerId",
          label: dictionary.registries.sender,
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCompanies(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          isMulti: true,
          optionStyle: getCompanyOptionStyle,
          primary: true,
          col: 3,
        },
        {
          fieldName: "delivery.name",
          label: dictionary.registries.receiver,
          type: "text",
          preciseSearch: false,
          primary: true,
          col: 3,
        },
        {
          fieldName: "warehouse.id",
          label: dictionary.registries.warehouse,
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllWarehouses(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          isMulti: false,
          optionStyle: getWarehouseOptionStyle,
          primary: true,
          col: 3,
        },
        {
          fieldName: "lastStatus",
          label: dictionary.words.status,
          type: "select",
          options: types.orderStatus,
          isMulti: true,
          optionStyle: getOrderStatusOptionStyle,
          primary: true,
          col: 3,
        },
        {
          fieldName: "type",
          label: dictionary.words.typology,
          type: "select",
          options: types.orderTypes,
          isMulti: true,
          primary: false,
          col: 3,
        },
        {
          fieldName: "creationUser",
          label: dictionary.users.creation_user,
          type: "text",
          preciseSearch: false,
          placeholder: "user@allabox.it",
          primary: false,
          col: 3,
        },
        {
          fieldName: "cashOnDeliveryValue",
          label: dictionary.options.cash_on_delivery,
          type: "select",
          options: types.confirmationOptions,
          primary: false,
          col: 2,
        },
        {
          fieldName: "insuranceValue",
          label: dictionary.options.insurance,
          type: "select",
          options: types.confirmationOptions,
          primary: false,
          col: 2,
        },
      ],
    },
  };
};
