import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { callErrorToast } from "../../../utilities"; // Function to handle and display errors
import { WarehousesService } from "../../../services/logistic/warehouses";

// Create an instance of the WarehousesService to handle API calls
const warehousesService = new WarehousesService();

/**
 * Props for the DeleteWarehouseProvider component.
 */
interface DeleteWarehouseProviderProps {
  children: ReactNode; // React children components that will be wrapped by the provider
  data?: string[]; // List of warehouse IDs to be deleted
  callback?: () => void; // Optional function to execute after deletion
}

/**
 * Context value that will be shared with consumers.
 */
interface DeleteWarehouseContextValue {
  loader: boolean; // Indicates whether a deletion request is in progress
  deleteWarehouses: () => void; // Function to trigger the deletion process
  data: string[]; // List of warehouse IDs to be deleted
  callback?: () => void; // Optional function to execute after deletion
}

// Create the context with an initial value of undefined
const DeleteWarehouseContext = createContext<
  DeleteWarehouseContextValue | undefined
>(undefined);

/**
 * Provider component that manages the deletion process of warehouses.
 * It wraps the children components and provides context data for deletion handling.
 */
const DeleteWarehouseProvider: React.FC<DeleteWarehouseProviderProps> = ({
  children,
  data = [],
  callback,
}) => {
  // State to store errors that may occur during deletion
  const [error, setError] = useState<unknown>(null);

  // State to track whether a deletion operation is currently in progress
  const [loader, setLoader] = useState<boolean>(false);

  /**
   * Function to delete all warehouses in the `data` list.
   * Calls the WarehousesService to delete each warehouse and updates the loader state.
   */
  const deleteWarehouses = (): void => {
    setLoader(true); // Set loading state to true before making API calls

    // Create an array of promises by calling the delete method for each warehouse ID
    const promises = data.map((id) => warehousesService.delete(id));

    // Execute all delete requests concurrently
    Promise.all(promises)
      .then(() => {
        setLoader(false); // Set loading state to false once deletion is complete

        // If a callback function is provided, execute it
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setLoader(false); // Ensure loading state is reset even if an error occurs
        setError(err); // Store the error for error handling
      });
  };

  /**
   * Effect to display an error toast when an error occurs.
   * Triggers when the `error` state changes.
   */
  useEffect(() => {
    if (error) {
      callErrorToast(error); // Display the error using a toast notification
    }
  }, [error]);

  // Provide the loader state and delete function to consumers
  return (
    <DeleteWarehouseContext.Provider
      value={{ loader, deleteWarehouses, data, callback }}
    >
      {children}
    </DeleteWarehouseContext.Provider>
  );
};

/**
 * Custom hook to access the DeleteWarehouseContext.
 * Ensures that the context is used only within a DeleteWarehouseProvider.
 */
const useDeleteWarehouseContext = (): DeleteWarehouseContextValue => {
  const context = useContext(DeleteWarehouseContext);

  // Throw an error if the hook is used outside the provider
  if (!context) {
    throw new Error(
      "useDeleteWarehouseContext must be used within a DeleteWarehouseProvider"
    );
  }

  return context;
};

// Export the provider and custom hook for use in other components
export { DeleteWarehouseProvider, useDeleteWarehouseContext };
