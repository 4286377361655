import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { createModalConfig, ModalConfig } from "../../../Models/ModalConfig";

interface IndexStockReleaseShipmentsProviderProps {
  children: ReactNode; // React children components that will be wrapped by the provider
  callback?: () => void; // Optional function to execute after an action
  parentId: string; // The parent entity ID related to the warehouse articles
}

interface IndexStockReleaseShipmentsContextValue {
  modalConfig: ModalConfig; // Configuration settings for modals
  setModalConfig: Dispatch<SetStateAction<ModalConfig>>; // Function to update modal configuration
  callback?: () => void; // Optional function to execute after an action
  parentId: string; // Parent entity ID
}

// Create the context with an initial value of undefined
const IndexStockReleaseShipmentsContext = createContext<
  IndexStockReleaseShipmentsContextValue | undefined
>(undefined);

// Provider component that manages modal configuration and selected warehouse articles
const IndexStockReleaseShipmentsProvider: React.FC<
  IndexStockReleaseShipmentsProviderProps
> = ({ children, callback, parentId }) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>(
    createModalConfig()
  ); // State for managing modal configuration

  return (
    <IndexStockReleaseShipmentsContext.Provider
      value={{
        modalConfig,
        setModalConfig,
        callback,
        parentId,
      }}
    >
      {children}
    </IndexStockReleaseShipmentsContext.Provider>
  );
};

/**
 * Custom hook to access the IndexStockReleaseShipmentsContext.
 * Ensures that the context is used only within an IndexStockReleaseShipmentsProvider.
 */
const useIndexStockReleaseShipmentsContext =
  (): IndexStockReleaseShipmentsContextValue => {
    const context = useContext(IndexStockReleaseShipmentsContext);
    if (!context) {
      throw new Error(
        "useIndexStockReleaseShipmentsContext must be used within an IndexStockReleaseShipmentsProvider"
      );
    }
    return context;
  };

// Export the provider and custom hook for use in other components
export {
  IndexStockReleaseShipmentsProvider,
  useIndexStockReleaseShipmentsContext,
};
