import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatepickerProps {
  name: string;
  selectedDate: Date | null;
  onChange: (name: string, value: Date | null) => void;
  placeholder?: string;
  dateFormat?: string;
  isDisabled?: boolean;
}

const Datepicker: React.FC<DatepickerProps> = ({
  name,
  selectedDate,
  onChange,
  placeholder = "Select",
  dateFormat = "dd/MM/yyyy",
  isDisabled = false,
}) => {
  return (
    <div className="w-100">
      <DatePicker
        name={name}
        selected={selectedDate}
        onChange={(value: Date | null) => {
          if (!isDisabled) {
            onChange(name, value);
          }
        }}
        dateFormat={dateFormat}
        className="form-control"
        wrapperClassName="w-100"
        placeholderText={placeholder}
        disabled={isDisabled}
      />
    </div>
  );
};

export default Datepicker;
