import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ZonesService } from "../../../services/registry/zones";
import { LocationsService } from "../../../services/registry/locations";
import { callErrorToast, valueIsEmpty } from "../../../utilities";
import { searchPlaceForText } from "../../../utilities/AWSLocationService";
import Papa from "papaparse";
import { locationNormalizer } from "../utilities";
import { useAppContext } from "../../../AppProvider";

const zonesService = new ZonesService();
const locationsService = new LocationsService();

const UploadZoneContext = createContext();

const UploadZoneProvider = ({ children, autosave }) => {
  const { dictionary } = useAppContext();
  const [zone, setZone] = useState({ name: "Senza nome", pub: false });
  const [zoneError, setZoneError] = useState(null);
  const [zoneLoader, setZoneLoader] = useState(false);

  const prevZoneError = useRef();

  const createZone = () => {
    setZoneLoader(true);
    zonesService
      .create(zone)
      .then((res) => {
        setZone(res.data);
        setZoneLoader(false);
      })
      .catch((err) => {
        setZoneLoader(false);
        callErrorToast(err, dictionary);
      });
  };

  const editZone = (zone) => {
    setZoneLoader(true);
    zonesService
      .edit(zone)
      .then((res) => {
        setZone(zone);
        setZoneLoader(false);
      })
      .catch((err) => {
        setZoneLoader(false);
        callErrorToast(err, dictionary);
      });
  };

  const uploadFile = (file) => {
    setZoneLoader(true);
    if (file) {
      Papa.parse(file, {
        complete: function (results) {
          const promises = [];
          results.data.forEach((elem) => {
            const text = `${elem.label || ""}}`;
            promises.push(
              searchPlaceForText(text)
                .then((res) => {
                  return { ...res, origin: elem };
                })
                .catch((err) => err)
            );
          });
          Promise.all(promises).then((response) => {
            const included = [];
            const excluded = [];
            const errors = [];
            response.forEach((elem) => {
              if (elem.origin.type === "IN") {
                if (
                  elem.Results.length === 1 &&
                  (elem.Results[0].Place.Categories[0] === "MunicipalityType" ||
                    elem.Results[0].Place.Categories[0] === "PostalCodeType" ||
                    elem.Results[0].Place.Categories[0] === "NeighborhoodType")
                ) {
                  included.push(elem.Results[0]);
                  return false;
                }
              } else if (elem.origin.type === "OUT") {
                if (
                  elem.Results.length === 1 &&
                  (elem.Results[0].Place.Categories[0] === "MunicipalityType" ||
                    elem.Results[0].Place.Categories[0] === "PostalCodeType" ||
                    elem.Results[0].Place.Categories[0] === "NeighborhoodType")
                ) {
                  excluded.push(elem.Results[0]);
                  return false;
                }
              }
              errors.push(elem.origin);
            });

            const createLocationIncludedPromises = included.map((elem) => {
              return createLocation(elem, true);
            });

            const createLocationExcludedPromises = excluded.map((elem) => {
              return createLocation(elem, false);
            });

            Promise.all([
              ...createLocationIncludedPromises,
              ...createLocationExcludedPromises,
            ]).then((res) => {
              setZone({
                ...zone,
                included: included,
                excluded: excluded,
                errors: errors,
              });
              setZoneLoader(false);
            });
          });
        },
        header: true,
      });
    }
  };

  const createLocation = (location, included) => {
    return new Promise((resolve, reject) => {
      const locationNormalized = locationNormalizer(location);
      locationsService
        .create(locationNormalized)
        .then((resLocation) => {
          zonesService
            .createLocationInZone(zone.id, {
              included,
              locationId: resLocation.data.id,
            })
            .then((resLocationInZone) => {
              resolve([resLocation, resLocationInZone]);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  const removeError = (property) => {
    const newZoneError = { ...zoneError };
    delete newZoneError.response?.data[property];
    if (!valueIsEmpty(newZoneError)) {
      prevZoneError.current = newZoneError;
      setZoneError(newZoneError);
    }
  };

  useEffect(() => {
    if (
      zoneError &&
      JSON.stringify(prevZoneError.current) !== JSON.stringify(zoneError)
    ) {
      callErrorToast(zoneError);
    }
  }, [zoneError]);

  return (
    <UploadZoneContext.Provider
      value={{
        zone,
        zoneError,
        zoneLoader,

        createZone,
        editZone,
        uploadFile,

        removeError,
        autosave,
      }}
    >
      {children}
    </UploadZoneContext.Provider>
  );
};

const useUploadZoneContext = () => {
  return useContext(UploadZoneContext);
};

export { UploadZoneProvider, useUploadZoneContext };
