import { CSSObject } from "@emotion/react";
import { GroupBase, StylesConfig } from "react-select";

export const useStyle = <T,>(
  error?: string
): StylesConfig<T, boolean, GroupBase<T>> => {
  return {
    container: (provided: CSSObject) => ({
      ...provided,
      display: "inline-grid",
      width: "100%",
    }),
    control: (provided: CSSObject, state) => ({
      ...provided,
      width: "100%",
      minWidth: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      outline: "none",
      boxShadow: state.isFocused ? "none" : undefined,
      backgroundColor: state.isDisabled ? "#F9FAFB" : "#fff",
      color: state.isDisabled ? "#000" : undefined,
      borderColor: state.isFocused
        ? "#999"
        : error
        ? "#FF0000"
        : provided.borderColor,
      "&:hover": {
        borderColor: error ? "#FF0000" : "#999",
      },
    }),
    singleValue: (provided: CSSObject, state) => ({
      ...provided,
      color: state.isDisabled ? "#000" : undefined,
    }),
    valueContainer: (provided: CSSObject) => ({
      ...provided,
      display: "flex",
      flexWrap: "nowrap",
      overflow: "hidden",
    }),
    menu: (provided: CSSObject) => ({
      ...provided,
      zIndex: 2,
    }),
    option: (provided: CSSObject, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#f5f6a8"
        : state.isSelected
        ? "#d9dfe6"
        : "#fff",
      color: state.isSelected ? "#000" : "#333",
      cursor: "pointer",
      ":active": {
        backgroundColor: "#f5f7fa",
      },
    }),
  };
};
