import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, FormFeedback } from "reactstrap";
import Select from "react-select";

import CompaniesService from "../../services/registry-service/companies";
import { UsersService } from "../../services/user/users";
import { customOptionCompany, userDefault } from "./util";
import { callErrorToast, timezones, valueIsEmpty } from "../../utilities";
import InputBox from "../../Components/common/InputBox";
import { Loader } from "../../Components/common/Loader";
import { useAppContext } from "../../AppProvider";

const usersService = new UsersService();
const companiesService = new CompaniesService();

const CreateEdit = ({ id, ownerId, callback }) => {
  const [user, setUser] = useState({ ...userDefault });
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { dictionary } = useAppContext();

  const getUser = () => {
    usersService
      .getUser(id)
      .then((response) => {
        response = response.data;
        timezones.forEach((elem) => {
          const timezone = elem.options.find(
            (option) => option.value === response.timeZone
          );
          if (timezone) {
            response.timeZone = timezone;
          }
        });

        response.roles =
          response.roles?.map((elem) => {
            return { code: elem.code, name: elem.code };
          }) || [];

        setUser(response);

        if (response.roles && response.roles.length > 0) {
          getAvailableRoles(response.ownerId, response);
          return false;
        }

        setLoading(false);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getAvailableRoles = (ownerId, oldUser) => {
    const newUser = oldUser || { ...user };
    companiesService
      .getAvailableRoles(ownerId)
      .then((response) => {
        response = response.map((elem) => {
          return { code: elem, name: elem };
        });
        setRoles(response);
        setUser({
          ...newUser,
          roles: [response[response.length - 1]],
          ownerId: ownerId,
        });
        setLoading(false);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const getAllCompanies = () => {
    companiesService
      .getAll()
      .then((response) => {
        const options = response.content.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setCompanies(options);
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const createUser = (user) => {
    usersService
      .create(user)
      .then((response) => {
        if (callback) {
          callback();
        }
        toast.success(dictionary.messages.save_successful, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const editUser = (user) => {
    usersService
      .edit(user)
      .then((response) => {
        if (callback) {
          callback();
        }
        toast.success(dictionary.messages.save_successful, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        callErrorToast(err, dictionary);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });

    if (errors) {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleTimezonesSelectChange = (option) => {
    setUser({ ...user, timeZone: option });
  };

  const handleCompaniesSelectChange = (option) => {
    const newUser = { ...user, owner: option, roles: [] };
    if (errors) {
      const newErrors = { ...errors };
      delete newErrors["ownerId"];
      setErrors(newErrors);
    }

    getAvailableRoles(option.id, newUser);
  };

  const handleRolesSelectChange = (options, event) => {
    setUser({ ...user, roles: options });

    if (errors) {
      const newErrors = { ...errors };
      delete newErrors["roles"];
      setErrors(newErrors);
    }
  };

  const userValidation = () => {
    let errors = {};
    var { name, surname, email, ownerId, roles, password, confirmPassword } =
      user;

    if (valueIsEmpty(name))
      errors["name"] = dictionary.messages.mandatory_field;
    if (valueIsEmpty(surname))
      errors["surname"] = dictionary.messages.mandatory_field;
    if (valueIsEmpty(ownerId))
      errors["ownerId"] = dictionary.messages.mandatory_field;
    if (valueIsEmpty(email))
      errors["email"] = dictionary.messages.mandatory_field;
    if (valueIsEmpty(roles))
      errors["roles"] = dictionary.messages.mandatory_field;

    if (!id) {
      if (valueIsEmpty(password))
        errors["password"] = dictionary.messages.mandatory_field;
      if (valueIsEmpty(confirmPassword))
        errors["confirmPassword"] = dictionary.messages.mandatory_field;
      if (
        !valueIsEmpty(password) &&
        !valueIsEmpty(confirmPassword) &&
        password !== confirmPassword
      )
        errors["confirmPassword"] = dictionary.messages.the_values_do_not_match;
    }

    return errors;
  };

  const userNormalizer = (user) => {
    const userNormalized = { ...user };
    userNormalized.timeZone = userNormalized.timeZone?.value;
    userNormalized.roles = userNormalized.roles.map((elem) => {
      return { code: elem.code };
    });
    return userNormalized;
  };

  const saveUser = () => {
    const errors = userValidation();
    if (valueIsEmpty(errors)) {
      const userNormalized = userNormalizer(user);
      if (id) {
        editUser(userNormalized);
        return false;
      }
      createUser(userNormalized);
    }

    setErrors(errors);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getUser(id);
    }
    getAllCompanies();
  }, []);

  return (
    <React.Fragment>
      {loading ? <Loader show={loading} /> : ""}

      <div className="row">
        <div className="col">
          <label className="form-label">{dictionary.registries.name}:</label>
          <InputBox
            name="name"
            value={user.name || ""}
            placeholder={"Mario"}
            type="text"
            onChange={handleInputChange}
            error={errors && errors["name"]}
          />
        </div>
        <div className="col">
          <label className="form-label">{dictionary.users.surname}:</label>
          <InputBox
            name="surname"
            value={user.surname}
            placeholder={"Rossi"}
            type="text"
            onChange={handleInputChange}
            error={errors && errors["surname"]}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <label className="form-label">{dictionary.registries.email}:</label>
          <InputBox
            name="email"
            value={user.email || ""}
            placeholder={"mario.rossi@email.it"}
            type="text"
            onChange={handleInputChange}
            error={errors && errors["email"]}
            disabled={id}
          />
        </div>
        <div className="col">
          <label className="form-label">{dictionary.locations.timezone}:</label>
          <Select
            name="timeZone"
            onChange={handleTimezonesSelectChange}
            defaultMenuIsOpen={false}
            className="select"
            options={timezones}
            value={user.timeZone}
            maxMenuHeight={200}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <label className="form-label">{dictionary.registries.company}:</label>
          <Select
            onChange={handleCompaniesSelectChange}
            formatOptionLabel={customOptionCompany}
            defaultMenuIsOpen={false}
            options={companies}
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => option["name"]}
            value={user.owner}
            className={`select ${
              errors && errors["ownerId"] ? "is-invalid" : ""
            }`}
          />
          {errors["ownerId"] && (
            <FormFeedback type="invalid" className="d-block">
              {errors["ownerId"]}
            </FormFeedback>
          )}
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="col">
          <label className="form-label">Ruolo:</label>
          <Select
            onChange={handleRolesSelectChange}
            defaultMenuIsOpen={false}
            options={roles}
            getOptionValue={(option) => option["code"]}
            getOptionLabel={(option) => option["name"]}
            isMulti
            value={user.roles}
            className={`select ${
              errors && errors["roles"] ? "is-invalid" : ""
            }`}
            isDisabled={!user.owner?.id}
          />
          {errors["roles"] && (
            <FormFeedback type="invalid" className="d-block">
              {errors["roles"]}
            </FormFeedback>
          )}
        </div>
      </div> */}

      {!id && (
        <>
          <div className="row mt-3">
            <div className="col">
              <label className="form-label">{dictionary.users.password}:</label>
              <InputBox
                name="password"
                value={user.password || ""}
                type="password"
                onChange={handleInputChange}
                error={errors && errors["password"]}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className="form-label">
                {dictionary.users.confirm_password}:
              </label>
              <InputBox
                name="confirmPassword"
                value={user.confirmPassword || ""}
                type="password"
                onChange={handleInputChange}
                error={errors && errors["confirmPassword"]}
              />
            </div>
          </div>
        </>
      )}
      <div className="row mt-3 align-items-center">
        <div className="col">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                if (callback) {
                  callback();
                }
              }}
            >
              {dictionary.actions.cancel}
            </button>
            <Button type="button" className="btn ms-3" onClick={saveUser}>
              {dictionary.actions.save}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateEdit;
