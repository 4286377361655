import { API_BASE_URL } from "../../constants/index"; // Base URL for the API
import HttpInterface from "../../helpers/HttpInterface"; // Helper class for making HTTP requests
import { defaultFetchParam, FetchParam } from "../../Models/FetchParam"; // Fetch parameters model and default values
import {
  CreateWarehouseArticle,
  UpdateWarehouseArticle,
} from "../../Models/WarehouseArticle"; // Models for creating and updating warehouse articles
import { buildAPIUrl } from "../../utilities/api"; // Utility function to build the API URL with query parameters

export class WarehouseArticlesService {
  private httpProxy: HttpInterface; // Instance of the HttpInterface to make HTTP requests

  // Constructor initializes the httpProxy with the HttpInterface
  constructor() {
    this.httpProxy = new HttpInterface(); // Creates an instance of the HttpInterface to handle requests
  }

  /**
   * Fetches all warehouse articles from the logistic service API.
   *
   * @param params The query parameters for the API request. Defaults to `defaultFetchParam` if not provided.
   * @returns A promise that resolves with the API response containing the list of warehouse articles.
   */
  all(params: FetchParam = defaultFetchParam): Promise<any> {
    const requestOptions = {
      method: "GET", // HTTP method for the request
    };

    // Builds the complete API URL using the base URL, endpoint, and parameters
    return this.httpProxy.fetch(
      buildAPIUrl(`${API_BASE_URL}/logistic-service/warehouse-article`, params),
      requestOptions
    );
  }

  /**
   * Fetches a single warehouse article by its ID from the API.
   *
   * @param id The unique identifier of the warehouse article to fetch.
   * @returns A promise that resolves with the API response containing the warehouse article details.
   */
  get(id: string): Promise<any> {
    const requestOptions = {
      method: "GET", // HTTP method for the request
    };

    // Constructs the URL for fetching a specific warehouse article by its ID
    return this.httpProxy.fetch(
      `${API_BASE_URL}/logistic-service/warehouse-article/${id}`,
      requestOptions
    );
  }

  /**
   * Creates a new warehouse article in the logistic service API.
   *
   * @param warehouseArticle The details of the warehouse article to create.
   * @returns A promise that resolves with the API response confirming the creation of the article.
   */
  create(warehouseArticle: CreateWarehouseArticle): Promise<any> {
    const requestOptions = {
      method: "POST", // HTTP method for the request
      body: JSON.stringify(warehouseArticle), // Sends the warehouse article data as JSON in the request body
    };

    // Sends a POST request to create the new warehouse article
    return this.httpProxy.fetch(
      `${API_BASE_URL}/logistic-service/warehouse-article`,
      requestOptions
    );
  }

  /**
   * Updates an existing warehouse article in the logistic service API.
   *
   * @param warehouseArticle The updated details of the warehouse article.
   * @returns A promise that resolves with the API response confirming the update.
   */
  edit(warehouseArticle: UpdateWarehouseArticle): Promise<any> {
    const requestOptions = {
      method: "PUT", // HTTP method for the request
      body: JSON.stringify(warehouseArticle), // Sends the updated warehouse article data as JSON in the request body
    };

    // Sends a PUT request to update the specific warehouse article by its ID
    return this.httpProxy.fetch(
      `${API_BASE_URL}/logistic-service/warehouse-article/${warehouseArticle.id}`,
      requestOptions
    );
  }

  /**
   * Deletes a warehouse article from the logistic service API.
   *
   * @param id The unique identifier of the warehouse article to delete.
   * @returns A promise that resolves with the API response confirming the deletion.
   */
  delete(id: string): Promise<any> {
    const requestOptions = {
      method: "DELETE", // HTTP method for the request
    };

    // Sends a DELETE request to remove the specific warehouse article by its ID
    return this.httpProxy.fetch(
      `${API_BASE_URL}/logistic-service/warehouse-article/${id}`,
      requestOptions
    );
  }
}
