import API from "../helpers/API";
import HereAPI from "../helpers/HereAPINew";

// Definizione delle classi API e HereAPI con i loro tipi
const api = new API();
const hereApi = new HereAPI();

export const API_BASE_URL = `https://api.${api.getURL()}`;
export const AWS_LOCATION_KEY = hereApi.HERE_API_KEY;
export const AWS_LOCATION_PLACE_INDEX = hereApi.PLACE_INDEX;
