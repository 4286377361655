import { AddressBook } from "./AddressBook";
import { StockReleaseByCarrier } from "./StockReleaseByCarrier";
import { getDictionary } from "../utilities/utilities";
import { ShipmentEvent } from "./ShipmentEvent";

const dictionary = getDictionary();

// Interface for GET (same as you have)
export interface StockReleaseShipment {
  id?: string;
  creationDate: string;
  creationUser: string;
  lastModifiedDate?: Date;
  lastModifiedUser?: string;
  shipmentInternalReference: string;
  stockReleaseCode: string;
  stockRelease: StockReleaseByCarrier;
  note?: string;
  newDeliveryDate?: Date;
  newDelivery?: AddressBook;
}

/**
 * Error response structure from API calls.
 */
export interface ErrorResponse {
  response: {
    data: Partial<StockReleaseShipment>;
  };
}

// Interface for POST (to create a new StockRelease)
export interface CreateStockReleaseShipment {
  shipmentInternalReference: string;
  newDelivery?: AddressBook;
  newDeliveryDate?: string;
  stockReleaseCode: string;
  note?: string;
}

// Convert function to create
export const convertStockReleaseShipmentToCreate = (
  stockRelease: StockReleaseShipment
): CreateStockReleaseShipment => {
  return {
    shipmentInternalReference: stockRelease.shipmentInternalReference,
    stockReleaseCode: stockRelease.stockRelease.code,
    newDelivery: stockRelease.newDelivery && {
      ...stockRelease.newDelivery,
      priv: false,
      gdo: false,
      ztl: false,
    },
    newDeliveryDate: stockRelease.newDeliveryDate?.toISOString(),
    note: stockRelease.note,
  };
};

export const converStockReleaseShipmentInShipmentEvent = (
  stockReleaseShipment: StockReleaseShipment,
  shipmentId: string
): ShipmentEvent => {
  return {
    ...({
      id: stockReleaseShipment.id,
      eventDate: stockReleaseShipment.creationDate,
      utc: false,
      stock: true,
      shipmentId,
      statusCode: "STOCK_RELEASE",
      note: `${stockReleaseShipment.stockRelease.name}`,
      isStockRelease: true,
    } as ShipmentEvent),
  };
};

export const validate = (
  stockRelease: StockReleaseShipment
): Record<string, string> | null => {
  const errors: Record<string, string> = {};
  if (!stockRelease.shipmentInternalReference) {
    errors.shipmentInternalReference = dictionary.messages.mandatory_field;
  }

  if (!stockRelease.stockRelease?.code) {
    errors.stockReleaseCode = dictionary.messages.mandatory_field;
    return errors;
  }

  if (
    stockRelease.stockRelease.deliveryDateRequired &&
    !stockRelease.newDeliveryDate
  ) {
    errors.stockReleaseCode = dictionary.messages.mandatory_field;
    return errors;
  }

  if (stockRelease.stockRelease.addressRequired) {
    if (!stockRelease.newDelivery?.location?.postalCode) {
      errors.location = dictionary.messages.mandatory_field;
    }
    if (!stockRelease.newDelivery?.name) {
      errors["newDelivery.name"] = dictionary.messages.mandatory_field;
    }
  }

  if (stockRelease.stockRelease.noteRequired && !stockRelease.note) {
    errors.note = dictionary.messages.mandatory_field;
  }

  if (Object.entries(errors).length === 0) {
    return null;
  }

  return errors;
};
