export interface ModalConfig {
  isOpen: boolean;
  size: "sm" | "md" | "lg" | "xl";
  className?: string;
  action?: string;
  data?: any[];
  title?: string;
}

const defaultModalConfig: ModalConfig = {
  isOpen: false,
  size: "md",
  data: [],
};

export function createModalConfig(
  overrides: Partial<ModalConfig> = {}
): ModalConfig {
  return { ...defaultModalConfig, ...overrides };
}
