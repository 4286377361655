import { Article } from "./Article";
import { Warehouse } from "./Warehouse";

// Interface for GET (same as you have)
export interface WarehouseArticle {
  id: string;
  creationDate?: Date;
  creationUser?: string;
  lastModifiedDate?: Date;
  lastModifiedUser?: string;
  warehouse: Warehouse;
  article: Article;
  articleId?: string;
  blockedQuantity: number;
  effectiveStockQuantity: number;
  logisticReference: string;
  warehouseInfo: {
    expectedInfo: {
      committed: number;
      blocked: number;
      available: number;
      total: number;
    };
    effectiveInfo: {
      blocked: number;
      available: number;
      lastEffectiveDate: Date;
    };
  };
  soldInfo: {
    lost: number;
    onDelivery: number;
    delivered: number;
    total: number;
  };
  incomingInfo: {
    processing: number;
    completed: number;
    total: number;
  };
  outgoingInfo: {
    newInfo: { processing: number; completed: number; total: number };
    usedInfo: { processing: number; completed: number; total: number };
    total: number;
  };
}

// Interface for POST (to create a new WarehouseArticle)
export interface CreateWarehouseArticle {
  articleId: string;
  warehouseId: string;
  blockedQuantity: number;
  effectiveStockQuantity: number;
}

// Interface for PUT (to update an existing WarehouseArticle)
export interface UpdateWarehouseArticle {
  id: string;
  articleId: string;
  warehouseId: string;
}

// Convert function to create
export const convertWarehouseArticleToCreate = (
  warehouseArticle: WarehouseArticle,
  parentId: string
): CreateWarehouseArticle => {
  return {
    articleId: warehouseArticle?.article?.id,
    warehouseId: parentId,
    blockedQuantity: 0,
    effectiveStockQuantity: 0,
  };
};

// Convert function to update
export const convertWarehouseArticleToUpdate = (
  warehouseArticle: WarehouseArticle
): UpdateWarehouseArticle => {
  return {
    id: warehouseArticle.id,
    articleId: warehouseArticle.article.id,
    warehouseId: warehouseArticle.warehouse.id,
  };
};
