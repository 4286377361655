import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { callErrorToast } from "../../../utilities/index.js";
import { ArticlesService } from "../../../services/logistic/articles.ts";

const articlesService = new ArticlesService();

interface DeleteArticleProviderProps {
  children: ReactNode;
  data?: string[];
  callback?: () => void;
}

interface DeleteArticleContextValue {
  loader: boolean;
  deleteArticles: () => void;
  data: string[];
  callback?: () => void;
}

const DeleteArticleContext = createContext<
  DeleteArticleContextValue | undefined
>(undefined);

const DeleteArticleProvider: React.FC<DeleteArticleProviderProps> = ({
  children,
  data = [],
  callback,
}) => {
  const [error, setError] = useState<unknown>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const deleteArticles = (): void => {
    setLoader(true);
    const promises = data.map((id) => articlesService.delete(id));
    Promise.all(promises)
      .then(() => {
        setLoader(false);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
      });
  };

  useEffect(() => {
    if (error) {
      callErrorToast(error);
    }
  }, [error]);

  return (
    <DeleteArticleContext.Provider
      value={{ loader, deleteArticles, data, callback }}
    >
      {children}
    </DeleteArticleContext.Provider>
  );
};

const useDeleteArticleContext = (): DeleteArticleContextValue => {
  const context = useContext(DeleteArticleContext);
  if (!context) {
    throw new Error(
      "useDeleteArticleContext must be used within a DeleteArticleProvider"
    );
  }
  return context;
};

export { DeleteArticleProvider, useDeleteArticleContext };
