import { API_BASE_URL } from "../../constants/index"; // Base URL for the API
import HttpInterface from "../../helpers/HttpInterface"; // Helper class for making HTTP requests
import { defaultFetchParam, FetchParam } from "../../Models/FetchParam"; // Fetch parameters model and default values
import { buildAPIUrl } from "../../utilities/api"; // Utility function to build the API URL with query parameters

export class StockReleasesService {
  private httpProxy: HttpInterface; // Instance of the HttpInterface to make HTTP requests

  constructor() {
    this.httpProxy = new HttpInterface(); // Creates an instance of the HttpInterface to handle requests
  }

  all(params: FetchParam = defaultFetchParam): Promise<any> {
    const requestOptions = {
      method: "GET", // HTTP method for the request
    };

    // Builds the complete API URL using the base URL, endpoint, and parameters
    return this.httpProxy.fetch(
      buildAPIUrl(`${API_BASE_URL}/registry-service/stock-releases`, params),
      requestOptions
    );
  }

  get(code: string): Promise<any> {
    const requestOptions = {
      method: "GET", // HTTP method for the request
    };

    return this.httpProxy.fetch(
      `${API_BASE_URL}/registry-service/stock-releases/${code}`,
      requestOptions
    );
  }
}
