import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

import { ArticlesService } from "../../../services/logistic/articles";
import {
  defaultArticleImage,
  defaultArticleVariant,
  normalizer as articleNormalizer,
} from "../utilities";
import { callErrorToast } from "../../../utilities";
import { dictionary } from "../../../utilities/dictionary";

export const IndexArticleVariantsContext = createContext();

const articlesService = new ArticlesService();

const IndexArticleVariantsProvider = ({ children, autosave, callback }) => {
  const { id } = useParams();
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [articleVariants, setArticleVariants] = useState([]);
  const [articleVariantsError, setArticleVariantsError] = useState(null);
  const [articleVariantsLoader, setArticleVariantsLoader] = useState(true);

  const getArticleVariants = () => {
    articlesService
      .all({
        search: [
          {
            selector: "parentId",
            value: id,
            preciseSearch: true,
          },
        ],
      })
      .then((res) => {
        setArticleVariants(res.data?.content || []);
        setArticleVariantsLoader(false);
      })
      .catch((err) => {
        setArticleVariantsError(err);
      });
  };

  // const createArticleVariant = () => {
  //   articlesService
  //     .create({ ...defaultArticleVariant, articleId: article.id })
  //     .then((res) => {
  //       setArticleVariants([...articleVariants, { ...res.data }]);
  //     })
  //     .catch((err) => {
  //       setArticleVariantsError(err);
  //     });
  // };

  // const editArticleVariant = (articleVariant) => {
  //   articlesService
  //     .edit({ ...articleVariant, articleId: article.id })
  //     .then((res) => {
  //       setArticleVariants([
  //         ...articleVariants.map((elem) => {
  //           if (elem.id === articleVariant.id) {
  //             return articleVariant;
  //           }
  //           return elem;
  //         }),
  //       ]);
  //     })
  //     .catch((err) => {
  //       setArticleVariantsError(err);
  //     });
  // };

  // const deleteArticleVariant = (id) => {
  //   articlesService
  //     .delete(id)
  //     .then((res) => {
  //       setArticleVariants([...articleVariants.filter((elem) => elem.id !== id)]);
  //     })
  //     .catch((err) => {
  //       setArticleVariantsError(err);
  //     });
  // };

  useEffect(() => {
    if (articleVariantsError) {
      callErrorToast(articleVariantsError, dictionary);
    }
  }, [articleVariantsError]);

  return (
    <IndexArticleVariantsContext.Provider
      value={{
        articleVariants,
        articleVariantsError,
        articleVariantsLoader,
        getArticleVariants,
        // createArticleVariant,
        // editArticleVariant,
        // deleteArticleVariant,

        callback,
        autosave,
      }}
    >
      {children}
    </IndexArticleVariantsContext.Provider>
  );
};

const useIndexArticleVariantsContext = () => {
  return useContext(IndexArticleVariantsContext);
};

export { IndexArticleVariantsProvider, useIndexArticleVariantsContext };
