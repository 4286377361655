import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from "react";
import { OrderDetailsService } from "../../../services/logistic/orderDetails.ts";
import { OrderDetail } from "../../../Models/OrderDetail.ts";
import { useIndexOrderDetailsContext } from "./Index.jsx";
import { useAppContext } from "../../../AppProvider.jsx";
import { callSuccessToast } from "../../../utilities/index.js";

const orderDetailsService = new OrderDetailsService();

interface BarcodeHandlerProviderProps {
  children: ReactNode;
  item: OrderDetail;
}

interface BarcodeHandlerContextValue {
  loader: boolean;
  error: ErrorResponse | null;
  editBarcode: (id: string, barcode: string) => void;
  deleteBarcode: (id: string) => void;
  data: Partial<OrderDetail>;
  inputRef: React.RefObject<HTMLInputElement | null>; //
}

/**
 * Error response structure from API calls.
 */
interface ErrorResponse {
  response?: {
    data: Partial<OrderDetail>;
  };
}

const BarcodeHandlerContext = createContext<
  BarcodeHandlerContextValue | undefined
>(undefined);

const BarcodeHandlerProvider: React.FC<BarcodeHandlerProviderProps> = ({
  children,
  item,
}) => {
  const { dictionary } = useAppContext();
  const { callback } = useIndexOrderDetailsContext();

  const [data, setData] = useState<Partial<OrderDetail>>(item);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const deleteRef = useRef<boolean>(false);

  const editBarcode = (id: string, barcode: string): void => {
    orderDetailsService
      .editBarcode(id, barcode)
      .then((res) => {
        inputRef.current?.setAttribute("data-is-valid", "true");
        setData({ ...data, barcode });
        setError(null);

        const allInputs = Array.from(
          document.querySelectorAll<HTMLInputElement>('input[name="barcode"]')
        );
        const allInputsAreCompleted = allInputs.every(
          (input) =>
            input.value.trim() && !input.classList.contains("is-invalid")
        );

        if (allInputsAreCompleted) {
          callSuccessToast(
            {
              status: 200,
              message: dictionary.products.all_products_are_completed,
            },
            dictionary
          );
          callback();
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  const deleteBarcode = (id: string): void => {
    orderDetailsService
      .deleteBarcode(id)
      .then((res) => {
        inputRef.current?.setAttribute("data-is-valid", "false");
        deleteRef.current = true;
        setData((prevData) => {
          const newData = { ...prevData };
          delete newData.barcode;
          return newData;
        });
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (!data.barcode && deleteRef.current) {
      inputRef.current?.focus();
      deleteRef.current = false;
    }
  }, [data.barcode]);

  return (
    <BarcodeHandlerContext.Provider
      value={{
        loader,
        error,
        editBarcode,
        deleteBarcode,
        data,
        inputRef,
      }}
    >
      {children}
    </BarcodeHandlerContext.Provider>
  );
};

const useBarcodeHandlerContext = (): BarcodeHandlerContextValue => {
  const context = useContext(BarcodeHandlerContext);
  if (!context) {
    throw new Error(
      "useBarcodeHandlerContext must be used within a BarcodeHandlerProvider"
    );
  }
  return context;
};

export { BarcodeHandlerProvider, useBarcodeHandlerContext };
