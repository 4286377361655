import React from "react";
import { useHandlerContext } from "./providers/Handler";
import { useAppContext } from "../../AppProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFieldError } from "../../utilities/utilities";
import { getStockReleasesByCarrier } from "../../Components/Selects/AsyncSelect/api";
import {
  faLoader,
  faCity,
  faUser,
  faPhoneAlt,
} from "../../utilities/fontawesomeIcons";
import {
  StockReleaseShipment,
  validate,
} from "../../Models/StockReleaseShipment";
import { StockReleaseByCarrier } from "../../Models/StockReleaseByCarrier";
import SyncSelect from "../../Components/Selects/SyncSelect/SyncSelect";
import Datepicker from "../../Components/DatePickers/DatePicker/DatePicker";
import InputBoxIcon from "../../Components/Inputs/InputIcon/InputIcon";
import SearchLocality from "../../Components/Selects/SearchLocality/SearchLocality";
import InputBox from "../../Components/Inputs/Input/Input";
import ButtonConfirm from "../../Components/Buttons/Confirm";
import ButtonUtility from "../../Components/Buttons/Utility";
import moment from "moment";

export const Handler = () => {
  const { dictionary } = useAppContext();
  const { data, error, loader, action, event, edit, onSubmit, callback } =
    useHandlerContext();

  const handleChange = (
    name: string,
    value: string | number | object | null,
    key: string
  ) => {
    edit(
      {
        ...data,
        [name]: value,
      } as StockReleaseShipment,
      key as keyof StockReleaseShipment,
      false
    );
  };

  return (
    <React.Fragment>
      <div className="row align-items-center mb-2">
        <div className="col">
          <h3 className="text-center">{dictionary.shipments.stock_release}</h3>
        </div>
      </div>
      {loader ? (
        <div className="text-center mt-2">
          <FontAwesomeIcon icon={faLoader} spin size="2x" />
        </div>
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <label className="label-2">
                {dictionary.references.carrier_reference}
              </label>
              <label className="label-3">{event?.carrierReference}</label>
            </div>
            {action === "details" && (
              <>
                <div className="col">
                  <label className="label-2">
                    {dictionary.users.creation_user}
                  </label>
                  <label className="label-3">{data?.creationUser}</label>
                </div>
                <div className="col">
                  <label className="label-2">
                    {dictionary.dates.creation_date}
                  </label>
                  <label className="label-3">
                    {moment(data.creationDate).format("DD/MM/YYYY HH:mm:ss")}
                  </label>
                </div>
              </>
            )}
          </div>
          {action !== "details" && event?.note && (
            <div className="row mt-2">
              <div className="col">
                <label className="label-2">{dictionary.words.motivation}</label>
                <label className="label-3">{event?.note}</label>
              </div>
            </div>
          )}
          <div className="row mt-2">
            <div className="col">
              <label className="label-2">{dictionary.words.mode} *</label>
              <SyncSelect
                name="stockRelease"
                optionValue="code"
                optionLabel="name"
                value={data.stockRelease || null}
                onChange={(
                  name: string,
                  value: StockReleaseByCarrier | StockReleaseByCarrier[] | null
                ) => {
                  if (action !== "details") {
                    handleChange(name, value, "stockReleaseCode");
                  }
                }}
                loadOptions={() =>
                  getStockReleasesByCarrier(event?.carrierCode as string)
                }
                isClearable={false}
                error={getFieldError(error, "stockReleaseCode")}
                placeholder={dictionary.actions.select}
                isDisabled={action === "details"}
              />
            </div>
          </div>
          <div className="row mt-2">
            {data.stockRelease?.deliveryDateRequired && (
              <div className="col">
                <label className="label-2">
                  {dictionary.dates.delivery_date} *
                </label>
                <Datepicker
                  name="newDeliveryDate"
                  selectedDate={data.newDeliveryDate || null}
                  onChange={(name: string, value: Date | null) =>
                    handleChange(name, value, "newDeliveryDate")
                  }
                  isDisabled={action === "details"}
                />
              </div>
            )}
            {data.stockRelease?.noteRequired && (
              <div className="col">
                <label className="label-2">{dictionary.words.note} *</label>
                <InputBoxIcon
                  name={"note"}
                  onChange={({ target }) =>
                    handleChange(target.name, target.value, "note")
                  }
                  value={data.note}
                  disabled={action === "details"}
                />
              </div>
            )}
          </div>
          {data.stockRelease?.addressRequired && (
            <>
              <h3 className="m-0 mt-2">
                {dictionary.words.new_sm} {dictionary.registries.receiver}
              </h3>
              <div className="row mt-2">
                <div className="col">
                  <label className="label-2">
                    {dictionary.registries.company_name} *
                  </label>
                  <InputBoxIcon
                    name="name"
                    onChange={({ target }) => {
                      const newDelivery = {
                        ...(data.newDelivery || {}),
                        [target.name]: target.value,
                      };
                      handleChange("newDelivery", newDelivery, "name");
                    }}
                    value={data.newDelivery?.name}
                    icon={faCity}
                    disabled={action === "details"}
                  />
                </div>
                <div className="col">
                  <label className="label-2">
                    {dictionary.registries.name} {dictionary.words.and}{" "}
                    {dictionary.users.surname}{" "}
                  </label>
                  <InputBoxIcon
                    name="contactName"
                    onChange={({ target }) => {
                      const newDelivery = {
                        ...(data.newDelivery || {}),
                        [target.name]: target.value,
                      };
                      handleChange("newDelivery", newDelivery, "contactName");
                    }}
                    value={data.newDelivery?.contactName}
                    icon={faUser}
                    disabled={action === "details"}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  {action !== "details" ? (
                    <>
                      <label className="label-2">
                        {dictionary.locations.address} *
                      </label>
                      <SearchLocality
                        name="location"
                        onChange={(name, option) => {
                          const newDelivery = {
                            ...(data.newDelivery || {}),
                            location: {
                              ...option.place,
                              id: data?.newDelivery?.location?.id,
                            },
                          };
                          handleChange("newDelivery", newDelivery, "location");
                        }}
                        value={data?.newDelivery?.location}
                        placeholder="Via Roma, 10, 10121 Torino TO, Italia"
                      />
                    </>
                  ) : (
                    <>
                      <label className="label-2">
                        {dictionary.locations.address}
                      </label>
                      <InputBox
                        name="location"
                        disabled
                        value={data.newDelivery?.location?.label}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label className="label-2">
                    {dictionary.registries.phone}
                  </label>
                  <InputBoxIcon
                    name="phone"
                    onChange={({ target }) => {
                      const newDelivery = {
                        ...(data.newDelivery || {}),
                        [target.name]: target.value,
                      };
                      handleChange("newDelivery", newDelivery, "phone");
                    }}
                    value={data.newDelivery?.phone}
                    icon={faPhoneAlt}
                    disabled={action === "details"}
                  />
                </div>
              </div>
            </>
          )}
          {action !== "details" && (
            <span className="label-5 text-end">
              * {dictionary.messages.mandatory_fields}
            </span>
          )}
          <div className="d-flex mt-2">
            <ButtonUtility
              className="w-50 me-2"
              onClick={() => {
                if (callback) {
                  callback();
                }
              }}
            >
              {dictionary.actions.cancel}
            </ButtonUtility>
            {action !== "details" && (
              <ButtonConfirm
                className="w-50"
                onClick={() => {
                  if (!validate(data as StockReleaseShipment)) {
                    onSubmit(data.id);
                  }
                }}
                disabled={loader || !!validate(data as StockReleaseShipment)}
                loading={loader}
              ></ButtonConfirm>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
