import {
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { useIndexArticlesContext } from "../providers/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export const isDisabledDelete = (selectedRows) => {
  const { accessTokenPayload } = useAppContext();

  return !selectedRows.every(
    (item) => item.ownerId === accessTokenPayload.owner
  );
};

export const useListProps = () => {
  const navigate = useNavigate();

  const { dictionary } = useAppContext();

  const context = useIndexArticlesContext();

  return {
    path: `/logistic-service/articles`,
    banner: {
      enabled: true,
    },
    pagination: { enabled: true },
    title: dictionary.products.products,
    create: {
      enabled: true,
      action: () => {
        navigate("/articles/create");
      },
      title: `${
        dictionary.actions.add
      } ${dictionary.products.product.toLowerCase()}`,
      icon: faPlus,
    },
    selectRowsEnabled: { enabled: true },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModalConfig({
            isOpen: true,
            title: dictionary.actions.delete_selected,
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),
        label: dictionary.actions.delete_selected,
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
        isDisabled: isDisabledDelete,
      },
    ],
    filters: {
      name: "articles",
      preserve: false,
      list: [
        {
          fieldName: "name",
          label: dictionary.registries.name,
          type: "text",
          primary: true,
          col: 3,
        },
      ],
    },
  };
};
