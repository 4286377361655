import React from "react";
import AsyncSelect from "../../Selects/AsyncSelect/AsyncSelect"; // Import AsyncSelect for dynamic search
import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper"; // Helper for AWS Location Service API Key
import {
  AWS_LOCATION_KEY, // Your AWS Location API Key
  AWS_LOCATION_PLACE_INDEX, // The place index for location search
} from "../../../utilities/constants"; // Constants for AWS integration
import { LocationClient } from "@aws-sdk/client-location"; // AWS SDK client for Location Services
import { SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location"; // Command for searching places
import { normalizer } from "./utilities"; // Function to normalize AWS results
import { useTypes } from "../../../utilities/types"; // Custom hook to fetch location levels
import { useAppContext } from "../../../AppProvider"; // Access global app context (e.g., dictionary)
import { useLocationHandlerContext } from "../../../pages/Locations/providers/Handler"; // Access location handler context

// Interface to define expected props for the component
interface SearchLocalityProps {
  name?: string; // Optional name of the input field
  onChange?: (selector: string, option: any) => void; // Callback to handle value changes
  placeholder?: string; // Placeholder text for the search input
  value?: any; // Selected value to be displayed in the input
  error?: string;
}

// Initialize the AWS Location Service client (with API key) outside of the component
let client: LocationClient;

const initializeAuthHelper = async () => {
  const authHelper = await withAPIKey(AWS_LOCATION_KEY || ""); // Initialize AWS Location API with the key
  client = new LocationClient({
    region: "eu-west-1", // Set AWS region
    ...authHelper.getClientConfig(), // Get client config from the helper
  });
};

// Initialize the client when the component loads
initializeAuthHelper();

// Main component to handle search for locality (location search)
const SearchLocality: React.FC<SearchLocalityProps> = ({
  name = "searchLocality", // Default input name
  onChange, // Optional onChange callback
  placeholder = "", // Default placeholder text
  value, // Selected value
  error = "",
}) => {
  const { locationLevels } = useTypes(); // Get location levels (could be used for further filtering)
  const { dictionary } = useAppContext(); // Access app dictionary (for translations)
  const context = useLocationHandlerContext(); // Access location context

  // Function to handle the change in selection
  const handleChange = (selector: string, option: any) => {
    if (onChange) {
      onChange(selector, option); // Call the onChange callback if provided
    }
    if (context) {
      const newLocation = { ...context.location, ...option.place }; // Merge selected place with existing context
      context.createEditLocation(newLocation, true); // Update the location in the context
    }
  };

  // Function to fetch data from AWS Location Service
  const fetch = async (text: string) => {
    const data = { content: [] }; // Default empty data array
    try {
      if (text) {
        const command = new SearchPlaceIndexForTextCommand({
          Text: text, // Text to search
          IndexName: AWS_LOCATION_PLACE_INDEX, // Index name for location search
          Language: "it", // Language code (Italian in this case)
        });
        const response = await client.send(command); // Send the search command to AWS
        data.content = normalizer(response?.Results); // Normalize the response results
        return { data }; // Return the data in expected format
      }
      return { data }; // If no text, return empty data
    } catch (error) {
      console.error("AWS Location Fetch Error:", error); // Log any fetch errors
      return { data }; // Return empty data on error
    }
  };

  return (
    <AsyncSelect
      name={name} // Name for the input field
      placeholder={placeholder || dictionary.contacts.search_a_new_address} // Use dictionary for placeholder if available
      onSearch={handleChange} // Pass the handleChange function to handle value change
      loadOptions={fetch} // Pass fetch function to load options
      isClearable={false} // Disable clearing the selected value
      searchOnOpen={false} // Disable search when the menu opens (optional)
      value={value} // Set the value of the input field
      noOptionsMessage={dictionary.contacts.type_to_enter_new_address} // Message when no options are available
      error={error}
    />
  );
};

export default SearchLocality; // Export the component for use in other parts of the app
