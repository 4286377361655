import React, { createContext, useContext, useEffect, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";
import { callErrorToast } from "../../../utilities";
import { dictionary } from "../../../utilities/dictionary";

const ConfirmOrderContext = createContext();

const ordersService = new OrdersService();

const ConfirmOrdersProvider = ({ children, data, callback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);

  const confirmOrders = () => {
    setOrdersLoader(true);
    const promises = orders.map((elem) => {
      return ordersService.confirm(elem);
    });
    Promise.all(promises)
      .then((response) => {
        setOrdersLoader(false);
        if (callback) {
          callback(response);
        }
      })
      .catch((err) => {
        setOrdersError(err);
      });
  };

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError, dictionary);
    }
  }, [ordersError]);

  return (
    <ConfirmOrderContext.Provider
      value={{
        orders,
        ordersError,
        ordersLoader,
        confirmOrders,

        callback,
      }}
    >
      {children}
    </ConfirmOrderContext.Provider>
  );
};

const useConfirmOrderContext = () => {
  return useContext(ConfirmOrderContext);
};

export { ConfirmOrdersProvider, useConfirmOrderContext };
