import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@awesome.me/kit-c1b85ff10f/icons/classic/solid";
import {
  faCircleUser,
  faHouse,
  faCircleDollar,
  faInbox,
  faGear,
  faArrowRightFromBracket,
  faUserCircle,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import ProfileIcon from "../../assets/images/profile-icon.svg";
import { useOidc } from "@axa-fr/react-oidc";
import { callErrorToast, getCompanyBadge } from "../../utilities";
import { useAppContext } from "../../AppProvider";

const ProfileDropdown = ({ company }) => {
  const { logout, renewTokens } = useOidc();
  const { dictionary, accessTokenPayload } = useAppContext();
  const [isDropdown, setIsDropdown] = useState(false);

  const toggleDropdown = () => {
    setIsDropdown(!isDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isDropdown}
        toggle={toggleDropdown}
        className="nav-item nav-item-dropdown-lg dropdown cursor-pointer"
      >
        <DropdownToggle
          tag="button"
          type="button"
          role="button"
          className="btn border-0 p-1 me-2"
          data-bs-toggle="dropdown"
        >
          <div className="d-none d-lg-flex align-items-center">
            <img src={ProfileIcon} className="mx-1" />
            <div className="text-start mx-2">
              <label className="fw-bold d-block cursor-pointer">
                {`${accessTokenPayload.name || ""} ${
                  accessTokenPayload.surname || ""
                }`}
              </label>
              <label className="fw-light d-block cursor-pointer">
                {accessTokenPayload.sub}
              </label>
            </div>
            <FontAwesomeIcon className="mx-2" icon={faCaretDown} />
          </div>
          <FontAwesomeIcon
            size="2x"
            className="d-block d-lg-none mx-2 color-white cursor-pointer"
            icon={faUserCircle}
          />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            href={process.env.PUBLIC_URL + "/company-profile"}
            className="dropdown-item"
            disabled
            style={{ backgroundColor: "#f0f0f0" }}
          >
            <FontAwesomeIcon
              className="cursor-pointer me-2"
              size="lg"
              icon={faHouse}
            />
            <span className="me-2 text-dark">{company?.name}</span>
            {getCompanyBadge(company?.type)}
          </DropdownItem>
          <DropdownItem
            href={process.env.PUBLIC_URL + "/user-profile"}
            className="dropdown-item"
          >
            <FontAwesomeIcon
              className="cursor-pointer me-2"
              size="lg"
              icon={faCircleUser}
            />
            {dictionary.users.my_profile}
          </DropdownItem>
          <DropdownItem href="#" className="dropdown-item" disabled>
            <FontAwesomeIcon
              className="cursor-pointer me-2"
              size="lg"
              icon={faCircleDollar}
            />
            My subscription
          </DropdownItem>
          <DropdownItem href="#" className="dropdown-item" disabled>
            <FontAwesomeIcon
              className="cursor-pointer me-2"
              size="lg"
              icon={faInbox}
            />
            My inbox
            {/* <span className="badge bg-primary rounded-pill ms-auto">26</span> */}
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="#" className="dropdown-item" disabled>
            <FontAwesomeIcon
              className="cursor-pointer me-2"
              size="lg"
              icon={faGear}
            />
            Account settings
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              const promise = renewTokens();
              promise
                .then((res) => {
                  logout(null);
                })
                .catch((err) => {
                  callErrorToast(err, dictionary);
                });
            }}
            className="dropdown-item text-danger"
          >
            <FontAwesomeIcon
              className="cursor-pointer me-2"
              size="lg"
              icon={faArrowRightFromBracket}
            />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
